import React, { useRef, useEffect, useContext, useState } from 'react';
import RedactorX from '../assets/redactorx-1-4-3/redactorx';
import '../assets/redactorx-1-4-3/redactorx.css';
import '../assets/redactorx-1-4-3/custom_redactorx.css';
import '../assets/redactorx-1-4-3/plugins/alignment/alignment';
import '../assets/redactorx-1-4-3/plugins/beyondgrammar/beyondgrammar';
import '../assets/redactorx-1-4-3/plugins/blockcode/blockcode';
import '../assets/redactorx-1-4-3/plugins/clips/clips';
import '../assets/redactorx-1-4-3/plugins/counter/counter';
import '../assets/redactorx-1-4-3/plugins/definedlinks/definedlinks';
import '../assets/redactorx-1-4-3/plugins/filelink/filelink';
import '../assets/redactorx-1-4-3/plugins/handle/handle';
import '../assets/redactorx-1-4-3/plugins/icons/icons';
import '../assets/redactorx-1-4-3/plugins/imageposition/imageposition';
import '../assets/redactorx-1-4-3/plugins/imageresize/imageresize';
import '../assets/redactorx-1-4-3/plugins/inlineformat/inlineformat';
import '../assets/redactorx-1-4-3/plugins/removeformat/removeformat';
import '../assets/redactorx-1-4-3/plugins/selector/selector';
import '../assets/redactorx-1-4-3/plugins/specialchars/specialchars';
import '../assets/redactorx-1-4-3/plugins/textdirection/textdirection';
import '../assets/redactorx-1-4-3/plugins/textexpander/textexpander';
import '../assets/redactorx-1-4-3/plugins/underline/underline';
import '../assets/redactorx-custom-plugins/add-video/addVideo.js';
import Box from '@material-ui/core/Box';
import { TranslationContext } from '../context/TranslationContext';
import { CustomButton } from './CustomButton';
import '../assets/article-editor-1-5-1/article-editor.min.css';
import '../assets/article-editor-1-5-1/plugins/icons/icons.css';
import '../assets/article-editor-1-5-1/plugins/style/style.css';
import { FieldArray, Form, Formik } from 'formik';
import { FormikFormInput } from './FormikInputs';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IMovePage, ITemplate, ITemplatePayload, ITemplatePayloadData } from '../services/ApiServiceV1';
import * as yup from 'yup';
import { BASE_URL } from '../context/ServicesContext';
import { assertNever } from '../utils/assertNever';
import { lintVar } from '../utils/ibg-html-pretty-fork/ibg-html-pretty-fork';
import { isNil } from 'lodash';
import { debounce } from 'ts-debounce';

interface ITemplateFormProps {
  onSubmit: (values: ITemplatePayload) => Promise<void | React.ReactNode>;
  template?: ITemplate | IMovePage;
  saveButtonLabel?: string;
}

const redactorXSettings = {
  plugins: [
    'alignment',
    'beyondgrammar',
    'blockcode',
    'clips',
    'counter',
    'definedlinks',
    'filelink',
    'handle',
    'icons',
    'imageposition',
    'imageresize',
    'inlineformat',
    'removeformat',
    'selector',
    'specialchars',
    'textdirection',
    'textexpander',
    'underline',
    'video',
  ],
  reorder: true,
  image: {
    upload: `${process.env['NODE_ENV'] === 'development' ? 'http://localhost:3000/api' : BASE_URL}/v1/images`,
    resizable: true,
    position: true,
  },
  control: true,
  context: true,
  toolbar: true,
  video: {
    upload: `${process.env['NODE_ENV'] === 'development' ? 'http://localhost:3000/api' : BASE_URL}/v1/images`,
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  accordionDetailsRoot: {
    backgroundColor: theme.palette.background.default,
  },
  accordionExpanded: {
    backgroundColor: theme.palette.common.white,
  },
}));

const TemplateForm: React.FunctionComponent<ITemplateFormProps> = ({ onSubmit, template, saveButtonLabel }) => {
  const { translate } = useContext(TranslationContext);

  const englishArticleRoot = useRef<HTMLTextAreaElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const englishArticleApp = useRef<any>(null);

  const norwegianArticleRoot = useRef<HTMLTextAreaElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const norwegianArticleApp = useRef<any>(null);

  const germanArticleRoot = useRef<HTMLTextAreaElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const germanArticleApp = useRef<any>(null);

  const danishArticleRoot = useRef<HTMLTextAreaElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const danishArticleApp = useRef<any>(null);

  const swedishArticleRoot = useRef<HTMLTextAreaElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const swedishArticleApp = useRef<any>(null);

  const norwegianNynorskArticleRoot = useRef<HTMLTextAreaElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const norwegianNynorskApp = useRef<any>(null);

  useEffect(() => {
    const addLinterCallback = debounce(() => {
      const knownNodes: Node[] = [];

      document.querySelectorAll("a[data-command='source.toggle']").forEach((button: any) => {
        if (knownNodes.indexOf(button) === -1) {
          button.addEventListener('click', function () {
            document.querySelectorAll('div.rx-editor').forEach((editorDiv: any) => {
              const linted = lintVar(editorDiv.innerHTML);
              editorDiv.parentNode.parentNode.querySelector('textarea.rx-source').value = linted;
            });
          });

          knownNodes.push(button);
        }
      });
    }, 50);

    const configureFormatting = () => {
      document.addEventListener(
        'DOMSubtreeModified',
        () => {
          addLinterCallback();
        },
        false,
      );
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    norwegianArticleApp.current = RedactorX(norwegianArticleRoot.current, redactorXSettings);

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    englishArticleApp.current = RedactorX(englishArticleRoot.current, redactorXSettings);

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    germanArticleApp.current = RedactorX(germanArticleRoot.current, redactorXSettings);

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    danishArticleApp.current = RedactorX(danishArticleRoot.current, redactorXSettings);

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    swedishArticleApp.current = RedactorX(swedishArticleRoot.current, redactorXSettings);

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    norwegianNynorskApp.current = RedactorX(norwegianNynorskArticleRoot.current, redactorXSettings);

    setTimeout(configureFormatting, 0);
  }, []);

  const englishTranslationPayloadData: ITemplatePayloadData | undefined = template
    ? template.translations.find((translationData) => translationData.locale === 'en')
    : undefined;
  const norwegianTranslationPayloadData: ITemplatePayloadData | undefined = template
    ? template.translations.find((translationData) => translationData.locale === 'no')
    : undefined;
  const germanTranslationPayloadData: ITemplatePayloadData | undefined = template
    ? template.translations.find((translationData) => translationData.locale === 'de')
    : undefined;
  const danishTranslationPayloadData: ITemplatePayloadData | undefined = template
    ? template.translations.find((translationData) => translationData.locale === 'da')
    : undefined;
  const swedishTranslationPayloadData: ITemplatePayloadData | undefined = template
    ? template.translations.find((translationData) => translationData.locale === 'se')
    : undefined;
  const norwegianNynorskTranslationPayloadData: ITemplatePayloadData | undefined = template
    ? template.translations.find((translationData) => translationData.locale === 'nn')
    : undefined;

  const formInitialValues: ITemplatePayload = {
    translations: [
      {
        locale: 'en',
        content: englishTranslationPayloadData ? englishTranslationPayloadData.content : '',
        title: englishTranslationPayloadData ? englishTranslationPayloadData.title : '',
      },
      {
        locale: 'no',
        content: norwegianTranslationPayloadData ? norwegianTranslationPayloadData.content : '',
        title: norwegianTranslationPayloadData ? norwegianTranslationPayloadData.title : '',
      },
      {
        locale: 'nn',
        content: norwegianNynorskTranslationPayloadData ? norwegianNynorskTranslationPayloadData.content : '',
        title: norwegianNynorskTranslationPayloadData ? norwegianNynorskTranslationPayloadData.title : '',
      },
      {
        locale: 'de',
        content: germanTranslationPayloadData ? germanTranslationPayloadData.content : '',
        title: germanTranslationPayloadData ? germanTranslationPayloadData.title : '',
      },
      {
        locale: 'da',
        content: danishTranslationPayloadData ? danishTranslationPayloadData.content : '',
        title: danishTranslationPayloadData ? danishTranslationPayloadData.title : '',
      },
      {
        locale: 'se',
        content: swedishTranslationPayloadData ? swedishTranslationPayloadData.content : '',
        title: swedishTranslationPayloadData ? swedishTranslationPayloadData.title : '',
      },
    ],
    position: 0,
  };

  let validationErrorIndex = -1;

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={yup.object().shape({
        translations: yup.array().of(
          yup.object().shape({
            title: yup.string().required(translate('field is required')),
          }),
        ),
      })}
      onSubmit={async (values, formikHelpers) => {
        values.translations = values.translations.map((template) => {
          switch (template.locale) {
            case 'en':
              template.content = englishArticleApp.current.editor.getContent() + ' ';
              break;
            case 'no':
              template.content = norwegianArticleApp.current.editor.getContent() + ' ';
              break;
            case 'nn':
              template.content = norwegianNynorskApp.current.editor.getContent() + ' ';
              break;
            case 'de':
              template.content = germanArticleApp.current.editor.getContent() + ' ';
              break;
            case 'da':
              template.content = danishArticleApp.current.editor.getContent() + ' ';
              break;
            case 'se':
              template.content = swedishArticleApp.current.editor.getContent() + ' ';
              break;
            default:
              assertNever(template.locale);
          }

          return template;
        });

        onSubmit(values);
      }}
    >
      {({ values, errors }) => {
        if (errors.translations && Array.isArray(errors.translations)) {
          validationErrorIndex = (errors.translations as string[]).findIndex(
            (translationError) => !isNil(translationError),
          );
        }
        return (
          <Form>
            <FieldArray name="translations">
              {() => (
                <>
                  {values.translations.map((template, index) => {
                    let languageDisplayName: string;
                    let appRoot: React.RefObject<HTMLTextAreaElement>;
                    switch (template.locale) {
                      case 'en':
                        languageDisplayName = translate('english');
                        appRoot = englishArticleRoot;
                        break;
                      case 'no':
                        languageDisplayName = translate('norwegian');
                        appRoot = norwegianArticleRoot;
                        break;
                      case 'nn':
                        languageDisplayName = translate('norwegiannynorsk');
                        appRoot = norwegianNynorskArticleRoot;
                        break;
                      case 'de':
                        languageDisplayName = translate('german');
                        appRoot = germanArticleRoot;
                        break;
                      case 'da':
                        languageDisplayName = translate('danish');
                        appRoot = danishArticleRoot;
                        break;
                      case 'se':
                        languageDisplayName = translate('swedish');
                        appRoot = swedishArticleRoot;
                        break;
                      default:
                        assertNever(template.locale);
                    }
                    return (
                      <FormAccordion
                        key={index}
                        index={index}
                        languageDisplayName={languageDisplayName}
                        template={template}
                        appRoot={appRoot}
                        validationErrorIndex={validationErrorIndex}
                      />
                    );
                  })}
                </>
              )}
            </FieldArray>
            <Box pt={3}>
              <CustomButton variant="contained" color="secondary" type="submit">
                {saveButtonLabel ? saveButtonLabel : translate('save')}
              </CustomButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

const FormAccordion: React.FunctionComponent<{
  index: number;
  languageDisplayName: string;
  template: ITemplatePayloadData;
  appRoot: React.RefObject<HTMLTextAreaElement>;
  validationErrorIndex: number;
}> = ({ index, languageDisplayName, template, appRoot, validationErrorIndex }) => {
  const { accordionDetailsRoot, accordionExpanded } = useStyles();
  const { translate } = useContext(TranslationContext);

  const [shouldExpandAccordion, setShouldExpandAccordion] = useState(false);

  // expand accordion if there's a validation error, so the user can see which form set is failing to submit
  useEffect(() => {
    setShouldExpandAccordion(index === validationErrorIndex);
  }, [index, validationErrorIndex]);

  return (
    <Accordion classes={{ root: accordionDetailsRoot }} expanded={shouldExpandAccordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={() => {
          setShouldExpandAccordion((oldValue) => !oldValue);
        }}
      >
        <Typography variant="h5">{languageDisplayName}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: accordionExpanded }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormikFormInput formikKey={`translations[${index}].title`} labelContent={translate('title')} />
          </Grid>
          <Grid item xs={12}>
            <textarea defaultValue={template ? template.content : ''} ref={appRoot} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export { TemplateForm };
