import { action, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';
import { IApiServiceV1, ILease, ILeasePayload } from '../services/ApiServiceV1';
import { ClientLeaseDomainStore } from './ClientLeaseDomainStore';
import { LeaseStore } from './LeaseStore';
import { RootStore } from './RootStore';

class ClientLeasesStore {
  @observable
  remoteClientLeases: RemoteData<ClientLeaseDomainStore[]> = RemoteData.NOT_ASKED;

  @observable
  clientLease: LeaseStore;

  @observable
  clientId: string | undefined;

  constructor(private apiService: IApiServiceV1, private rootStore: RootStore) {
    this.clientLease = new LeaseStore(apiService);
  }

  @action
  async fetch(clientId: string, forceFetch = false) {
    if (RemoteData.isReady(this.remoteClientLeases) && this.clientId === clientId && !forceFetch) {
      return;
    }

    this.remoteClientLeases = RemoteData.LOADING;
    this.clientId = clientId;

    try {
      const clientLeases = await this.apiService.fetchClientLeases(clientId);
      runInAction(() => {
        this.remoteClientLeases = clientLeases;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteClientLeases = RemoteData.fail();
      });
    }
  }

  @action
  async saveNewLease(clientId: string, leasePayload: ILeasePayload) {
    if (RemoteData.isReady(this.remoteClientLeases)) {
      const lease = await this.apiService.addClientLease(clientId, leasePayload);
      runInAction(() => {
        (this.remoteClientLeases as ILease[]).push(lease);
      });
    }
  }

  @action
  async updateLease(leaseId: string, payload: ILeasePayload) {
    await this.clientLease.updateLease(leaseId, payload);

    runInAction(() => {
      if (RemoteData.isReady(this.remoteClientLeases)) {
        this.remoteClientLeases = this.remoteClientLeases.map((clientLease) => {
          if (clientLease.id === leaseId) {
            return this.clientLease.remoteLease as ILease;
          }
          return clientLease;
        });
      }
    });
  }

  @action
  increaseLeaseRemindersCount(leaseId: string) {
    if (RemoteData.isReady(this.remoteClientLeases)) {
      const foundLease = this.remoteClientLeases.find((clientLease) => clientLease.id === leaseId);
      foundLease && foundLease.remindersCount++;
    }
  }

  @action
  decreaseLeaseRemindersCount(leaseId: string) {
    if (RemoteData.isReady(this.remoteClientLeases)) {
      const foundLease = this.remoteClientLeases.find((clientLease) => clientLease.id === leaseId);
      foundLease && foundLease.remindersCount--;
    }
  }
}

export { ClientLeasesStore };
