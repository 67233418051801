import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Person from '@material-ui/icons/Person';
import { ILocation, IClient, IUser, AuthorizationScheme } from '../../services/ApiServiceV1';
import { TranslationContext } from '../../context/TranslationContext';
import { DashboardBox } from '../../components/DashboardBox';
import { RouteMapContext } from '../../context/RouteMapContext';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { ServiceContext } from '../../context/ServicesContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import { observer } from 'mobx-react';
import { TeamMemberAvatar } from './TeamMemberAvatar';
import { getTranslatedLanguages } from '../../locales/locales';
import { getBackendBaseUrl, getRelokatorPathLabel, getRelokatorUrl } from '../../utils/getBackendBaseUrl';
import { Link } from '@material-ui/core';
import styled from 'styled-components';

interface IMoveInformationBoxProps {
  location: ILocation;
  client: IClient;
  user: IUser;
  moveId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  subheader: {
    color: theme.palette.text.primary,
  },
  locationBoxContent: {
    padding: theme.spacing(2, 0),
    '& > * > *': {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(0, 2),
      },
    },
  },
  locationInformationTopItem: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  locationInformationSubItem: {
    paddingLeft: theme.spacing(1),
  },
  styledDivider: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  teamMemberContainer: {
    padding: theme.spacing(2, 0),
  },
  teamMemberWrapper: {
    padding: theme.spacing(0, 2),
  },
}));

const Logo = styled.img`
  display: inine-block;
  height: 40px;
`;

const MoveInformationBox: React.FunctionComponent<IMoveInformationBoxProps> = observer(
  ({ location, client, user, moveId }) => {
    const { translate } = useContext(TranslationContext);
    const { getPathWithProps } = useContext(RouteMapContext);
    const {
      locationBoxContent,
      locationInformationTopItem,
      locationInformationSubItem,
      styledDivider,
      teamMemberContainer,
      teamMemberWrapper,
      subheader,
    } = useStyles();
    const {
      apiServiceV1,
      rootStore: { moveStore },
    } = useContext(ServiceContext);

    useAsyncOperation(() => {
      return moveStore.fetchMove(location.id, moveId);
    }, [apiServiceV1, location, moveId]);

    const logoUrl = `${getBackendBaseUrl()}/v1/moves/${moveId}/logo`;

    return (
      <DashboardBox
        title={translate('general information')}
        url={getPathWithProps<{ locationId: string; moveId: string; clientId: string }>('EDIT_LOCATION_MOVE_SERVICE', {
          locationId: location.id,
          moveId: moveId,
          clientId: client.id,
        })}
        shouldShowUrl={user.isExternal === false}
      >
        <RemoteSuspense
          data={moveStore.remoteMove}
          loadingFallback={<LinearProgress />}
          failureFallback={<Typography color="error">{translate('failed to fetch data')}</Typography>}
        >
          {(move) => (
            <Grid container justify="space-evenly" className={locationBoxContent}>
              <Grid container item sm={11} md={3} direction="column" spacing={2}>
                <Grid item className={locationInformationTopItem}>
                  <Typography variant="button">{translate('client')}</Typography>
                  <Typography>{client.name}</Typography>
                </Grid>
                {move && (
                  <>
                    <Grid item className={locationInformationTopItem}>
                      <Typography variant="button">{translate('move website')}</Typography>
                      <Typography>
                        <Link href={getRelokatorUrl(move?.path)} target="_blank">
                          {getRelokatorPathLabel(move.path)}
                        </Link>
                      </Typography>
                      <Grid className={locationInformationSubItem}>
                        <Typography variant="body2" className="subheader">
                          {move.authorizationScheme !== AuthorizationScheme.Password && (
                            <div>
                              <span className={subheader}>{translate('whitelisted domains')}: </span>
                              {move.whitelistedDomains.join(', ')}
                            </div>
                          )}
                          {move.authorizationScheme !== AuthorizationScheme.Email && (
                            <div>
                              <span className={subheader}>{translate('password')}: </span>
                              {move.password}
                            </div>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="button">{translate('languages')}</Typography>
                      <Typography>
                        {getTranslatedLanguages(translate)
                          .filter((translatedLanguage) =>
                            move.languages.find((moveLanguage) => moveLanguage === translatedLanguage.language),
                          )
                          .map((translatedLanguage) => translatedLanguage.translatedLanguageName)
                          .join(', ')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="button">{translate('default language')}</Typography>
                      <Typography>
                        {
                          getTranslatedLanguages(translate).find(({ language }) => language === move.defaultLanguage)
                            ?.translatedLanguageName
                        }
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item>
                  <Typography variant="button">Logo</Typography>
                  <Typography>
                    <Logo src={logoUrl} alt=" "></Logo>
                  </Typography>
                </Grid>
              </Grid>
              <Divider orientation="vertical" flexItem className={styledDivider} />
              <Grid container item sm={11} md={3} direction="column">
                <Grid item className={locationInformationTopItem}>
                  <Typography variant="button">{translate('fmg team')}</Typography>
                  <RemoteSuspense
                    data={moveStore.remoteTeamMemberUsers}
                    loadingFallback={<LinearProgress />}
                    failureFallback={<Typography color="error">{translate('failed to fetch data')}</Typography>}
                  >
                    {(teamMemberUsers) =>
                      teamMemberUsers.map((userStore) => (
                        <Box key={userStore.user.user.id}>
                          <Grid container direction="row" className={teamMemberContainer}>
                            <TeamMemberAvatar userDomainStore={userStore} />
                            <Grid className={teamMemberWrapper} item>
                              <Typography>{userStore.user.user.displayName}</Typography>
                              <Typography variant="body2">{userStore.user.user.mail}</Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                        </Box>
                      ))
                    }
                  </RemoteSuspense>
                </Grid>
              </Grid>
              <Divider orientation="vertical" flexItem className={styledDivider} />
              <Grid container item sm={11} md={3} direction="column">
                <Grid item className={locationInformationTopItem}>
                  <Typography variant="button">{translate('team')}</Typography>
                  <RemoteSuspense
                    data={moveStore.remoteClientContact}
                    loadingFallback={<LinearProgress />}
                    failureFallback={<Typography color="error">{translate('failed to fetch data')}</Typography>}
                  >
                    {(clientContacts) =>
                      clientContacts?.map((clientContact) => (
                        <Box key={clientContact.user.id}>
                          <Grid container direction="row" className={teamMemberContainer}>
                            <Person></Person>
                            <Grid className={teamMemberWrapper} item>
                              <Typography>{clientContact.user.displayName}</Typography>
                              <Typography variant="body2">{clientContact.user.mail}</Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                        </Box>
                      ))
                    }
                  </RemoteSuspense>
                </Grid>
              </Grid>
            </Grid>
          )}
        </RemoteSuspense>
      </DashboardBox>
    );
  },
);

export { MoveInformationBox };
