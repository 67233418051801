import React, { useContext } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { FormikCheckbox, FormikFormInput } from '../components/FormikInputs';
import { TranslationContext } from '../context/TranslationContext';
import { FormikCountrySelect } from './FormikCountrySelect';
import { CustomButton } from './CustomButton';
import { Label } from './Label';
import { ILocationPayload, ILocation } from '../services/ApiServiceV1';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  inputWrapper: {
    width: '12.25rem',
  },
});

interface ILocationFormProps {
  formSubmit: (values: ILocationPayload, formikBag: FormikHelpers<ILocationPayload>) => Promise<void>;
  location?: ILocation;
}

const LocationForm: React.FunctionComponent<ILocationFormProps> = ({ formSubmit, location }) => {
  const { translate } = useContext(TranslationContext);
  const { inputWrapper } = useStyles();

  return (
    <>
      <Formik
        initialValues={{
          isOffice: location ? location.isOffice : false,
          isStorage: location ? location.isStorage : false,
          isProduction: location ? location.isProduction : false,
          name: location ? location.name : '',
          countryId: location ? location.country.id : '',
          address: location ? location.address : '',
          city: location ? location.city : '',
        }}
        onSubmit={async (values: ILocationPayload, formikBag) => {
          formSubmit(values, formikBag);
        }}
        validationSchema={yup.object({
          name: yup.string().required(translate('field is required')),
          countryId: yup.string().required(translate('field is required')),
          address: yup.string().required(translate('field is required')),
          city: yup.string().required(translate('field is required')),
        })}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box>
                  <Label>{translate('property type')}</Label>
                </Box>

                <FormikCheckbox formikKey="isOffice" labelContent={translate('office')} color="primary" />
                <FormikCheckbox formikKey="isStorage" labelContent={translate('storage')} color="primary" />
                <FormikCheckbox formikKey="isProduction" labelContent={translate('production')} color="primary" />
              </Grid>

              <Grid container item spacing={3} direction="column">
                <Grid item xs={12} sm={12} md={6} lg={5}>
                  <FormikFormInput
                    formikKey="name"
                    labelContent={translate('name')}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                  <FormikFormInput
                    formikKey="address"
                    labelContent={translate('address')}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                  <FormikFormInput formikKey="city" labelContent={translate('city')} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                  <FormikCountrySelect
                    formikKey="countryId"
                    labelContent={translate('country')}
                    native={true}
                    variant="outlined"
                    className={inputWrapper}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomButton type="submit" variant="contained" color="secondary" disabled={isSubmitting}>
                    {translate('save')}
                  </CustomButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export { LocationForm };
