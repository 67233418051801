import { FormikHelpers } from 'formik';
import { observer } from 'mobx-react';
import React from 'react';
import { useContext } from 'react';
import { useCallback } from 'react';
import { CustomButton } from '../../components/CustomButton';
import { NotificationContext } from '../../context/NotificationContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { ILease, IReminderPayload } from '../../services/ApiServiceV1';
import { ReminderForm } from './ReminderForm';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

interface ICreateReminderProps {
  lease: ILease;
}

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(2),
  },
  cancelButton: {
    marginTop: theme.spacing(1),
  },
}));

const CreateReminder: React.FunctionComponent<ICreateReminderProps> = observer(({ lease }) => {
  const {
    stores: { clientStore },
    rootStore: { leaseRemindersStore, leaseRemindersUiStore },
  } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { translate } = useContext(TranslationContext);

  const onSubmit = useCallback(
    async (values: IReminderPayload, helpers: FormikHelpers<IReminderPayload>) => {
      try {
        await leaseRemindersStore.create(lease.id, values);
        clientStore.invalidateRemoteLeaseReminders();
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully added new lease reminder'),
            color: 'success',
          },
        });
        helpers.resetForm();
        leaseRemindersUiStore.toggleShouldShowAddReminderForm();
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to add new lease reminder'),
            color: 'error',
          },
        });
      }
    },
    [clientStore, lease.id, leaseRemindersStore, leaseRemindersUiStore, notificationDispatch, translate],
  );

  const { formContainer, cancelButton } = useStyles();

  return (
    <Collapse in={leaseRemindersUiStore.shouldShowAddReminderForm}>
      <Paper className={formContainer}>
        <Box padding={2}>
          <ReminderForm onSubmit={onSubmit} />
          <CustomButton
            variant="outlined"
            color="default"
            onClick={() => leaseRemindersUiStore.toggleShouldShowAddReminderForm()}
            className={cancelButton}
          >
            {translate('cancel')}
          </CustomButton>
        </Box>
      </Paper>
    </Collapse>
  );
});

export { CreateReminder };
