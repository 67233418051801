import { Color } from '@material-ui/lab';
import { merge } from 'lodash';
import { action, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';
import { IApiServiceV1, IClient, ILocation, IUpdateClientResponse } from '../services/ApiServiceV1';
import { ClientDomainStore } from './ClientDomainStore';
import { ReminderLeaseDomainStore } from './reminder/ReminderLeaseDomainStore';

class ClientStore {
  @observable
  remoteClient: RemoteData<ClientDomainStore> = RemoteData.NOT_ASKED;

  @observable
  remoteLocations: RemoteData<ILocation[]> = RemoteData.NOT_ASKED;

  @observable
  remoteClientLeaseReminders: RemoteData<ReminderLeaseDomainStore[]> = RemoteData.NOT_ASKED;

  @observable
  primaryColor = '';

  @observable
  darkerPrimaryColor = '';

  @observable
  secondaryColor = '';

  @observable
  darkerSecondaryColor = '';

  @observable
  backgroundColor = '';

  @observable
  headingColor = '';

  @observable
  notificationText = '';

  @observable
  notificationColor: Color = 'success';

  @observable
  showNotification = false;

  private readonly _apiService: IApiServiceV1;

  constructor(apiService: IApiServiceV1) {
    this._apiService = apiService;
  }

  @action
  async fetchClientData(clientId: string, forceFetch = false) {
    const shouldSkipFetching =
      RemoteData.isReady(this.remoteClient) && this.remoteClient.id === clientId && !forceFetch;

    this.fetchClient(clientId, shouldSkipFetching);
    this.fetchLocations(clientId, shouldSkipFetching);
  }

  private async fetchClient(clientId: string, shouldSkipFetching: boolean) {
    if (shouldSkipFetching) {
      return;
    }
    this.remoteClient = RemoteData.LOADING;

    try {
      const client = await this._apiService.fetchClient(clientId);
      runInAction(() => {
        this.remoteClient = new ClientDomainStore(client);
      });
    } catch (error) {
      this.remoteClient = RemoteData.fail();
    }
  }

  private async fetchLocations(clientId: string, shouldSkipFetching: boolean) {
    if (RemoteData.isReady(this.remoteLocations) && shouldSkipFetching) {
      return;
    }
    this.remoteLocations = RemoteData.LOADING;
    try {
      const { locations } = await this._apiService.fetchClientLocations(clientId);

      const results: RemoteData<ILocation[]> = [];
      const promises = locations.map((l) =>
        this._apiService.fetchLocation(clientId, l.id).then((location) => results.push(location)),
      );
      await Promise.all(promises);

      runInAction(() => {
        this.remoteLocations = results;
      });
    } catch (error) {
      this.remoteLocations = RemoteData.fail();
    }
  }

  @action
  invalidateRemoteLocations() {
    this.remoteLocations = RemoteData.NOT_ASKED;
  }

  @action
  invalidateRemoteLeaseReminders() {
    this.remoteClientLeaseReminders = RemoteData.NOT_ASKED;
  }

  @action
  setRemoteClient(client: IClient) {
    this.remoteClient = new ClientDomainStore(client);
  }

  @action
  updateRemoteClientData(updateClientResponse: IUpdateClientResponse) {
    if (RemoteData.isReady(this.remoteClient)) {
      this.remoteClient.country = updateClientResponse.country;
      this.remoteClient.active = updateClientResponse.active;
      this.remoteClient.name = updateClientResponse.name;
      this.remoteClient.orgNumber = updateClientResponse.orgNumber;
    }
  }

  @action
  addClientLocation(location: ILocation) {
    if (RemoteData.isReady(this.remoteClient)) {
      this.remoteClient.locations.push(location);
    }

    if (RemoteData.isReady(this.remoteLocations)) {
      this.remoteLocations.push(location);
    }
  }

  @action
  updateClientLocations(updatedLocation: ILocation) {
    const updateLocationMapper = (location: ILocation) => {
      if (location.id === updatedLocation.id) {
        return updatedLocation;
      }
      return location;
    };
    if (RemoteData.isReady(this.remoteClient)) {
      this.remoteClient.locations = this.remoteClient.locations.map(updateLocationMapper);
    }

    if (RemoteData.isReady(this.remoteLocations)) {
      this.remoteLocations = this.remoteLocations.map(updateLocationMapper);
    }
  }

  @action
  async updatePrimaryColor(color: string, moveId: string) {
    try {
      const colors = merge(this.getCurrentColors(), { primaryColor: color, moveId: moveId });
      await this._apiService.createColors(colors);
      runInAction(() => {
        color
          ? this.displayNotification(`Successfully updated primary color to ${color}`, 'success')
          : this.displayNotification('Primary color was successfully removed.', 'success');
        this.primaryColor = color;
      });
    } catch (error) {
      runInAction(() => {
        this.displayNotification('Failed to update primary color', 'error');
      });
    }
  }

  @action
  async updateDarkerPrimaryColor(color: string, moveId: string) {
    try {
      const colors = merge(this.getCurrentColors(), { darkerPrimaryColor: color, moveId: moveId });
      await this._apiService.createColors(colors);
      runInAction(() => {
        color
          ? this.displayNotification(`Successfully updated primary color (darker) to ${color}`, 'success')
          : this.displayNotification('Primary color (darker) was successfully removed.', 'success');
        this.darkerPrimaryColor = color;
      });
    } catch (error) {
      runInAction(() => {
        this.displayNotification('Failed to update darker primary color', 'error');
      });
    }
  }
  @action
  async updateSecondaryColor(color: string, moveId: string) {
    try {
      const colors = merge(this.getCurrentColors(), { secondaryColor: color, moveId: moveId });
      await this._apiService.createColors(colors);
      runInAction(() => {
        color
          ? this.displayNotification(`Successfully updated secondary color to ${color}`, 'success')
          : this.displayNotification('Secondary color was successfully removed.', 'success');
        this.secondaryColor = color;
      });
    } catch (error) {
      runInAction(() => {
        this.displayNotification('Failed to update secondary color', 'error');
      });
    }
  }

  @action
  async updateDarkerSecondaryColor(color: string, moveId: string) {
    try {
      const colors = merge(this.getCurrentColors(), { darkerSecondaryColor: color, moveId: moveId });
      await this._apiService.createColors(colors);
      runInAction(() => {
        color
          ? this.displayNotification(`Successfully updated secondary color (darker) to ${color}`, 'success')
          : this.displayNotification('Secondary color (darker) was successfully removed.', 'success');

        this.darkerSecondaryColor = color;
      });
    } catch (error) {
      runInAction(() => {
        this.displayNotification('Failed to update darker secondary color', 'error');
      });
    }
  }

  @action
  async updateBackgroundColor(color: string, moveId: string) {
    try {
      const colors = merge(this.getCurrentColors(), { backgroundColor: color, moveId: moveId });
      await this._apiService.createColors(colors);
      runInAction(() => {
        color
          ? this.displayNotification(`Successfully updated background color to ${color}`, 'success')
          : this.displayNotification('Background color was successfully removed.', 'success');

        this.backgroundColor = color;
      });
    } catch (error) {
      runInAction(() => {
        this.displayNotification('Failed to update background color', 'error');
      });
    }
  }

  @action
  async updateHeadingColor(color: string, moveId: string) {
    try {
      const colors = merge(this.getCurrentColors(), { headingColor: color, moveId: moveId });
      await this._apiService.createColors(colors);
      runInAction(() => {
        color
          ? this.displayNotification(`Successfully updated heading color to ${color}`, 'success')
          : this.displayNotification('Heading color was successfully removed.', 'success');

        this.headingColor = color;
      });
    } catch (error) {
      runInAction(() => {
        this.displayNotification('Failed to update heading color', 'error');
      });
    }
  }

  @action
  async getColors(moveId: string) {
    try {
      const colors = await this._apiService.getColors(moveId);
      runInAction(() => {
        this.primaryColor = colors?.primaryColor;
        this.darkerPrimaryColor = colors?.darkerPrimaryColor;
        this.secondaryColor = colors?.secondaryColor;
        this.darkerSecondaryColor = colors?.darkerSecondaryColor;
        this.backgroundColor = colors?.backgroundColor;
        this.headingColor = colors?.headingColor;
      });
    } catch (error) {
      runInAction(() => {
        this.displayNotification('Failed to get custom colors', 'error');
      });
    }
  }
  @action
  async clearColors(moveId: string) {
    try {
      await this._apiService.clearColors(moveId);
      runInAction(() => {
        this.primaryColor = '';
        this.darkerPrimaryColor = '';
        this.secondaryColor = '';
        this.darkerSecondaryColor = '';
        this.backgroundColor = '';
        this.headingColor = '';
        this.displayNotification('Successfully cleared custom colors', 'success');
      });
    } catch (error) {
      runInAction(() => {
        this.displayNotification('Failed to clear custom colors', 'error');
      });
    }
  }

  private getCurrentColors() {
    return {
      primaryColor: this.primaryColor,
      darkerPrimaryColor: this.darkerPrimaryColor,
      secondaryColor: this.secondaryColor,
      darkerSecondaryColor: this.darkerSecondaryColor,
      backgroundColor: this.backgroundColor,
      headingColor: this.headingColor,
    };
  }
  @action
  displayNotification(notificationText: string, notificationColor: Color) {
    this.notificationColor = notificationColor;
    this.notificationText = notificationText;
    this.showNotification = true;
  }
}

export { ClientStore };
