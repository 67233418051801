import { ILocation } from '../services/ApiServiceV1';
import { Translate } from '../context/TranslationContext';

export function getLocationPropertyTypes(location: ILocation, translate: Translate) {
  const propertyTypes = [];
  location.isOffice && propertyTypes.push(translate('office'));
  location.isStorage && propertyTypes.push(translate('storage'));
  location.isProduction && propertyTypes.push(translate('production'));

  return propertyTypes;
}
