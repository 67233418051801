import { TimelineEvent } from '@fmg-packages/common-components';

type Action =
  | { type: 'ADD_EDIT_TIMELINE_EVENT'; payload: TimelineEvent }
  | { type: 'REMOVE_EDIT_TIMELINE_EVENT'; payload: TimelineEvent };

type State = TimelineEvent[];

export function editTimelineEventsReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'ADD_EDIT_TIMELINE_EVENT':
      return [...state, action.payload];
    case 'REMOVE_EDIT_TIMELINE_EVENT':
      return state.filter((timelineSection) => timelineSection.id !== action.payload.id);
    default: {
      const x: never = action;
      return x;
    }
  }
}
