import { action, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';
import { IApiServiceV1, IMoveDocument } from '../services/ApiServiceV1';
import { RootStore } from './RootStore';

class DocumentsStore {
  @observable
  remoteMoveDocuments: RemoteData<IMoveDocument[]> = RemoteData.NOT_ASKED;

  _apiService: IApiServiceV1;

  constructor(apiService: IApiServiceV1, private rootStore: RootStore) {
    this._apiService = apiService;
  }

  @action
  async storeDocument(locationId: string, moveId: string, file: File, forceFetch = false): Promise<boolean> {
    try {
      await this._apiService.storeDocument(locationId, moveId, { file: file });
      await this.fetchDocuments(locationId, moveId, true);
      return true;
    } catch (error) {
      return false;
    }
  }

  @action
  async fetchDocuments(locationId: string, moveId: string, forceFetch = false) {
    if (RemoteData.isReady(this.remoteMoveDocuments) && !forceFetch) {
      return;
    }

    this.remoteMoveDocuments = RemoteData.LOADING;
    try {
      const moveDocuments = await this._apiService.fetchMoveDocuments(locationId, moveId);

      runInAction(() => {
        this.remoteMoveDocuments = moveDocuments;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteMoveDocuments = RemoteData.fail();
      });
    }
  }

  @action
  async deleteDocument(locationId: string, moveId: string, documentId: string): Promise<boolean> {
    try {
      await this._apiService.deleteDocument(locationId, moveId, documentId);
      await this.fetchDocuments(locationId, moveId, true);
      return true;
    } catch (error) {
      return false;
    }
  }

  @action
  async downloadDocument(locationId: string, moveId: string, documentId: string): Promise<Blob> {
    return await this._apiService.downloadDocument(locationId, moveId, documentId);
  }

  @action
  async updateDocumentPosition(locationId: string, moveId: string, documentId: string, position: number) {
    try {
      await this._apiService.updateDocumentPosition(locationId, moveId, documentId, position);
      await this.fetchDocuments(locationId, moveId, true);
      return true;
    } catch (error) {
      return false;
    }
  }
}

export { DocumentsStore };
