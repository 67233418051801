import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

export interface IInfoItemProps {
  label: string;
  content?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing(3),
  },
}));

const InfoItem: React.FunctionComponent<IInfoItemProps> = ({ label, content, children }) => {
  const { container } = useStyles();
  return (
    <Grid item className={container}>
      <Typography variant="button">{label}</Typography>
      {content && <Typography>{content}</Typography>}
      {children}
    </Grid>
  );
};

export { InfoItem };
