import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';

const useStyles = makeStyles((theme: Theme) => ({
  dragIconButton: {
    cursor: 'grab',
  },
}));

const DragButtonIcon: React.FunctionComponent = () => {
  const { dragIconButton } = useStyles();
  return (
    <IconButton className={dragIconButton}>
      <DragHandleIcon />
    </IconButton>
  );
};

export { DragButtonIcon };
