import React, { useState } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import TopBar from '../TopBar/TopBar';
import { MobileMenu, LargeScreenMenu, drawerWidth } from '../Menu/Menu';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    },
    width: `calc(100% - ${drawerWidth}px)`,
    // width: `100%`,
  },
}));

const AdminLayout: React.FunctionComponent = ({ children }) => {
  const [isDrawerOpen, setDrawerIsOpen] = useState(true);
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerIsOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerIsOpen(false);
  };
  const handleDrawerToggle = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const { root, content, toolbar } = useStyles({ isDrawerOpen });
  const theme = useTheme();

  return (
    <div className={root}>
      <TopBar isDrawerOpen={isDrawerOpen} handleDrawerToggle={handleDrawerToggle} />
      <Hidden smUp>
        <MobileMenu isMobileOpen={isMobileOpen} handleDrawerToggle={handleDrawerToggle} isDrawerOpen={isDrawerOpen} />
      </Hidden>
      <Hidden xsDown>
        <LargeScreenMenu
          isDrawerOpen={isDrawerOpen}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
      </Hidden>
      <div className={content}>
        <div className={toolbar} />
        <Box bgcolor={theme.palette.background.default} padding={0} margin={0}>
          {children}
        </Box>
      </div>
    </div>
  );
};

export default AdminLayout;
