import { ShowTimeline, TimelineSection } from '@fmg-packages/common-components';
import React from 'react';
import { DashboardBox } from './DashboardBox';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { ButtonLink } from './ButtonLink';
import { useContext } from 'react';
import { TranslationContext } from '../context/TranslationContext';

interface ITimelineDashboardBoxProps {
  timelineSections: TimelineSection[];
  editTimelineUrl: string;
}

const TimelineDashboardBox: React.FunctionComponent<ITimelineDashboardBoxProps> = ({
  timelineSections,
  editTimelineUrl,
}) => {
  const { translate } = useContext(TranslationContext);
  return (
    <DashboardBox url={editTimelineUrl} title={translate('move timeline')}>
      {timelineSections.length > 0 ? (
        <ShowTimeline timelineSections={timelineSections} />
      ) : (
        <>
          <Box p={2}>
            <Typography variant="h6">{translate('no timeline created yet')}</Typography>
          </Box>
          <Box p={2}>
            <ButtonLink variant="contained" color="secondary" to={editTimelineUrl}>
              {translate('create new timeline')}
            </ButtonLink>
          </Box>
        </>
      )}
    </DashboardBox>
  );
};

export { TimelineDashboardBox };
