import { IApiServiceV1, IClients } from '../services/ApiServiceV1';
import RemoteData from 'ts-remote-data';
import { observable, action, computed } from 'mobx';
import { runInAction } from 'mobx';
import { SortDirection, compareValues } from '../utils/sort';

class ClientsStore {
  _apiService: IApiServiceV1;
  @observable selectedCountryId: string | undefined = undefined;
  @observable companyNameFilter: string | undefined = undefined;
  @observable orgNumberFilter: string | undefined = undefined;
  @observable selectedActiveInfoFilter: string | undefined = undefined;
  @observable remoteClients: RemoteData<IClients[]> = RemoteData.NOT_ASKED;
  @observable sortColumn: string | undefined;
  @observable sortDirection: SortDirection | undefined;

  constructor(apiService: IApiServiceV1) {
    this._apiService = apiService;
  }

  @action
  async fetch(forceFetch = false) {
    if (RemoteData.isReady(this.remoteClients) && !forceFetch) {
      return;
    }
    this.remoteClients = RemoteData.LOADING;
    try {
      const clients = await this._apiService.fetchClients();
      runInAction(() => {
        this.remoteClients = clients;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteClients = RemoteData.fail();
      });
    }
  }

  @action
  setCountryId(countryId?: string) {
    this.selectedCountryId = countryId;
  }

  @action
  setActiveFilter(activeInfoFilter?: string) {
    this.selectedActiveInfoFilter = activeInfoFilter;
  }

  @action
  setCompanyNameFilter(companyName?: string) {
    this.companyNameFilter = companyName;
  }

  @action
  setOrgNumberFilter(orgNumber?: string) {
    this.orgNumberFilter = orgNumber;
  }

  @computed
  get filteredSortedClients(): IClients[] {
    if (RemoteData.isReady(this.remoteClients)) {
      let filteredResult = this.selectedCountryId
        ? this.remoteClients.filter((client) => client.country.id === this.selectedCountryId)
        : this.remoteClients;

      filteredResult = this.selectedActiveInfoFilter
        ? filteredResult.filter((client) => Function('x', this.selectedActiveInfoFilter!)(client))
        : filteredResult.filter((client) => client.countOfActiveLocations > 0);

      filteredResult = this.companyNameFilter
        ? filteredResult.filter(
            (client) => client.name.toLowerCase().indexOf(this.companyNameFilter!.toLowerCase()) >= 0,
          )
        : filteredResult;

      filteredResult = this.orgNumberFilter
        ? filteredResult.filter(
            (client) => client.orgNumber.toLowerCase().indexOf(this.orgNumberFilter!.toLowerCase()) >= 0,
          )
        : filteredResult;

      return this.sortColumn && this.sortDirection ? this.sort(filteredResult) : filteredResult;
    } else {
      return [];
    }
  }

  @action
  setSort(sortColumn: string, sortDirection: SortDirection) {
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
  }

  private sort(clients: IClients[]) {
    if (RemoteData.isReady(this.remoteClients) && this.sortColumn && this.sortDirection) {
      const sortedClients = clients.slice();
      sortedClients.sort(compareValues(this.sortColumn.split('.'), this.sortDirection));
      return sortedClients;
    }

    return clients;
  }
}

export { ClientsStore };
