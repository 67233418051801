import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const StyledLink: React.FunctionComponent<LinkProps> = (props) => {
  const { link } = useStyles();
  return (
    <Link className={link} {...props}>
      {props.children}
    </Link>
  );
};

export { StyledLink };
