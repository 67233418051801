import { useEffect } from 'react';
import RemoteData from 'ts-remote-data';
import { IUser } from '../services/ApiServiceV1';
import * as H from 'history';
import { GetPathWithProps } from '../context/RouteMapContext';

export function useUserRedirect(
  remoteIsAuthenticated: RemoteData<boolean>,
  remoteUser: RemoteData<IUser>,
  history: H.History,
  getPathWithProps: GetPathWithProps,
) {
  useEffect(() => {
    if (
      RemoteData.isReady(remoteIsAuthenticated) &&
      remoteIsAuthenticated &&
      RemoteData.isReady(remoteUser) &&
      remoteUser
    ) {
      if (remoteUser.isExternal) {
        remoteUser.client
          ? history.replace(
              getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: remoteUser.client?.id }),
            )
          : history.replace(getPathWithProps('NO_CLIENT'));
      } else {
        history.replace(getPathWithProps('ADMIN_ROOT'));
      }
    }
  }, [getPathWithProps, history, remoteIsAuthenticated, remoteUser]);
}
