import Typography from '@material-ui/core/Typography';
import React, { useContext, useCallback } from 'react';
import { ServiceContext } from '../../context/ServicesContext';
import { NavigationBreadcrumbsWrapper } from '../../components/NavigationBreadcrumbs';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import RemoteData from 'ts-remote-data';
import LinearProgress from '@material-ui/core/LinearProgress';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { RouteMapContext } from '../../context/RouteMapContext';
import { TranslationContext } from '../../context/TranslationContext';
import { Header } from '../../components/typography/Header';
import { BrokeringProjectForm } from '../../components/BrokeringProjectForm';
import { IBrokeringPayload } from '../../services/ApiServiceV1';
import { NotificationContext } from '../../context/NotificationContext';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

const EditBrokeringProject: React.FunctionComponent = observer(() => {
  const {
    stores: { clientStore, brokeringStore },
    apiServiceV1,
  } = useContext(ServiceContext);
  const { clientId, brokeringId } = useParams<{ clientId: string; brokeringId: string }>();
  const { getPathWithProps } = useContext(RouteMapContext);
  const { translate } = useContext(TranslationContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const history = useHistory();

  useAsyncOperation(() => {
    return Promise.all([clientStore.fetchClientData(clientId), brokeringStore.fetchBrokering(brokeringId)]);
  }, [clientStore, brokeringStore]);

  const onSubmit = useCallback(
    async (values: IBrokeringPayload) => {
      try {
        const brokering = await apiServiceV1.updateBrokering(brokeringId, values);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully saved brokering project'),
            color: 'success',
          },
        });
        brokeringStore.setRemoteBrokering(brokering);
        history.push(
          getPathWithProps<{ clientId: string; brokeringId: string }>('BROKERING_DASHBOARD', { clientId, brokeringId }),
        );
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to update brokering project'),
            color: 'error',
          },
        });
      }
    },
    [apiServiceV1, brokeringId, brokeringStore, clientId, getPathWithProps, history, notificationDispatch, translate],
  );

  return (
    <>
      <RemoteSuspense
        data={RemoteData.all(clientStore.remoteClient, brokeringStore.remoteBrokering)}
        loadingFallback={<LinearProgress />}
        failureFallback={(error) => {
          return (
            <Typography variant="h5" color="error">
              {translate('failed to fetch data')}
            </Typography>
          );
        }}
      >
        {([client, brokering]) => {
          return (
            <>
              <NavigationBreadcrumbsWrapper
                items={[
                  {
                    content: client.name,
                    url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId }),
                  },
                  {
                    content: brokering.name,
                    url: getPathWithProps<{ clientId: string; brokeringId: string }>('BROKERING_DASHBOARD', {
                      clientId,
                      brokeringId,
                    }),
                  },
                  { content: translate('edit brokering project') },
                ]}
              />
            </>
          );
        }}
      </RemoteSuspense>
      <RemoteSuspense
        data={RemoteData.all(
          brokeringStore.remoteBrokering,
          brokeringStore.remoteClientContact,
          brokeringStore.remoteProjectManager,
          brokeringStore.remoteTeamMembers,
          brokeringStore.remoteExternalTeamMembers,
        )}
        loadingFallback={<LinearProgress />}
        failureFallback={(error) => {
          return (
            <Typography variant="h5" color="error">
              {translate('failed to fetch brokering data')}
            </Typography>
          );
        }}
      >
        {([brokering, brokeringClientContact, brokeringProjectManager, brokeringTeamMembers, externalTeamMembers]) => (
          <>
            <Header>{translate('edit {{brokeringProjectName}}', { brokeringProjectName: brokering.name })}</Header>
            <Paper>
              <Box py={6} px={5}>
                <BrokeringProjectForm
                  brokeringProject={brokering}
                  onSubmit={onSubmit}
                  clientContact={brokeringClientContact && brokeringClientContact.user}
                  projectManager={brokeringProjectManager && brokeringProjectManager.user}
                  teamMembers={brokeringTeamMembers.map((teamMember) => teamMember.user.user)}
                  externalTeamMembers={externalTeamMembers.map((externalTeamMember) => externalTeamMember.user)}
                />
              </Box>
            </Paper>
          </>
        )}
      </RemoteSuspense>
    </>
  );
});

export { EditBrokeringProject };
