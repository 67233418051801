import { LinearProgress } from '@material-ui/core';
import React, { useContext, useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { RemoteSuspense } from 'ts-remote-data-react';
import { SectionContainer } from '../../components/containers/SectionContainer';
import { TemplateForm } from '../../components/TemplateForm';
import { NotificationContext } from '../../context/NotificationContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { ITemplate, ITemplatePayload } from '../../services/ApiServiceV1';
import { isTemplateOrMovePage } from '../../utils/isTemplateOrMovePage';
import { useHistory } from 'react-router-dom';
import AdminContentHeader from '../../components/AdminContentHeader';
import { RouteMapContext } from '../../context/RouteMapContext';

const EditTemplate: React.FunctionComponent = () => {
  const { translate } = useContext(TranslationContext);
  const [remoteTemplate, setRemoteTemplate] = useState<RemoteData<ITemplate>>(RemoteData.NOT_ASKED);
  const { apiServiceV1 } = useContext(ServiceContext);
  const { templateId } = useParams<{ templateId: string }>();
  const { notificationDispatch } = useContext(NotificationContext);
  const history = useHistory();
  const { getPathWithProps } = useContext(RouteMapContext);

  useEffect(() => {
    async function fetchTemplate() {
      try {
        setRemoteTemplate(RemoteData.LOADING);
        const template = await apiServiceV1.fetchTemplate(templateId);
        setRemoteTemplate(template);
      } catch (error) {
        setRemoteTemplate(RemoteData.fail());
      }
    }
    fetchTemplate();
  }, [apiServiceV1, templateId]);

  const onSubmit = useCallback(
    async (values: ITemplatePayload) => {
      try {
        setRemoteTemplate(RemoteData.LOADING);
        const updatedTemplate = await apiServiceV1.updateTemplate(templateId, values);
        setRemoteTemplate(updatedTemplate);
        const path = getPathWithProps('TEMPLATES_ROOT');
        history.push(path);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully updated'),
            color: 'success',
          },
        });
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: 'failed to save template',
          },
        });
        setRemoteTemplate(RemoteData.failWith(remoteTemplate));
      }
    },
    [apiServiceV1, notificationDispatch, remoteTemplate, templateId, history, translate, getPathWithProps],
  );
  return (
    <>
      <AdminContentHeader headerText={translate('edit template')} />
      <SectionContainer>
        <RemoteSuspense
          data={remoteTemplate}
          loadingFallback={<LinearProgress />}
          failureFallback={(error) =>
            isTemplateOrMovePage(error) ? <TemplateForm onSubmit={onSubmit} template={error} /> : null
          }
        >
          {(template) => <TemplateForm onSubmit={onSubmit} template={template} />}
        </RemoteSuspense>
      </SectionContainer>
    </>
  );
};

export { EditTemplate };
