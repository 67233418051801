import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { observer } from 'mobx-react';
import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { CustomButton } from '../../components/CustomButton';
import { NavigationBreadcrumbsWrapper } from '../../components/NavigationBreadcrumbs';
import { Header } from '../../components/typography/Header';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { IMove, ILocationMove, IMoveContact } from '../../services/ApiServiceV1';
import { CreateMoveContact } from './CreateMoveContact';
import { MoveContactsList } from './MoveContactsList';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { NotificationContext } from '../../context/NotificationContext';

const MoveContacts: React.FunctionComponent = observer(() => {
  const { clientId, locationId, moveId } = useParams<{ clientId: string; locationId: string; moveId: string }>();
  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const {
    stores: { clientStore, locationStore, moveContactsStore },
  } = useContext(ServiceContext);

  useAsyncOperation(() => {
    return clientStore.fetchClientData(clientId);
  }, [clientId]);

  useAsyncOperation(() => {
    return locationStore.fetch(clientId, locationId);
  }, [locationId]);

  useAsyncOperation(() => {
    return moveContactsStore.fetch(moveId);
  }, []);

  return (
    <RemoteSuspense
      data={RemoteData.all(
        clientStore.remoteClient,
        locationStore.remoteLocation,
        moveContactsStore.sortedRemoteContacts,
      )}
      loadingFallback={<LinearProgress />}
    >
      {([client, location, moveContacts]) => (
        <>
          <NavigationBreadcrumbsWrapper
            items={[
              {
                content: client.name,
                url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
              },
              {
                content: location.name,
                url: getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
                  clientId: client.id,
                  locationId: location.id,
                }),
              },
              {
                content: translate('move contacts'),
              },
            ]}
          />
          <Header>{translate('move contacts')}</Header>
          {/* casting here since move has to be present on a location, since we're already beyond location dashboard page */}
          <MoveContactsImpl move={location.services.move as ILocationMove} moveContacts={moveContacts} />
        </>
      )}
    </RemoteSuspense>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  addNewContact: {
    marginBottom: theme.spacing(2),
  },
  descriptionText: {
    paddingBottom: theme.spacing(5),
  },
}));

interface IMoveContactsImplProps {
  move: ILocationMove | IMove;
  moveContacts: IMoveContact[];
}

const MoveContactsImpl: React.FunctionComponent<IMoveContactsImplProps> = observer(({ move, moveContacts }) => {
  const { translate } = useContext(TranslationContext);
  const {
    stores: { moveContactsUiStore, moveContactsStore },
  } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { addNewContact, descriptionText } = useStyles();
  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (result.destination) {
        try {
          await moveContactsStore.reorder(move.id, {
            newPosition: result.destination.index,
            contactId: result.draggableId,
          });
          notificationDispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              message: translate('successfully reordered move contacts'),
              color: 'success',
            },
          });
        } catch (error) {
          notificationDispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              message: translate('failed to reorder move contacts'),
              color: 'error',
            },
          });
        }
      }
    },
    [move.id, moveContactsStore, notificationDispatch, translate],
  );
  return (
    <Paper>
      <Box py={6} px={5}>
        <Typography variant="h6">{translate('contacts information')}</Typography>
        <Typography className={descriptionText}>
          {translate(
            'enter contact information about the people involved in the move service here. This is shown to the customer',
          )}
        </Typography>
        {moveContactsUiStore.shouldShowAddMoveContactForm === false && (
          <CustomButton
            variant="contained"
            color="secondary"
            onClick={() => {
              moveContactsUiStore.toggleShouldShowAddMoveContactForm();
            }}
            className={addNewContact}
          >
            {translate('add new contact')}
          </CustomButton>
        )}
        <CreateMoveContact move={move} />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="moveContactList">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <MoveContactsList moveContacts={moveContacts} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Paper>
  );
});

export { MoveContacts };
