import { action, observable } from 'mobx';
import { IBrokering, IClient, ICountry, ILocation } from '../services/ApiServiceV1';

class ClientDomainStore {
  id: string;
  name: string;
  orgNumber: string;
  country: ICountry;
  active: boolean;

  @observable
  locations: ILocation[];
  @observable
  brokerings: IBrokering[];

  constructor(client: IClient) {
    this.id = client.id;
    this.name = client.name;
    this.orgNumber = client.orgNumber;
    this.country = client.country;
    this.active = client.active;
    this.locations = client.locations;
    this.brokerings = client.brokerings;
  }

  @action
  addBrokering(newBrokering: IBrokering) {
    this.brokerings.push(newBrokering);
  }
}

export { ClientDomainStore };
