import { TimelineSection } from '@fmg-packages/common-components';
import { action, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';
import { IApiServiceV1, TimelineSectionType, ITimelineSectionPayload } from '../services/ApiServiceV1';

class TimelineSectionStore {
  @observable
  remoteTimelineSections: RemoteData<TimelineSection[]> = RemoteData.NOT_ASKED;

  _apiService: IApiServiceV1;

  constructor(apiService: IApiServiceV1) {
    this._apiService = apiService;
  }

  @action
  async fetch(resourceId: string, type: TimelineSectionType, forceFetch = false) {
    if (RemoteData.isReady(this.remoteTimelineSections) && !forceFetch) {
      return;
    }

    this.remoteTimelineSections = RemoteData.LOADING;
    try {
      const timelineSections =
        type === 'brokering'
          ? await this._apiService.fetchBrokeringTimelineSections(resourceId)
          : await this._apiService.fetchTimelineSections(resourceId);

      runInAction(() => {
        this.remoteTimelineSections = timelineSections;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteTimelineSections = RemoteData.fail();
      });
    }
  }

  @action
  async updateTimelineSection(timelineSection: TimelineSection, type: TimelineSectionType) {
    if (RemoteData.isReady(this.remoteTimelineSections)) {
      const tempTimelineSections = this.remoteTimelineSections;

      this.remoteTimelineSections = RemoteData.LOADING;
      try {
        const updatedTimelineSection =
          type === 'move'
            ? await this._apiService.updateTimelineSection(timelineSection.id, timelineSection)
            : await this._apiService.updateBrokeringTimelineSection(timelineSection.id, timelineSection);
        runInAction(() => {
          this.remoteTimelineSections = tempTimelineSections.map((section) => {
            if (updatedTimelineSection.id === section.id) {
              return updatedTimelineSection;
            }
            return section;
          });
        });
      } catch (error) {
        runInAction(() => {
          this.remoteTimelineSections = RemoteData.failWith(tempTimelineSections);
        });
      }
    }
  }

  @action
  async addTimelineSection(
    resourceId: string,
    timelineSectionPayload: ITimelineSectionPayload,
    type: TimelineSectionType,
  ) {
    if (RemoteData.isReady(this.remoteTimelineSections)) {
      const tempTimelineSections = this.remoteTimelineSections;

      this.remoteTimelineSections = RemoteData.LOADING;

      try {
        type === 'move'
          ? await this._apiService.addTimelineSection(resourceId, timelineSectionPayload)
          : await this._apiService.addBrokeringTimelineSection(resourceId, timelineSectionPayload);
        // NOTE: We're refetching here, since backend is in charge of sorting sections. Since we don't want to sort manually, we're just refetching timeline sections again
        this.fetch(resourceId, type, true);
      } catch (error) {
        runInAction(() => {
          this.remoteTimelineSections = RemoteData.failWith(tempTimelineSections);
        });
      }
    }
  }

  @action
  async deleteTimelineSection(timelineSectionId: string, type: TimelineSectionType) {
    if (RemoteData.isReady(this.remoteTimelineSections)) {
      const tempTimelineSections = this.remoteTimelineSections;
      this.remoteTimelineSections = RemoteData.LOADING;
      try {
        (await type) === 'move'
          ? this._apiService.deleteTimelineSection(timelineSectionId)
          : this._apiService.deleteBrokeringTimelineSection(timelineSectionId);
        runInAction(() => {
          this.remoteTimelineSections = tempTimelineSections.filter(
            (timelineSection) => timelineSection.id !== timelineSectionId,
          );
        });
      } catch (error) {
        runInAction(() => {
          this.remoteTimelineSections = RemoteData.failWith(tempTimelineSections);
        });
      }
    }
  }

  @action
  setRemoteTimelineSections(timelineSections: TimelineSection[]) {
    this.remoteTimelineSections = timelineSections;
  }
}

export { TimelineSectionStore };
