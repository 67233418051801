import React from 'react';

interface IProps {
  onChange?: (e: any) => void;
  placeholder?: string;
}

const GenericSearch: React.FunctionComponent<IProps> = (props: IProps) => {
  const { onChange, placeholder } = props;

  return (
    <input
      type="text"
      onChange={onChange}
      placeholder={placeholder}
      style={{ textTransform: 'uppercase', fontSize: '1.0rem' }}
    />
  );
};

export default GenericSearch;
