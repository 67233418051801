import RemoteData from 'ts-remote-data';
import { useAsyncOperation } from 'ts-remote-data-react';
import { IApiServiceV1 } from '../services/ApiServiceV1';
import { ClientStore } from '../stores/ClientStore';

export function useFetchClient(apiServiceV1: IApiServiceV1, id: string, clientStore: ClientStore) {
  return useAsyncOperation(async () => {
    if (RemoteData.isReady(clientStore.remoteClient) && clientStore.remoteClient.id === id) {
      return Promise.resolve(clientStore.remoteClient);
    }

    const client = await apiServiceV1.fetchClient(id);
    clientStore.setRemoteClient(client);

    return client;
  }, [apiServiceV1, id]);
}
