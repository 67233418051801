import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { IUser } from '../../services/ApiServiceV1';
import { StyledLink } from '../../components/StyledLink';
import { RouteMapContext } from '../../context/RouteMapContext';
import { useContext } from 'react';

const UserTableRow: React.FunctionComponent<IUser> = ({ client, user }) => {
  const { getPathWithProps } = useContext(RouteMapContext);
  return (
    <TableRow>
      <TableCell>{client !== null ? client.name : ''}</TableCell>
      <TableCell>
        <StyledLink to={getPathWithProps<{ userId: string }>('EDIT_USER', { userId: (user.id as unknown) as string })}>
          {user.displayName}
        </StyledLink>
      </TableCell>
      <TableCell>{client !== null ? client.country.name : ''}</TableCell>
      <TableCell>{user.externalUserState}</TableCell>
    </TableRow>
  );
};

export { UserTableRow };
