import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { ClientUserWrapper } from '../../components/ClientUserWrapper';
import { NavigationBreadcrumbs } from '../../components/NavigationBreadcrumbs';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { TimelineForm } from '../../components/TimelineForm';
import { observer } from 'mobx-react';

const MoveTimeline: React.FunctionComponent = observer(() => {
  const { translate } = useContext(TranslationContext);
  const {
    stores: { locationStore },
  } = useContext(ServiceContext);
  const { getPathWithProps } = useContext(RouteMapContext);

  const { clientId, locationId, moveId } = useParams<{ clientId: string; locationId: string; moveId: string }>();

  useAsyncOperation(() => {
    return locationStore.fetch(clientId, locationId);
  }, [clientId, locationId]);

  return (
    <ClientUserWrapper
      render={(client) => (
        <>
          <RemoteSuspense data={locationStore.remoteLocation}>
            {(location) => (
              <NavigationBreadcrumbs
                items={[
                  {
                    content: client.name,
                    url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                  },
                  {
                    content: location.name,
                    url: getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
                      clientId: client.id,
                      locationId: location.id,
                    }),
                  },
                  {
                    content: translate('move timeline'),
                  },
                ]}
              />
            )}
          </RemoteSuspense>
          <TimelineForm timelineResourceId={moveId} timelineType="move" />
        </>
      )}
    />
  );
});

export { MoveTimeline };
