import 'react-app-polyfill/ie11';
import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './screens/app/App';
import * as serviceWorker from './serviceWorker';
import { ServiceProvider } from './context/ServicesContext';
import { AuthenticationProvider } from './context/AuthenticationContext';
import theme from './context/Theme';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import './i18n';
import TranslationProvider from './context/TranslationContext';
import { NotificationProvider } from './context/NotificationContext';
import { ModalContextProvider } from './context/ModalContext';
import 'mobx-react/batchingForReactDom';
import { BrowserRouter } from 'react-router-dom';
import { RouteMapContextProvider } from './context/RouteMapContext';
import { ROUTES } from './routes';
import { DayjsProvider } from './context/DayjsContext';

const ContextApp: React.FunctionComponent = () => (
  <BrowserRouter>
    <ServiceProvider>
      <AuthenticationProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <TranslationProvider>
            <DayjsProvider>
              <NotificationProvider>
                <ModalContextProvider>
                  <RouteMapContextProvider routes={ROUTES}>
                    <App />
                  </RouteMapContextProvider>
                </ModalContextProvider>
              </NotificationProvider>
            </DayjsProvider>
          </TranslationProvider>
        </ThemeProvider>
      </AuthenticationProvider>
    </ServiceProvider>
  </BrowserRouter>
);

ReactDOM.render(<ContextApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
