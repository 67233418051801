import React from 'react';
import Typography from '@material-ui/core/Typography';

const AdminHome: React.FunctionComponent = () => {
  return (
    <div>
      <Typography>Admin dashboard goes here</Typography>
    </div>
  );
};

export default AdminHome;
