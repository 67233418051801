import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormikCheckbox, FormikFormInput, FormikKeyboardDatePicker } from '../../components/FormikInputs';
import { useContext } from 'react';
import { TranslationContext } from '../../context/TranslationContext';
import { numberedDateFormat } from '@fmg-packages/common-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import { CustomButton } from '../../components/CustomButton';
import * as yup from 'yup';
import { IReminder, IReminderPayload } from '../../services/ApiServiceV1';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IReminderFormProps {
  onSubmit: (values: IReminderPayload, formHelpers: FormikHelpers<IReminderPayload>) => Promise<void>;
  reminder?: IReminder;
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
  },
}));

const ReminderForm: React.FunctionComponent<IReminderFormProps> = ({ onSubmit, reminder }) => {
  const initialValues: IReminderPayload = {
    title: reminder?.title || '',
    description: reminder?.description || '',
    dueDate: reminder?.dueDate || null,
    done: reminder?.done || false,
    doneComment: reminder?.doneComment || '',
  };

  const { translate } = useContext(TranslationContext);
  const { form } = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object({
        title: yup.string().required(translate('field is required')),
        description: yup.string().required(translate('field is required')),
        dueDate: yup.mixed().required(translate('field is required')),
      })}
      onSubmit={(values: IReminderPayload, formHelpers: FormikHelpers<IReminderPayload>) => {
        onSubmit(values, formHelpers);
      }}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <Form className={form}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FormikFormInput
                formikKey="title"
                labelContent={translate('title')}
                formControlProps={{ fullWidth: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <MuiPickersUtilsProvider utils={DayjsUtils}>
                <FormikKeyboardDatePicker
                  formikKey="dueDate"
                  labelContent={translate('due date')}
                  format={numberedDateFormat}
                  inputVariant="outlined"
                  formControlProps={{ fullWidth: true }}
                  disablePast={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={12} lg={8}>
              <FormikFormInput
                formikKey="description"
                multiline={true}
                rows={7}
                labelContent={translate('description')}
                formControlProps={{ fullWidth: true }}
              />
            </Grid>
            {reminder && (
              <>
                <Grid item xs={12} md={12} lg={8}>
                  <FormikCheckbox formikKey="done" labelContent={translate('mark as done')} />
                </Grid>
                <Grid item xs={12} md={12} lg={8}>
                  <FormikFormInput
                    formikKey="doneComment"
                    multiline={true}
                    rows={4}
                    labelContent={translate('done comment')}
                    formControlProps={{ fullWidth: true }}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <CustomButton variant="contained" color="secondary" type="submit" disabled={isSubmitting}>
                {reminder ? translate('update reminder') : translate('save')}
              </CustomButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export { ReminderForm };
