import React, { useContext } from 'react';
import { TranslationContext } from '../../context/TranslationContext';
import { ServiceContext } from '../../context/ServicesContext';
import { ILocationPayload } from '../../services/ApiServiceV1';
import { LocationForm } from '../../components/LocationForm';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { NotificationContext } from '../../context/NotificationContext';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { LinearProgress, Typography } from '@material-ui/core';
import { SectionContainer } from '../../components/containers/SectionContainer';
import AdminContentHeader from '../../components/AdminContentHeader';
import { useHistory } from 'react-router-dom';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ClientUserWrapper } from '../../components/ClientUserWrapper';
import { NavigationBreadcrumbs } from '../../components/NavigationBreadcrumbs';
import { observer } from 'mobx-react';

const LocationEdit: React.FunctionComponent = observer(() => {
  const { translate } = useContext(TranslationContext);
  const {
    stores: { locationStore, locationsStore, clientStore },
  } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);

  const { clientId, locationId } = useParams<{ clientId: string; locationId: string }>();

  useAsyncOperation(() => {
    return locationStore.fetch(clientId, locationId);
  }, [clientId, locationId]);

  const history = useHistory();
  const { getPathWithProps } = useContext(RouteMapContext);

  const onSubmit = useCallback(
    async (payload: ILocationPayload) => {
      try {
        // TODO: refactor this by making sure that stores can reference each other instead of having separate calls to different stores
        const updatedLocation = await locationsStore.update(clientId, locationId, payload);
        clientStore.updateClientLocations(updatedLocation);
        locationStore.updateLocation(updatedLocation);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully saved location'),
            color: 'success',
          },
        });
        const path = getPathWithProps<{
          clientId: string;
          locationId: string;
        }>('CLIENT_LOCATION', {
          clientId: clientId,
          locationId: locationId,
        });
        history.push(path);
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to save location'),
            color: 'error',
          },
        });
      }
    },
    [
      locationsStore,
      clientId,
      locationId,
      clientStore,
      locationStore,
      notificationDispatch,
      translate,
      getPathWithProps,
      history,
    ],
  );
  return (
    <ClientUserWrapper
      render={(client) => (
        <RemoteSuspense
          data={locationStore.remoteLocation}
          loadingFallback={<LinearProgress />}
          failureFallback={<Typography color="error">{translate('failed to fetch data')}</Typography>}
        >
          {(location) => (
            <>
              <NavigationBreadcrumbs
                items={[
                  {
                    content: client.name,
                    url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                  },
                  {
                    content: location.name,
                    url: getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
                      clientId: client.id,
                      locationId: location.id,
                    }),
                  },
                  { content: translate('edit location') },
                ]}
              />
              <AdminContentHeader headerText={translate('edit {{location}}', { location: location.name })} />
              <SectionContainer
                title={translate('key information')}
                subtitle={translate('enter the information about the location here')}
              >
                <LocationForm formSubmit={onSubmit} location={location} />
              </SectionContainer>
            </>
          )}
        </RemoteSuspense>
      )}
    />
  );
});

export { LocationEdit };
