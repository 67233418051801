import React from 'react';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootCustomLabel: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
}));

const CustomInputLabel: React.FunctionComponent<InputLabelProps> = ({ children, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <InputLabel
      {...props}
      classes={{
        root: classes.rootCustomLabel,
      }}
    >
      {children}
    </InputLabel>
  );
};

export { CustomInputLabel };
