import React, { useContext, useCallback } from 'react';
import { TranslationContext } from '../../context/TranslationContext';
import { TemplateForm } from '../../components/TemplateForm';
import { ServiceContext } from '../../context/ServicesContext';
import { ITemplatePayload } from '../../services/ApiServiceV1';
import { NotificationContext } from '../../context/NotificationContext';
import { SectionContainer } from '../../components/containers/SectionContainer';
import { useHistory } from 'react-router-dom';
import AdminContentHeader from '../../components/AdminContentHeader';
import { RouteMapContext } from '../../context/RouteMapContext';

const CreateTemplate: React.FunctionComponent = () => {
  const { translate } = useContext(TranslationContext);
  const {
    apiServiceV1,
    stores: { templatesStore },
  } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const history = useHistory();
  const { getPathWithProps } = useContext(RouteMapContext);

  const onSubmit = useCallback(
    async (values: ITemplatePayload) => {
      try {
        values.position = templatesStore.getCurrentPosition();
        await apiServiceV1.addTemplate(values);
        const path = getPathWithProps('TEMPLATES_ROOT');
        history.push(path);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully added'),
            color: 'success',
          },
        });
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to save a template'),
          },
        });
      }
    },
    [templatesStore, apiServiceV1, getPathWithProps, history, notificationDispatch, translate],
  );

  return (
    <>
      <AdminContentHeader headerText={translate('create template')} />
      <SectionContainer>
        <TemplateForm onSubmit={onSubmit} />
      </SectionContainer>
    </>
  );
};

export { CreateTemplate };
