import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import { Formik, Form } from 'formik';
import React from 'react';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import Grid from '@material-ui/core/Grid';
import { FormikFormInput } from '../FormikInputs';
import { useContext } from 'react';
import { TranslationContext } from '../../context/TranslationContext';
import { CustomButton } from '../CustomButton';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormikKeyboardDatePicker } from '../FormikInputs';
import { makeStyles } from '@material-ui/core/styles';
import { TimelineEventPayload } from '../../services/ApiServiceV1';
import { DayjsContext } from '../../context/DayjsContext';
import { TimelineEvent, numberedDateFormat } from '@fmg-packages/common-components';
import * as yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import { ModalContext } from '../../context/ModalContext';
import { ConfirmDialog } from '../ConfirmDialog';

export interface IEventTimelineFormProps {
  shouldConnect: boolean;
  onDotIconClick: () => void;
  timelineEvent?: TimelineEvent;
  onSubmit: (values: TimelineEventPayload) => void;
  onDelete?: () => Promise<void>;
}

const useStyles = makeStyles((theme) => ({
  timelineDot: {
    borderStyle: 'dotted',
    borderColor: theme.palette.common.white,
  },
  white: {
    color: theme.palette.common.white,
  },
  timelineEventButtonContainer: {
    paddingBottom: theme.spacing(2),
  },
}));

const EventTimelineForm: React.FunctionComponent<IEventTimelineFormProps> = ({
  shouldConnect,
  onDotIconClick,
  timelineEvent,
  onSubmit,
  onDelete,
}) => {
  const { timelineDot, white, timelineEventButtonContainer } = useStyles();
  const { translate } = useContext(TranslationContext);
  const { dayjsLocale } = useContext(DayjsContext);
  const { setModalState } = useContext(ModalContext);

  const initialValues: TimelineEventPayload = {
    title: timelineEvent ? timelineEvent.title : '',
    description: timelineEvent && timelineEvent.description ? timelineEvent.description : '',
    startDate: timelineEvent ? timelineEvent.startDate : dayjs(),
    endDate: timelineEvent && timelineEvent.endDate ? timelineEvent.endDate : ((null as unknown) as Dayjs),
  };

  return (
    <Formik
      onSubmit={(values: TimelineEventPayload) => {
        if (!values.endDate) {
          values.endDate = null;
        }
        if (values.description === '') {
          values.description = null;
        }
        onSubmit(values);
      }}
      initialValues={initialValues}
      validationSchema={yup.object({
        title: yup.string().required(translate('field is required')),
        startDate: yup.string().required(translate('field is required')),
      })}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form name="event-timeline-form" data-testid="timeline-event-form">
            <TimelineItem>
              <TimelineOppositeContent>
                <MuiPickersUtilsProvider utils={DayjsUtils} locale={dayjsLocale}>
                  <Grid container direction="column" spacing={3}>
                    <Grid item>
                      <FormikKeyboardDatePicker
                        formikKey="startDate"
                        labelContent={translate('start')}
                        showLabel={false}
                        format={numberedDateFormat}
                        inputVariant="outlined"
                        placeholder={translate('start')}
                      />
                    </Grid>
                    {values.startDate && (
                      <Grid item>
                        <FormikKeyboardDatePicker
                          formikKey="endDate"
                          labelContent={translate('end')}
                          showLabel={false}
                          format={numberedDateFormat}
                          inputVariant="outlined"
                          placeholder={translate('end')}
                          minDate={values.startDate.add(1, 'day')}
                        />
                      </Grid>
                    )}
                  </Grid>
                </MuiPickersUtilsProvider>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <div>
                  <TimelineDot className={timelineDot}>
                    <IconButton onClick={onDotIconClick} size="small">
                      {timelineEvent ? <CreateIcon className={white} /> : <AddIcon />}
                    </IconButton>
                  </TimelineDot>
                </div>
                {shouldConnect && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <FormikFormInput
                      formikKey="title"
                      labelContent={translate('title')}
                      showLabel={false}
                      placeholder={translate('title')}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormikFormInput
                      formikKey="description"
                      labelContent={translate('description')}
                      showLabel={false}
                      multiline={true}
                      placeholder={translate('description')}
                      rows={7}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        maxLength: 255,
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    classes={{
                      root: timelineEventButtonContainer,
                    }}
                  >
                    <Grid item>
                      <CustomButton type="submit" variant="contained" color="secondary" disabled={isSubmitting}>
                        {translate('save')}
                      </CustomButton>
                    </Grid>

                    {timelineEvent && onDelete && (
                      <Grid item>
                        <CustomButton
                          variant="contained"
                          color="default"
                          onClick={() => {
                            setModalState(
                              true,
                              <ConfirmDialog
                                data={timelineEvent.id}
                                handleClick={onDelete}
                                dialogTitle={translate('are you sure you want to delete ')}
                                recordTitle={timelineEvent.title}
                                content={translate("This item will be deleted immediately. You can't undo this action")}
                                primaryButtonLabel={translate('delete')}
                                cancelLabel={translate('cancel')}
                              />,
                            );
                          }}
                        >
                          {translate('delete')}
                        </CustomButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </TimelineContent>
            </TimelineItem>
          </Form>
        );
      }}
    </Formik>
  );
};

export { EventTimelineForm };
