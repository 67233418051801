import { Translate } from '../context/TranslationContext';
import { Lang } from '../i18n';
import { assertNever } from '../utils/assertNever';

export type Languages = Record<Lang, string>;

export const languages: Languages = {
  no: 'Norwegian - bokmål',
  en: 'English',
  de: 'German',
  da: 'Danish',
  se: 'Swedish',
  nn: 'Norwegian - nynorsk',
};

const languageArray: Lang[] = ['en', 'no', 'nn', 'de', 'da', 'se'];

interface ITranslatedLanguage {
  language: Lang;
  translatedLanguageName: string;
}

export function getTranslatedLanguages(translate: Translate): ITranslatedLanguage[] {
  return languageArray.map((lang) => {
    let translatedLanguage: string;
    switch (lang) {
      case 'en':
        translatedLanguage = translate('english');
        break;
      case 'no':
        translatedLanguage = translate('norwegian');
        break;
      case 'de':
        translatedLanguage = translate('german');
        break;
      case 'da':
        translatedLanguage = translate('danish');
        break;
      case 'se':
        translatedLanguage = translate('swedish');
        break;
      case 'nn':
        translatedLanguage = translate('norwegiannynorsk');
        break;
      default:
        assertNever(lang);
    }

    return {
      language: lang,
      translatedLanguageName: translatedLanguage,
    };
  });
}
