import dayjs from 'dayjs';
import { action, computed, observable } from 'mobx';
import RemoteData from 'ts-remote-data';
import { IReminder } from '../../services/ApiServiceV1';
import { assertNever } from '../../utils/assertNever';
import { compareValues } from '../../utils/sort';
import { ReminderDomainStore } from './ReminderDomainStore';
import { RootStore } from './../RootStore';

export type ReminderFilter = 'all' | 'pending' | 'done';

class LeaseRemindersUiStore {
  @observable
  shouldShowAddReminderForm = false;

  @observable
  reminderFilter: ReminderFilter = 'pending';

  @observable
  shouldFilterPastReminders = false;

  constructor(private rootStore: RootStore) {}

  @action
  toggleShouldShowAddReminderForm() {
    this.shouldShowAddReminderForm = !this.shouldShowAddReminderForm;
  }

  @action
  toggleShouldFilterPastReminders() {
    this.shouldFilterPastReminders = !this.shouldFilterPastReminders;
  }

  @action
  setReminderFilter(filterValue: ReminderFilter) {
    this.reminderFilter = filterValue;
  }

  @computed
  get sortedRemoteReminders(): RemoteData<ReminderDomainStore[]> {
    if (RemoteData.isReady(this.rootStore.leaseRemindersStore.remoteReminders)) {
      const sortedReminders = this.sortRemindersByDueDate(this.rootStore.leaseRemindersStore.remoteReminders);
      const filteredReminders = this.filterRemindersBySelectedFilter(sortedReminders);

      return this.shouldFilterPastReminders ? this.filterPastReminders(filteredReminders) : filteredReminders;
    } else {
      return this.rootStore.leaseRemindersStore.remoteReminders;
    }
  }

  private sortRemindersByDueDate(reminders: ReminderDomainStore[]): ReminderDomainStore[] {
    const remindersCopy = reminders.slice();
    const unixDateReminders = remindersCopy.map((reminder) => ({
      ...reminder,
      dueDate: reminder.dueDate.unix(),
    }));

    /**
     * TODO: create utils service and inject it into stores so that it can be used without importing it directly into file
     */
    unixDateReminders.sort(compareValues(['dueDate'], 'asc'));

    const sortedReminders = unixDateReminders
      .map<IReminder>((unixDateReminder) => ({
        ...unixDateReminder,
        dueDate: dayjs.unix(unixDateReminder.dueDate),
      }))
      .map((reminder) => new ReminderDomainStore(reminder));

    return sortedReminders;
  }

  private filterRemindersBySelectedFilter(reminders: ReminderDomainStore[]): ReminderDomainStore[] {
    return reminders.filter((reminder) => {
      let result: boolean;
      switch (this.reminderFilter) {
        case 'all':
          result = true;
          break;
        case 'pending':
          result = !reminder.done;
          break;
        case 'done':
          result = reminder.done;
          break;
        default:
          assertNever(this.reminderFilter);
      }

      return result;
    });
  }

  private filterPastReminders(reminders: ReminderDomainStore[]): ReminderDomainStore[] {
    const now = dayjs();

    return reminders.filter(
      (reminder) => now.isBefore(reminder.dueDate, 'date') || now.isSame(reminder.dueDate, 'date'),
    );
  }
}

export { LeaseRemindersUiStore };
