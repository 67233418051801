const parse5 = require('parse5');

let HLA = [];
let ORDERED_ATTRS = [
  'id',
  'class',

  'accept',
  'accept-charset',
  'accesskey',
  'action',
  'align',
  'alt',
  'async',
  'autocomplete',
  'autofocus',
  'autoplay',
  'color',
  'data',
  'for',
  'href',
  'min',
  'max',
  'name',
  'src',

  'ng-class',
  'ng-if',
  'ng-show',
  'ng-hide'
];

function buildHLA(HTO) {
  HLA = [];
  HLA = _traverse(HTO);

  HLA = _removeFragment(HLA);
  HLA = _indent(HLA);

  return _buildPage(HLA);
}

function _traverse(node) {
  switch (node.nodeName) {
      case '#comment':
        _handleCommentTraverse(node);
        break;
      case '#text':
        _handleTextTraverse(node);
        break;
      case 'img':
        _handleVoidElementTraverse(node);
        break;
      case 'input':
        _handleVoidElementTraverse(node);
        break;
      case 'br':
        _handleVoidElementTraverse(node);
        break;
      default:
        _handleTagTraverse(node);
  }

  return HLA;
}

function _handleTagTraverse(node) {
  let htmlLine = {
    line: `<${node.nodeName}`,
    indentSize: node.indentSize,
    tagStatus: 'open',
    node: node.nodeName,
  };


  node.orderedAttrs = _orderAttrs(node);

  if (node.orderedAttrs && node.orderedAttrs.length > 1) {

    HLA.push(htmlLine);
    for (let index = 0; index < node.orderedAttrs.length; index++) {
      let attr = node.orderedAttrs[index];
      let prefix = attr.prefix ? (attr.prefix + ':') : '';
      let name = attr.name || '';
      let value = attr.value ? ('="' + attr.value + '"') : '';
      htmlLine = {
        line: `${prefix}${name}${value}`,
        indentSize: node.indentSize + 1,
        isMultipleAttr: (node.orderedAttrs.length > 1),
        isAttr: true,
      };

      htmlLine.line += (index === (node.orderedAttrs.length - 1)) ? `>`:``;
      HLA.push(htmlLine);
    }
  } else if (node.orderedAttrs && node.orderedAttrs.length === 1) {
    let attr = node.orderedAttrs[0];
    let prefix = attr.prefix ? (attr.prefix + ':') : '';
    let name = attr.name || '';
    let value = attr.value ? ('="' + attr.value + '"') : '';

    htmlLine.line += ` ${prefix}${name}${value}>`;
    HLA.push(htmlLine);
  } else {
    htmlLine.line += `>`;
    HLA.push(htmlLine);
  }

  if (node.childNodes) {
    for (let childNode of node.childNodes) {
      _traverse(childNode);
    }
  }

  htmlLine = {
    line: `</${node.nodeName}>`,
    indentSize: node.indentSize,
    tagStatus: 'close',
    node: node.nodeName,
  };

  HLA.push(htmlLine);
}

function _handleVoidElementTraverse(node) {
  var htmlLine = {
    line: `<${node.nodeName}`,
    indentSize: node.indentSize,
    tagStatus: 'void',
    node: node.nodeName,
  };


  node.orderedAttrs = _orderAttrs(node);

  if (node.orderedAttrs && node.orderedAttrs.length > 1) {

    HLA.push(htmlLine);
    for (let index = 0; index < node.orderedAttrs.length; index++) {
      let attr = node.orderedAttrs[index];
      let prefix = attr.prefix ? (attr.prefix + ':') : '';
      let name = attr.name || '';
      let value = attr.value ? ('="' + attr.value + '"') : '';

      htmlLine = {
        line: `${prefix}${name}${value}`,
        indentSize: node.indentSize + 1,
        isMultipleAttr: (node.orderedAttrs.length > 1),
        isAttr: true,
      };

      htmlLine.line += (index === (node.orderedAttrs.length - 1)) ? `/>`:``;
      HLA.push(htmlLine);
    }
  } else if (node.orderedAttrs && node.orderedAttrs.length === 1) {
    let attr = node.orderedAttrs[0];
    let prefix = attr.prefix ? (attr.prefix + ':') : '';
    let name = attr.name || '';
    let value = attr.value ? ('="' + attr.value + '"') : '';
    htmlLine.line += ` ${prefix}${name}${value}/>`;
    HLA.push(htmlLine);
  } else {
    htmlLine.line += `/>`;
    HLA.push(htmlLine);
  }
}

function _handleTextTraverse(node) {
  var htmlLine = {
    line: '',
    indentSize: node.indentSize,
    tagStatus: 'void',
  };

  htmlLine.line = node.value.replace(/\n/g, ' ');
  htmlLine.line = htmlLine.line.trim();
  htmlLine.line = htmlLine.line.replace(/\t/g, '');
  if (htmlLine.line !== '') HLA.push(htmlLine);
}

function _handleCommentTraverse(node) {
  let comment = node.data;
  comment = comment.replace(/\n/g, ' ');
  comment = comment.replace(/\t/g, '');
  comment = comment.trim();

  var htmlLine = {
    line: ` ${comment} `,
    indentSize: node.indentSize + 1,
    tagStatus: 'void',
  };

  if (comment !== '') {
    HLA.push({
      line: '<!--',
      indentSize: node.indentSize,
      tagStatus: 'open',
    });
    HLA.push(htmlLine);
    HLA.push({
      line: '-->',
      indentSize: node.indentSize,
      tagStatus: 'close',
    });
  }


}

function _orderAttrs(node) {
  if (node.attrs && node.attrs.length) {
    node.orderedAttrs = [];
    for (let orderedAttr of ORDERED_ATTRS) {
      for (let index = 0; index < node.attrs.length; index++) {
        let attr = node.attrs[index];
        if (attr.name === orderedAttr) {
          node.orderedAttrs.push(attr);
          node.attrs.splice(index, 1);
          break;
        }
      }
    }


    if (node.attrs.length) {
      node.orderedAttrs = node.orderedAttrs.concat(node.attrs);
    }

  }

  return node.orderedAttrs;
}

function _removeFragment(hla) {
  let newHla = [];
  for (let lineObj of hla) {
    if (['#document-fragment', '#document'].indexOf(lineObj.node) > -1) {
      continue;
    } else {
      newHla.push(lineObj);
    }
  }

  return newHla;
}

function _indent(hla) {
  for (let lineObj of hla) {
    lineObj.line = '  '.repeat(lineObj.indentSize) + lineObj.line;
  }

  return hla;
}

function _buildPage(hla) {
  let page = '';

  for (let lineObj of hla) {
    page += lineObj.line + '\n';
  }

  return page;
}

let HTO = {};

function parse(file) {
  let ast = parse5.parseFragment(file);
  return ast;
}

function initHTO(root) {
  HTO = root;

  if (HTO.childNodes) {
    _traverseForHTO(HTO, -1);
  }

  return HTO;
}

function _traverseForHTO(node, indentBefore) {
  node.indentSize = indentBefore;
  delete node.parentNode;
  indentBefore ++;
  if (node.childNodes) {
    for (let childNode of node.childNodes) {
        _traverseForHTO(childNode, indentBefore);
    }
  }
}

export function lintVar(data) {
  let root = parse(data);
  let HTO = initHTO(root, 0);
  let HLA = buildHLA(HTO);

  return HLA;
}