import Grid from '@material-ui/core/Grid';
import { Formik, Form, FormikHelpers } from 'formik';
import React from 'react';
import { useContext } from 'react';
import { CustomButton } from '../../components/CustomButton';
import { FormikFormInput } from '../../components/FormikInputs';
import { TranslationContext } from '../../context/TranslationContext';
import { IMoveContact, IMoveContactPayload } from '../../services/ApiServiceV1';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as yup from 'yup';

interface IMoveContactFormProps {
  onSubmit: (values: IMoveContactPayload, helpers: FormikHelpers<IMoveContactPayload>) => Promise<void>;
  moveContact?: IMoveContact;
}

const MoveContactForm: React.FunctionComponent<IMoveContactFormProps> = ({ onSubmit, moveContact }) => {
  const initialValues: IMoveContactPayload = {
    name: moveContact?.name || '',
    phonenumber: moveContact?.phonenumber || null,
    email: moveContact?.email || null,
    title: moveContact?.title || null,
  };
  const { translate } = useContext(TranslationContext);
  const theme = useTheme();
  const shouldDisplaySpaceGrid = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object({
        name: yup.string().required(translate('field is required')),
        email: yup.string().email(translate('field must be a valid email')),
      })}
      onSubmit={(values: IMoveContactPayload, helpers) => onSubmit(values, helpers)}
    >
      {() => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FormikFormInput
                formikKey="name"
                labelContent={translate('name')}
                formControlProps={{ fullWidth: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FormikFormInput
                formikKey="title"
                labelContent={translate('title')}
                formControlProps={{ fullWidth: true }}
              />
            </Grid>
            {shouldDisplaySpaceGrid && <Grid item lg={4} />}
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FormikFormInput
                formikKey="phonenumber"
                labelContent={translate('phone')}
                formControlProps={{ fullWidth: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FormikFormInput
                formikKey="email"
                labelContent={translate('email')}
                formControlProps={{ fullWidth: true }}
              />
            </Grid>
            {shouldDisplaySpaceGrid && <Grid item lg={4} />}
            <Grid item xs={12}>
              <CustomButton type="submit" variant="contained" color="secondary">
                {translate('save')}
              </CustomButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export { MoveContactForm };
