import { action, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';
import { IApiServiceV1, ITemplate } from '../services/ApiServiceV1';

class TemplatesStore {
  @observable
  remoteTemplates: RemoteData<ITemplate[]> = RemoteData.NOT_ASKED;

  _apiService: IApiServiceV1;

  constructor(apiService: IApiServiceV1) {
    this._apiService = apiService;
  }

  @action
  async fetchTemplates(forceFetch = false) {
    if (RemoteData.isReady(this.remoteTemplates) && !forceFetch) {
      return;
    }
    this.remoteTemplates = RemoteData.LOADING;
    try {
      const templates = await this._apiService.fetchTemplates();
      runInAction(() => {
        this.remoteTemplates = templates;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteTemplates = RemoteData.fail();
      });
    }
  }

  @action
  async deleteTemplate(templateId: string) {
    try {
      await this._apiService.deleteTemplate(templateId);
      await this.fetchTemplates(true);
      return true;
    } catch (error) {
      return false;
    }
  }

  @action
  async updateTemplatePosition(templateId: string, position: number) {
    try {
      await this._apiService.updateTemplatePosition(templateId, position);
      await this.fetchTemplates(true);
      return true;
    } catch (error) {
      return false;
    }
  }

  @action
  async updateAvailableForInternal(templateId: string, availableForInternal: boolean) {
    try {
      await this._apiService.updateAvailableForInternal(templateId, availableForInternal);
      await this.fetchTemplates(true);
      return true;
    } catch (error) {
      return false;
    }
  }

  @action
  async updateAvailableForCustomer(templateId: string, availableForCustomer: boolean) {
    try {
      await this._apiService.updateAvailableForCustomer(templateId, availableForCustomer);
      await this.fetchTemplates(true);
      return true;
    } catch (error) {
      return false;
    }
  }

  @action
  getCurrentPosition(): number {
    if (RemoteData.isReady(this.remoteTemplates)) {
      const templates = this.remoteTemplates.slice();
      return Math.max(...templates.map((t) => t.position)) + 1;
    }
    return 0;
  }
}

export { TemplatesStore };
