import { action, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';
import { IApiServiceV1, IMove, IUser } from '../services/ApiServiceV1';
import { updateTeamMembers } from '../utils/updateTeamMembers';
import { RootStore } from './RootStore';
import { TimelineSectionStore } from './TimelineSectionStore';
import { UserDomainStore } from './UserDomainStore';

class MoveStore {
  @observable
  remoteMove: RemoteData<IMove> = RemoteData.NOT_ASKED;

  @observable
  remoteClientContact: RemoteData<IUser[] | undefined> = RemoteData.NOT_ASKED;

  @observable
  remoteTeamMemberUsers: RemoteData<UserDomainStore[]> = RemoteData.NOT_ASKED;

  @observable
  timelineSections: TimelineSectionStore;

  _apiService: IApiServiceV1;

  constructor(apiService: IApiServiceV1, private rootStore: RootStore) {
    this._apiService = apiService;
    this.timelineSections = new TimelineSectionStore(apiService);
  }

  @action
  async fetchMove(locationId: string, moveId: string, forceFetch = false) {
    if (RemoteData.isReady(this.remoteMove) && this.remoteMove.id === moveId && !forceFetch) {
      return;
    }

    this.timelineSections.fetch(moveId, 'move', true);

    this.remoteMove = RemoteData.LOADING;
    try {
      const move = await this._apiService.fetchMove(locationId, moveId);

      runInAction(() => {
        this.remoteMove = move;
        if (move.clientContact) {
          this.remoteClientContact = RemoteData.LOADING;
        }
        this.remoteTeamMemberUsers = RemoteData.LOADING;
      });

      const teamMembersPromises = move.teamMembers.map((teamMember) => this._apiService.fetchUser(teamMember.id));

      const clientContactPromises = move.clientContact
        ? move.clientContact.split(',').map((c) => this._apiService.fetchUser(c))
        : [];

      // there might be users that are not found or failed to fetch so we need to filter out only the ones that have been fetched
      const settledTeamMemberPromises = Promise.allSettled(teamMembersPromises);
      const settledClientContactPromises = Promise.allSettled(clientContactPromises);

      const [settledTeamMembers, settledClientContacts] = await Promise.all<PromiseSettledResult<IUser>[]>([
        settledTeamMemberPromises,
        settledClientContactPromises,
      ]);
      const fullfilledTeamMemberPromises = settledTeamMembers.filter(
        (settledTeamMemberPromise) => settledTeamMemberPromise.status === 'fulfilled',
      ) as PromiseFulfilledResult<IUser>[];
      const teamMembers = fullfilledTeamMemberPromises.map(
        (fullfilledTeamMemberPromise) => fullfilledTeamMemberPromise.value,
      );

      const teamMemberUserStores = teamMembers
        ? teamMembers.map((teamMember) => {
            const userDomainStore = new UserDomainStore(teamMember);
            return userDomainStore;
          })
        : [];

      const fulfilledClientContactPromises = settledClientContacts.filter(
        (settledClientContactPromise) => settledClientContactPromise.status === 'fulfilled',
      ) as PromiseFulfilledResult<IUser>[];
      const clientContacts = fulfilledClientContactPromises.map(
        (settledClientContactPromise) => settledClientContactPromise.value,
      );

      runInAction(() => {
        this.remoteTeamMemberUsers = teamMemberUserStores;
        this.remoteClientContact = clientContacts;
      });

      RemoteData.isReady(this.remoteTeamMemberUsers) &&
        this.remoteTeamMemberUsers.forEach((teamMemberUser) => teamMemberUser.fetchUserImage(this._apiService));
    } catch (error) {
      runInAction(() => {
        this.remoteMove = RemoteData.fail();
      });
    }
  }

  @action
  async setMove(newMove: IMove) {
    this.setRemoteClientContact(newMove);
    this.setRemoteTeamMembers(newMove);
    this.remoteMove = newMove;
  }

  private async setRemoteClientContact(move: IMove) {
    const shouldUpdateClientContact =
      RemoteData.isReady(this.remoteMove) &&
      move.clientContact !== undefined &&
      (this.remoteMove.clientContact === undefined || this.remoteMove.clientContact !== move.clientContact);
    const shouldRemoveClientContact =
      RemoteData.isReady(this.remoteClientContact) &&
      this.remoteClientContact !== undefined &&
      move.clientContact === undefined;

    if (shouldRemoveClientContact) {
      this.remoteClientContact = undefined;
      return;
    }

    if (shouldUpdateClientContact) {
      this.remoteClientContact = RemoteData.LOADING;

      try {
        const clientContact = await Promise.allSettled(
          (move.clientContact as string).split(',').map((u) => this._apiService.fetchUser(u)),
        );
        runInAction(() => {
          this.remoteClientContact = (clientContact.filter((p) => p.status === 'fulfilled') as PromiseFulfilledResult<
            IUser
          >[]).map((p) => p.value);
        });
      } catch {
        runInAction(() => {
          this.remoteClientContact = RemoteData.fail();
        });
      }
    }
  }

  private async setRemoteTeamMembers(newMove: IMove) {
    const completeUsers = await updateTeamMembers(newMove, this.remoteTeamMemberUsers, this._apiService);
    runInAction(() => {
      this.remoteTeamMemberUsers = completeUsers;
    });
  }
}

export { MoveStore };
