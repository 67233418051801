import React from 'react';
import FormLabel, { FormLabelProps } from '@material-ui/core/FormLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';

export interface ILabelProps {
  labelProps?: FormLabelProps;
}

const useStyles = makeStyles((theme) => ({
  rootCustomLabel: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
}));

const Label: React.FunctionComponent<ILabelProps> = ({ labelProps, children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <FormLabel
      {...labelProps}
      classes={{
        root: classes.rootCustomLabel,
      }}
    >
      {children}
    </FormLabel>
  );
};

export { Label, useStyles };
