import React, { useContext, useCallback } from 'react';
import { TranslationContext } from '../../context/TranslationContext';
import { ServiceContext } from '../../context/ServicesContext';
import { IClientPayload } from '../../services/ApiServiceV1';
import { FormikHelpers } from 'formik';
import { NotificationContext } from '../../context/NotificationContext';
import { SectionContainer } from '../../components/containers/SectionContainer';
import ClientForm from '../../components/ClientForm/ClientForm';
import { useHistory } from 'react-router-dom';
import AdminContentHeader from '../../components/AdminContentHeader';
import { RouteMapContext } from '../../context/RouteMapContext';

const AddClient: React.FunctionComponent = () => {
  const { translate } = useContext(TranslationContext);
  const { apiServiceV1 } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const history = useHistory();
  const { getPathWithProps } = useContext(RouteMapContext);

  const onClientFormSubmit = useCallback(
    async (payload: IClientPayload, formikBag: FormikHelpers<IClientPayload>) => {
      try {
        apiServiceV1.addClient(payload);

        formikBag.resetForm();
        formikBag.setFieldValue('active', false);
        const path = getPathWithProps('CLIENTS');
        history.push(path);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully saved client'),
            color: 'success',
          },
        });
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to save client'),
            color: 'error',
          },
        });
      }
    },
    [apiServiceV1, notificationDispatch, translate, history, getPathWithProps],
  );

  return (
    <>
      <AdminContentHeader headerText={translate('add client')} />
      <SectionContainer
        subtitle={translate('enter the key information about the client here')}
        title={translate('client information')}
      >
        <ClientForm onSubmit={onClientFormSubmit} />
      </SectionContainer>
    </>
  );
};

export default AddClient;
