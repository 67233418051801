import React, { useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { StyledLink } from './StyledLink';
import { RemoteSuspense } from 'ts-remote-data-react';
import { ITemplate } from '../services/ApiServiceV1';
import RemoteData from 'ts-remote-data';
import { TranslationContext } from '../context/TranslationContext';
import { RouteKeys, RouteMapContext } from '../context/RouteMapContext';
import { isTemplateOrMovePage } from '../utils/isTemplateOrMovePage';
import { ModalContext } from '../context/ModalContext';
import { ConfirmDialog } from './ConfirmDialog';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Table, TableBody, TableHead } from '@material-ui/core';
import { DragButtonIcon } from '../components/DragButtonIcon';
import { StyledTableCell } from './StyledCell';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

interface ITemplateMovePageTableRowContentProps {
  remoteItems: RemoteData<ITemplate[]>;
  handleDelete: (item: string) => Promise<void>;
  handlePositionUpdate: (item: string, position: number) => Promise<void>;
  handleAvailableForInternalChange: (itemId: string, checked: boolean) => Promise<void>;
  handleAvailableForCustomerChange: (itemId: string, checked: boolean) => Promise<void>;
  checkbox?: boolean;
  dialogText: string;
  dialogPrimaryButtonLabel: string;
  dialogCancelLabel: string;
  dialogTitle: string;
  url: Extract<RouteKeys, 'EDIT_TEMPLATE' | 'EDIT_PAGE'>;
  urlData?: PartialRecord<'clientId' | 'locationId' | 'movePageId' | 'templateId', string>;
  onCheckboxChange?: (checked: boolean, item: ITemplate) => Promise<void>;
}

/** TODO: Remove this component, since it's only used only once. Also, the api of the component is far from ideal */
const TemplateMovePageTableRowContent: React.FunctionComponent<ITemplateMovePageTableRowContentProps> = (props) => {
  const { remoteItems } = props;
  return (
    <RemoteSuspense
      data={remoteItems}
      failureFallback={(error) =>
        Array.isArray(error) && isTemplateOrMovePage(error[0]) ? <TableRows items={error} {...props} /> : null
      }
    >
      {(items) => <TableRows items={items} {...props} />}
    </RemoteSuspense>
  );
};

interface ITableRowsProps {
  items: Array<ITemplate>;
}

type TableRowsProps = ITableRowsProps & Omit<ITemplateMovePageTableRowContentProps, 'remoteItems'>;

/*
component is manually casted to any since ts compiler throws fails to compile the code in `TableRows` component
https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356
Once the typing issue is resolved in @types/react, the any typecast can be removed
*/

// const TableRows: React.FunctionComponent<TableRowsProps> = ({ items, handleDelete, checkbox, url }) => {
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableRows: any = ({
  items,
  handleDelete,
  handlePositionUpdate,
  handleAvailableForInternalChange,
  handleAvailableForCustomerChange,
  checkbox,
  url,
  dialogText,
  dialogPrimaryButtonLabel,
  dialogCancelLabel,
  dialogTitle,
  urlData,
}: TableRowsProps) => {
  const { locale, translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);

  const { setModalState } = useContext(ModalContext);
  const onDragEnd = async (result: DropResult) => {
    if (result.destination) {
      await handlePositionUpdate(result.draggableId, result.destination.index);
    }
  };

  return items.length > 0 ? (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell size="small" />
          <StyledTableCell>{translate('title')}</StyledTableCell>
          <StyledTableCell size="small">{translate('Available for FMG')}</StyledTableCell>
          <StyledTableCell size="small">{translate('Available for Customers')}</StyledTableCell>
          <StyledTableCell size="small" />
        </TableRow>
      </TableHead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="moveTemplateRows">
          {(provided) => (
            <TableBody {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <>
                  <Draggable draggableId={item.id} index={index} key={item.id}>
                    {(draggableProvider) => (
                      <TableRow
                        {...draggableProvider.draggableProps}
                        {...draggableProvider.dragHandleProps}
                        ref={draggableProvider.innerRef}
                        key={item.id}
                      >
                        <TableCell>
                          <DragButtonIcon />
                        </TableCell>
                        <TableCell>
                          <StyledLink
                            to={
                              url === 'EDIT_TEMPLATE'
                                ? getPathWithProps<{ templateId: string }>(url, {
                                    templateId: item.id,
                                  })
                                : getPathWithProps(url, {
                                    ...urlData,
                                    movePageId: item.id,
                                  })
                            }
                          >
                            {item.translations.find((translation) => translation.locale === locale)?.title}
                          </StyledLink>
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            onChange={(event, checked) => {
                              handleAvailableForInternalChange(item.id, checked);
                            }}
                            checked={item.availableForInternal}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            onChange={(event, checked) => {
                              handleAvailableForCustomerChange(item.id, checked);
                            }}
                            checked={item.availableForCustomer}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => {
                              setModalState(
                                true,
                                <ConfirmDialog
                                  data={item.id}
                                  handleClick={handleDelete}
                                  dialogTitle={dialogTitle}
                                  recordTitle={
                                    item.translations.find((translation) => translation.locale === locale)?.title
                                  }
                                  content={dialogText}
                                  primaryButtonLabel={dialogPrimaryButtonLabel}
                                  cancelLabel={dialogCancelLabel}
                                />,
                              );
                            }}
                          >
                            <Delete fontSize="small" color="action" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )}
                  </Draggable>
                </>
              ))}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    </Table>
  ) : (
    <TableRow>
      <TableCell colSpan={3}>{translate('no items to show')}</TableCell>
    </TableRow>
  );
};

export { TemplateMovePageTableRowContent };
