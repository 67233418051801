import React, { useContext, useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { TranslationContext } from '../../context/TranslationContext';
import { SectionContainer } from '../../components/containers/SectionContainer';
import { Header } from '../../components/typography/Header';
import { HeaderContainer } from '../../components/containers/HeaderContainer';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { ServiceContext } from '../../context/ServicesContext';
import { IFormInitialValues } from './FeedbackRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useParams } from 'react-router-dom';
import { NotificationContext } from '../../context/NotificationContext';
import { ModalContext } from '../../context/ModalContext';
import RemoteData from 'ts-remote-data';
import FeedbackTable from './FeedbackTable';
import { IsFeedback } from '../../utils/IsFeedback';
import { FeedbackStatusType, FeedbackType, IFeedback } from '../../services/ApiServiceV1';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormSelect from '../../components/FormSelect';
import { ClientUserWrapper } from '../../components/ClientUserWrapper';
import { NavigationBreadcrumbs } from '../../components/NavigationBreadcrumbs';
import { RouteMapContext } from '../../context/RouteMapContext';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 120,
    padding: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& button:first-of-type': {
      marginRight: theme.spacing(2),
    },
  },
  selectedButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
  },
}));

const ListFeedback: React.FunctionComponent = observer(() => {
  const { translate } = useContext(TranslationContext);
  const {
    stores: { locationStore },
  } = useContext(ServiceContext);
  const { clientId, locationId } = useParams<{ clientId: string; locationId: string }>();
  const { getPathWithProps } = useContext(RouteMapContext);

  useAsyncOperation(() => {
    return locationStore.fetch(clientId, locationId);
  }, [clientId, locationId]);

  return (
    <ClientUserWrapper
      render={(client) => (
        <>
          <RemoteSuspense data={locationStore.remoteLocation}>
            {(location) => (
              <NavigationBreadcrumbs
                items={[
                  {
                    content: client.name,
                    url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                  },
                  {
                    content: location.name,
                    url: getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
                      clientId: client.id,
                      locationId: location.id,
                    }),
                  },
                  {
                    content: translate('feedback'),
                  },
                ]}
              />
            )}
          </RemoteSuspense>
          <HeaderContainer>
            <Header>{translate('feedback')}</Header>
          </HeaderContainer>
          <ListFeedbackImpl />
        </>
      )}
    />
  );
});

const ListFeedbackImpl: React.FunctionComponent = observer(() => {
  const urlParams = useParams<{ moveId: string }>();
  const { translate } = useContext(TranslationContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { setIsModalOpen } = useContext(ModalContext);
  const [selected, setSelected] = useState({ pending: false, answered: false });

  const { buttonContainer } = useStyles();

  const {
    stores: { feedbackStore },
  } = useContext(ServiceContext);

  useEffect(() => {
    feedbackStore.fetch(urlParams.moveId);
  }, [feedbackStore, urlParams.moveId]);

  const handleDelete = useCallback(
    async (feedbackId: string) => {
      await feedbackStore.deleteFeedback(feedbackId);
      setIsModalOpen(false);
      if (RemoteData.isFailure(feedbackStore.remoteFeedbacks)) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to delete feedback'),
          },
        });
      } else {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully deleted'),
            color: 'success',
          },
        });
      }
    },
    [feedbackStore, notificationDispatch, setIsModalOpen, translate],
  );

  const handleSave = useCallback(
    async (values: IFormInitialValues) => {
      await feedbackStore.updateMoveFeedback(values.id, { status: values.status, response: values.response });
      setIsModalOpen(false);
      if (RemoteData.isFailure(feedbackStore.remoteFeedbacks)) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to submit answer'),
          },
        });
      } else {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully saved'),
            color: 'success',
          },
        });
      }
    },
    [feedbackStore, notificationDispatch, setIsModalOpen, translate],
  );

  const sendEmail = useCallback(
    async (values: IFormInitialValues) => {
      await feedbackStore.sendFeedbackAnswer(values.id, { status: values.status, response: values.response });
      setIsModalOpen(false);
      if (RemoteData.isFailure(feedbackStore.remoteFeedbacks)) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to send email'),
          },
        });
      } else {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully sent email'),
            color: 'success',
          },
        });
      }
    },
    [feedbackStore, notificationDispatch, setIsModalOpen, translate],
  );

  function handleSort(status: FeedbackStatusType) {
    if (status === 'pending') {
      setSelected({ ...selected, pending: true, answered: false });
    } else {
      setSelected({ ...selected, pending: false, answered: true });
    }
    feedbackStore.filterFeedbackByStatus(status);
  }

  const [type, setType] = React.useState('');

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelected({ ...selected, pending: false, answered: false });
    setType(event.target.value as FeedbackType);
    const value = event.target.value;
    const feedbackType = value === 'All' ? undefined : (value as FeedbackType);
    feedbackStore.filterFeedbackByType(feedbackType);
  };
  return (
    <>
      <FormSelect
        native={true}
        variant="outlined"
        labelId="type-select-label"
        id="type-select"
        value={type}
        onChange={handleChange}
        label={translate('feedback type')}
      >
        <option>{translate('all')}</option>
        <option value={'question'}>{translate('question')}</option>
        <option value={'feedback'}>{translate('feedback')}</option>
        <option value={'error'}>{translate('error')}</option>
      </FormSelect>

      <div style={{ marginTop: '2rem' }}>
        <Typography>{translate('only show forms that are')}</Typography>

        <div className={buttonContainer}>
          <Button
            variant={selected.pending ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => handleSort('pending')}
          >
            {translate('pending')}
          </Button>
          <Button
            variant={selected.answered ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => handleSort('answered')}
          >
            {translate('answered')}
          </Button>
        </div>
      </div>

      <SectionContainer>
        <RemoteSuspense
          data={feedbackStore.remoteFeedbacks}
          failureFallback={(error: IFeedback[]) =>
            Array.isArray(error) && IsFeedback(error[0]) ? (
              <FeedbackTable
                feedbacks={error}
                handleDelete={handleDelete}
                handleSave={handleSave}
                sendEmail={sendEmail}
              />
            ) : (
              <Typography color="error">{translate('fetching feedback failed')}</Typography>
            )
          }
          loadingFallback={<LinearProgress />}
        >
          {(feedbacks) => (
            <FeedbackTable
              feedbacks={feedbacks}
              handleDelete={handleDelete}
              handleSave={handleSave}
              sendEmail={sendEmail}
            />
          )}
        </RemoteSuspense>
      </SectionContainer>
    </>
  );
});

export { ListFeedback };
