import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Label } from '../Label';
import { StyledOutlinedInput } from '../FormInput';
import { IFormControlProps, IFormikField } from '.';
import { useField } from 'formik';

export const FormikFormInput: React.FunctionComponent<
  IFormikField & OutlinedInputProps & IFormControlProps & { onChangeCb?: (value: string) => void }
> = ({ formikKey, labelContent, showLabel = true, formControlProps, onChangeCb, ...props }) => {
  const [field, meta] = useField({ name: formikKey, type: 'text' });
  return (
    <FormControl error={meta.touched && Boolean(meta.error)} {...formControlProps}>
      {showLabel && <Label>{labelContent}</Label>}
      <StyledOutlinedInput
        value={field.value || ''}
        onChange={(event) => {
          if (onChangeCb) onChangeCb(event?.currentTarget?.value ?? '');
          field.onChange(event);
        }}
        name={field.name}
        onBlur={field.onBlur}
        {...props}
      />
      {meta.touched && meta.error && (
        <Box py={1}>
          <Typography color="error">{meta.error}</Typography>
        </Box>
      )}
    </FormControl>
  );
};
