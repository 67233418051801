import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link } from 'react-router-dom';

interface IDashboardBoxProps {
  title: string;
  url?: string;
  square?: boolean;
  shouldShowUrl?: boolean;
  linkComponent?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  boxTitle: {
    padding: theme.spacing(2),
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 0, 2, 3),
    },
  },
  link: {
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
    },
  },
  paperContainer: {
    width: '100%',
  },
  linkFlexElement: {
    textDecoration: 'none',
    flex: 1,
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  linkIcon: {
    marginRight: '20px',
  },
}));

const DashboardBox: React.FunctionComponent<IDashboardBoxProps> = ({
  title,
  url,
  children,
  square,
  shouldShowUrl = true,
  linkComponent,
}) => {
  const { boxTitle, link, paperContainer } = useStyles();
  return (
    <Paper square={square} className={paperContainer}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={boxTitle} color="primary" variant="body1">
          {title}
        </Typography>
        {url && shouldShowUrl && (
          <IconButton component={Link} to={url} className={link}>
            {square ? (
              <ArrowForwardIosIcon fontSize="small" color="action" />
            ) : (
              <EditIcon fontSize="small" color="action" />
            )}
          </IconButton>
        )}
        {linkComponent && linkComponent}
      </Box>
      <Divider />
      {children}
    </Paper>
  );
};

const DashboardLinkBox: React.FunctionComponent<IDashboardBoxProps> = ({ title, url, square }) => {
  const { boxTitle, link, paperContainer, linkFlexElement, innerContainer, linkIcon } = useStyles();

  if (!url) return null;

  return (
    <Paper square={square} className={paperContainer}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Link to={url} className={linkFlexElement}>
          <div className={innerContainer}>
            <Typography className={boxTitle} color="primary" variant="body1">
              {title}
            </Typography>
            {square ? (
              <ArrowForwardIosIcon fontSize="small" color="action" className={`${link} ${linkIcon}`} />
            ) : (
              <EditIcon fontSize="small" color="action" className={link} />
            )}
          </div>
        </Link>
      </Box>
      <Divider />
    </Paper>
  );
};

export { DashboardBox, DashboardLinkBox, useStyles };
