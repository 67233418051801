import { action, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';
import { IApiServiceV1, IUser } from '../services/ApiServiceV1';

class UserDomainStore {
  @observable
  user: IUser;

  @observable
  remotePhoto: RemoteData<string> = RemoteData.NOT_ASKED;

  constructor(user: IUser) {
    this.user = user;
  }

  @action
  async fetchUserImage(apiService: IApiServiceV1) {
    // don't fetch the image if we don't have proper user || image is loading || we already have the image
    if (!this.user.user.id || RemoteData.LOADING === this.remotePhoto || RemoteData.isReady(this.remotePhoto)) {
      return;
    }
    this.remotePhoto = RemoteData.LOADING;
    try {
      const photo = await apiService.fetchUserPhoto(this.user.user.id, '120x120');
      runInAction(() => {
        this.remotePhoto = photo;
      });
    } catch (error) {
      runInAction(() => {
        this.remotePhoto = RemoteData.fail();
      });
    }
  }
}

export { UserDomainStore };
