import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { RemoteSuspense } from 'ts-remote-data-react';
import AdminContentHeader from '../../components/AdminContentHeader';
import { BrokeringProjectForm } from '../../components/BrokeringProjectForm';
import { SectionContainer } from '../../components/containers/SectionContainer';
import { NavigationBreadcrumbs } from '../../components/NavigationBreadcrumbs';
import { NotificationContext } from '../../context/NotificationContext';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { useFetchClient } from '../../hooks/useFetchClient';
import { IBrokeringPayload } from '../../services/ApiServiceV1';

const CreateBrokeringProject: React.FunctionComponent = observer(() => {
  const { translate } = useContext(TranslationContext);
  const {
    apiServiceV1,
    stores: { clientStore },
  } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const { clientId } = useParams<{ clientId: string }>();

  const history = useHistory();

  const onSubmit = useCallback(
    async (values: IBrokeringPayload) => {
      try {
        const newBrokering = await apiServiceV1.addBrokeringProject(clientId, values);
        if (RemoteData.isReady(clientStore.remoteClient)) {
          clientStore.remoteClient.addBrokering(newBrokering);
        }
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully saved'),
            color: 'success',
          },
        });

        history.push(
          getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId }),
        );
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to save brokering project'),
            color: 'error',
          },
        });
      }
    },
    [apiServiceV1, clientId, clientStore.remoteClient, getPathWithProps, history, notificationDispatch, translate],
  );

  const remoteClient = useFetchClient(apiServiceV1, clientId, clientStore);

  return (
    <>
      <RemoteSuspense data={remoteClient}>
        {(client) => (
          <NavigationBreadcrumbs
            items={[
              {
                content: client.name,
                url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
              },
              { content: translate('create brokering project') },
            ]}
          />
        )}
      </RemoteSuspense>
      <AdminContentHeader headerText={translate('create brokering project')} />
      <SectionContainer
        subtitle={translate('enter the general information about the brokering project here')}
        title={translate('general information')}
      >
        <BrokeringProjectForm onSubmit={onSubmit} />
      </SectionContainer>
    </>
  );
});

export { CreateBrokeringProject };
