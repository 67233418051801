import { Form, Formik, FormikHelpers } from 'formik';
import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { IMoveDetails, IMoveDetailsPayload } from '../../services/ApiServiceV1';
import { FormikFormInput } from '../../components/FormikInputs';
import { CustomButton } from '../../components/CustomButton';
import { TranslationContext } from '../../context/TranslationContext';

interface IMoveDetailFormProps {
  onSubmit: (values: IMoveDetailsPayload, formikHelpers?: FormikHelpers<IMoveDetailsPayload>) => Promise<void>;
  moveDetails?: IMoveDetails;
}

const MoveDetailForm: React.FunctionComponent<IMoveDetailFormProps> = ({ onSubmit, moveDetails }) => {
  const { translate } = useContext(TranslationContext);
  const initialValues: IMoveDetailsPayload = {
    title: moveDetails?.title || '',
    description: moveDetails?.description || '',
    type: 'text',
  };
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8}>
              <FormikFormInput
                formikKey="title"
                labelContent={translate('title')}
                formControlProps={{ fullWidth: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <FormikFormInput
                formikKey="description"
                labelContent={translate('description')}
                formControlProps={{ fullWidth: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomButton variant="contained" color="secondary" type="submit" disabled={isSubmitting}>
                {translate('save')}
              </CustomButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export { MoveDetailForm };
