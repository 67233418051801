import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { NavigationBreadcrumbs } from '../../components/NavigationBreadcrumbs';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ServiceContext } from '../../context/ServicesContext';
import { useFetchClient } from '../../hooks/useFetchClient';
import { ClientUserWrapper } from '../../components/ClientUserWrapper';
import { TranslationContext } from '../../context/TranslationContext';
import { Header } from '../../components/typography/Header';
import LinearProgress from '@material-ui/core/LinearProgress';
import { observer } from 'mobx-react';
import { GeneralInfoBox } from './GeneralInfoBox';
import { BrokeringTimelineBox } from './MoveTimelineBox';
import { DocumentsBox } from './DocumentsBox';

const BrokeringDashboard: React.FunctionComponent = observer(() => {
  const {
    apiServiceV1,
    stores: { clientStore, brokeringStore },
  } = useContext(ServiceContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const { clientId, brokeringId } = useParams<{ clientId: string; brokeringId: string }>();
  const { translate } = useContext(TranslationContext);

  const remoteClient = useFetchClient(apiServiceV1, clientId, clientStore);
  useAsyncOperation(() => {
    return brokeringStore.fetchBrokering(brokeringId);
  }, [brokeringId]);

  return (
    <RemoteSuspense
      data={RemoteData.all(remoteClient, brokeringStore.remoteBrokering)}
      failureFallback={<Typography color="error">{translate('failed to fetch data')}</Typography>}
      loadingFallback={<LinearProgress />}
    >
      {([client, brokering]) => (
        <>
          <ClientUserWrapper
            render={() => (
              <>
                <Box mb={2}>
                  <NavigationBreadcrumbs
                    items={[
                      {
                        content: client.name,
                        url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                      },
                      { content: brokering.name },
                    ]}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Header>{brokering.name}</Header>
                  </Grid>
                  {/* grid content starts here */}

                  <Grid item xs={12}>
                    <GeneralInfoBox client={client} brokering={brokering} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8}>
                    <DocumentsBox brokering={brokering} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <BrokeringTimelineBox client={client} brokering={brokering} />
                  </Grid>
                </Grid>
              </>
            )}
          />
        </>
      )}
    </RemoteSuspense>
  );
});

export { BrokeringDashboard };
