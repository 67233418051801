import { IApiServiceV1 } from '../services/ApiServiceV1';
import { ClientLeasesStore } from './ClientLeasesStore';
import { LeaseRemindersStore } from './reminder/LeaseRemindersStore';
import { LeaseRemindersUiStore } from './reminder/LeaseRemindersUiStore';
import { MoveDetailsStore } from './MoveDetailsStore';
import { MoveDetailsUiStore } from './MoveDetailsUiStore';
import { MovePagesStore } from './MovePagesStore';
import { MoveStore } from './MoveStore';
import { DocumentsStore } from './DocumentsStore';

class RootStore {
  clientLeasesStore: ClientLeasesStore;
  leaseRemindersStore: LeaseRemindersStore;
  leaseRemindersUiStore: LeaseRemindersUiStore;
  movePagesStore: MovePagesStore;
  moveDetailsUiStore: MoveDetailsUiStore;
  moveDetailsStore: MoveDetailsStore;
  moveStore: MoveStore;
  documentsStore: DocumentsStore;

  constructor(apiService: IApiServiceV1) {
    this.clientLeasesStore = new ClientLeasesStore(apiService, this);
    this.leaseRemindersStore = new LeaseRemindersStore(apiService, this);
    this.leaseRemindersUiStore = new LeaseRemindersUiStore(this);
    this.movePagesStore = new MovePagesStore(apiService, this);
    this.moveDetailsUiStore = new MoveDetailsUiStore(this);
    this.moveDetailsStore = new MoveDetailsStore(apiService, this);
    this.moveStore = new MoveStore(apiService, this);
    this.documentsStore = new DocumentsStore(apiService, this);
  }
}

export { RootStore };
