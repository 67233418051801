import React, { useContext } from 'react';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { ServiceContext } from '../../context/ServicesContext';
import { IBrokering } from '../../services/ApiServiceV1';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { IFileTreeNode } from '../../utils/convertLibraryResponse';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { StyledExternalLink } from '../../components/StyledExternalLink';
import dayjs from 'dayjs';
import { dateFormat } from '@fmg-packages/common-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DashboardBox, useStyles as useDashboardBoxStyles } from '../../components/DashboardBox';
import { TranslationContext } from '../../context/TranslationContext';
import Tooltip from '@material-ui/core/Tooltip';
import PersonIcon from '@material-ui/icons/Person';
import UpdateIcon from '@material-ui/icons/Update';
import { AxiosError } from 'axios';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import { IconButton } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';

interface IDocumentsBoxProps {
  brokering: IBrokering;
}

const useLabelStyles = makeStyles((theme: Theme) => ({
  treeRowInfoLabel: {
    paddingLeft: theme.spacing(1),
  },
}));

const Label: React.FunctionComponent<{ node: IFileTreeNode }> = ({ node }) => {
  const { translate } = useContext(TranslationContext);
  const { treeRowInfoLabel } = useLabelStyles();
  return (
    <Box display="flex" py={1}>
      <>
        {node.document ? (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <StyledExternalLink url={node.document.documentOpenPath}>{node.document.title}</StyledExternalLink>
            </Grid>
            <Grid item xs={4}>
              <Tooltip title={translate('last modified by')}>
                <Box display="flex" alignItems="center">
                  <PersonIcon fontSize="small" />
                  <Typography variant="button" color="textPrimary" className={treeRowInfoLabel}>
                    {node.document.documentModifiedBy}
                  </Typography>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip title={translate('last modified at')}>
                <Box display="flex" justifyContent="flex-end">
                  <UpdateIcon fontSize="small" />
                  <Typography variant="button" color="textPrimary" align="right" className={treeRowInfoLabel}>
                    {dayjs(node.document.documentModifiedDate).format(dateFormat)}
                  </Typography>
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          <Typography>{node.id}</Typography>
        )}
      </>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  itemRoot: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  itemLabel: {},
}));

const SharepointLink: React.FunctionComponent<{ url: string | null }> = ({ url }) => {
  const { link } = useDashboardBoxStyles();
  const { translate } = useContext(TranslationContext);
  return url ? (
    <a href={url} target="_blank" rel="noopener noreferrer" className={link}>
      <Tooltip title={translate('go to sharepoint site')}>
        <IconButton>
          <LinkIcon fontSize="small" color="action" />
        </IconButton>
      </Tooltip>
    </a>
  ) : null;
};

const DocumentsBox: React.FunctionComponent<IDocumentsBoxProps> = ({ brokering }) => {
  const { apiServiceV1 } = useContext(ServiceContext);
  const { translate } = useContext(TranslationContext);
  const { remoteGraphAPIAccessToken } = useContext(AuthenticationContext);
  const remoteDocumentLibrary = useAsyncOperation(() => {
    return apiServiceV1.getBrokeringDocuments(brokering.id);
  }, [brokering.id, remoteGraphAPIAccessToken]);
  const renderTree = (treeNode: IFileTreeNode) => {
    const nodeId = treeNode.document ? treeNode.document.documentPath : treeNode.id;

    return (
      <TreeItem
        key={nodeId}
        nodeId={nodeId}
        label={<Label node={treeNode} />}
        expandIcon={treeNode.children ? <FolderIcon /> : null}
        collapseIcon={treeNode.children ? <FolderOpenIcon /> : null}
        icon={treeNode.document ? <DescriptionIcon /> : null}
      >
        {Array.isArray(treeNode.children) ? treeNode.children.map((childNode) => renderTree(childNode)) : null}
      </TreeItem>
    );
  };

  const { itemRoot } = useStyles();

  return (
    <RemoteSuspense
      data={remoteDocumentLibrary}
      loadingFallback={<LinearProgress />}
      failureFallback={(error: AxiosError) => {
        if (error.isAxiosError) {
          switch (error.response?.status) {
            case 404:
              return <Typography>{translate('no documents for this brokering project')}</Typography>;
            case 500:
              return (
                <Typography color="error">
                  {translate(
                    "you're not allowed to access the documents. contact the administrator to grant you access",
                  )}
                </Typography>
              );
            default:
              return <Typography color="error">{translate('failed to fetch documents')}</Typography>;
          }
        }
        return <Typography color="error">{translate('failed to fetch documents')}</Typography>;
      }}
    >
      {({ filetree, baseUrl }) => {
        return (
          <DashboardBox title={translate('brokering documents')} linkComponent={<SharepointLink url={baseUrl} />}>
            <Box p={2}>
              <TreeView>
                {filetree.map((documentFileTreeNode, index) =>
                  documentFileTreeNode.files ? (
                    renderTree(documentFileTreeNode.files)
                  ) : (
                    <TreeItem
                      key={index}
                      label={
                        <Box py={1} display="flex">
                          {documentFileTreeNode.title}
                        </Box>
                      }
                      nodeId={documentFileTreeNode.title}
                      icon={<FolderIcon />}
                      classes={{
                        root: itemRoot,
                      }}
                    />
                  ),
                )}
              </TreeView>
            </Box>
          </DashboardBox>
        );
      }}
    </RemoteSuspense>
  );
};

export { DocumentsBox };
