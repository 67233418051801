import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { RemoteSuspense } from 'ts-remote-data-react';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import UserIcon from './UserIcon';

interface ITopBarProps {
  handleDrawerToggle: () => void;
  isDrawerOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  appBarFormControls: (props: { isDrawerOpen: boolean }) => {
    return {
      marginLeft: props.isDrawerOpen ? '14rem' : '4rem',
      marginRight: 'auto',
      [theme.breakpoints.only('xs')]: {
        marginLeft: 0,
      },
    };
  },
  root: {
    backgroundColor: theme.palette.common.white,
  },
}));

const TopBar: React.FunctionComponent<ITopBarProps> = ({ handleDrawerToggle, isDrawerOpen }) => {
  const { remoteUser, remotePhoto } = useContext(AuthenticationContext);
  const theme = useTheme();
  const classes = useStyles({ isDrawerOpen });
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <AppBar position="fixed" color="transparent" classes={{ root: classes.root }}>
      <Toolbar>
        <Grid container alignItems="center" justify={isExtraSmall ? 'space-between' : 'flex-end'}>
          <Hidden smUp>
            <Grid item>
              <IconButton onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>

          <Grid item>
            <Grid container alignItems="center">
              {!isExtraSmall && (
                <Grid item>
                  <RemoteSuspense data={remoteUser}>
                    {({ user }) => <Typography>{user.displayName}</Typography>}
                  </RemoteSuspense>
                </Grid>
              )}
              <Grid item>
                <RemoteSuspense data={remotePhoto} failureFallback={<UserIcon />}>
                  {(userFile) => <UserIcon photo={userFile} />}
                </RemoteSuspense>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
