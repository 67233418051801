import LinearProgress from '@material-ui/core/LinearProgress';
import React, { useContext, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { LeaseForm } from '../../components/LeaseForm';
import { NavigationBreadcrumbsWrapper } from '../../components/NavigationBreadcrumbs';
import { Header } from '../../components/typography/Header';
import { NotificationContext } from '../../context/NotificationContext';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { ILeasePayload } from '../../services/ApiServiceV1';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

const EditLease: React.FunctionComponent = () => {
  const { clientId, leaseId } = useParams<{ clientId: string; leaseId: string }>();
  const {
    stores: { clientStore },
    rootStore: { clientLeasesStore },
  } = useContext(ServiceContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const { translate } = useContext(TranslationContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const history = useHistory();

  useAsyncOperation(() => {
    return clientStore.fetchClientData(clientId);
  }, [clientId]);

  useAsyncOperation(() => {
    return clientLeasesStore.clientLease.fetch(leaseId);
  }, [leaseId]);

  const onSubmit = useCallback(
    async (values: ILeasePayload) => {
      try {
        await clientLeasesStore.updateLease(leaseId, values);
        history.push(
          getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId }),
        );
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully updated lease'),
            color: 'success',
          },
        });
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to update lease'),
            color: 'error',
          },
        });
      }
    },
    [clientId, clientLeasesStore, getPathWithProps, history, leaseId, notificationDispatch, translate],
  );

  return (
    <RemoteSuspense
      data={RemoteData.all(clientStore.remoteClient, clientLeasesStore.clientLease.remoteLease)}
      loadingFallback={<LinearProgress />}
    >
      {([client, lease]) => (
        <>
          <NavigationBreadcrumbsWrapper
            items={[
              {
                content: client.name,
                url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
              },
              {
                content: translate('edit lease'),
              },
            ]}
          />
          <Header>{translate('edit lease')}</Header>
          <Paper>
            <Box py={6} px={5}>
              <LeaseForm client={client} onSubmit={onSubmit} lease={lease} />
            </Box>
          </Paper>
        </>
      )}
    </RemoteSuspense>
  );
};

export { EditLease };
