import { action, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';
import { IApiServiceV1, IBrokering, IUser, IUserBackendData } from '../services/ApiServiceV1';
import { updateTeamMembers } from '../utils/updateTeamMembers';
import { TimelineSectionStore } from './TimelineSectionStore';
import { UserDomainStore } from './UserDomainStore';

class BrokeringStore {
  @observable
  remoteBrokering: RemoteData<IBrokering> = RemoteData.NOT_ASKED;

  @observable
  remoteClientContact: RemoteData<IUser | undefined> = RemoteData.NOT_ASKED;

  @observable
  remoteTeamMembers: RemoteData<UserDomainStore[]> = RemoteData.NOT_ASKED;

  @observable
  remoteExternalTeamMembers: RemoteData<IUser[]> = RemoteData.NOT_ASKED;

  @observable
  remoteProjectManager: RemoteData<IUser | undefined> = RemoteData.NOT_ASKED;

  @observable
  timelineSections: TimelineSectionStore;

  _apiService: IApiServiceV1;

  constructor(apiService: IApiServiceV1) {
    this._apiService = apiService;
    this.timelineSections = new TimelineSectionStore(apiService);
  }

  @action
  async fetchBrokering(brokeringId: string, forceFetch = false) {
    if (RemoteData.isReady(this.remoteBrokering) && this.remoteBrokering.id === brokeringId && forceFetch === false) {
      // we already have the data and not requesting a reload of the data, so we're not fetching the data
      return;
    }
    this.timelineSections.fetch(brokeringId, 'brokering', true);

    this.remoteBrokering = RemoteData.LOADING;
    try {
      const brokering = await this._apiService.fetchBrokering(brokeringId);

      runInAction(() => {
        this.remoteBrokering = brokering;
        if (brokering.clientContact) {
          this.remoteClientContact = RemoteData.LOADING;
        }
        if (brokering.projectManager) {
          this.remoteProjectManager = RemoteData.LOADING;
        }

        this.remoteTeamMembers = RemoteData.LOADING;
        this.remoteExternalTeamMembers = RemoteData.LOADING;
      });

      const teamMembersPromises = brokering.teamMembers.map((teamMember) => this._apiService.fetchUser(teamMember.id));
      const externamTeamMembersPromises = brokering.externalTeamMembers.map((externalTeamMember) =>
        this._apiService.fetchUser(externalTeamMember.id),
      );

      const [teamMembersUsers, externalTeamMembers, client, projectManager] = await Promise.all([
        Promise.all(teamMembersPromises),
        Promise.all(externamTeamMembersPromises),
        brokering.clientContact && this._apiService.fetchUser(brokering.clientContact.id),
        brokering.projectManager && this._apiService.fetchUser(brokering.projectManager.id),
      ]);

      const teamMembers = teamMembersUsers.map((teamMemberUser) => new UserDomainStore(teamMemberUser));

      runInAction(() => {
        this.remoteClientContact = client;
        this.remoteProjectManager = projectManager;
        this.remoteTeamMembers = teamMembers;
        this.remoteExternalTeamMembers = externalTeamMembers;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteBrokering = RemoteData.fail();
        this.remoteClientContact = RemoteData.fail();
        this.remoteProjectManager = RemoteData.fail();
        this.remoteTeamMembers = RemoteData.fail();
      });
    }
  }

  @action
  async setRemoteBrokering(newBrokering: IBrokering) {
    this.setRemoteProjectManger(newBrokering);
    this.setRemoteClientContact(newBrokering);
    this.setRemoteTeamMembers(newBrokering);
    this.setRemoteExternalTeamMembers(newBrokering);

    this.remoteBrokering = newBrokering;
  }

  private async setRemoteProjectManger(newBrokering: IBrokering) {
    const shouldUpdateProjectManager =
      RemoteData.isReady(this.remoteBrokering) &&
      newBrokering.projectManager !== undefined &&
      (this.remoteBrokering.projectManager === undefined ||
        this.remoteBrokering.projectManager.id !== newBrokering.projectManager.id);
    const shouldRemoveProjectManager =
      RemoteData.isReady(this.remoteProjectManager) &&
      this.remoteProjectManager !== undefined &&
      newBrokering.projectManager === undefined;

    if (shouldRemoveProjectManager) {
      this.remoteProjectManager = undefined;
      return;
    }

    if (shouldUpdateProjectManager) {
      this.remoteProjectManager = RemoteData.LOADING;
      try {
        const projectManager = await this._apiService.fetchUser((newBrokering.projectManager as IUserBackendData).id);
        runInAction(() => {
          this.remoteProjectManager = projectManager;
        });
      } catch (error) {
        runInAction(() => {
          this.remoteProjectManager = RemoteData.fail();
        });
      }
    }
  }

  private async setRemoteClientContact(newBrokering: IBrokering) {
    const shouldUpdateClientContact =
      RemoteData.isReady(this.remoteBrokering) &&
      newBrokering.clientContact !== undefined &&
      (this.remoteBrokering.clientContact === undefined ||
        this.remoteBrokering.clientContact.id !== newBrokering.clientContact.id);
    const shouldRemoveClientContact =
      RemoteData.isReady(this.remoteClientContact) &&
      this.remoteClientContact &&
      newBrokering.clientContact === undefined;

    if (shouldRemoveClientContact) {
      this.remoteClientContact = undefined;
      return;
    }

    if (shouldUpdateClientContact) {
      this.remoteClientContact = RemoteData.LOADING;
      try {
        const clientContact = await this._apiService.fetchUser((newBrokering.clientContact as IUserBackendData).id);
        runInAction(() => {
          this.remoteClientContact = clientContact;
        });
      } catch (error) {
        runInAction(() => {
          this.remoteClientContact = RemoteData.fail();
        });
      }
    }
  }

  private async setRemoteTeamMembers(newBrokering: IBrokering) {
    const completeUsers = await updateTeamMembers(newBrokering, this.remoteTeamMembers, this._apiService);
    runInAction(() => {
      this.remoteTeamMembers = completeUsers;
    });
  }

  private async setRemoteExternalTeamMembers(newBrokering: IBrokering) {
    const existingTeamMemberIds = RemoteData.isReady(this.remoteExternalTeamMembers)
      ? this.remoteExternalTeamMembers.map((externalTeamMember) => externalTeamMember.user.id as string)
      : [];
    const newTeamMemberIds = newBrokering.externalTeamMembers.map((teamMember) => teamMember.id);

    const usersToFetch = newTeamMemberIds.filter((newTeamMemberId) => !existingTeamMemberIds.includes(newTeamMemberId));
    const usersToRemove = existingTeamMemberIds.filter(
      (existingTeamMemberId) => !newTeamMemberIds.includes(existingTeamMemberId),
    );

    // remove users
    if (usersToRemove.length > 0 && RemoteData.isReady(this.remoteExternalTeamMembers)) {
      this.remoteExternalTeamMembers = this.remoteExternalTeamMembers.filter(
        (teamMember) => usersToRemove.includes(teamMember.user.id as string) === false,
      );
    }

    // add users
    if (usersToFetch.length > 0) {
      const newTeamMembers = await Promise.all(
        usersToFetch.map((userIdToFetch) => this._apiService.fetchUser(userIdToFetch)),
      );
      runInAction(() => {
        if (RemoteData.isReady(this.remoteExternalTeamMembers))
          this.remoteExternalTeamMembers = this.remoteExternalTeamMembers.concat(newTeamMembers);
      });
    }
  }
}

export { BrokeringStore };
