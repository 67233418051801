import React, { useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import { TranslationContext } from '../../context/TranslationContext';
import { ServiceContext } from '../../context/ServicesContext';

interface IUserIconProps {
  photo?: string;
}

const UserIcon: React.FunctionComponent<IUserIconProps> = ({ photo }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);
  const isOpen = !!anchorEl;

  const { translate } = useContext(TranslationContext);
  const { authenticationService } = useContext(ServiceContext);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    authenticationService.logout();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
        {photo ? <Avatar src={photo} /> : <AccountCircle />}
      </IconButton>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose} keepMounted>
        <MenuItem onClick={handleClose}>{translate('profile')}</MenuItem>
        <MenuItem onClick={handleSignOut}>{translate('sign out')}</MenuItem>
      </Menu>
    </>
  );
};

export default UserIcon;
