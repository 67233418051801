type Action =
  | { type: 'ADD_NEW_TIMELINE_EVENT_INDEX'; payload: number }
  | { type: 'REMOVE_NEW_TIMELINE_EVENT_INDEX'; payload: number };

type State = number[];

export function newTimelineEventIndexesReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'ADD_NEW_TIMELINE_EVENT_INDEX':
      return [...state, action.payload];
    case 'REMOVE_NEW_TIMELINE_EVENT_INDEX':
      return state.filter((newTimelineEventIndex) => newTimelineEventIndex !== action.payload);
    default: {
      const x: never = action;
      return x;
    }
  }
}
