import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  styledHeader: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& h1': {
      paddingBottom: theme.spacing(2),
    },
  },
}));

const HeaderContainer: React.FunctionComponent = ({ children }) => {
  const { styledHeader } = useStyles();

  return (
    <Grid container justify="space-between" className={styledHeader}>
      {children}
    </Grid>
  );
};
export { HeaderContainer };
