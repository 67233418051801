global['RedactorX'].add('plugin', 'video', {
    defaults: {
        upload: false,
        name: 'file',
        type: 'video',
        types: ['video/mp4', 'video/mov', 'video/ogg', 'video/webm', '.mov'],
    },
    translations: {
        en: {
            video: {
                'upload-new-placeholder': 'Drag to upload a new video or click select'
            },
        },
    },
    init: function() {
    }, 
    start: function() {
        this.app.addbar.add('videobutton', {
            title: 'Video',
            icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style="width: 20px"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"/></svg>',
            command: 'video.popup',
        });
    },
    popup: function(params, button) {
        // create
        var popup = this.app.popup.create('video', {
            title: 'Add Video',
            width: '100%',
        });
        var $body = this.app.popup.getBody();
        this._createUploadBox($body);

        // open
        this.app.popup.open();
    },
    _createUploadBox: function($body) {
        this.$upload = this.createUploadBox(this.opts.video.upload, $body);
        this._buildUpload(this.$upload, 'video.insertByUpload');
    },
    createUploadBox: function(upload, $body) {
        if (!upload) return;

        var $upload = this.dom('<div>');
        $body.append($upload);

        return $upload;
    },
    _buildUpload: function($item, callback) {
        if (!this.opts.video.upload) return;

        var params = {
            box: true,
            placeholder: this.lang.get('video.upload-new-placeholder'),
            url: this.opts.video.upload,
            name: this.opts.video.name,
            success: callback,
            error: 'video.error',
            type: this.opts.video.type,
            types: this.opts.video.types,
        };

        this.app.create('upload', $item, params);
    },
    save: function(popup) {
        this.app.popup.close();

        var data = popup.getData();
        var instance = this.app.block.get();
        var $block = instance.getBlock();

        if (data.id !== '') {
            $block.attr('id', data.id);
        }
        else {
            $block.removeAttr('id');
        }
    },
    insertByUpload: function(response){
        // popup close
        this.app.popup.close();

        // loop
        for (var key in response) {
            if (response.hasOwnProperty(key)) {
                var $source = this.dom('<div>');
                var $video = this._createVideoFromResponseItem(response[key]);

                $source.append($video);

                var instance = this.app.create('block.line', $source);
                this.app.block.add({ instance: instance });
            }
        }
    },
    _createVideoFromResponseItem: function(item) {
        var $video = this.dom('<video>').attr('src', item.url).attr('controls', '').attr('width', '100%').attr('height', '400px');
        var $item = $video;

        var attrs = ['id', 'alt', 'width', 'height'];
        for (var i = 0; i < attrs.length; i++) {
            if (Object.prototype.hasOwnProperty.call(item, attrs[i])) {
                $item.attr(attrs[i], item[attrs[i]]);
            }
        }

        return $item;
    },
});