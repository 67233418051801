import React, { useContext } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { DashboardBox } from '../../components/DashboardBox';
import { StyledTableCell } from '../../components/StyledCell';
import { IClient, IUser, ILocation } from '../../services/ApiServiceV1';
import { TranslationContext } from '../../context/TranslationContext';
import { ButtonLink } from '../../components/ButtonLink';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { StyledLink } from '../../components/StyledLink';
import { RouteMapContext } from '../../context/RouteMapContext';

interface IClientLocationsProps {
  client: IClient;
  locations: ILocation[];
  user: IUser;
}

const useStyles = makeStyles((theme: Theme) => ({
  noLocationsMessage: {
    paddingTop: theme.spacing(3),
  },
}));

const ClientLocations: React.FunctionComponent<IClientLocationsProps> = ({ client, locations, user }) => {
  const { translate } = useContext(TranslationContext);
  const { noLocationsMessage } = useStyles();
  const { getPathWithProps } = useContext(RouteMapContext);

  return (
    <DashboardBox title={translate('locations')}>
      <Box px={6} py={2}>
        {locations.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>{translate('name')}</StyledTableCell>
                  <StyledTableCell>{translate('address')}</StyledTableCell>
                  <StyledTableCell>{translate('active')}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {locations.map((location) => (
                  <TableRow key={location.id}>
                    <TableCell>
                      <StyledLink
                        to={getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
                          clientId: client.id,
                          locationId: location.id,
                        })}
                      >
                        {location.name}
                      </StyledLink>
                    </TableCell>
                    <TableCell>{location.address}</TableCell>
                    <TableCell>{translate(location.services?.move?.isEnabled ? 'yes' : 'no')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography className={noLocationsMessage} variant="body1">
            {translate('there are no locations for this client')}
          </Typography>
        )}
        {user.isExternal === false && (
          <Box paddingTop={2}>
            <ButtonLink
              to={getPathWithProps<{ clientId: string }>('CREATE_CLIENT_LOCATION', {
                clientId: client.id,
              })}
              variant="contained"
              color="secondary"
            >
              {translate('create location')}
            </ButtonLink>
          </Box>
        )}
      </Box>
    </DashboardBox>
  );
};

export { ClientLocations };
