import enResource from './locales/en.json';
import noResource from './locales/no.json';
import deResource from './locales/de.json';
import daResource from './locales/da.json';
import seResource from './locales/se.json';
import nnResource from './locales/nn.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { isDayjs } from 'dayjs';

export type Lang = 'en' | 'no' | 'nn' | 'de' | 'da' | 'se';

const resources = {
  en: {
    translation: enResource,
  },
  no: {
    translation: noResource,
  },
  de: {
    translation: deResource,
  },
  da: {
    translation: daResource,
  },
  se: {
    translation: seResource,
  },
  nn: {
    translation: nnResource,
  },
};

export interface ITranslations {
  clients: string;
  locations: string;
  settings: string;
  more: string;
  language: string;
  selectClient: string;
  addClient: string;
  'client information': string;
  'enter the key information about the client here': string;
  name: string;
  'organisation number': string;
  country: string;
  save: string;
  'field is required': string;
  'please select': string;
  'is active': string;
  'is enabled': string;
  'successfully saved client': string;
  'failed to save client': string;
  'org. number': string;
  'no. of brokering projects': string;
  'no. of locations': string;
  'no. of active locations': string;
  'add client': string;
  'country: all': string;
  'rows per page': string;
  all: string;
  'edit client': string;
  office: string;
  storage: string;
  production: string;
  address: string;
  active: string;
  inactive: string;
  active_accessible: string;
  yes: string;
  no: string;
  city: string;
  email: string;
  phone: string;
  client: string;
  'create location': string;
  'key information': string;
  'enter the information about the location here': string;
  'property type': string;
  'successfully saved location': string;
  'failed to save location': string;
  'there are no locations for this client': string;
  'create location service': string;
  'location information': string;
  'edit {{location}}': string;
  overview: string;
  advisory: string;
  move: string;
  assembly: string;
  lease: string;
  'team members': string;
  password: string;
  'box stickers for export': string;
  'add field': string;
  title: string;
  description: string;
  'remove field': string;
  norwegian: string;
  english: string;
  german: string;
  danish: string;
  swedish: string;
  norwegiannynorsk: string;
  'successfully saved a move': string;
  'failed to save a move': string;
  path: string;
  'path can be letters numbers dash or underscore': string;
  'minimum amount is {{minimum}}': string;
  'general information': string;
  'move website': string;
  'whitelisted domains': string;
  'whitelisted domains eg': string;
  'authorization scheme': string;
  'password only': string;
  'password and email': string;
  'email only': string;
  'client contact person': string;
  team: string;
  'fmg team': string;
  'add move location service': string;
  'edit move location service': string;
  templates: string;
  "FAQ's": string;
  pages: string;
  forms: string;
  contacts: string;
  floorplan: string;
  'create new template': string;
  'create template': string;
  'successfully saved a template': string;
  'failed to save a template': string;
  'edit template': string;
  create: string;
  published: string;
  'create new page': string;
  'add new page': string;
  'add new page customer': string;
  add: string;
  'failed to create move page': string;
  'failed to remove a move page': string;
  'no items to show': string;
  'are you sure you want to delete ': string;
  'are you sure you want to delete this page': string;
  cancel: string;
  delete: string;
  "This item will be deleted immediately. You can't undo this action": string;
  'edit move page': string;
  'failed to update move page': string;
  'successfully saved a page': string;
  'successfully removed a page': string;
  date: string;
  start: string;
  end: string;
  'add event': string;
  'add section': string;
  'date range': string;
  'date time': string;
  'time range': string;
  feedback: string;
  'successfully deleted': string;
  'failed to remove feedback': string;
  'successfully saved': string;
  'failed to submit answer': string;
  status: string;
  type: string;
  created: string;
  'last updated': string;
  'created by': string;
  question: string;
  'fetching feedback failed': string;
  'only show forms that are': string;
  answered: string;
  pending: string;
  error: string;
  'feedback type': string;
  'Field is required': string;
  'failed to delete feedback': string;
  'frequently asked question': string;
  answer: string;
  'failed to delete FAQ': string;
  'failed to update FAQ': string;
  'failed to change position': string;
  'successfully rearranged': string;
  'successfully updated': string;
  'failed to reaarange FAQ': string;
  'invalid date': string;
  'successfully updated timeline event': string;
  'failed to update timeline event': string;
  'date type': string;
  'failed to update section': string;
  'successfully saved section': string;
  'failed to delete': string;
  'move timeline': string;
  'no timeline created yet': string;
  'create new timeline': string;
  'add question': string;
  'failed to add question': string;
  'successfully added': string;
  submit: string;
  'page added': string;
  'no feedback found': string;
  'move page added': string;
  users: string;
  'external users': string;
  'add user': string;
  'contact phone': string;
  'contact email': string;
  'create user': string;
  'field must be a valid email': string;
  'failed to save external user': string;
  profile: string;
  'sign out': string;
  'sign in': string;
  'send login link': string;
  'failed to fetch users': string;
  'failed to fetch user': string;
  "failed to update user's data": string;
  "updated user's details": string;
  'no assigned client': string;
  'restricted access': string;
  'click {{here}} to be transferred home': string;
  dashboard: string;
  'no faqs yet': string;
  'edit location': string;
  'main client contact': string;
  'primary color': string;
  'darker primary color': string;
  'secondary color': string;
  'darker secondary color': string;
  'background color': string;
  'heading color': string;
  'primary color uppercase': string;
  'darker primary color uppercase': string;
  'secondary color uppercase': string;
  'darker secondary color uppercase': string;
  'background color uppercase': string;
  'heading color uppercase': string;
  'all custom colors': string;
  'recommendations about image': string;
  'failed to fetch data': string;
  'enter the general information about the brokering project here': string;
  'project name': string;
  'project manager': string;
  'failed to save brokering project': string;
  'brokering projects': string;
  'create brokering project': string;
  'unable to fetch internal user list': string;
  'unable to fetch external user list': string;
  'edit brokering project': string;
  'edit {{brokeringProjectName}}': string;
  'successfully saved brokering project': string;
  'failed to update brokering project': string;
  'brokering timeline': string;
  leases: string;
  'add new lease': string;
  'create lease': string;
  'office space': string;
  'parking space': string;
  'storage space': string;
  'production space': string;
  other: string;
  floors: string;
  'lease start': string;
  'lease end': string;
  'space & parking': string;
  'area space': string;
  'number of people': string;
  seats: string;
  'number of parking spaces': string;
  'deadlines & prices': string;
  'lease renewal deadline': string;
  'increased floor space deadline': string;
  'lease price': string;
  'consumer price index date': string;
  'joint cost': string;
  'no leases created yet': string;
  'successfully saved a lease': string;
  'failed to save a lease': string;
  'the following is mandatory information for a lease': string;
  'lease type': string;
  'edit lease': string;
  'successfully updated lease': string;
  'failed to update lease': string;
  'update lease': string;
  'brokering documents': string;
  'last modified at': string;
  'last modified by': string;
  "you're not allowed to access the documents. contact the administrator to grant you access": string;
  'no documents for this brokering project': string;
  'failed to fetch documents': string;
  'due date': string;
  'successfully added new lease reminder': string;
  'failed to add new lease reminder': string;
  '{{leaseTitle}} reminders': string;
  reminders: string;
  'reminders for {{leaseTitle}}': string;
  'Enter reminders here. They will be seen by the customer': string;
  edit: string;
  'add reminder': string;
  'update reminder': string;
  'successfully updated reminder': string;
  'failed to update reminder': string;
  'successfully deleted reminder': string;
  'failed to delete reminder': string;
  'lease: {{leaseName}}': string;
  'number of reminders': string;
  'failed to fetch client data': string;
  'no reminders created yet': string;
  'add new contact': string;
  'move contacts': string;
  'successfully created new move contact': string;
  'failed to create new move contact': string;
  'successfully updated move contact': string;
  'failed to update move contact': string;
  'contacts information': string;
  'enter contact information about the people involved in the move service here. This is shown to the customer': string;
  'successfully removed move contact': string;
  'failed to remove move contact': string;
  'successfully reordered move contacts': string;
  'failed to reorder move contacts': string;
  'go to sharepoint site': string;
  'external team members': string;
  'project id': string;
  'document prefix': string;
  'failed to fetch brokering data': string;
  'sharepoint connection': string;
  'move details': string;
  'click to expand': string;
  'add move information that you would like to share with your employees': string;
  'add move details': string;
  'failed to update move details': string;
  'successfully updated move details': string;
  'failed to add move details': string;
  'successfully added move details': string;
  'successfully deleted move details': string;
  'failed to delete move details': string;
  'successfully updated move details position': string;
  'failed to update move details position': string;
  'user with provided email already exists': string;
  'successfully toggled published value': string;
  'failed to toggle published value': string;
  'successfully updated move page position': string;
  'failed to update move page position': string;
  upcoming: string;
  completed: string;
  failed: string;
  'show reminders': string;
  'mark as done': string;
  done: string;
  'done comment': string;
  'filter past reminders': string;
  languages: string;
  'default language': string;
  'successfully updated faq': string;
  'previously provided answers': string;
  '{{user}} replied at {{time}}': string;
  'send email': string;
  'failed to send email': string;
  'successfully sent email': string;
  documents: string;
  'add documents that you would like to share with your employees': string;
  'Failed to store the document': string;
  'Failed to delete the document': string;
  'Browse...': string;
  'Uploaded documents': string;
  'No documents uploaded': string;
  Name: string;
  'Are you sure you want to delete?': string;
  'Are you sure you want to clear': string;
  "You can't undo this action": string;
  UppercaseClear: string;
  'hide floor selection': string;
  'Available for FMG': string;
  'Available for Customers': string;
}

const currentLocale = localStorage.getItem('i18nextLng') || 'en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: currentLocale,
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    nsSeparator: false,
    fallbackLng: 'en',
    // debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format, lng) {
        if (isDayjs(value)) {
          return value.locale(lng as Lang).format(format);
        }

        return value;
      },
    },
  });

export default i18n;
