import React, { createContext, useState } from 'react';
import { StringMap, TOptions } from 'i18next';
import { ITranslations, Lang } from '../i18n';
import { useTranslation } from 'react-i18next';

export type Translate = (key: keyof ITranslations, options?: TOptions<StringMap>) => string;

interface ITranslationContext {
  translate: Translate;
  locale: Lang;
}

export const TranslationContext = createContext({} as ITranslationContext);

const TranslationProvider: React.FunctionComponent = ({ children }) => {
  const { t, i18n } = useTranslation();
  const currentLocale = localStorage.getItem('i18nextLng') || 'en';
  const [locale, setLocale] = useState<Lang>(currentLocale as Lang);
  i18n.on('languageChanged', function (lng) {
    setLocale(lng as Lang);
  });
  return <TranslationContext.Provider value={{ translate: t, locale }}>{children}</TranslationContext.Provider>;
};

export default TranslationProvider;
