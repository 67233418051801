import { IAppRoute, RouteKeys } from '../context/RouteMapContext';

export const matchRoute = (key: RouteKeys, routes: IAppRoute[]): IAppRoute | undefined => {
  for (const route in routes) {
    const appRoute = routes[route];
    if (appRoute.key === key) {
      return appRoute;
    }
    if (appRoute.routes) {
      const result = matchRoute(key, appRoute.routes);
      if (result) {
        return result;
      }
    }
  }
};
