import React from 'react';
import RemoteData from 'ts-remote-data';
import { Action as NotificationContextAction } from '../../context/NotificationContext';
import { TimelineSectionStore } from '../../stores/TimelineSectionStore';

export function handleTimelineNotification(
  notificationDispatch: React.Dispatch<NotificationContextAction>,
  timelineSectionsStore: TimelineSectionStore,
  successMessage: string,
  failureMessage: string,
) {
  if (RemoteData.isFailure(timelineSectionsStore.remoteTimelineSections)) {
    notificationDispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        message: failureMessage,
        color: 'error',
      },
    });

    if (Array.isArray(timelineSectionsStore.remoteTimelineSections.error)) {
      timelineSectionsStore.setRemoteTimelineSections(timelineSectionsStore.remoteTimelineSections.error);
    }
  } else {
    notificationDispatch({
      type: 'SET_NOTIFICATION',
      payload: {
        message: successMessage,
        color: 'success',
      },
    });
  }
}
