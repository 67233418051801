import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ILocation, IClient, IUser } from '../../services/ApiServiceV1';
import { TranslationContext } from '../../context/TranslationContext';
import { DashboardBox } from '../../components/DashboardBox';
import { getLocationPropertyTypes } from '../../utils/locationPropertyTypes';
import { RouteMapContext } from '../../context/RouteMapContext';

interface ILocationInformationBoxProps {
  location: ILocation;
  client: IClient;
  user: IUser;
}

const useStyles = makeStyles((theme: Theme) => ({
  locationBoxContent: {
    padding: theme.spacing(2, 0),
    '& > * > *': {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(0, 2),
      },
    },
  },
  locationInformationTopItem: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(3),
    },
  },
}));

const LocationInformationBox: React.FunctionComponent<ILocationInformationBoxProps> = ({ location, client, user }) => {
  const { translate } = useContext(TranslationContext);
  const { locationBoxContent, locationInformationTopItem } = useStyles();
  const propertyTypes = getLocationPropertyTypes(location, translate);
  const { getPathWithProps } = useContext(RouteMapContext);
  const params = { clientId: client.id, locationId: location.id };

  return (
    <DashboardBox
      title={translate('location information')}
      url={getPathWithProps('CLIENT_LOCATION_EDIT', params)}
      shouldShowUrl={user.isExternal === false}
    >
      <Grid container justify="space-evenly" className={locationBoxContent}>
        <Grid container item sm={12} md={3} direction="column">
          <Grid item className={locationInformationTopItem}>
            <Typography variant="button">{translate('name')}</Typography>
            <Typography>{location.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="button">{translate('address')}</Typography>
            <Typography>{location.address}</Typography>
          </Grid>
        </Grid>

        <Grid container item sm={12} md={3} direction="column">
          <Grid item className={locationInformationTopItem}>
            <Typography variant="button">{translate('client')}</Typography>
            <Typography>{client.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="button">{translate('country')}</Typography>
            <Typography>{client.country.name}</Typography>
          </Grid>
        </Grid>

        <Grid container item sm={12} md={3} direction="column">
          <Grid item className={locationInformationTopItem}>
            <Typography variant="button">{translate('property type')}</Typography>
            <Typography>{propertyTypes.join(', ')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="button">{translate('city')}</Typography>
            <Typography>{location.city}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </DashboardBox>
  );
};

export { LocationInformationBox };
