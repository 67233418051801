import React from 'react';
import { ClientUserWrapper } from '../../components/ClientUserWrapper';
import { NavigationBreadcrumbsWrapper } from '../../components/NavigationBreadcrumbs';
import { useContext } from 'react';
import { RouteMapContext } from '../../context/RouteMapContext';
import { TranslationContext } from '../../context/TranslationContext';
import { TimelineForm } from '../../components/TimelineForm';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ServiceContext } from '../../context/ServicesContext';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import LinearProgress from '@material-ui/core/LinearProgress';

const BrokeringTimeline: React.FunctionComponent = observer(() => {
  const { getPathWithProps } = useContext(RouteMapContext);
  const { translate } = useContext(TranslationContext);
  const { brokeringId } = useParams<{ brokeringId: string }>();
  const {
    stores: { brokeringStore },
  } = useContext(ServiceContext);
  useAsyncOperation(() => {
    return brokeringStore.timelineSections.fetch(brokeringId, 'brokering');
  }, []);
  useAsyncOperation(() => {
    return brokeringStore.fetchBrokering(brokeringId);
  }, [brokeringId]);
  return (
    <>
      <RemoteSuspense data={brokeringStore.remoteBrokering} loadingFallback={<LinearProgress />}>
        {(brokering) => (
          <ClientUserWrapper
            render={(client) => (
              <NavigationBreadcrumbsWrapper
                items={[
                  {
                    content: client.name,
                    url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                  },
                  {
                    content: brokering.name,
                    url: getPathWithProps<{ clientId: string; brokeringId: string }>('BROKERING_DASHBOARD', {
                      clientId: client.id,
                      brokeringId: brokering.id,
                    }),
                  },
                  {
                    content: translate('brokering timeline'),
                  },
                ]}
              />
            )}
          />
        )}
      </RemoteSuspense>
      <TimelineForm timelineResourceId={brokeringId} timelineType="brokering" />
    </>
  );
});

export { BrokeringTimeline };
