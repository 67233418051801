import RemoteData from 'ts-remote-data';
import { observable, action, computed } from 'mobx';
import { runInAction } from 'mobx';
import { IApiServiceV1, IUser } from '../services/ApiServiceV1';
import { SortDirection, extractAndCompareValues } from '../utils/sort';

type UserStoreSortColumns = [string, (_: IUser) => any];

class UsersStore {
  _apiService: IApiServiceV1;

  @observable
  remoteUsers: RemoteData<IUser[]> = RemoteData.NOT_ASKED;
  @observable
  selectedCountryId: string | undefined;
  @observable
  sortColumnNames: UserStoreSortColumns = ['none', (_: IUser) => _];
  @observable
  sortDirection: SortDirection | undefined;
  @observable companyNameFilter: string | undefined = undefined;

  constructor(apiService: IApiServiceV1) {
    this._apiService = apiService;
  }

  @action
  async fetch(forceFetch = false) {
    if (RemoteData.isReady(this.remoteUsers) && !forceFetch) {
      return;
    }
    this.remoteUsers = RemoteData.LOADING;
    try {
      const users = await this._apiService.fetchExternalUsers();
      runInAction(() => {
        this.remoteUsers = users;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteUsers = RemoteData.failWith(error);
      });
    }
  }

  @action
  setCountryId(countryId?: string) {
    this.selectedCountryId = countryId;
  }

  @action
  setCompanyNameFilter(companyName?: string) {
    this.companyNameFilter = companyName;
  }

  @computed
  get filteredSortUsers(): RemoteData<IUser[]> {
    if (RemoteData.isReady(this.remoteUsers)) {
      let filteredUsers = this.selectedCountryId
        ? this.remoteUsers.filter((user) => user.client && user.client.country.id === this.selectedCountryId)
        : this.remoteUsers;

      filteredUsers = this.companyNameFilter
        ? filteredUsers.filter(
            (client) => (client.client?.name || '').toLowerCase().indexOf(this.companyNameFilter!.toLowerCase()) >= 0,
          )
        : filteredUsers;

      return this.sort(filteredUsers);
    }
    return this.remoteUsers;
  }

  @action
  setSort(sortColumns: UserStoreSortColumns, sortDirection: SortDirection) {
    this.sortColumnNames = sortColumns;
    this.sortDirection = sortDirection;
  }

  private sort(users: IUser[]) {
    if (RemoteData.isReady(this.remoteUsers) && this.sortColumnNames && this.sortDirection) {
      const sortedUsers = users.slice();
      sortedUsers.sort(extractAndCompareValues(this.sortColumnNames[1], this.sortDirection));
      return sortedUsers;
    }

    return users;
  }
}

export { UsersStore };
