import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { IClient, ILease, ILeasePayload } from '../services/ApiServiceV1';
import dayjs from 'dayjs';
import { FormikAutocomplete, FormikCheckbox, FormikFormInput, FormikKeyboardDatePicker } from './FormikInputs';
import { useContext } from 'react';
import { TranslationContext } from '../context/TranslationContext';
import { ServiceContext } from '../context/ServicesContext';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { numberedDateFormat } from '@fmg-packages/common-components';
import { CustomButton } from './CustomButton';
import * as yup from 'yup';
import { Label } from './Label';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  areaSpaceItem: {
    marginTop: '-4px',
  },
}));

interface ILeaseFormProps {
  client: IClient;
  onSubmit: (values: ILeasePayload) => Promise<void>;
  lease?: ILease;
}

const LeaseForm: React.FunctionComponent<ILeaseFormProps> = ({ client, onSubmit, lease }) => {
  const initialValues: ILeasePayload = {
    isOffice: lease && lease.isOffice !== undefined ? lease.isOffice : false,
    isParking: lease && lease.isParking !== undefined ? lease.isParking : false,
    isStorage: lease && lease.isStorage !== undefined ? lease.isStorage : false,
    isProduction: lease && lease.isProduction !== undefined ? lease.isProduction : false,
    isOther: lease && lease.isOther ? lease.isOther : false,
    locations: lease ? lease.locations : [],
    title: lease ? lease.title : '',
    floors: lease && lease.floors !== undefined ? lease.floors : '',
    leaseStart: lease && lease.leaseStart ? lease.leaseStart : null,
    leaseEnd: lease && lease.leaseEnd ? lease.leaseEnd : null,
    areaSpace: lease && lease.areaSpace ? lease.areaSpace : 0,
    numberOfPeople: lease && lease.numberOfPeople ? lease.numberOfPeople : 0,
    seats: lease && lease.seats ? lease.seats : 0,
    numberOfParkingSpaces: lease && lease.numberOfParkingSpaces ? lease.numberOfParkingSpaces : 0,
    leaseRenewalDeadline: lease && lease.leaseRenewalDeadline ? lease.leaseRenewalDeadline : null,
    increasedFloorSpaceDeadline: lease && lease.increasedFloorSpaceDeadline ? lease.increasedFloorSpaceDeadline : null,
    leasePrice: lease && lease.leasePrice ? lease.leasePrice : 0,
    consumerPriceIndexDate: lease && lease.consumerPriceIndexDate ? lease.consumerPriceIndexDate : null,
    sharedCost: lease && lease.sharedCost ? lease.sharedCost : 0,
    description: lease && lease.description ? lease.description : '',
  };
  const { translate } = useContext(TranslationContext);
  const {
    stores: { clientStore },
  } = useContext(ServiceContext);
  const { areaSpaceItem } = useStyles();

  useAsyncOperation(() => {
    return clientStore.fetchClientData(client.id);
  }, [client.id]);

  const theme = useTheme();
  const isBiggerThanLg = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Formik
      validationSchema={yup.object({
        title: yup.string().required(translate('field is required')),
        //locations: yup.array().required(translate('field is required')),
      })}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <Grid container spacing={2}>
              <Grid item>
                <Box pb={2}>
                  <Typography gutterBottom variant="h6">
                    {translate('general information')}
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {translate('the following is mandatory information for a lease')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Label>{translate('lease type')}</Label>
                </Box>
                <FormikCheckbox formikKey="isOffice" labelContent={translate('office space')} color="primary" />
                <FormikCheckbox formikKey="isParking" labelContent={translate('parking space')} color="primary" />
                <FormikCheckbox formikKey="isStorage" labelContent={translate('storage space')} color="primary" />
                <FormikCheckbox formikKey="isProduction" labelContent={translate('production space')} />
                <FormikCheckbox formikKey="isOther" labelContent={translate('other')} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={8}>
                <RemoteSuspense data={clientStore.remoteLocations}>
                  {(locations) => (
                    <FormikAutocomplete
                      formikKey="locations"
                      data={locations}
                      optionLabelKey="name"
                      labelContent={translate('locations')}
                      formControlProps={{ fullWidth: true }}
                      isMultiple={true}
                    />
                  )}
                </RemoteSuspense>
              </Grid>
              {isBiggerThanLg && <Grid item lg={4} />}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormikFormInput
                  formikKey="title"
                  labelContent={translate('title')}
                  formControlProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormikFormInput
                  formikKey="floors"
                  labelContent={translate('floors')}
                  formControlProps={{ fullWidth: true }}
                />
              </Grid>
              {isBiggerThanLg && <Grid item lg={4} />}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormikKeyboardDatePicker
                  formikKey="leaseStart"
                  labelContent={translate('lease start')}
                  format={numberedDateFormat}
                  inputVariant="outlined"
                  formControlProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormikKeyboardDatePicker
                  formikKey="leaseEnd"
                  labelContent={translate('lease end')}
                  format={numberedDateFormat}
                  inputVariant="outlined"
                  formControlProps={{ fullWidth: true }}
                  minDate={values.leaseStart || dayjs()}
                />
              </Grid>
              <Grid item xs={12}>
                <Box py={5}>
                  <Typography variant="h6">{translate('space & parking')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} className={areaSpaceItem}>
                <FormikFormInput
                  formikKey="areaSpace"
                  labelContent={
                    <span>
                      {translate('area space')} m<sup>2</sup>
                    </span>
                  }
                  formControlProps={{ fullWidth: true }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormikFormInput
                  formikKey="numberOfPeople"
                  labelContent={<span>{translate('number of people')}</span>}
                  formControlProps={{ fullWidth: true }}
                  type="number"
                />
              </Grid>
              {isBiggerThanLg && <Grid item lg={4} />}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormikFormInput
                  formikKey="seats"
                  labelContent={translate('seats')}
                  formControlProps={{ fullWidth: true }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormikFormInput
                  formikKey="numberOfParkingSpaces"
                  labelContent={translate('number of parking spaces')}
                  formControlProps={{ fullWidth: true }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <Box py={5}>
                  <Typography variant="h6">{translate('deadlines & prices')}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormikKeyboardDatePicker
                  formikKey="leaseRenewalDeadline"
                  labelContent={translate('lease renewal deadline')}
                  format={numberedDateFormat}
                  inputVariant="outlined"
                  formControlProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormikKeyboardDatePicker
                  formikKey="increasedFloorSpaceDeadline"
                  labelContent={translate('increased floor space deadline')}
                  format={numberedDateFormat}
                  inputVariant="outlined"
                  formControlProps={{ fullWidth: true }}
                />
              </Grid>
              {isBiggerThanLg && <Grid item lg={4} />}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormikFormInput
                  formikKey="leasePrice"
                  labelContent={translate('lease price')}
                  formControlProps={{ fullWidth: true }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormikKeyboardDatePicker
                  formikKey="consumerPriceIndexDate"
                  labelContent={translate('consumer price index date')}
                  format={numberedDateFormat}
                  inputVariant="outlined"
                  formControlProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormikFormInput
                  formikKey="shared cost"
                  labelContent={translate('joint cost')}
                  formControlProps={{ fullWidth: true }}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <FormikFormInput
                  formikKey="description"
                  labelContent={translate('description')}
                  multiline={true}
                  rows={7}
                  formControlProps={{ fullWidth: true }}
                />
              </Grid>
              <Grid item xs={12} />
              <Grid item>
                <CustomButton variant="contained" color="secondary" type="submit" disabled={isSubmitting}>
                  {lease ? translate('update lease') : translate('create lease')}
                </CustomButton>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Form>
      )}
    </Formik>
  );
};

export { LeaseForm };
