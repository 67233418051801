import React from 'react';
import { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { AuthenticationContext } from '../context/AuthenticationContext';
import { IAppRoute, IAppRouteComponentProps } from '../context/RouteMapContext';
import ProtectedRoute from './ProtectedRoute';
import { RestrictedAccess } from './RestrictedAccess';

export const RenderRoutes = (props: IAppRouteComponentProps) => {
  const { routes } = props;

  return routes ? (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes {...route} key={route.key} />;
      })}
      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  ) : null;
};

function RouteWithSubRoutes(route: IAppRoute) {
  const { remoteIsAuthenticated, remoteUser } = useContext(AuthenticationContext);

  if (
    RemoteData.isReady(remoteIsAuthenticated) &&
    RemoteData.isReady(remoteUser) &&
    remoteIsAuthenticated &&
    remoteUser.isExternal &&
    route.restrictExternal
  ) {
    return <RestrictedAccess />;
  }

  return route.isProtected ? (
    <ProtectedRoute path={route.path} exact={route.exact} restrictInternalSuper={route.restrictInternalSuper}>
      <route.component routes={route.routes} />
    </ProtectedRoute>
  ) : (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}
