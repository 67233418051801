import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';

const useStyles = makeStyles({
  label: {
    textTransform: 'none',
  },
});

const CustomButton: React.FunctionComponent<ButtonProps> = (props) => {
  const { label } = useStyles();

  return (
    <Button classes={{ label }} {...props}>
      {props.children}
    </Button>
  );
};

export { CustomButton };
