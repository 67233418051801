import { action, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';
import { IApiServiceV1, ILease, ILeasePayload } from '../services/ApiServiceV1';

class LeaseStore {
  @observable
  remoteLease: RemoteData<ILease> = RemoteData.NOT_ASKED;

  _apiService: IApiServiceV1;

  constructor(apiService: IApiServiceV1) {
    this._apiService = apiService;
  }

  @action
  async fetch(leaseId: string, forceFetch = false) {
    if (RemoteData.isReady(this.remoteLease) && this.remoteLease.id === leaseId && !forceFetch) {
      return;
    }
    this.remoteLease = RemoteData.LOADING;

    try {
      const lease = await this._apiService.fetchLease(leaseId);
      runInAction(() => {
        this.remoteLease = lease;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteLease = RemoteData.fail();
      });
    }
  }

  @action
  async updateLease(leaseId: string, payload: ILeasePayload) {
    const updatedLease = await this._apiService.updateLease(leaseId, payload);
    runInAction(() => {
      this.remoteLease = updatedLease;
    });
  }
}

export { LeaseStore };
