import dayjs, { Dayjs } from 'dayjs';
import { action, computed } from 'mobx';
import { IReminder } from '../../services/ApiServiceV1';

class ReminderDomainStore {
  id: string;
  title: string;
  description: string;
  dueDate: Dayjs;
  done: boolean;
  sentAt: Dayjs | null;
  doneComment: string;

  constructor(reminder: IReminder) {
    this.id = reminder.id;
    this.title = reminder.title;
    this.description = reminder.description;
    this.dueDate = reminder.dueDate;
    this.sentAt = reminder.sentAt;
    this.done = reminder.done;
    this.doneComment = reminder.doneComment;
  }

  @computed
  get unsent(): boolean {
    return this.sentAt === null && dayjs().isAfter(this.dueDate);
  }

  @computed
  get upcoming(): boolean {
    return this.done === false && this.sentAt === null && dayjs().isBefore(this.dueDate);
  }

  @action
  updateReminderData(reminder: IReminder) {
    this.title = reminder.title;
    this.description = reminder.description;
    this.dueDate = reminder.dueDate;
    this.sentAt = reminder.sentAt;
    this.done = reminder.done;
    this.doneComment = reminder.doneComment;
  }
}

export { ReminderDomainStore };
