import React from 'react';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { fieldPadding } from './FormInput';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  input: {
    padding: fieldPadding,
  },
});

const StyledDatePicker: React.FunctionComponent<DatePickerProps> = (props) => {
  const { input } = useStyles();
  return <DatePicker {...props} InputProps={{ classes: { input } }} />;
};

export default StyledDatePicker;
