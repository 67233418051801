import React, { useContext, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SvgIconComponent } from '@material-ui/icons';
import HelpIcon from '@material-ui/icons/Help';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ErrorIcon from '@material-ui/icons/Error';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import { FormikFormInput } from '../../components/FormikInputs';
import { IFeedback, FeedbackStatusType } from '../../services/ApiServiceV1';
import { TranslationContext } from '../../context/TranslationContext';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { ModalContext } from '../../context/ModalContext';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(4),
      marginLRight: theme.spacing(4),
    },
  },
  subheader: {
    color: theme.palette.text.primary,
    marginLeft: '5px',
  },
  buttonGroup: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  background: {
    backgroundColor: theme.palette.background.default,
  },
  statusAnswered: {
    color: theme.palette.secondary.main,
  },
  statusPending: {
    color: theme.palette.primary.main,
  },
}));

interface IFeedbackRowProps {
  feedback: IFeedback;
  onDelete: (feedbackId: string) => Promise<void>;
  onSave: (values: IFormInitialValues) => Promise<void>;
  sendEmail: (values: IFormInitialValues) => Promise<void>;
}
export interface IFormInitialValues {
  id: string;
  status: FeedbackStatusType;
  response: string;
}

export const FeedbackRow: React.FunctionComponent<IFeedbackRowProps> = ({ feedback, onDelete, onSave, sendEmail }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [isOpen, setIsOpen] = useState(false);
  const isGreaterThanSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const { translate } = useContext(TranslationContext);
  const { setModalState } = useContext(ModalContext);
  const { subheader, formWrapper, buttonGroup, statusAnswered, statusPending } = useStyles();

  const { id, question, feedbackType, name, email, phone, status, createdAt, updatedAt, answers } = feedback;

  let TypeIcon: SvgIconComponent;
  if (feedbackType === 'error') {
    TypeIcon = ErrorIcon;
  } else if (feedbackType === 'feedback') {
    TypeIcon = AnnouncementIcon;
  } else {
    TypeIcon = HelpIcon;
  }

  const initialValues: IFormInitialValues = {
    id: feedback.id,
    response: feedback.answer,
    status: 'answered',
  };

  return (
    <>
      <TableRow onClick={() => setIsOpen(!isOpen)} className={isOpen ? classes.background : ''}>
        <TableCell>
          <IconButton>
            <TypeIcon color={isOpen ? 'primary' : 'inherit'} />
          </IconButton>
          {question}
        </TableCell>
        <TableCell className={status === 'pending' ? statusPending : statusAnswered} size="small">
          {status}
        </TableCell>
        <TableCell size="small">{feedbackType}</TableCell>
        <TableCell align="right" size="small">
          <IconButton>{isOpen ? <KeyboardArrowUpIcon color="secondary" /> : <KeyboardArrowDownIcon />}</IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={isOpen ? classes.background : ''}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }} colSpan={4}>
          <Collapse in={isOpen} timeout="auto">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) =>
                (window?.event as any)?.submitter?.name === 'saveButton' ? onSave(values) : sendEmail(values)
              }
              validationSchema={yup.object({
                response: yup.string().required(translate('field is required')),
              })}
            >
              {({ isSubmitting }) => (
                <Form className={formWrapper}>
                  <Grid container spacing={5}>
                    <Grid item sm={8} lg={8} xs={11}>
                      <Box py={1.5}>
                        <Box my={0.5}>
                          <Typography variant="h5">{translate('question')}</Typography>
                        </Box>
                        <Box my={0.5}>
                          <Typography variant="body1">{question}</Typography>
                        </Box>
                      </Box>
                      <Box py={1.5}>
                        <Box my={0.5}>
                          <Typography variant="h5">{translate('previously provided answers')}</Typography>
                        </Box>
                        {answers.map((a) => (
                          <Box key={a.id} my={0.5}>
                            <Typography variant="body1">
                              {translate('{{user}} replied at {{time}}', {
                                user: a.user,
                                time: dayjs(a.createdAt).format('YYYY-MM-DD HH:mm'),
                              })}
                            </Typography>
                            <Typography variant="body1">{a.response}</Typography>
                          </Box>
                        ))}
                      </Box>
                      <Box py={1.5}>
                        <FormikFormInput
                          name="response"
                          formikKey="response"
                          multiline={true}
                          fullWidth={true}
                          labelContent="Response"
                          rows={5}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </Box>
                      <Box py={1.5}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setModalState(
                              true,
                              <ConfirmDialog
                                data={id}
                                handleClick={onDelete}
                                dialogTitle={translate('are you sure you want to delete ')}
                                recordTitle={feedback.question}
                                content={translate("This item will be deleted immediately. You can't undo this action")}
                                primaryButtonLabel={translate('delete')}
                                cancelLabel={translate('cancel')}
                              />,
                            );
                          }}
                        >
                          {translate('delete')}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item sm={4} lg={4} xs={11}>
                      <Box>
                        <Box pt={1.5}>
                          <Typography variant="button">{translate('created')}</Typography>
                          <Typography>{dayjs(createdAt).format('YYYY-MM-DD HH:mm')}</Typography>
                        </Box>
                        <Box pt={1.5}>
                          <Typography variant="button">{translate('last updated')}</Typography>
                          <Typography>{dayjs(updatedAt).format('YYYY-MM-DD HH:mm')}</Typography>
                        </Box>
                        <Box pt={1.5}>
                          <Typography variant="button">{translate('created by')}</Typography>
                          <Typography>{name}</Typography>
                          <Box ml={2}>
                            <Typography variant="body2">
                              {translate('phone')}
                              <span className={subheader}>{phone}</span>
                            </Typography>
                            <Typography variant="body2">
                              {translate('email')}
                              <span className={subheader}>{email} </span>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      {/* save and cancel buttons */}
                      <Grid
                        container
                        className={buttonGroup}
                        spacing={1}
                        justify={isGreaterThanSmall ? 'flex-end' : 'flex-start'}
                      >
                        <Grid item>
                          <Button
                            variant="contained"
                            disabled={isSubmitting}
                            type="submit"
                            color="secondary"
                            name="saveButton"
                          >
                            {translate('save')}
                          </Button>
                          <Button
                            variant="contained"
                            disabled={isSubmitting}
                            type="submit"
                            color="secondary"
                            name="sendMailButton"
                          >
                            {translate('send email')}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined" onClick={() => setIsOpen(!isOpen)}>
                            {translate('cancel')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
