import React, { useContext, useState, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Delete from '@material-ui/icons/Delete';
import { useParams } from 'react-router-dom';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { ButtonLink } from '../../components/ButtonLink';
import { HeaderContainer } from '../../components/containers/HeaderContainer';
import { SectionContainer } from '../../components/containers/SectionContainer';
import { Header } from '../../components/typography/Header';
import { TranslationContext } from '../../context/TranslationContext';
import { RouteMapContext } from '../../context/RouteMapContext';
import { Label } from '../../components/Label';
import FormSelect from '../../components/FormSelect';
import { ServiceContext } from '../../context/ServicesContext';
import { ILocation, IMovePage, ITemplate } from '../../services/ApiServiceV1';
import { StyledTableCell } from '../../components/StyledCell';
import { NotificationContext } from '../../context/NotificationContext';
import { observer } from 'mobx-react';
import { ModalContext } from '../../context/ModalContext';
import { NavigationBreadcrumbs } from '../../components/NavigationBreadcrumbs';
import { ClientUserWrapper } from '../../components/ClientUserWrapper';
import { StyledLink } from '../../components/StyledLink';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { DragDropContext, Droppable, DropResult, Draggable } from 'react-beautiful-dnd';
import { DragButtonIcon } from '../../components/DragButtonIcon';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import { Role } from '../../domain/Role';

const ListPages: React.FunctionComponent = observer(() => {
  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const {
    stores: { locationStore },
  } = useContext(ServiceContext);
  const { clientId, locationId } = useParams<{ clientId: string; locationId: string; moveId: string }>();

  useAsyncOperation(() => {
    return locationStore.fetch(clientId, locationId);
  }, [clientId, locationId]);

  return (
    <ClientUserWrapper
      render={(client) => (
        <>
          <RemoteSuspense data={locationStore.remoteLocation} loadingFallback={<LinearProgress />}>
            {(location) => (
              <>
                <NavigationBreadcrumbs
                  items={[
                    {
                      content: client.name,
                      url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                    },
                    {
                      content: location.name,
                      url: getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
                        clientId: client.id,
                        locationId: location.id,
                      }),
                    },
                    { content: translate('pages') },
                  ]}
                />
                {/* move page listing into it's own component since mobx doesn't play well with render props; TODO: need to investigate this later */}
                <ListPagesImpl location={location} />
              </>
            )}
          </RemoteSuspense>
        </>
      )}
    />
  );
});

const ListPagesImpl: React.FunctionComponent<{ location: ILocation }> = observer(({ location }) => {
  const { translate, locale } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { setIsModalOpen, setModalState } = useContext(ModalContext);
  const {
    apiServiceV1,
    rootStore: { movePagesStore },
  } = useContext(ServiceContext);
  const { accountRoles } = useContext(AuthenticationContext);
  const { clientId, locationId, moveId } = useParams<{ clientId: string; locationId: string; moveId: string }>();

  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | undefined>(undefined);

  const remoteTemplates = useAsyncOperation(() => {
    return apiServiceV1.fetchTemplates();
  }, []);

  useAsyncOperation(() => {
    return movePagesStore.fetch(moveId);
  }, [moveId]);

  const handleDelete = useCallback(
    async (itemId: string) => {
      try {
        await movePagesStore.deleteMovePage(itemId);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully removed a page'),
            color: 'success',
          },
        });
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to remove a move page'),
            color: 'error',
          },
        });
      }

      setIsModalOpen(false);
    },
    [movePagesStore, notificationDispatch, setIsModalOpen, translate],
  );

  const handleCheckboxChange = useCallback(
    async (checked: boolean, item: IMovePage) => {
      try {
        await movePagesStore.updateMovePage(item.id, { ...item, published: checked });
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'success',
            message: translate('successfully toggled published value'),
          },
        });
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to toggle published value'),
          },
        });
      }
    },
    [movePagesStore, notificationDispatch, translate],
  );

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (result.destination) {
        try {
          await movePagesStore.updateMovePagesPosition(moveId, {
            movePageId: result.draggableId,
            newPosition: result.destination.index,
          });
          notificationDispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              message: translate('successfully updated move page position'),
              color: 'success',
            },
          });
        } catch (error) {
          notificationDispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              message: translate('failed to update move page position'),
              color: 'error',
            },
          });
        }
      }
    },
    [moveId, movePagesStore, notificationDispatch, translate],
  );

  return (
    <RemoteSuspense data={movePagesStore.remoteMovePages} loadingFallback={<LinearProgress />}>
      {(movePages) => (
        <>
          <HeaderContainer>
            <Header>{translate('pages')}</Header>
          </HeaderContainer>
          <SectionContainer>
            <Box mb={5}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell size="small" />
                    <StyledTableCell>{translate('title')}</StyledTableCell>
                    <StyledTableCell align="right" size="small">
                      {translate('published')}
                    </StyledTableCell>
                    <StyledTableCell align="right" size="small" />
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="movePageRows">
                    {(provided) => (
                      <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                        {movePages.length > 0 &&
                          movePages.map((movePage, index) => {
                            const translationItem = movePage.translations.find(
                              (translation) => translation.locale === (movePage.defaultLanguage || locale),
                            );
                            return translationItem ? (
                              <Draggable draggableId={movePage.id} index={index} key={movePage.id}>
                                {(draggableProvider) => (
                                  <TableRow
                                    {...draggableProvider.draggableProps}
                                    {...draggableProvider.dragHandleProps}
                                    ref={draggableProvider.innerRef}
                                  >
                                    <TableCell size="small">
                                      <DragButtonIcon />
                                    </TableCell>
                                    <TableCell>
                                      <StyledLink
                                        to={getPathWithProps<{
                                          clientId: string;
                                          locationId: string;
                                          movePageId: string;
                                        }>('EDIT_PAGE', { clientId, locationId, movePageId: movePage.id })}
                                      >
                                        {translationItem.title}
                                      </StyledLink>
                                    </TableCell>
                                    <TableCell align="right">
                                      <Checkbox
                                        checked={movePage.published}
                                        color="primary"
                                        onChange={(_event, checked) => {
                                          handleCheckboxChange(checked, movePage);
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell align="right">
                                      <IconButton
                                        onClick={() => {
                                          setModalState(
                                            true,
                                            <ConfirmDialog
                                              data={movePage.id}
                                              handleClick={handleDelete}
                                              dialogTitle={translate('are you sure you want to delete ')}
                                              recordTitle={translationItem.title}
                                              content={translate(
                                                "This item will be deleted immediately. You can't undo this action",
                                              )}
                                              primaryButtonLabel={translate('delete')}
                                              cancelLabel={translate('cancel')}
                                            />,
                                          );
                                        }}
                                      >
                                        <Delete fontSize="small" color="action" />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </Draggable>
                            ) : null;
                          })}
                        {(movePagesStore.remoteMovePages as IMovePage[]).length === 0 && (
                          <TableRow>
                            <TableCell colSpan={3}>{translate('no items to show')}</TableCell>
                          </TableRow>
                        )}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </Box>
            <Box mb={3}>
              <RemoteSuspense data={accountRoles} loadingFallback={<LinearProgress />}>
                {(accountRoles) =>
                  accountRoles.some((x) => x === Role.Internal) && (
                    <RemoteSuspense data={remoteTemplates} loadingFallback={<LinearProgress />}>
                      {(templates) => (
                        <FormControl>
                          <Label>{translate('add new page')}</Label>
                          <FormSelect
                            variant="outlined"
                            onChange={(event) => {
                              const foundTemplate = templates.find((template) => template.id === event.target.value);
                              setSelectedTemplate(foundTemplate);
                            }}
                          >
                            <option value="">{translate('please select')}</option>
                            {templates
                              .filter((template) => template.availableForInternal)
                              .map((template) =>
                                template.translations
                                  .filter(
                                    (templateTranslation) =>
                                      templateTranslation.locale ===
                                      ((movePages.length > 0 && movePages[0].defaultLanguage) || locale),
                                  )
                                  .map((filteredTemplateTranslation) => (
                                    <option key={template.id} value={template.id}>
                                      {filteredTemplateTranslation.title}
                                    </option>
                                  )),
                              )}
                          </FormSelect>
                        </FormControl>
                      )}
                    </RemoteSuspense>
                  )
                }
              </RemoteSuspense>
            </Box>
            <RemoteSuspense data={remoteTemplates} loadingFallback={<LinearProgress />}>
              {(templates) => (
                <FormControl>
                  <Label>{translate('add new page customer')}</Label>
                  <FormSelect
                    variant="outlined"
                    onChange={(event) => {
                      const foundTemplate = templates.find((template) => template.id === event.target.value);
                      setSelectedTemplate(foundTemplate);
                    }}
                  >
                    <option value="">{translate('please select')}</option>
                    {templates
                      .filter((template) => template.availableForCustomer)
                      .map((template) =>
                        template.translations
                          .filter(
                            (templateTranslation) =>
                              templateTranslation.locale ===
                              ((movePages.length > 0 && movePages[0].defaultLanguage) || locale),
                          )
                          .map((filteredTemplateTranslation) => (
                            <option key={template.id} value={template.id}>
                              {filteredTemplateTranslation.title}
                            </option>
                          )),
                      )}
                  </FormSelect>
                </FormControl>
              )}
            </RemoteSuspense>
            {selectedTemplate && (
              <Box mt={2}>
                <ButtonLink
                  to={getPathWithProps<{ clientId: string; locationId: string; templateId: string }>(
                    'CREATE_MOVE_PAGE',
                    {
                      clientId,
                      locationId: location.id,
                      templateId: selectedTemplate.id,
                    },
                  )}
                  variant="contained"
                  color="secondary"
                >
                  {translate('add')}
                </ButtonLink>
              </Box>
            )}
          </SectionContainer>
        </>
      )}
    </RemoteSuspense>
  );
});

export { ListPages };
