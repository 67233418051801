import React from 'react';
import Select from 'react-select';
import { CSSProperties } from 'react';

interface IOption {
  value: any;
  label: any;
}

interface IProps {
  options: IOption[];
  onChange?: (e: any) => void;
  value?: IOption;
  styles?: CSSProperties;
}

const GenericSelect: React.FunctionComponent<IProps> = (props: IProps) => {
  const { options, onChange, value, styles } = props;

  return (
    <Select
      options={options}
      onChange={onChange}
      value={value}
      defaultValue={options[0]}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          ...styles,
        }),
      }}
    />
  );
};

export default GenericSelect;
