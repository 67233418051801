import React, { PropsWithChildren } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Label } from '../Label';
import { FormikAutocompleteProps } from '.';
import { useField } from 'formik';

export function FormikAutocomplete<DataType>({
  formikKey,
  labelContent,
  data,
  optionLabelKey,
  formControlProps,
  isMultiple = false,
  onChangeCb,
}: PropsWithChildren<FormikAutocompleteProps<DataType>>) {
  const [field, meta, fieldHelpers] = useField({ name: formikKey, multiple: isMultiple });

  return (
    <FormControl error={meta.touched && Boolean(meta.error)} {...formControlProps}>
      <Label>{labelContent}</Label>
      <Autocomplete
        multiple={isMultiple}
        options={data}
        value={field.value}
        onChange={(event, value) => {
          fieldHelpers.setValue(value);
          if (onChangeCb) {
            onChangeCb(event, value);
          }
        }}
        onBlur={() => {
          fieldHelpers.setTouched(true);
        }}
        getOptionLabel={(option) => {
          return ((option as DataType)[optionLabelKey] as unknown) as string;
        }}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            {...params}
            inputProps={{ ...params.inputProps, autoComplete: 'no-autocomplete' }}
          />
        )}
        getOptionSelected={(option, value) => {
          return option !== undefined && value !== undefined
            ? (option as DataType)[optionLabelKey] === (value as DataType)[optionLabelKey]
            : false;
        }}
        size="small"
      />
      {meta.touched && meta.error && (
        <Box py={1}>
          <Typography color="error">{meta.error}</Typography>
        </Box>
      )}
    </FormControl>
  );
}
