import {
  PublicClientApplication,
  AuthenticationResult,
  AccountInfo,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';

interface IAuthenticationServiceConfiguration {
  appId: string;
  redirectUri: string;
  scopes: string[];
  authority: string;
}

export interface IAuthenticationServiceNormalizedError {
  message: string;
  debug?: string;
}

export interface IAuthenticationService {
  login: () => Promise<AuthenticationResult>;
  getLatestError: () => IAuthenticationServiceNormalizedError | undefined;
  logout: () => void;
  getAccessToken: () => Promise<string>;
  getGraphApiAccessToken: () => Promise<string>;
  getAccount: () => AccountInfo | null;
}

export class AuthenticationService implements IAuthenticationService {
  private userAgentApplication: PublicClientApplication;
  private scopes: string[];
  private error?: IAuthenticationServiceNormalizedError;
  private appId: string;

  constructor(config: IAuthenticationServiceConfiguration) {
    // Initialize the MSAL application object
    this.userAgentApplication = new PublicClientApplication({
      auth: {
        clientId: config.appId,
        redirectUri: config.redirectUri,
        authority: config.authority,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    });
    this.scopes = config.scopes;
    this.appId = config.appId;
  }

  async login() {
    try {
      // Login via popup
      const loginResponse = await this.userAgentApplication.loginPopup({
        scopes: ['openid', 'profile', 'offline_access'],
        prompt: 'select_account',
        // prompt: 'login',
      });
      this.userAgentApplication.setActiveAccount(loginResponse.account);
      return loginResponse;
    } catch (err) {
      this.error = this.normalizeError(err);
      throw err;
    }
  }

  getLatestError() {
    return this.error;
  }

  get latestError() {
    return this.error;
  }

  logout() {
    this.userAgentApplication.logout();
  }

  async getAccessToken(): Promise<string> {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      const silentResult = await this.userAgentApplication.acquireTokenSilent({
        scopes: ['openid', 'profile', ...this.scopes],
      });

      return silentResult.accessToken;
    } catch (err) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (this.isInteractionRequired(err)) {
        const interactiveResult = await this.userAgentApplication.acquireTokenPopup({
          scopes: this.scopes,
        });
        return interactiveResult.accessToken;
      } else {
        this.error = err;
        throw err;
      }
    }
  }

  async getGraphApiAccessToken(): Promise<string> {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      const silentResult = await this.userAgentApplication.acquireTokenSilent({
        scopes: ['openid', 'profile', `https://graph.microsoft.com/.default`],
      });

      return silentResult.accessToken;
    } catch (err) {
      // If a silent request fails, it may be because the user needs
      // to login or grant consent to one or more of the requested scopes
      if (this.isInteractionRequired(err)) {
        const interactiveResult = await this.userAgentApplication.acquireTokenPopup({
          scopes: ['https://graph.microsoft.com/.default'],
        });

        return interactiveResult.accessToken;
      } else {
        this.error = err;
        throw err;
      }
    }
  }

  private isInteractionRequired(error: Error): boolean {
    if (!error.message || error.message.length <= 0) {
      return false;
    }

    return error instanceof InteractionRequiredAuthError;
  }

  private normalizeError(error: string | Error): IAuthenticationServiceNormalizedError {
    let normalizedError: IAuthenticationServiceNormalizedError;
    if (typeof error === 'string') {
      const errParts = error.split('|');
      normalizedError = errParts.length > 1 ? { message: errParts[1], debug: errParts[0] } : { message: error };
    } else {
      normalizedError = {
        message: error.message,
        debug: JSON.stringify(error),
      };
    }
    return normalizedError;
  }

  getAccount() {
    const accounts = this.userAgentApplication.getAllAccounts();
    if (accounts.length > 0) {
      this.userAgentApplication.setActiveAccount(accounts[0]);
      return accounts[0];
    }

    return null;
  }

  getApplication() {
    return this.userAgentApplication;
  }

  getScopes() {
    return this.scopes;
  }
}

export function getAuthenticationService(config: IAuthenticationServiceConfiguration) {
  return new AuthenticationService(config);
}
