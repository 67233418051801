import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { NavigationBreadcrumbsWrapper } from '../../components/NavigationBreadcrumbs';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { observer } from 'mobx-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
import colors from '../../utils/colors';
import { ChangeEvent, useState } from 'react';
import dayjs from 'dayjs';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { Snackbar } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { Button, DialogActions, DialogTitle } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { DragDropContext, Droppable, DropResult, Draggable } from 'react-beautiful-dnd';
import { DragButtonIcon } from '../../components/DragButtonIcon';
import { StyledTableCell } from '../../components/StyledCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  submitButton: {
    backgroundColor: theme.palette.primary.light,
    marginRight: theme.spacing(2),
  },
  fileUploadLabel: {
    color: theme.palette.text.primary,
  },
}));

const FileUploadLabel = styled.label`
  display: inline-block;
`;

const FileUploadInput = styled.input`
  display: none;
`;

const FileUploadInfo = styled.span`
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
`;

const TableContainer = styled.div``;

const TableTitle = styled.div`
  height: 12px;
  color: ${colors.GRAY};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.36px;
  line-height: 12px;
  text-transform: uppercase;
  margin-top: 16px;
`;

interface IDocumentsState {
  selectedFile?: File;
  isSubmitting: boolean;
  shouldShowNotification: boolean;
  notificationText: string;
  notificationColor: Color;
  isConfirmDialogOpen: boolean;
  documentIdToDelete?: string;
}

const Documents: React.FunctionComponent = observer(() => {
  const [documentsState, setDocumentsState] = useState<IDocumentsState>({
    selectedFile: undefined,
    isSubmitting: false,
    shouldShowNotification: false,
    notificationText: '',
    notificationColor: 'error',
    isConfirmDialogOpen: false,
    documentIdToDelete: undefined,
  });

  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const {
    stores: { clientStore, locationStore },
    rootStore: { moveStore, documentsStore },
  } = useContext(ServiceContext);

  const { formContainer, submitButton, fileUploadLabel } = useStyles();

  const { clientId, locationId, moveId } = useParams<{ clientId: string; locationId: string; moveId: string }>();

  useAsyncOperation(() => {
    return clientStore.fetchClientData(clientId);
  }, [clientId]);

  useAsyncOperation(() => {
    return locationStore.fetch(clientId, locationId);
  }, [clientId, locationId]);

  useAsyncOperation(() => {
    return moveStore.fetchMove(locationId, moveId);
  }, [locationId, moveId]);

  useAsyncOperation(() => {
    return documentsStore.fetchDocuments(locationId, moveId);
  }, [moveId]);

  const onFileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.item(0) ?? undefined;
    setDocumentsState({
      ...documentsState,
      selectedFile: selectedFile,
    });
  };

  const validateInputs = () => {
    return !!documentsState.selectedFile;
  };

  const onSaveClicked = async () => {
    if (validateInputs()) {
      setDocumentsState({
        ...documentsState,
        isSubmitting: true,
      });

      if (!(await documentsStore.storeDocument(locationId, moveId, documentsState.selectedFile!))) {
        setDocumentsState({
          ...documentsState,
          shouldShowNotification: true,
          notificationText: translate('Failed to store the document'),
          isSubmitting: false,
        });
      } else {
        setDocumentsState({
          ...documentsState,
          selectedFile: undefined,
          isSubmitting: false,
        });
      }
    }
  };

  const onDeleteClicked = async (documentId: string) => {
    setDocumentsState({
      ...documentsState,
      isConfirmDialogOpen: true,
      documentIdToDelete: documentId,
    });
  };

  const onDeleteCanceled = async () => {
    setDocumentsState({
      ...documentsState,
      isConfirmDialogOpen: false,
    });
  };

  const onDeleteConfirmed = async () => {
    setDocumentsState({
      ...documentsState,
      isConfirmDialogOpen: false,
    });

    if (!(await documentsStore.deleteDocument(locationId, moveId, documentsState.documentIdToDelete!))) {
      setDocumentsState({
        ...documentsState,
        shouldShowNotification: true,
        notificationText: translate('Failed to delete the document'),
      });
    }
  };

  const onDownloadClicked = async (documentId: string, filename: string) => {
    const documentBlob = await documentsStore.downloadDocument(locationId, moveId, documentId);
    const url = window.URL.createObjectURL(documentBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  };

  const onDragEnd = async (result: DropResult) => {
    if (result.destination) {
      setDocumentsState({
        ...documentsState,
        isSubmitting: true,
      });

      if (
        !(await documentsStore.updateDocumentPosition(locationId, moveId, result.draggableId, result.destination.index))
      ) {
        setDocumentsState({
          ...documentsState,
          shouldShowNotification: true,
          notificationText: translate('Failed to store the document'),
          isSubmitting: false,
        });
      } else {
        setDocumentsState({
          ...documentsState,
          selectedFile: undefined,
          isSubmitting: false,
        });
      }
    }
  };

  return (
    <RemoteSuspense
      data={RemoteData.all(
        clientStore.remoteClient,
        locationStore.remoteLocation,
        moveStore.remoteMove,
        documentsStore.remoteMoveDocuments,
      )}
      loadingFallback={<LinearProgress />}
    >
      {([client, location, move, documents]) => (
        <>
          <NavigationBreadcrumbsWrapper
            items={[
              {
                url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                content: client.name,
              },
              {
                url: getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
                  clientId: clientId,
                  locationId: location.id,
                }),
                content: location.name,
              },
              {
                content: translate('documents'),
              },
            ]}
          />
          <Paper>
            <Box py={6} px={5}>
              <Typography variant="h6">{translate('documents')}</Typography>
              <Box pb={5}>
                <Typography>{translate('add documents that you would like to share with your employees')}</Typography>
              </Box>

              <Box pt={2}>
                <Paper className={formContainer}>
                  <Button
                    className={submitButton}
                    disabled={!documentsState.selectedFile || documentsState.isSubmitting}
                    onClick={onSaveClicked}
                  >
                    Add file
                  </Button>
                  <FileUploadLabel>
                    <FileUploadInfo className={fileUploadLabel}>
                      {documentsState.selectedFile && documentsState.selectedFile.name}
                      {!documentsState.selectedFile && translate('Browse...')}
                    </FileUploadInfo>
                    <FileUploadInput type="file" onChange={onFileSelected} />
                  </FileUploadLabel>
                </Paper>
              </Box>

              <TableContainer>
                <TableTitle>
                  {documents.length ? translate('Uploaded documents') : translate('No documents uploaded')}
                </TableTitle>
                {(documents.length && (
                  <Table style={{ marginTop: 4, marginBottom: 0 }}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell size="small" />
                        <StyledTableCell>{translate('Name')}</StyledTableCell>
                        <StyledTableCell style={{ width: 250 }}>{translate('date')}</StyledTableCell>
                        <StyledTableCell style={{ width: 80 }}></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="movePageRows">
                        {(provided) => (
                          <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                            {documents.map((document, index) => (
                              <>
                                <Draggable draggableId={document.id} index={index} key={document.id}>
                                  {(draggableProvider) => (
                                    <TableRow
                                      {...draggableProvider.draggableProps}
                                      {...draggableProvider.dragHandleProps}
                                      ref={draggableProvider.innerRef}
                                      key={document.id}
                                    >
                                      <TableCell size="small">
                                        <DragButtonIcon />
                                      </TableCell>
                                      <TableCell>{document.originalFilename}</TableCell>
                                      <TableCell>{dayjs(document.createdAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                                      <TableCell>
                                        <IconButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            onDownloadClicked(document.id, document.originalFilename);
                                          }}
                                        >
                                          <CloudDownload fontSize="small" color="action" />
                                        </IconButton>
                                        <IconButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            onDeleteClicked(document.id);
                                          }}
                                        >
                                          <DeleteIcon fontSize="small" color="action" />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Draggable>
                              </>
                            ))}
                          </TableBody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Table>
                )) || <></>}
              </TableContainer>
            </Box>
          </Paper>

          <Snackbar
            open={documentsState.shouldShowNotification}
            autoHideDuration={3000}
            onClose={() => {
              setDocumentsState({
                ...documentsState,
                shouldShowNotification: false,
              });
            }}
          >
            <Alert
              variant="filled"
              severity={documentsState.notificationColor}
              onClose={() => {
                setDocumentsState({
                  ...documentsState,
                  shouldShowNotification: false,
                  notificationText: '',
                });
              }}
            >
              {documentsState.notificationText}
            </Alert>
          </Snackbar>

          <Dialog open={documentsState.isConfirmDialogOpen}>
            <DialogTitle>{translate('Are you sure you want to delete?')}</DialogTitle>
            <DialogActions>
              <Button color="secondary" onClick={onDeleteCanceled}>
                {translate('cancel')}
              </Button>
              <Button color="primary" onClick={onDeleteConfirmed}>
                {translate('delete')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </RemoteSuspense>
  );
});

export { Documents };
