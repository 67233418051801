import { isNil } from 'lodash';

export type SortDirection = 'asc' | 'desc';

/**
 *
 * @param keys array of keys; order of keys is important, since it's possible to create a path for sort object;
 *             for example, if the key we want to sort by is nested in the object, `keys` argument would contain array of key names to access the nested object property;
 * @param order
 */
export function compareValues(keys: string[], order: SortDirection = 'asc') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function innerSort(a: Record<string, any>, b: Record<string, any>): number {
    const valA = keys.reduce((acc, key) => {
      return typeof acc === 'object' && !isNil(acc) ? acc[key] : a[key];
    }, keys[0]);

    const valB = keys.reduce((acc, key) => {
      return typeof acc === 'object' && !isNil(acc) ? acc[key] : b[key];
    }, keys[0]);

    return compare(valA, valB, order);
  };
}

/**
 * @param keys array of keys; order of keys is important, since it's possible to create a path for sort object;
 *             for example, if the key we want to sort by is nested in the object, `keys` argument would contain array of key names to access the nested object property;
 * @param order
 */
export function extractAndCompareValues(extractor: (_: any) => any, order: SortDirection = 'asc') {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function innerSort(a: any, b: any): number {
    const valA = extractor(a);
    const valB = extractor(b);

    return compare(valA, valB, order);
  };
}

function compare(valA: any, valB: any, order: SortDirection = 'asc'): number {
  let comparison = 0;
  // Nulls go at the end
  if (isNil(valA) && isNil(valB)) {
    comparison = 0;
  } else if (isNil(valA)) {
    comparison = 1;
  } else if (isNil(valB)) {
    comparison = -1;
  } else if (valA > valB) {
    comparison = 1;
  } else if (valA < valB) {
    comparison = -1;
  }
  return order === 'desc' ? comparison * -1 : comparison;
}

export function sortData<T>(data: T[], sortColumns: string[], sortDirection: SortDirection) {
  const dataCopy = data.slice();
  dataCopy.sort(compareValues(sortColumns, sortDirection));
  return dataCopy;
}
