import { observer } from 'mobx-react';
import React, { useContext, useCallback, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { NavigationBreadcrumbsWrapper } from '../../components/NavigationBreadcrumbs';
import { Header } from '../../components/typography/Header';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { ILease } from '../../services/ApiServiceV1';
import { LeaseRemindersList } from './LeaseRemindersList';
import { CustomButton } from '../../components/CustomButton';
import { CreateReminder } from './CreateReminder';
import FormSelect from '../../components/FormSelect';
import { Label } from '../../components/Label';
import FormControl from '@material-ui/core/FormControl';
import { ReminderFilter } from '../../stores/reminder/LeaseRemindersUiStore';
import { CustomInputLabel } from '../../components/CustomInputLabel';

const Reminders: React.FunctionComponent = observer(() => {
  const {
    stores: { clientStore },
    apiServiceV1,
  } = useContext(ServiceContext);
  const { leaseId, clientId } = useParams<{ clientId: string; leaseId: string }>();
  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);

  useAsyncOperation(() => {
    return clientStore.fetchClientData(clientId);
  }, [clientId]);

  const remoteLease = useAsyncOperation(() => {
    return apiServiceV1.fetchLease(leaseId);
  }, [leaseId]);

  return (
    <RemoteSuspense data={RemoteData.all(clientStore.remoteClient, remoteLease)}>
      {([client, lease]) => (
        <>
          <NavigationBreadcrumbsWrapper
            items={[
              {
                content: client.name,
                url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
              },
              {
                content: translate('{{leaseTitle}} reminders', { leaseTitle: lease.title }),
              },
            ]}
          />
          <Header>{translate('reminders')}</Header>
          <RemindersContentImpl lease={lease} />
        </>
      )}
    </RemoteSuspense>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  addReminderButton: {
    marginBottom: theme.spacing(2),
  },
  filterControlsContainer: {
    marginBottom: theme.spacing(1),
  },
}));

interface IRemindersContentImplProps {
  lease: ILease;
}

const RemindersContentImpl: React.FunctionComponent<IRemindersContentImplProps> = observer(({ lease }) => {
  const {
    rootStore: { leaseRemindersUiStore },
  } = useContext(ServiceContext);

  function onAddReminderClick() {
    leaseRemindersUiStore.toggleShouldShowAddReminderForm();
  }

  const onReminderFilterChange = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
      leaseRemindersUiStore.setReminderFilter(event.currentTarget.value as ReminderFilter);
    },
    [leaseRemindersUiStore],
  );

  const onFilterPastRemindersChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      leaseRemindersUiStore.toggleShouldFilterPastReminders();
    },
    [leaseRemindersUiStore],
  );

  const { translate } = useContext(TranslationContext);

  const { addReminderButton, filterControlsContainer } = useStyles();

  return (
    <Paper>
      <Box py={6} px={5}>
        <Typography variant="h6" gutterBottom>
          {translate('reminders for {{leaseTitle}}', { leaseTitle: lease.title })}
        </Typography>
        <Collapse in={!leaseRemindersUiStore.shouldShowAddReminderForm}>
          <CustomButton
            variant="contained"
            color="secondary"
            onClick={onAddReminderClick}
            className={addReminderButton}
          >
            {translate('add reminder')}
          </CustomButton>
        </Collapse>
        <Grid container alignItems="flex-end" spacing={2} className={filterControlsContainer}>
          <Grid item>
            <FormControl>
              <Label>{translate('show reminders')}</Label>
              <FormSelect
                native
                variant="outlined"
                value={leaseRemindersUiStore.reminderFilter}
                onChange={onReminderFilterChange}
              >
                <option value="all">{translate('all')}</option>
                <option value="pending">{translate('pending')}</option>
                <option value="done">{translate('done')}</option>
              </FormSelect>
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container item alignItems="baseline">
              <Grid item>
                <Checkbox
                  id="filter-past-reminders"
                  onChange={onFilterPastRemindersChange}
                  value={leaseRemindersUiStore.shouldFilterPastReminders}
                />
              </Grid>
              <Grid item>
                <CustomInputLabel htmlFor="filter-past-reminders">
                  {translate('filter past reminders')}
                </CustomInputLabel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Box pb={2} display="flex" alignItems="center"></Box> */}
        <CreateReminder lease={lease} />
        <LeaseRemindersList lease={lease} />
      </Box>
    </Paper>
  );
});

export { Reminders };
