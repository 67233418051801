import LinearProgress from '@material-ui/core/LinearProgress';
import { observer } from 'mobx-react';
import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { RemoteSuspense } from 'ts-remote-data-react';
import { ServiceContext } from '../context/ServicesContext';
import { FormikAutocomplete, IFormControlProps, IFormikField } from './FormikInputs';

const ClientsFormikDropdown: React.FunctionComponent<IFormikField & IFormControlProps> = observer((props) => {
  const {
    stores: { clientsStore },
  } = useContext(ServiceContext);
  useEffect(() => {
    async function fetchClients() {
      await clientsStore.fetch();
    }

    fetchClients();
  }, [clientsStore]);
  return (
    <RemoteSuspense data={clientsStore.remoteClients} loadingFallback={<LinearProgress />}>
      {(clients) => <FormikAutocomplete {...props} data={clients} optionLabelKey="name" />}
    </RemoteSuspense>
  );
});

export { ClientsFormikDropdown };
