import React, { useContext, useCallback } from 'react';
import { LeaseForm } from '../../components/LeaseForm';
import { observer } from 'mobx-react';
import { ServiceContext } from '../../context/ServicesContext';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { useHistory, useParams } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { NavigationBreadcrumbsWrapper } from '../../components/NavigationBreadcrumbs';
import { RouteMapContext } from '../../context/RouteMapContext';
import { TranslationContext } from '../../context/TranslationContext';
import { Header } from '../../components/typography/Header';
import { ILeasePayload } from '../../services/ApiServiceV1';
import { NotificationContext } from '../../context/NotificationContext';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

const CreateLease: React.FunctionComponent = observer(() => {
  const { clientId } = useParams<{ clientId: string }>();
  const history = useHistory();
  const {
    stores: { clientStore },
    rootStore: { clientLeasesStore },
  } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const { translate } = useContext(TranslationContext);

  useAsyncOperation(() => {
    return clientStore.fetchClientData(clientId);
  }, []);

  const onSubmit = useCallback(
    async (values: ILeasePayload) => {
      try {
        await clientLeasesStore.saveNewLease(clientId, values);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully saved a lease'),
            color: 'success',
          },
        });
        history.push(
          getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId }),
        );
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to save a lease'),
            color: 'error',
          },
        });
      }
    },
    [clientId, clientLeasesStore, getPathWithProps, history, notificationDispatch, translate],
  );

  return (
    <>
      <RemoteSuspense data={clientStore.remoteClient} loadingFallback={<LinearProgress />}>
        {(client) => (
          <>
            <NavigationBreadcrumbsWrapper
              items={[
                {
                  content: client.name,
                  url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                },
                {
                  content: translate('add new lease'),
                },
              ]}
            />
            <Header>{translate('create lease')}</Header>
            <Paper>
              <Box py={6} px={5}>
                <LeaseForm client={client} onSubmit={onSubmit} />
              </Box>
            </Paper>
          </>
        )}
      </RemoteSuspense>
    </>
  );
});

export { CreateLease };
