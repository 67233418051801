import React, { useContext } from 'react';
import { AuthenticationContext } from '../context/AuthenticationContext';
import { useHistory } from 'react-router-dom';
import { RemoteSuspense } from 'ts-remote-data-react';
import { TranslationContext } from '../context/TranslationContext';
import { CustomButton } from './CustomButton';
import RemoteData from 'ts-remote-data';
import { RouteMapContext } from '../context/RouteMapContext';

const AuthButton: React.FunctionComponent = () => {
  const { remoteIsAuthenticated, login, logout, remoteUser } = useContext(AuthenticationContext);
  const history = useHistory();
  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const remoteData = {
    isAuthenticated: remoteIsAuthenticated,
    user: remoteUser,
  };
  return (
    <RemoteSuspense data={remoteData} loadingFallback={<button disabled>{translate('sign in')}</button>}>
      {({ isAuthenticated, user }) => {
        return (
          <CustomButton
            variant="contained"
            color="secondary"
            onClick={async () => {
              if (isAuthenticated) {
                logout();
              } else {
                await login();
                if (RemoteData.isReady(user)) {
                  if (user.isExternal) {
                    user.client
                      ? history.replace(
                          getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: user.client.id }),
                        )
                      : history.replace(getPathWithProps('NO_CLIENT'));
                  } else {
                    history.replace('/admin');
                  }
                }
              }
            }}
          >
            {isAuthenticated ? translate('sign out') : translate('sign in')}
          </CustomButton>
        );
      }}
    </RemoteSuspense>
  );
};

export default AuthButton;
