import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  styledHeader: {
    textTransform: 'uppercase',
  },
}));

const Header: React.FunctionComponent = ({ children }) => {
  const { styledHeader } = useStyles();

  return (
    <Typography variant="h5" className={styledHeader}>
      {children}
    </Typography>
  );
};
export { Header };
