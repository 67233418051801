import { action, observable } from 'mobx';

class MoveContactsUiStore {
  @observable
  shouldShowAddMoveContactForm = false;

  @action
  toggleShouldShowAddMoveContactForm() {
    this.shouldShowAddMoveContactForm = !this.shouldShowAddMoveContactForm;
  }
}

export { MoveContactsUiStore };
