import React, { useCallback, useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { IMoveContact, IMoveContactPayload } from '../../services/ApiServiceV1';
import { Typography } from '@material-ui/core';
import { MoveContactForm } from './MoveContactForm';
import { observer } from 'mobx-react';
import { ServiceContext } from '../../context/ServicesContext';
import { NotificationContext } from '../../context/NotificationContext';
import { TranslationContext } from '../../context/TranslationContext';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CustomButton } from '../../components/CustomButton';
import { ModalContext } from '../../context/ModalContext';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { Draggable } from 'react-beautiful-dnd';
import { DraggableGreyAccordionContainer } from '../../components/DraggableGreyAccordionContainer';
import { DragButtonIcon } from '../../components/DragButtonIcon';

interface IMoveContactListItemProps {
  moveContact: IMoveContact;
  index: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteButton: {
    marginTop: theme.spacing(1),
  },
}));

const MoveContactListItem: React.FunctionComponent<IMoveContactListItemProps> = observer(({ moveContact, index }) => {
  const {
    stores: { moveContactsStore },
  } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { translate } = useContext(TranslationContext);
  const { setModalState, setIsModalOpen } = useContext(ModalContext);

  const [isExpanded, setExpanded] = useState(false);

  const { deleteButton } = useStyles();

  const onSubmit = useCallback(
    async (values: IMoveContactPayload) => {
      try {
        await moveContactsStore.update(moveContact.id, values);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully updated move contact'),
            color: 'success',
          },
        });
        setExpanded(false);
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to update move contact'),
            color: 'error',
          },
        });
      }
    },
    [moveContact.id, moveContactsStore, notificationDispatch, translate],
  );

  const onDelete = useCallback(async () => {
    try {
      await moveContactsStore.delete(moveContact.id);
      notificationDispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          message: translate('successfully removed move contact'),
          color: 'success',
        },
      });
    } catch (error) {
      notificationDispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          message: translate('failed to remove move contact'),
          color: 'error',
        },
      });
    } finally {
      setIsModalOpen(false);
    }
  }, [moveContact.id, moveContactsStore, notificationDispatch, setIsModalOpen, translate]);

  return (
    <Draggable draggableId={moveContact.id} index={index}>
      {(provided) => (
        <DraggableGreyAccordionContainer
          provided={provided}
          onSummaryClick={() => {
            setExpanded((prevValue) => !prevValue);
          }}
          expanded={isExpanded}
          summaryComponent={
            <Grid container alignItems="center">
              <Grid container item xs={3} alignItems="center">
                <Grid item>
                  <DragButtonIcon />
                </Grid>
                <Grid item>
                  <Typography color="textPrimary" variant="h5">
                    {moveContact.name}
                  </Typography>
                </Grid>
              </Grid>
              {moveContact.title && (
                <Grid container item direction="column" xs={3}>
                  <Grid item>
                    <Typography variant="button">{translate('title')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{moveContact.title}</Typography>
                  </Grid>
                </Grid>
              )}
              {moveContact.phonenumber && (
                <Grid container item direction="column" xs={3}>
                  <Grid item>
                    <Typography variant="button">{translate('phone')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{moveContact.phonenumber}</Typography>
                  </Grid>
                </Grid>
              )}
              {moveContact.email && (
                <Grid container item direction="column" xs={3}>
                  <Grid item>
                    <Typography variant="button">{translate('email')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{moveContact.email}</Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          }
        >
          <Box width={1}>
            <MoveContactForm onSubmit={onSubmit} moveContact={moveContact} />
            <CustomButton
              variant="outlined"
              className={deleteButton}
              onClick={() => {
                setModalState(
                  true,
                  <ConfirmDialog
                    data={moveContact.id}
                    handleClick={onDelete}
                    dialogTitle={translate('are you sure you want to delete ')}
                    recordTitle={moveContact.name}
                    content={translate("This item will be deleted immediately. You can't undo this action")}
                    primaryButtonLabel={translate('delete')}
                    cancelLabel={translate('cancel')}
                  />,
                );
              }}
            >
              {translate('delete')}
            </CustomButton>
          </Box>
        </DraggableGreyAccordionContainer>
      )}
    </Draggable>
  );
});

export { MoveContactListItem };
