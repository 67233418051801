export function getBackendBaseUrl(): string {
  return process.env['NODE_ENV'] === 'development'
    ? `http://localhost:${process.env['REACT_APP_DEVELOPMENT_PORT']}/api`
    : `${process.env['REACT_APP_BACKEND_BASE_URL']}/api`;
}

export function isBackendUrl(url: string) {
  const matchResult = url.match(getBackendBaseUrl());
  return matchResult && matchResult.length > 0 && matchResult[0] !== '' ? true : false;
}

export function getRelokatorPathLabel(clientPath: string): string {
  if (!clientPath) return '';

  return process.env['NODE_ENV'] === 'development'
    ? `${process.env['REACT_APP_CLIENT_SITE_BASE_URL']}`
    : `${clientPath}.${process.env['REACT_APP_CLIENT_SITE_BASE_URL']}`;
}

export function getRelokatorUrl(clientPath: string): string {
  if (!clientPath) return '';

  const protocol = process.env['NODE_ENV'] === 'development' ? 'http' : 'https';
  return `${protocol}://${getRelokatorPathLabel(clientPath)}/`;
}
