import React, { useContext } from 'react';
import { DashboardLinkBox } from './DashboardBox';
import { TranslationContext } from '../context/TranslationContext';
import { RouteMapContext } from '../context/RouteMapContext';
import { IClient, ILocation, ILocationMove, IMove } from '../services/ApiServiceV1';

interface ISubNavigationBoxProps {
  client: IClient;
  location: ILocation;
  move: IMove | ILocationMove;
}

interface IMovePage {
  clientId: string;
  locationId: string;
  moveId: string;
}

const SubNavigationBox: React.FunctionComponent<ISubNavigationBoxProps> = ({ location, move, client }) => {
  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  return (
    <>
      <DashboardLinkBox
        title={translate('move details')}
        url={getPathWithProps<IMovePage>('MOVE_DETAILS', {
          clientId: client.id,
          locationId: location.id,
          moveId: move.id,
        })}
        square
      />
      <DashboardLinkBox
        title={translate("FAQ's")}
        url={getPathWithProps<IMovePage>('MOVE_FAQs', {
          clientId: client.id,
          locationId: location.id,
          moveId: move.id,
        })}
        square
      />
      <DashboardLinkBox
        title={translate('pages')}
        url={getPathWithProps<IMovePage>('MOVE_PAGES', {
          clientId: client.id,
          locationId: location.id,
          moveId: move.id,
        })}
        square
      />
      <DashboardLinkBox
        title={translate('forms')}
        url={getPathWithProps<IMovePage>('MOVE_FEEDBACK', {
          clientId: client.id,
          locationId: location.id,
          moveId: move.id,
        })}
        square
      />
      <DashboardLinkBox
        title={translate('contacts')}
        url={getPathWithProps<IMovePage>('MOVE_CONTACTS', {
          clientId: client.id,
          locationId: location.id,
          moveId: move.id,
        })}
        square
      />
      <DashboardLinkBox
        title={translate('floorplan')}
        url={getPathWithProps<IMovePage>('FLOOR_PLANNER', {
          clientId: client.id,
          locationId: location.id,
          moveId: move.id,
        })}
        square
      />
      <DashboardLinkBox
        title={translate('documents')}
        url={getPathWithProps<IMovePage>('DOCUMENTS', {
          clientId: client.id,
          locationId: location.id,
          moveId: move.id,
        })}
        square
      />
    </>
  );
};

export { SubNavigationBox };
