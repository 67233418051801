import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ViewListIcon from '@material-ui/icons/ViewList';
import ExploreIcon from '@material-ui/icons/Explore';
import PeopleIcon from '@material-ui/icons/People';
import BallotIcon from '@material-ui/icons/Ballot';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import LanguageSelect from './LanguageSelect';
import { TranslationContext } from '../../context/TranslationContext';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { RouteMapContext } from '../../context/RouteMapContext';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import RemoteData from 'ts-remote-data';
import AppsIcon from '@material-ui/icons/Apps';
import { RemoteSuspense } from 'ts-remote-data-react';
import { IClients } from '../../services/ApiServiceV1';
import { Role } from '../../domain/Role';
// import SettingsIcon from '@material-ui/icons/Settings';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles((theme: Theme) => ({
  drawerGrid: {
    minHeight: `calc(100vh - 4rem)`,
  },
  listItemBorderTop: {
    borderTop: '1px solid rgba(255, 255, 255, 0.2)',
  },
  whiteColor: {
    color: theme.palette.common.white,
  },
  drawerBottomContent: (props: { isDrawerOpen: boolean }) => ({
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
    },
    textAlign: props.isDrawerOpen ? 'center' : 'inherit',
  }),
  highlightMenuItem: {
    background: `${theme.palette.primary.dark}`,
    '&:hover': {
      background: `${theme.palette.primary.dark}`,
    },
  },
}));

interface IDrawerContentProps {
  isDrawerOpen: boolean;
}

const DrawerContent: React.FunctionComponent<IDrawerContentProps> = ({ isDrawerOpen }) => {
  const { translate } = useContext(TranslationContext);
  const { drawerGrid, listItemBorderTop, whiteColor, drawerBottomContent, highlightMenuItem } = useStyles({
    isDrawerOpen,
  });
  const [isClientHighlighted, setIsClientHighlighted] = useState(false);
  const [isLocationsHighlighted, setIsLocationsHighlighted] = useState(false);
  const [isTemplatesHighlighted, setIsTemplatesHighlighted] = useState(false);
  const [isUsersHighlighted, setIsUsersHighlighted] = useState(false);
  const [isDashboardHighlighted, setIsDashboardHighlighted] = useState(false);
  const location = useLocation();

  const { getPathWithProps } = useContext(RouteMapContext);
  const { remoteUser, remoteIsAuthenticated, accountRoles } = useContext(AuthenticationContext);

  useEffect(() => {
    const isCLientRegex = /\/admin\/clients/gm;
    setIsClientHighlighted(!!isCLientRegex.exec(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    const isLocationsRegex = /\/admin\/locations/gm;
    setIsLocationsHighlighted(!!isLocationsRegex.exec(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    const isTemplatesRegex = /\/admin\/templates/gm;
    setIsTemplatesHighlighted(!!isTemplatesRegex.exec(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    const isUsersRegex = /\/admin\/users/gm;
    setIsUsersHighlighted(!!isUsersRegex.exec(location.pathname));
  }, [location.pathname]);

  const isInternalUser =
    RemoteData.isReady(remoteIsAuthenticated) &&
    RemoteData.isReady(remoteUser) &&
    remoteIsAuthenticated &&
    remoteUser.isExternal === false;

  const isSuperUser = RemoteData.isReady(accountRoles) && accountRoles.some((x) => x === Role.InternalSuper);

  useEffect(() => {
    const internalUserRootPathRegex = /\/admin$/gm;
    const externalUserRootPathRegex = /\/admin\/clients\/[A-Z\-0-9]+$/gm;

    const isInternalAdminDashboard = !!internalUserRootPathRegex.exec(location.pathname) && isInternalUser === true;
    const isExternalAdminDashboard = !!externalUserRootPathRegex.exec(location.pathname) && isInternalUser === false;

    const shouldHighlight = isInternalAdminDashboard || isExternalAdminDashboard;
    setIsDashboardHighlighted(shouldHighlight);
  }, [isInternalUser, location.pathname]);

  return (
    <Grid container direction="column" justify="space-between" className={drawerGrid}>
      <Grid item>
        <List>
          <RemoteSuspense data={remoteUser}>
            {(user) => (
              <ListItem
                button
                className={listItemBorderTop}
                component={Link}
                to={
                  isInternalUser
                    ? getPathWithProps('ADMIN_ROOT')
                    : getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', {
                        clientId: (user.client as IClients).id,
                      })
                }
                classes={{
                  root: clsx({ [highlightMenuItem]: isDashboardHighlighted }),
                }}
              >
                <ListItemIcon>
                  <AppsIcon classes={{ root: whiteColor }} />
                </ListItemIcon>
                <ListItemText primary={translate('dashboard')} />
              </ListItem>
            )}
          </RemoteSuspense>

          {/* templates && users */}
          {isInternalUser && (
            <>
              {/* clients */}
              <ListItem
                button
                component={Link}
                to={getPathWithProps('CLIENTS_ROOT')}
                classes={{
                  root: clsx({ [highlightMenuItem]: isClientHighlighted }),
                }}
              >
                <ListItemIcon>
                  <ViewListIcon classes={{ root: whiteColor }} />
                </ListItemIcon>
                <ListItemText primary={translate('clients')} />
              </ListItem>
              {/* locations */}
              <ListItem
                button
                component={Link}
                to={getPathWithProps('LOCATIONS_ROOT')}
                classes={{
                  root: clsx({ [highlightMenuItem]: isLocationsHighlighted }),
                }}
              >
                <ListItemIcon>
                  <ExploreIcon classes={{ root: whiteColor }} />
                </ListItemIcon>
                <ListItemText primary={translate('locations')} />
              </ListItem>
              {isSuperUser && (
                <>
                  <ListItem
                    button
                    component={Link}
                    to={getPathWithProps('TEMPLATES_ROOT')}
                    classes={{
                      root: clsx({ [highlightMenuItem]: isTemplatesHighlighted }),
                    }}
                  >
                    <ListItemIcon>
                      <BallotIcon classes={{ root: whiteColor }} />
                    </ListItemIcon>
                    <ListItemText primary={translate('templates')} />
                  </ListItem>
                </>
              )}

              <ListItem
                button
                component={Link}
                to={getPathWithProps('USERS_LIST')}
                classes={{
                  root: clsx({ [highlightMenuItem]: isUsersHighlighted }),
                }}
              >
                <ListItemIcon>
                  <PeopleIcon classes={{ root: whiteColor }} />
                </ListItemIcon>
                <ListItemText primary={translate('users')} />
              </ListItem>
            </>
          )}

          {/* settings */}
          {/* <ListItem button>
            <ListItemIcon>
              <SettingsIcon classes={{ root: whiteColor }} />
            </ListItemIcon>
            <ListItemText primary={translate('settings')} />
          </ListItem> */}

          {/* more */}
          {/* <ListItem button>
            <ListItemIcon>
              <MoreHorizIcon classes={{ root: whiteColor }} />
            </ListItemIcon>
            <ListItemText primary={translate('more')} />
          </ListItem> */}
        </List>
      </Grid>
      <Grid item>
        <Box pb={8} className={drawerBottomContent}>
          <LanguageSelect isDrawerOpen={isDrawerOpen} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default DrawerContent;
