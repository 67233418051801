import { useField } from 'formik';
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FormikDatepickerProps } from '.';
import { Label } from '../Label';
import StyledDatePicker from '../StyledDatePicker';

const FormikDatePicker: React.FunctionComponent<FormikDatepickerProps> = ({
  formikKey,
  labelContent,
  showLabel,
  formControlProps,
  ...props
}) => {
  const [field, meta, helpers] = useField({ name: formikKey, type: 'text' });
  return (
    <FormControl error={meta.touched && Boolean(meta.error)} {...formControlProps}>
      {showLabel && <Label>{labelContent}</Label>}
      <StyledDatePicker
        {...props}
        value={field.value}
        onChange={(date) => {
          helpers.setValue(date);
        }}
      />
      {meta.touched && meta.error && (
        <Box py={1}>
          <Typography color="error">{meta.error}</Typography>
        </Box>
      )}
    </FormControl>
  );
};

export { FormikDatePicker };
