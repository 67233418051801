import React from 'react';

import Table from '@material-ui/core/Table';
import { FAQRow } from './FAQrow';
import { IFAQ, IFAQPayload } from '../../services/ApiServiceV1';
import { Grid, Typography } from '@material-ui/core';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useContext } from 'react';
import { TranslationContext } from '../../context/TranslationContext';
import { OnFaqPublished } from '.';

interface IFAQTable {
  FAQs: IFAQ[];
  handleDelete: (feedbackId: string) => Promise<void>;
  handleSave: (values: IFAQPayload) => Promise<void>;
  handlePublishedToggle: OnFaqPublished;
}
const FAQTable: React.FC<IFAQTable> = ({ FAQs, handleDelete, handleSave, handlePublishedToggle }) => {
  const { translate } = useContext(TranslationContext);
  return (
    <Droppable droppableId={'FAQlist'}>
      {(provided) => (
        <Grid container {...provided.droppableProps} ref={provided.innerRef}>
          {FAQs.length > 0 ? (
            FAQs.map((FAQ, index: number) => (
              <Draggable key={FAQ.id} draggableId={FAQ.id} index={index}>
                {(provided) => (
                  <Table ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <FAQRow
                      FAQ={FAQ}
                      handleDelete={handleDelete}
                      handleSave={handleSave}
                      key={index}
                      index={index}
                      handlePublishedToggle={handlePublishedToggle}
                    />
                  </Table>
                )}
              </Draggable>
            ))
          ) : (
            <Typography>{translate('no faqs yet')}</Typography>
          )}
          {provided.placeholder}
        </Grid>
      )}
    </Droppable>
  );
};

export default FAQTable;
