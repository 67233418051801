import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IHttpService {
  instance: AxiosInstance;
  setInstanceAuthToken: (authToken: string) => void;
  documentInstance: AxiosInstance;
  setDocumentInstanceAuthToken: (authToken: string) => void;
  tokenRefreshRetryAmount: number;
  tokenRefreshRetryLimit: number;
  setTokenRefreshRetryAmount: (amount: number) => void;
}

export function createHttpService(config: AxiosRequestConfig) {
  const httpService = new HttpService(config);
  return httpService;
}

class HttpService implements IHttpService {
  private _instance: AxiosInstance;
  private _documentInstance: AxiosInstance;
  private _tokenRefreshRetryAmount = 0;
  private _tokenRefreshRetryLimit = 5;
  constructor(axiosConfig: AxiosRequestConfig) {
    this._instance = axios.create(axiosConfig);
    this._documentInstance = axios.create(axiosConfig);
  }

  get instance() {
    return this._instance;
  }

  get documentInstance() {
    return this._documentInstance;
  }

  get tokenRefreshRetryAmount() {
    return this._tokenRefreshRetryAmount;
  }

  setTokenRefreshRetryAmount(amount: number) {
    this._tokenRefreshRetryAmount = amount;
  }

  get tokenRefreshRetryLimit() {
    return this._tokenRefreshRetryLimit;
  }

  setInstanceAuthToken(authToken: string) {
    this._instance.defaults.headers['Authorization'] = `Bearer ${authToken}`;
  }

  setDocumentInstanceAuthToken(authToken: string) {
    this._documentInstance.defaults.headers['Authorization'] = `Bearer ${authToken}`;
  }
}
