import { Lang } from '../i18n';
import { IHttpService } from './httpService';
import {
  TimelineSection,
  TimelineEvent,
  TimelineEventRequest,
  TimelineSectionRequest,
  convertTimelineSectionsResponse,
} from '@fmg-packages/common-components';
import dayjs, { Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { convertLibraryResponse, IFileTree } from '../utils/convertLibraryResponse';
import { ResponseType } from 'axios';

export interface ICountry {
  id: string;
  name: string;
}

export interface IClient {
  id: string;
  name: string;
  orgNumber: string;
  country: ICountry;
  active: boolean;
  locations: ILocation[];
  brokerings: IBrokering[];
}

export interface IClients {
  id: string;
  name: string;
  orgNumber: string;
  country: ICountry;
  active: boolean;
  countOfLocations: number;
  countOfActiveLocations: number;
}

export interface IClientLocations {
  client: IClient;
  locations: ILocation[];
}

export interface IClientPayload {
  name: string;
  orgNumber: string;
  countryId: string;
  active: boolean;
}

export interface IUpdateClientResponse {
  name: string;
  orgNumber: string;
  country: ICountry;
  active: boolean;
}

export interface ILocationPayload {
  name: string;
  address: string;
  city: string;
  isOffice: boolean;
  isProduction: boolean;
  countryId: string;
}

export enum AuthorizationScheme {
  Password = 1,
  Email = 2,
  EmailWithPassword = 3,
}

export interface IMove {
  id: string;
  languages: Lang[];
  defaultLanguage: Lang;
  moveDetails: IMoveDetails[];
  password: string;
  path: string;
  stickersCount: number;
  client: IClient;
  teamMembers: {
    id: string;
  }[];
  clientContact?: string | null;
  faqQuestions: IFAQ[];
  feedback: IFeedback[];
  authorizationScheme: AuthorizationScheme;
  whitelistedDomains: string[];
  hideFloorSelection: boolean;
  isEnabled: boolean;
}

export interface ILocation {
  id: string;
  name: string;
  address: string;
  city: string;
  isOffice: boolean;
  isProduction: boolean;
  isStorage: boolean;
  country: ICountry;
  clientId: string;
  client: IClient;
  services: {
    move: ILocationMove | null;
  };
}

export interface ILocationMove {
  id: string;
  languages: Lang[];
  defaultLanguage: Lang;
  moveDetails: IMoveDetails[];
  password: string;
  path: string;
  stickersCount: number;
  isEnabled: boolean;
}

export interface IClientsResponse {
  id: string;
  name: string;
  orgNumber: string;
  country: ICountry;
  active: boolean;
  data: {
    countOfLocations: number;
    countOfActiveLocations: number;
  };
}

export interface IMoveDetails {
  id: string;
  type: 'text';
  title: string;
  description: string;
  position: number;
}

export interface IMoveDocument {
  id: string;
  originalFilename: string;
  createdAt: Date;
  position: number;
}

export interface IMoveDetailsPayload {
  type: 'text';
  title: string;
  description: string;
}

export interface IMoveDetailsPositionPayload {
  moveDetailId: string;
  newPosition: number;
}

export interface IMovePayload {
  languages: Lang[];
  defaultLanguage: Lang;
  password: string;
  stickersCount: number;
  clientContact: IBackendUser[];
  teamMembers: IBackendUser[];
  path: string;
  authorizationScheme: AuthorizationScheme;
  whitelistedDomains: string[] | string;
  hideFloorSelection: boolean;
}

export interface IMovePagePositionPayload {
  movePageId: string;
  newPosition: number;
}

export interface ITemplatePayloadData {
  locale: Lang;
  title: string;
  content: string;
}

export interface ITemplateData extends ITemplatePayloadData {
  id: string;
}

export interface ITemplate {
  id: string;
  translations: ITemplateData[];
  position: number;
  availableForInternal: boolean;
  availableForCustomer: boolean;
}

export interface ITemplatePayload {
  id?: string;
  translations: ITemplatePayloadData[];
  position: number;
}

export interface ITemplatePositionPayload {
  id: string;
  position: number;
}
export interface ITemplateAvailableForInternalPayload {
  id: string;
  availableForInternal: boolean;
}
export interface ITemplateAvailableForCustomerPayload {
  id: string;
  availableForCustomer: boolean;
}

export interface IMovePage {
  id: string;
  published: boolean;
  templateId: string;
  translations: ITemplateData[];
  defaultLanguage: Lang;
}

export interface IMovePagePayload {
  published: boolean;
  templateId: string;
  translations: ITemplatePayloadData[];
}

export interface IDeleteResponse {
  message: string;
}
export interface IFAQ {
  id: string;
  question: string;
  answer: string;
  position: number;
  published: boolean;
}

export interface IColorsPayload {
  moveId: string;
  primaryColor: string;
  darkerPrimaryColor: string;
  secondaryColor: string;
  darkerSecondaryColor: string;
  backgroundColor: string;
  headingColor: string;
}

export interface IFAQPayload {
  question: string;
  answer: string;
  published: boolean;
}

export type AddFaqPayload = Omit<IFAQPayload, 'published'>;

export interface IFAQPositionPayload {
  faqQuestionId: string;
  newPosition: number;
}

export type FeedbackType = 'question' | 'feedback' | 'error';

export type FeedbackStatusType = 'pending' | 'answered';

export interface IFeedbackStatusPayload {
  status: FeedbackStatusType;
  response: string;
}

export interface IFeedbackPayload {
  feedback: {
    question: string;
    feedbackType: FeedbackType;
    answer: string;
    name: string;
    email: string;
    phone: string;
  };
}

export interface IFeedback {
  id: string;
  question: string;
  answer: string;
  feedbackType: FeedbackType;
  name: string;
  email: string;
  phone: string;
  status: FeedbackStatusType;
  createdAt: Date;
  updatedAt: Date;
  answers: IFeedbackAnswer[];
}

export interface IFeedbackAnswer {
  id: string;
  response: string;
  user: string;
  createdAt: Date;
}

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
type RequiredBy<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;
export type TimelineEventPayload = {
  id?: string;
  title: string;
  description: string | null;
  startDate: Dayjs;
  endDate: Dayjs | null;
};
export type ITimelineSectionPayload = PartialBy<TimelineSection, 'id'>;

export interface IBackendUser {
  id?: string;
  displayName?: string;
  mail?: string;
  externalUserState?: 'PendingAcceptance' | 'Accepted';
}

export type Client = {
  id: string;
  name: string;
  orgNumber: string;
  country: ICountry;
  active: boolean;
};

export interface IUser {
  user: IBackendUser;
  client: Client | null;
  isExternal: boolean;
}

type PhotoSize = '48x48' | '64x64' | '96x96' | '120x120' | '240x240' | '360x360' | '432x432' | '504x504' | '648x648';

export interface IExternalUserPayload {
  name: string;
  email: string;
  clientId: string;
}

interface IUserUpdatePayload {
  clientId: string;
}

export interface IBrokeringPayload {
  name: string;
  clientContact?: IBackendUser;
  projectManager?: IBackendUser;
  teamMembers: IBackendUser[];
  externalTeamMembers: IBackendUser[];
  documentProjectId: string;
  documentPrefix: string;
}

export interface IUserBackendData {
  id: string;
}

export interface IBrokering {
  id: string;
  name: string;
  clientContact?: IUserBackendData;
  projectManager?: IUserBackendData;
  teamMembers: IUserBackendData[];
  externalTeamMembers: IUserBackendData[];
  documentProjectId: string;
  documentPrefix: string;
}

export interface ILease {
  id: string;
  isOffice?: boolean;
  isParking?: boolean;
  isStorage?: boolean;
  isProduction?: boolean;
  isOther?: boolean;
  locations: ILocation[];
  title: string;
  floors?: string;
  leaseStart?: Dayjs | null;
  leaseEnd?: Dayjs | null;
  areaSpace?: number;
  numberOfPeople?: number;
  seats?: number;
  numberOfParkingSpaces?: number;
  leaseRenewalDeadline?: Dayjs | null;
  increasedFloorSpaceDeadline?: Dayjs | null;
  leasePrice?: number;
  consumerPriceIndexDate?: Dayjs | null;
  sharedCost?: number;
  description?: string;
  remindersCount: number;
}

export interface ILeaseResponse {
  isOffice?: boolean;
  isParking?: boolean;
  isStorage?: boolean;
  isProduction?: boolean;
  isOther?: boolean;
  locations: ILocation[];
  title: string;
  floors?: string;
  leaseStart?: string;
  leaseEnd?: string;
  areaSpace?: number;
  numberOfPeople?: number;
  seats?: number;
  numberOfParkingSpaces?: number;
  leaseRenewalDeadline: string;
  increasedFloorSpaceDeadline: string;
  leasePrice?: number;
  consumerPriceIndexDate: string;
  sharedCost?: number;
  description?: string;
  remindersCount: number;
}

export interface ILeasePayload {
  isOffice: boolean;
  isParking: boolean;
  isStorage: boolean;
  isProduction: boolean;
  isOther: boolean;
  locations: ILocation[];
  title: string;
  floors: string;
  leaseStart: Dayjs | null;
  leaseEnd: Dayjs | null;
  areaSpace?: number;
  numberOfPeople?: number;
  seats?: number;
  numberOfParkingSpaces?: number;
  leaseRenewalDeadline: Dayjs | null;
  increasedFloorSpaceDeadline: Dayjs | null;
  leasePrice?: number;
  consumerPriceIndexDate: Dayjs | null;
  sharedCost?: number;
  description?: string;
}

export type TimelineSectionType = 'move' | 'brokering';

export interface IDocument {
  title: string;
  documentPath: string;
  documentOpenPath: string;
  documentType: string;
  documentModifiedDate: string;
  documentModifiedBy: string;
  documentSize: string;
}

export interface ILibrary {
  title: string;
  documents: IDocument[];
}

export interface IDocumentsResponse {
  filetree: IFileTree[];
  baseUrl: string | null;
}

export interface IReminderPayload {
  title: string;
  description: string;
  dueDate: Dayjs | null;
  done: boolean;
  doneComment: string;
}

export interface IReminderResponse {
  id: string;
  title: string;
  description: string;
  dueDate: string;
  sentAt: string | null;
  done: boolean;
  doneComment: string;
}

export interface IReminder {
  id: string;
  title: string;
  description: string;
  dueDate: Dayjs;
  sentAt: Dayjs | null;
  done: boolean;
  doneComment: string;
}

export interface IReminderLeaseResponse {
  id: string;
  title: string;
  description: string;
  dueDate: string;
  lease: ILeaseResponse;
}

export interface IReminderLease {
  id: string;
  title: string;
  description: string;
  dueDate: Dayjs;
  lease: ILease;
}

export interface IMoveContact {
  id: string;
  name: string;
  title?: string;
  phonenumber?: string;
  email?: string;
  index: number;
}

export interface IMoveContactPayload {
  name: string;
  title: string | null;
  phonenumber: string | null;
  email: string | null;
}

export interface IMoveContactOrderPosition {
  contactId: string;
  newPosition: number;
}

export interface IAddDocumentPayload {
  file: File;
}

export interface IApiServiceV1 {
  fetchCountries: () => Promise<ICountry[]>;
  addClient: (payload: IClientPayload) => Promise<IClient>;
  fetchClients: () => Promise<IClients[]>;
  updateClient: (id: string, payload: IClientPayload) => Promise<IUpdateClientResponse>;
  fetchClient: (id: string) => Promise<IClient>;
  addLocation: (clientId: string, payload: ILocationPayload) => Promise<ILocation>;
  fetchLocation: (clientId: string, locationId: string) => Promise<ILocation>;
  fetchClientLocations: (clientId: string) => Promise<IClientLocations>;
  updateLocation: (clientId: string, locationId: string, payload: ILocationPayload) => Promise<ILocation>;
  fetchLocations: () => Promise<ILocation[]>;
  addMove: (locationId: string, payload: IMovePayload) => Promise<IMove>;
  fetchMove: (locationId: string, moveId: string) => Promise<IMove>;
  updateMove: (locationId: string, moveId: string, payload: IMovePayload) => Promise<IMove>;
  uploadMoveLogo: (locationId: string, moveId: string, logo: File | null) => Promise<void>;
  uploadMoveBackground: (locationId: string, moveId: string, background: File | null) => Promise<void>;
  addTemplate: (payload: ITemplatePayload) => Promise<ITemplate>;
  fetchTemplate: (templateId: string) => Promise<ITemplate>;
  updateTemplate: (templateId: string, payload: ITemplatePayload) => Promise<ITemplate>;
  fetchTemplates: () => Promise<ITemplate[]>;
  deleteTemplate: (templateId: string) => Promise<ITemplate[]>;
  updateTemplatePosition: (templateId: string, position: number) => Promise<void>;
  updateAvailableForInternal: (templateId: string, availableForInternal: boolean) => Promise<void>;
  updateAvailableForCustomer: (templateId: string, availableForCustomer: boolean) => Promise<void>;
  addMovePage: (moveId: string, payload: IMovePagePayload) => Promise<IMovePage>;
  fetchMovePages: (moveId: string) => Promise<IMovePage[]>;
  deleteMovePage: (movePageId: string) => Promise<IDeleteResponse>;
  fetchMovePage: (movePageId: string) => Promise<IMovePage>;
  updateMovePage: (movePageId: string, payload: IMovePagePayload) => Promise<IMovePage>;
  updateMovePagePosition: (moveId: string, payload: IMovePagePositionPayload) => Promise<IMovePage[]>;
  fetchMoveFeedbacks: (moveId: string) => Promise<IFeedback[]>;
  addMoveFeedback: (moveId: string, payload: IFeedbackPayload) => Promise<IFeedback>;
  fetchMoveFeedback: (moveFeedbackId: string) => Promise<IFeedback>;
  deleteMoveFeedback: (moveFeedbackId: string) => Promise<string>;
  updateMoveFeedback: (moveFeedbackId: string, payload: IFeedbackStatusPayload) => Promise<IFeedback>;
  sendFeedbackAnswer: (moveFeedbackId: string, payload: IFeedbackStatusPayload) => Promise<IFeedback>;
  fetchFAQs: (moveId: string) => Promise<IFAQ[]>;
  addFAQ: (moveId: string, payload: AddFaqPayload) => Promise<IFAQ>;
  fetchFAQ: (moveFaqQuestionId: string) => Promise<IFAQ>;
  updateFAQ: (moveFaqQuestionId: string, payload: IFAQPayload) => Promise<IFAQ>;
  deleteFAQ: (moveFaqQuestionId: string) => Promise<IFAQ[]>;
  updateFAQposition: (moveId: string, payload: IFAQPositionPayload) => Promise<IFAQ[]>;
  fetchTimelineSections: (moveId: string) => Promise<TimelineSection[]>;
  updateTimelineSection: (timelineSectionId: string, payload: TimelineSection) => Promise<TimelineSection>;
  addTimelineSection: (moveId: string, payload: ITimelineSectionPayload) => Promise<TimelineSection>;
  deleteTimelineSection: (timelineSectionId: string) => Promise<IDeleteResponse>;
  fetchUserDetails: () => Promise<IUser>;
  fetchInternalUsers: () => Promise<IBackendUser[]>;
  fetchExternalUsers: (client?: string) => Promise<IUser[]>;
  fetchUserImage: (size: PhotoSize) => Promise<string>;
  inviteExternalUser: (payload: IExternalUserPayload) => Promise<IUser>;
  fetchUser: (userId: string) => Promise<IUser>;
  updateUser: (userId: string, payload: IUserUpdatePayload) => Promise<IUser>;
  addBrokeringProject: (clientId: string, payload: IBrokeringPayload) => Promise<IBrokering>;
  fetchBrokering: (brokeringId: string) => Promise<IBrokering>;
  updateBrokering: (brokeringId: string, payload: IBrokeringPayload) => Promise<IBrokering>;
  fetchUserPhoto: (userId: string, size: PhotoSize) => Promise<string>;
  fetchBrokeringTimelineSections: (brokeringId: string) => Promise<TimelineSection[]>;
  addBrokeringTimelineSection: (brokeringId: string, payload: ITimelineSectionPayload) => Promise<TimelineSection>;
  updateBrokeringTimelineSection: (brokeringId: string, payload: TimelineSection) => Promise<TimelineSection>;
  deleteBrokeringTimelineSection: (brokeringId: string) => Promise<TimelineSection>;
  getBrokeringDocuments: (brokeringId: string) => Promise<IDocumentsResponse>;
  fetchClientLeases: (clientId: string) => Promise<ILease[]>;
  addClientLease: (clientId: string, payload: ILeasePayload) => Promise<ILease>;
  fetchLease: (leaseId: string) => Promise<ILease>;
  updateLease: (leaseId: string, payload: ILeasePayload) => Promise<ILease>;
  fetchLeaseReminders: (leaseId: string) => Promise<IReminder[]>;
  addLeaseReminder: (leaseId: string, payload: IReminderPayload) => Promise<IReminder>;
  updateLeaseReminder: (reminderId: string, payload: IReminderPayload) => Promise<IReminder>;
  deleteLeaseReminder: (reminderId: string) => Promise<IDeleteResponse>;
  fetchClientLeaseReminders: (clientId: string) => Promise<IReminderLease[]>;
  addMoveContact: (moveId: string, payload: IMoveContactPayload) => Promise<IMoveContact>;
  fetchMoveContacts: (moveId: string) => Promise<IMoveContact[]>;
  updateMoveContact: (moveContactId: string, payload: IMoveContactPayload) => Promise<IMoveContact>;
  deleteMoveContact: (moveContactId: string) => Promise<IDeleteResponse>;
  reorderMoveContacts: (moveId: string, payload: IMoveContactOrderPosition) => Promise<IMoveContact[]>;
  fetchMoveDetails: (moveId: string) => Promise<IMoveDetails[]>;
  updateMoveDetails: (moveId: string, payload: IMoveDetailsPayload) => Promise<IMoveDetails>;
  addMoveDetails: (moveId: string, payload: IMoveDetailsPayload) => Promise<IMoveDetails>;
  deleteMoveDetails: (moveDetailsId: string) => Promise<IDeleteResponse>;
  updateMoveDetailsPosition: (moveId: string, payload: IMoveDetailsPositionPayload) => Promise<IMoveDetails[]>;
  storeDocument: (locationId: string, moveId: string, payload: IAddDocumentPayload) => Promise<void>;
  fetchMoveDocuments: (locationId: string, moveId: string) => Promise<IMoveDocument[]>;
  deleteDocument: (locationId: string, moveId: string, documentId: string) => Promise<void>;
  downloadDocument: (locationId: string, moveId: string, documentId: string) => Promise<Blob>;
  updateDocumentPosition: (locationId: string, moveId: string, documentId: string, position: number) => Promise<void>;
  createColors: (payload: IColorsPayload) => Promise<IColorsPayload>;
  updateColors: (payload: IColorsPayload) => Promise<IColorsPayload>;
  getColors: (moveId: string) => Promise<IColorsPayload>;
  clearColors: (moveId: string) => Promise<string>;
}

function safeUrl(strings: TemplateStringsArray, ...params: string[]) {
  return params
    .map((param, i) => `${strings[i]}${encodeURIComponent(param)}`)
    .concat([strings[strings.length - 1]])
    .join('');
}

class ApiServiceV1 implements IApiServiceV1 {
  private httpService: IHttpService;
  constructor(httpService: IHttpService) {
    this.httpService = httpService;
  }

  async fetchCountries() {
    const result = await this.httpService.instance.get<ICountry[]>('/v1/countries');
    return result.data;
  }

  async addClient(payload: IClientPayload) {
    const result = await this.httpService.instance.post<IClient>('/v1/clients', payload);
    return result.data;
  }

  async fetchClients() {
    const result = await this.httpService.instance.get<IClientsResponse[]>('/v1/clients');
    return result.data.map(convertClientResponse);
  }

  async updateClient(id: string, payload: IClientPayload) {
    const { data } = await this.httpService.instance.put<IUpdateClientResponse>(safeUrl`/v1/clients/${id}`, payload);
    return data;
  }

  async fetchClient(id: string) {
    const { data } = await this.httpService.instance.get<IClient>(safeUrl`/v1/clients/${id}`);
    return data;
  }

  async addLocation(clientId: string, payload: ILocationPayload) {
    const { data } = await this.httpService.instance.post<ILocation>(
      safeUrl`/v1/clients/${clientId}/locations`,
      payload,
    );
    return data;
  }

  async fetchLocation(clientId: string, locationId: string) {
    const { data } = await this.httpService.instance.get<ILocation>(
      safeUrl`/v1/clients/${clientId}/locations/${locationId}`,
    );
    return data;
  }

  async fetchClientLocations(clientId: string) {
    const { data } = await this.httpService.instance.get<IClientLocations>(safeUrl`/v1/clients/${clientId}/locations`);
    return data;
  }

  async updateLocation(clientId: string, locationId: string, payload: ILocationPayload) {
    const { data } = await this.httpService.instance.put<ILocation>(
      safeUrl`/v1/clients/${clientId}/locations/${locationId}`,
      payload,
    );
    return data;
  }

  async fetchLocations() {
    const { data } = await this.httpService.instance.get<ILocation[]>('/v1/locations');
    return data;
  }

  async addMove(locationId: string, payload: IMovePayload) {
    const { data } = await this.httpService.instance.post<IMove>(safeUrl`/v1/locations/${locationId}/moves`, payload);
    return data;
  }

  async fetchMove(locationId: string, moveId: string) {
    const { data } = await this.httpService.instance.get<IMove>(safeUrl`/v1/locations/${locationId}/moves/${moveId}`);
    return data;
  }

  async updateMove(locationId: string, moveId: string, payload: IMovePayload) {
    const { data } = await this.httpService.instance.put<IMove>(
      safeUrl`/v1/locations/${locationId}/moves/${moveId}`,
      payload,
    );

    return data;
  }

  async uploadMoveLogo(locationId: string, moveId: string, logo: File | null) {
    const formData = new FormData();
    if (logo !== null) {
      formData.append('logo', logo);
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    await this.httpService.instance.post<void>(
      safeUrl`/v1/locations/${locationId}/moves/${moveId}/logo`,
      formData,
      config,
    );
  }

  async uploadMoveBackground(locationId: string, moveId: string, background: File | null) {
    const formData = new FormData();
    if (background !== null) {
      formData.append('background', background);
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    await this.httpService.instance.post<void>(
      safeUrl`/v1/locations/${locationId}/moves/${moveId}/background`,
      formData,
      config,
    );
  }

  async addTemplate(payload: ITemplatePayload) {
    const { data } = await this.httpService.instance.post<ITemplate>('/v1/templates', payload);
    return data;
  }

  async fetchTemplate(templateId: string) {
    const { data } = await this.httpService.instance.get<ITemplate>(safeUrl`/v1/templates/${templateId}`);
    return data;
  }

  async updateTemplate(templateId: string, payload: ITemplatePayload) {
    const { data } = await this.httpService.instance.put<ITemplate>(safeUrl`/v1/templates/${templateId}`, payload);
    return data;
  }
  async fetchTemplates() {
    const { data } = await this.httpService.instance.get<ITemplate[]>('/v1/templates');
    return data.sort((a, b) => a.position - b.position);
  }

  async deleteTemplate(tempalteId: string) {
    const { data } = await this.httpService.instance.delete<ITemplate[]>(safeUrl`/v1/templates/${tempalteId}`);
    return data;
  }
  async updateTemplatePosition(templateId: string, position: number): Promise<void> {
    await this.httpService.instance.post<ITemplatePositionPayload>(safeUrl`/v1/templates/${templateId}/position`, {
      position: position,
    });
  }

  async updateAvailableForInternal(templateId: string, availableForInternal: boolean): Promise<void> {
    await this.httpService.instance.post<ITemplateAvailableForInternalPayload>(
      safeUrl`/v1/templates/${templateId}/availableForInternal`,
      {
        availableForInternal: availableForInternal,
      },
    );
  }

  async updateAvailableForCustomer(templateId: string, availableForCustomer: boolean): Promise<void> {
    await this.httpService.instance.post<ITemplateAvailableForCustomerPayload>(
      safeUrl`/v1/templates/${templateId}/availableForCustomer`,
      {
        availableForCustomer: availableForCustomer,
      },
    );
  }

  async addMovePage(moveId: string, payload: IMovePagePayload) {
    const { data } = await this.httpService.instance.post<IMovePage>(safeUrl`/v1/moves/${moveId}/pages`, payload);
    return data;
  }

  async fetchMovePages(moveId: string) {
    const { data } = await this.httpService.instance.get<IMovePage[]>(safeUrl`/v1/moves/${moveId}/pages`);
    return data;
  }

  async deleteMovePage(movePageId: string) {
    const { data } = await this.httpService.instance.delete<IDeleteResponse>(safeUrl`/v1/pages/${movePageId}`);
    return data;
  }

  async fetchMovePage(movePageId: string) {
    const { data } = await this.httpService.instance.get<IMovePage>(safeUrl`/v1/pages/${movePageId}`);
    return data;
  }

  async updateMovePage(movePageId: string, payload: IMovePagePayload) {
    const { data } = await this.httpService.instance.patch<IMovePage>(safeUrl`/v1/pages/${movePageId}`, payload);
    return data;
  }

  async updateMovePagePosition(moveId: string, payload: IMovePagePositionPayload) {
    const { data } = await this.httpService.instance.patch<IMovePage[]>(
      safeUrl`/v1/moves/${moveId}/pages/position`,
      payload,
    );

    return data;
  }

  async fetchMoveFeedbacks(moveId: string) {
    const { data } = await this.httpService.instance.get<IFeedback[]>(safeUrl`/v1/moves/${moveId}/feedback`);
    return data;
  }
  async addMoveFeedback(moveId: string, payload: IFeedbackPayload) {
    const { data } = await this.httpService.instance.post<IFeedback>(safeUrl`/v1/moves/${moveId}/feedback`);
    return data;
  }
  async fetchMoveFeedback(moveFeedbackId: string) {
    const { data } = await this.httpService.instance.get<IFeedback>(safeUrl`/v1/feedback/${moveFeedbackId}/`);
    return data;
  }
  async deleteMoveFeedback(moveFeedbackId: string) {
    const { data } = await this.httpService.instance.delete<string>(safeUrl`/v1/feedback/${moveFeedbackId}/`);
    return data;
  }

  async updateMoveFeedback(moveFeedbackId: string, payload: IFeedbackStatusPayload) {
    const { data } = await this.httpService.instance.patch<IFeedback>(
      safeUrl`/v1/feedback/${moveFeedbackId}/`,
      payload,
    );
    return data;
  }

  async sendFeedbackAnswer(moveFeedbackId: string, payload: IFeedbackStatusPayload) {
    const { data } = await this.httpService.instance.post<IFeedback>(
      safeUrl`/v1/feedback/${moveFeedbackId}/mail`,
      payload,
    );
    return data;
  }

  async createColors(payload: IColorsPayload) {
    const { data } = await this.httpService.instance.post<IColorsPayload>(safeUrl`/v1/colors`, payload);
    return data;
  }
  async updateColors(payload: IColorsPayload) {
    const { data } = await this.httpService.instance.put<IColorsPayload>(safeUrl`/v1/colors`, payload);
    return data;
  }
  async getColors(moveId: string) {
    const { data } = await this.httpService.instance.get<IColorsPayload>(safeUrl`/v1/colors/${moveId}`);
    return data;
  }
  async clearColors(moveId: string) {
    const { data } = await this.httpService.instance.delete<string>(safeUrl`/v1/colors/${moveId}`);
    return data;
  }

  async fetchFAQs(moveId: string) {
    const { data } = await this.httpService.instance.get<IFAQ[]>(safeUrl`/v1/moves/${moveId}/faq_questions`);
    return data;
  }
  async addFAQ(moveId: string, payload: AddFaqPayload) {
    const { data } = await this.httpService.instance.post<IFAQ>(safeUrl`/v1/moves/${moveId}/faq_questions`, payload);
    return data;
  }
  async fetchFAQ(moveFaqQuestionId: string) {
    const { data } = await this.httpService.instance.get<IFAQ>(safeUrl`/v1/faq_questions/${moveFaqQuestionId}`);
    return data;
  }
  async updateFAQ(moveFaqQuestionId: string, payload: IFAQPayload) {
    const { data } = await this.httpService.instance.put<IFAQ>(
      safeUrl`/v1/faq_questions/${moveFaqQuestionId}`,
      payload,
    );
    return data;
  }
  async deleteFAQ(moveFaqQuestionId: string) {
    const { data } = await this.httpService.instance.delete<IFAQ[]>(safeUrl`/v1/faq_questions/${moveFaqQuestionId}`);
    return data;
  }
  async updateFAQposition(moveId: string, payload: IFAQPositionPayload) {
    const { data } = await this.httpService.instance.patch<IFAQ[]>(
      safeUrl`v1/moves/${moveId}/faq_questions/position`,
      payload,
    );
    return data;
  }

  async fetchTimelineSections(moveId: string) {
    const { data } = await this.httpService.instance.get<TimelineSectionRequest[]>(
      safeUrl`/v1/moves/${moveId}/timeline_sections`,
    );
    return data.map(convertTimelineSectionsResponse);
  }

  async updateTimelineSection(timelineSectionId: string, payload: TimelineSection) {
    const convertedPayload = convertTimelineSectionToRequestPayload(payload);

    const { data } = await this.httpService.instance.patch<TimelineSectionRequest>(
      safeUrl`/v1/timeline_sections/${timelineSectionId}`,
      convertedPayload,
    );
    return convertTimelineSectionsResponse(data);
  }

  async addTimelineSection(moveId: string, payload: ITimelineSectionPayload) {
    const convertedPayload = convertTimelineSectionToRequestPayload(payload);
    const { data } = await this.httpService.instance.post<TimelineSectionRequest>(
      safeUrl`/v1/moves/${moveId}/timeline_sections`,
      convertedPayload,
    );
    return convertTimelineSectionsResponse(data);
  }

  async deleteTimelineSection(timelineSectionId: string) {
    const { data } = await this.httpService.instance.delete(safeUrl`/v1/timeline_sections/${timelineSectionId}`);
    return data;
  }

  async fetchUserDetails() {
    const { data } = await this.httpService.instance.get<IUser>(safeUrl`/v1/users/me`);
    return data;
  }

  async fetchInternalUsers() {
    const { data } = await this.httpService.instance.get<IBackendUser[]>(safeUrl`/v1/users`);
    return data;
  }

  async fetchExternalUsers(client?: string) {
    const { data } = await this.httpService.instance.get<IUser[]>(safeUrl`/v1/users/external`, {
      params: {
        client,
      },
    });
    return data;
  }

  async fetchUserImage(size: PhotoSize) {
    const { data } = await this.httpService.instance.get(safeUrl`/v1/users/me/photo/${size}`);
    return data;
  }

  async inviteExternalUser(payload: IExternalUserPayload) {
    const { data } = await this.httpService.instance.post<IUser>('/v1/users/invitation', payload);
    return data;
  }

  async fetchUser(userId: string) {
    const { data } = await this.httpService.instance.get<IUser>(safeUrl`/v1/users/${userId}`);
    return data;
  }

  async updateUser(userId: string, payload: IUserUpdatePayload) {
    const { data } = await this.httpService.instance.patch<IUser>(safeUrl`/v1/users/${userId}`, payload);
    return data;
  }

  async addBrokeringProject(clientId: string, payload: IBrokeringPayload) {
    const { data } = await this.httpService.instance.post<IBrokering>(
      safeUrl`/v1/clients/${clientId}/brokerings`,
      payload,
    );
    return data;
  }

  async fetchBrokering(brokeringId: string) {
    const { data } = await this.httpService.instance.get<IBrokering>(safeUrl`/v1/brokerings/${brokeringId}`);
    return data;
  }

  async updateBrokering(brokeringId: string, payload: IBrokeringPayload) {
    const { data } = await this.httpService.instance.put<IBrokering>(safeUrl`/v1/brokerings/${brokeringId}`, payload);
    return data;
  }

  async fetchUserPhoto(userId: string, size: PhotoSize) {
    const { data } = await this.httpService.instance.get(safeUrl`/v1/users/${userId}/photo/${size}`);
    return data;
  }

  async fetchBrokeringTimelineSections(brokeringId: string) {
    const { data } = await this.httpService.instance.get<TimelineSectionRequest[]>(
      safeUrl`/v1/brokerings/${brokeringId}/timeline_sections`,
    );
    return data.map(convertTimelineSectionsResponse);
  }

  async addBrokeringTimelineSection(brokeringId: string, payload: ITimelineSectionPayload) {
    const { data } = await this.httpService.instance.post<TimelineSectionRequest>(
      `/v1/brokerings/${brokeringId}/timeline_sections`,
      payload,
    );
    return convertTimelineSectionsResponse(data);
  }

  async updateBrokeringTimelineSection(timelineSectionId: string, payload: TimelineSection) {
    const convertedPayload = convertTimelineSectionToRequestPayload(payload);

    const { data } = await this.httpService.instance.patch<TimelineSectionRequest>(
      safeUrl`/v1/brokering_timeline_sections/${timelineSectionId}`,
      convertedPayload,
    );
    return convertTimelineSectionsResponse(data);
  }

  async deleteBrokeringTimelineSection(timelineSectionId: string) {
    const { data } = await this.httpService.instance.delete(
      safeUrl`/v1/brokering_timeline_sections/${timelineSectionId}`,
    );
    return data;
  }

  async getBrokeringDocuments(brokeringId: string) {
    const { data } = await this.httpService.documentInstance.get<ILibrary[]>(
      safeUrl`/v1/brokerings/${brokeringId}/documents`,
    );
    return convertLibraryResponse(data);
  }

  async fetchClientLeases(clientId: string) {
    const { data } = await this.httpService.instance.get<ILeaseResponse[]>(safeUrl`/v1/clients/${clientId}/leases`);
    const result = data.map((leaseResponse) => {
      return convertResponseDatesToDayjs<ILeaseResponse, ILease>(leaseResponse, leaseResponseDateKeys);
    });

    return result;
  }

  async addClientLease(clientId: string, payload: ILeasePayload) {
    const { data } = await this.httpService.instance.post<ILeaseResponse>(
      safeUrl`/v1/clients/${clientId}/leases`,
      payload,
    );
    return convertResponseDatesToDayjs<ILeaseResponse, ILease>(data, leaseResponseDateKeys);
  }

  async fetchLease(leaseId: string) {
    const { data } = await this.httpService.instance.get<ILeaseResponse>(safeUrl`/v1/leases/${leaseId}`);
    return convertResponseDatesToDayjs<ILeaseResponse, ILease>(data, leaseResponseDateKeys);
  }

  async updateLease(leaseId: string, payload: ILeasePayload) {
    const { data } = await this.httpService.instance.put<ILeaseResponse>(safeUrl`/v1/leases/${leaseId}`, payload);
    return convertResponseDatesToDayjs<ILeaseResponse, ILease>(data, leaseResponseDateKeys);
  }

  async fetchLeaseReminders(leaseId: string) {
    const { data } = await this.httpService.instance.get<IReminderResponse[]>(safeUrl`/v1/leases/${leaseId}/reminders`);
    return data.map((reminderResponse) =>
      convertResponseDatesToDayjs<IReminderResponse, IReminder>(reminderResponse, ['dueDate', 'sentAt']),
    );
  }

  async addLeaseReminder(leaseId: string, payload: IReminderPayload) {
    const { data } = await this.httpService.instance.post<IReminderResponse>(
      safeUrl`/v1/leases/${leaseId}/reminders`,
      payload,
    );

    return convertResponseDatesToDayjs<IReminderResponse, IReminder>(data, ['dueDate']);
  }

  async updateLeaseReminder(reminderId: string, payload: IReminderPayload) {
    const { data } = await this.httpService.instance.put<IReminderResponse>(
      safeUrl`/v1/reminders/${reminderId}`,
      payload,
    );

    return convertResponseDatesToDayjs<IReminderResponse, IReminder>(data, ['dueDate']);
  }

  async deleteLeaseReminder(reminderId: string) {
    const { data } = await this.httpService.instance.delete<IDeleteResponse>(safeUrl`/v1/reminders/${reminderId}`);
    return data;
  }

  async fetchClientLeaseReminders(clientId: string) {
    const { data } = await this.httpService.instance.get<IReminderLeaseResponse[]>(
      safeUrl`/v1/clients/${clientId}/reminders`,
    );
    return data.map<IReminderLease>((reminderLeaseResponse) => {
      const reminder = convertResponseDatesToDayjs<IReminderLeaseResponse, IReminder>(reminderLeaseResponse, [
        'dueDate',
      ]);
      const lease = convertResponseDatesToDayjs<ILeaseResponse, ILease>(
        reminderLeaseResponse.lease,
        leaseResponseDateKeys,
      );

      return {
        ...reminder,
        lease,
      };
    });
  }

  async addMoveContact(moveId: string, payload: IMoveContactPayload) {
    const { data } = await this.httpService.instance.post<IMoveContact>(safeUrl`/v1/moves/${moveId}/contacts`, payload);

    return data;
  }

  async fetchMoveContacts(moveId: string) {
    const { data } = await this.httpService.instance.get<IMoveContact[]>(safeUrl`/v1/moves/${moveId}/contacts`);

    return data;
  }

  async updateMoveContact(moveContactId: string, payload: IMoveContactPayload) {
    const { data } = await this.httpService.instance.put<IMoveContact>(
      safeUrl`/v1/move_contacts/${moveContactId}`,
      payload,
    );

    return data;
  }

  async deleteMoveContact(moveContactId: string) {
    const { data } = await this.httpService.instance.delete<IDeleteResponse>(
      safeUrl`/v1/move_contacts/${moveContactId}`,
    );

    return data;
  }

  async reorderMoveContacts(moveId: string, payload: IMoveContactOrderPosition) {
    const { data } = await this.httpService.instance.patch<IMoveContact[]>(
      safeUrl`/v1/moves/${moveId}/contacts/position`,
      payload,
    );

    return data;
  }

  async fetchMoveDetails(moveId: string) {
    const { data } = await this.httpService.instance.get<IMoveDetails[]>(safeUrl`/v1/moves/${moveId}/details`);

    return data;
  }

  async updateMoveDetails(moveId: string, payload: IMoveDetailsPayload) {
    const { data } = await this.httpService.instance.put<IMoveDetails>(safeUrl`/v1/details/${moveId}`, payload);

    return data;
  }

  async addMoveDetails(moveId: string, payload: IMoveDetailsPayload) {
    const { data } = await this.httpService.instance.post<IMoveDetails>(safeUrl`/v1/moves/${moveId}/details`, payload);

    return data;
  }

  async deleteMoveDetails(moveDetailsId: string) {
    const { data } = await this.httpService.instance.delete<IDeleteResponse>(safeUrl`/v1/details/${moveDetailsId}`);

    return data;
  }

  async updateMoveDetailsPosition(moveId: string, payload: IMoveDetailsPositionPayload) {
    const { data } = await this.httpService.instance.patch<IMoveDetails[]>(
      safeUrl`/v1/moves/${moveId}/details/position`,
      payload,
    );

    return data;
  }

  async storeDocument(locationId: string, moveId: string, payload: IAddDocumentPayload): Promise<void> {
    const formData = new FormData();
    formData.append('document', payload.file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    await this.httpService.instance.post<void>(
      safeUrl`/v1/locations/${locationId}/moves/${moveId}/document`,
      formData,
      config,
    );
  }

  async fetchMoveDocuments(locationId: string, moveId: string): Promise<IMoveDocument[]> {
    const { data } = await this.httpService.instance.get<IMoveDocument[]>(
      safeUrl`/v1/locations/${locationId}/moves/${moveId}/documents`,
    );

    return data;
  }

  async deleteDocument(locationId: string, moveId: string, documentId: string): Promise<void> {
    await this.httpService.instance.delete<void>(
      safeUrl`/v1/locations/${locationId}/moves/${moveId}/documents/${documentId}`,
    );
  }

  async downloadDocument(locationId: string, moveId: string, documentId: string): Promise<Blob> {
    const result = await this.httpService.instance.get<any>(
      safeUrl`/v1/locations/${locationId}/moves/${moveId}/documents/${documentId}`,
      {
        responseType: 'arraybuffer' as ResponseType,
      },
    );
    return new Blob([result.data]);
  }

  async updateDocumentPosition(
    locationId: string,
    moveId: string,
    documentId: string,
    position: number,
  ): Promise<void> {
    await this.httpService.instance.post<IMoveContact>(
      safeUrl`/v1/locations/${locationId}/moves/${moveId}/documents/${documentId}/position`,
      {
        position: position,
      },
    );
  }
}

const leaseResponseDateKeys: Array<keyof ILeaseResponse> = [
  'leaseStart',
  'leaseEnd',
  'leaseRenewalDeadline',
  'increasedFloorSpaceDeadline',
  'consumerPriceIndexDate',
];

function convertClientResponse(clientResponse: IClientsResponse): IClients {
  return {
    id: clientResponse.id,
    name: clientResponse.name,
    orgNumber: clientResponse.orgNumber,
    country: clientResponse.country,
    active: clientResponse.active,
    countOfLocations: clientResponse.data.countOfLocations,
    countOfActiveLocations: clientResponse.data.countOfActiveLocations,
  };
}

function convertTimelineSectionToRequestPayload(
  timelineSection: TimelineSection | ITimelineSectionPayload,
): Omit<TimelineSectionRequest, 'id'> {
  return {
    title: timelineSection.title,
    date: timelineSection.date.toISOString(),
    events: timelineSection.events.map(convertTimelineEventToRequestPayload),
  };
}

function convertTimelineEventToRequestPayload(
  timelineEvent: TimelineEvent | TimelineEventPayload,
): TimelineEventRequest {
  const timelineEventPayload: TimelineEventRequest = {
    title: timelineEvent.title,
    startDate: timelineEvent.startDate.toISOString(),
    endDate: timelineEvent.endDate ? timelineEvent.endDate.toISOString() : null,
    description: timelineEvent.description,
  };

  if (timelineEvent.id) {
    timelineEventPayload.id = timelineEvent.id;
  }

  return timelineEventPayload;
}

function convertResponseDatesToDayjs<T, R>(rawResponse: T, keys: Array<keyof T>): R {
  keys
    .filter((key) => !isNil(rawResponse[key]))
    .forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      rawResponse[key] = dayjs((rawResponse[key] as unknown) as string) as any;
    });
  return (rawResponse as unknown) as R;
}

export function createApiServiceV1(httpService: IHttpService) {
  return new ApiServiceV1(httpService);
}
