import React from 'react';
import Grid, { GridSize } from '@material-ui/core/Grid';

export interface IDividedContentContainerProps {
  md?: GridSize;
  lg?: GridSize;
}

const DividedContentContainer: React.FunctionComponent<IDividedContentContainerProps> = ({
  children,
  md = 6,
  lg = 4,
}) => {
  return (
    <Grid container item sm={12} md={md} lg={lg} direction="column" spacing={2}>
      {children}
    </Grid>
  );
};

export { DividedContentContainer };
