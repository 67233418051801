import React, { useState, useCallback } from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/core/styles';
import { SectionTimelineForm } from './SectionTimelineForm';
import { AddNewTimelineItem } from './AddNewTimelineItem';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import { EventTimelineForm } from './EventTimelineForm';
import { ITimelineSectionPayload, TimelineSectionType } from '../../services/ApiServiceV1';
import { useReducer } from 'react';
import { editTimelineSectionsReducer } from './editTimelineSectionsReducer';
import { editTimelineEventsReducer } from './editTimelineEventsReducer';
import { newTimelineEventIndexesReducer } from './newTimelineEventIndexesReducer';
import { dayMonthFormat, yearFormat } from '@fmg-packages/common-components';
import { TimelineSection, TimelineEvent } from '@fmg-packages/common-components';
import { useContext } from 'react';
import { ServiceContext } from '../../context/ServicesContext';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { LinearProgress } from '@material-ui/core';
import { NotificationContext } from '../../context/NotificationContext';
import { TranslationContext } from '../../context/TranslationContext';
import { observer } from 'mobx-react';
import { ModalContext } from '../../context/ModalContext';
import { handleTimelineNotification } from './utils';

const useStyles = makeStyles((theme) => ({
  addNewSectionButton: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  white: {
    color: theme.palette.common.white,
  },
  sectionTimelineConnector: {
    paddingTop: theme.spacing(2),
  },
  sectionContent: {
    paddingTop: theme.spacing(2.5),
  },
}));

interface ITimelineFormProps {
  timelineResourceId: string;
  timelineType: TimelineSectionType;
}

const TimelineForm: React.FunctionComponent<ITimelineFormProps> = observer(({ timelineResourceId, timelineType }) => {
  const { addNewSectionButton, white, sectionTimelineConnector, sectionContent } = useStyles();
  const { notificationDispatch } = useContext(NotificationContext);
  const { setIsModalOpen } = useContext(ModalContext);
  const { translate } = useContext(TranslationContext);
  const {
    stores,
    rootStore: { moveStore },
  } = useContext(ServiceContext);
  const timelineSectionsStore =
    timelineType === 'brokering' ? stores.brokeringStore.timelineSections : moveStore.timelineSections;
  useAsyncOperation(() => {
    return timelineSectionsStore.fetch(timelineResourceId, timelineType);
  }, [timelineResourceId, timelineType]);

  const [isAddingNewSection, setIsAddingNewSection] = useState(false);
  const [editTimelineSections, editTimelineSectionsDispatch] = useReducer(editTimelineSectionsReducer, []);
  const [editTimelineEvents, editTimelineEventsDispatch] = useReducer(editTimelineEventsReducer, []);
  const [newTimelineEventIndexes, newTimelineEventIndexesDispatch] = useReducer(newTimelineEventIndexesReducer, []);

  function onSectionClick(timelineSection: TimelineSection) {
    const isSectionInEditMode = editTimelineSections.find(
      (editTimelineSection) => editTimelineSection.id === timelineSection.id,
    );
    isSectionInEditMode
      ? editTimelineSectionsDispatch({ type: 'REMOVE_EDIT_SECTION', payload: timelineSection })
      : editTimelineSectionsDispatch({ type: 'ADD_EDIT_SECTION', payload: timelineSection });
  }

  function onTimelineEventClick(timelineEvent: TimelineEvent) {
    const isTimelineEventInEditMode = editTimelineEvents.find(
      (editTimelineEvent) => editTimelineEvent.id === timelineEvent.id,
    );
    isTimelineEventInEditMode
      ? editTimelineEventsDispatch({ type: 'REMOVE_EDIT_TIMELINE_EVENT', payload: timelineEvent })
      : editTimelineEventsDispatch({ type: 'ADD_EDIT_TIMELINE_EVENT', payload: timelineEvent });
  }

  const onSectionSave =
    // = useCallback(
    async (values: TimelineSection | ITimelineSectionPayload) => {
      await timelineSectionsStore.updateTimelineSection(values as TimelineSection, timelineType);
      handleTimelineNotification(
        notificationDispatch,
        timelineSectionsStore,
        translate('successfully saved section'),
        translate('failed to update section'),
      );

      editTimelineSectionsDispatch({ type: 'REMOVE_EDIT_SECTION', payload: values as TimelineSection });
    };
  //,
  //   [notificationDispatch, timelineSectionsStore, timelineType, translate],
  // );

  const onTimelineEventSave =
    // = useCallback(
    async (timelineEvent: TimelineEvent, timelineSection: TimelineSection) => {
      const timelineSectionToUpdate: TimelineSection = {
        ...timelineSection,
        events: timelineSection.events.map((event) => {
          if (timelineEvent.id === event.id) {
            return timelineEvent;
          }
          return event;
        }),
      };
      await timelineSectionsStore.updateTimelineSection(timelineSectionToUpdate, timelineType);
      handleTimelineNotification(
        notificationDispatch,
        timelineSectionsStore,
        translate('successfully saved section'),
        translate('failed to update section'),
      );

      editTimelineEventsDispatch({ type: 'REMOVE_EDIT_TIMELINE_EVENT', payload: timelineEvent });
    };
  //   ,
  //   [notificationDispatch, timelineSectionsStore, timelineType, translate],
  // );

  const onNewEventSave = useCallback(
    async (timelineEvent: TimelineEvent, timelineSection: TimelineSection, sectionIndex: number) => {
      const timelineSectionToUpdate = {
        ...timelineSection,
        events: [...timelineSection.events, timelineEvent],
      };
      await timelineSectionsStore.updateTimelineSection(timelineSectionToUpdate, timelineType);
      handleTimelineNotification(
        notificationDispatch,
        timelineSectionsStore,
        translate('successfully saved section'),
        translate('failed to update section'),
      );
      newTimelineEventIndexesDispatch({ type: 'REMOVE_NEW_TIMELINE_EVENT_INDEX', payload: sectionIndex });
    },
    [notificationDispatch, timelineSectionsStore, timelineType, translate],
  );

  const onNewSectionSave = useCallback(
    async (values: ITimelineSectionPayload) => {
      await timelineSectionsStore.addTimelineSection(timelineResourceId, values, timelineType);
      handleTimelineNotification(
        notificationDispatch,
        timelineSectionsStore,
        translate('successfully saved section'),
        translate('failed to update section'),
      );
      setIsAddingNewSection(false);
    },
    [notificationDispatch, timelineResourceId, timelineSectionsStore, timelineType, translate],
  );

  const onEventTimelineDelete =
    // useCallback(
    async (timelineEvent: TimelineEvent, timelineSection: TimelineSection) => {
      const timelineSectionToUpdate: TimelineSection = {
        ...timelineSection,
        events: timelineSection.events.filter((timelineSectionEvent) => timelineSectionEvent.id !== timelineEvent.id),
      };

      await timelineSectionsStore.updateTimelineSection(timelineSectionToUpdate, timelineType);
      setIsModalOpen(false);
      handleTimelineNotification(
        notificationDispatch,
        timelineSectionsStore,
        translate('successfully saved section'),
        translate('failed to update section'),
      );
    };
  // ,
  //   [notificationDispatch, setIsModalOpen, timelineSectionsStore, timelineType, translate],
  // );

  return (
    <RemoteSuspense data={timelineSectionsStore.remoteTimelineSections} loadingFallback={<LinearProgress />}>
      {(timelineSections) => {
        return (
          <Timeline align="left" data-testid="timeline-root">
            {timelineSections.map((timelineSection, sectionIndex) => {
              const isSectionInEditMode = editTimelineSections.find(
                (editTimelineSection) => editTimelineSection.id === timelineSection.id,
              );
              const isTimelineEventInEditMode = newTimelineEventIndexes.find(
                (timelineEventIndex) => timelineEventIndex === sectionIndex,
              );
              const isLastSection = sectionIndex + 1 === timelineSections.length;

              return (
                <div key={sectionIndex}>
                  {isSectionInEditMode ? (
                    <SectionTimelineForm
                      timelineSection={timelineSection}
                      onSectionClick={(timelineSectionData) =>
                        onSectionClick({
                          ...timelineSectionData,
                          id: timelineSection.id,
                        })
                      }
                      onSave={onSectionSave}
                      shouldConnect={timelineSection.events.length > 0 || isLastSection}
                      store={timelineSectionsStore}
                      type={timelineType}
                    />
                  ) : (
                    <TimelineItem>
                      <TimelineOppositeContent className={sectionContent}>
                        <Typography>{timelineSection.date.format(dayMonthFormat)}</Typography>
                        <Typography color="textSecondary" variant="button">
                          {timelineSection.date.format(yearFormat)}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="secondary">
                          <IconButton
                            size="medium"
                            onClick={() => {
                              onSectionClick(timelineSection);
                            }}
                          >
                            <CreateIcon className={white} />
                          </IconButton>
                        </TimelineDot>
                        <TimelineConnector className={sectionTimelineConnector} />
                      </TimelineSeparator>
                      <TimelineContent className={sectionContent}>
                        <Typography variant="h5">{timelineSection.title}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  )}
                  {timelineSection.events.length > 0 &&
                    timelineSection.events.map((timelineEvent, timelineItemIndex) => {
                      const isTimelineItemEntryInEditMode = editTimelineEvents.find(
                        (editTimelineEvent) => editTimelineEvent.id === timelineEvent.id,
                      );
                      return (
                        <div key={timelineItemIndex}>
                          {isTimelineItemEntryInEditMode ? (
                            <EventTimelineForm
                              timelineEvent={timelineEvent}
                              shouldConnect={true}
                              onDotIconClick={() => {
                                onTimelineEventClick(timelineEvent);
                              }}
                              onSubmit={async (values) => {
                                const updatedTimelineEvent: TimelineEvent = {
                                  id: timelineEvent.id,
                                  ...values,
                                };
                                onTimelineEventSave(updatedTimelineEvent, timelineSection);
                              }}
                              onDelete={async () => {
                                onEventTimelineDelete(timelineEvent, timelineSection);
                              }}
                            />
                          ) : (
                            <TimelineItem key={timelineItemIndex}>
                              <TimelineOppositeContent>
                                <>
                                  <Typography>{`${timelineEvent.startDate.format(dayMonthFormat)} ${
                                    timelineEvent.endDate ? `- ${timelineEvent.endDate.format(dayMonthFormat)}` : ''
                                  }`}</Typography>
                                  <Typography variant="button" color="textSecondary">
                                    {timelineEvent.startDate.format(yearFormat)}{' '}
                                    {timelineEvent.endDate &&
                                    timelineEvent.startDate.format(yearFormat) !==
                                      timelineEvent.endDate.format(yearFormat)
                                      ? `- ${timelineEvent.endDate.format(yearFormat)}`
                                      : ''}
                                  </Typography>
                                </>
                              </TimelineOppositeContent>
                              <TimelineSeparator>
                                <TimelineDot>
                                  <IconButton onClick={() => onTimelineEventClick(timelineEvent)} size="small">
                                    <CreateIcon className={white} />
                                  </IconButton>
                                </TimelineDot>
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent>
                                <Typography variant="h6">{timelineEvent.title}</Typography>
                                <Typography variant="body1">{timelineEvent.description}</Typography>
                              </TimelineContent>
                            </TimelineItem>
                          )}
                        </div>
                      );
                    })}
                  {isTimelineEventInEditMode !== undefined ? (
                    <EventTimelineForm
                      shouldConnect={true}
                      onDotIconClick={() => {
                        newTimelineEventIndexesDispatch({
                          type: 'REMOVE_NEW_TIMELINE_EVENT_INDEX',
                          payload: sectionIndex,
                        });
                      }}
                      onSubmit={async (values) => {
                        onNewEventSave(values as TimelineEvent, timelineSection, sectionIndex);
                      }}
                    />
                  ) : (
                    <AddNewTimelineItem shouldConnect={true}>
                      <TimelineDot>
                        <IconButton
                          size="small"
                          onClick={() => {
                            newTimelineEventIndexesDispatch({
                              type: 'ADD_NEW_TIMELINE_EVENT_INDEX',
                              payload: sectionIndex,
                            });
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </TimelineDot>
                    </AddNewTimelineItem>
                  )}
                </div>
              );
            })}
            {isAddingNewSection ? (
              <SectionTimelineForm
                onSectionClick={() => {
                  setIsAddingNewSection(false);
                }}
                onSave={async (values) => {
                  onNewSectionSave(values);
                }}
                shouldConnect={false}
                store={timelineSectionsStore}
                type={timelineType}
              />
            ) : (
              <AddNewTimelineItem shouldConnect={false}>
                <TimelineDot color="secondary">
                  <IconButton
                    className={addNewSectionButton}
                    onClick={() => {
                      setIsAddingNewSection(true);
                    }}
                  >
                    <AddIcon className={white} />
                  </IconButton>
                </TimelineDot>
              </AddNewTimelineItem>
            )}
          </Timeline>
        );
      }}
    </RemoteSuspense>
  );
});

export { TimelineForm };
