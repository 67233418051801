import React from 'react';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import { makeStyles } from '@material-ui/core/styles';

interface IAddNewTimelineItemProps {
  shouldConnect: boolean;
}

const useStyles = makeStyles((theme) => ({
  connector: {
    paddingBottom: theme.spacing(5),
  },
}));

const AddNewTimelineItem: React.FunctionComponent<IAddNewTimelineItemProps> = ({ shouldConnect, children }) => {
  const { connector } = useStyles();
  return (
    <TimelineItem>
      <TimelineSeparator>
        {children}
        {shouldConnect && <TimelineConnector className={connector} />}
      </TimelineSeparator>
      <TimelineContent />
    </TimelineItem>
  );
};

export { AddNewTimelineItem };
