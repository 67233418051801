import React, { useContext } from 'react';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CreateIcon from '@material-ui/icons/Create';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DayjsUtils from '@date-io/dayjs';
import { Form, Formik } from 'formik';
import { FormikFormInput } from '../FormikInputs';
import { CustomButton } from '../CustomButton';
import { TranslationContext } from '../../context/TranslationContext';
import { FormikDatePicker } from '../FormikInputs/FormikDatePicker';
import { IconButton } from '@material-ui/core';
import { ITimelineSectionPayload, TimelineSectionType } from '../../services/ApiServiceV1';
import { DayjsContext } from '../../context/DayjsContext';
import { dateFormat } from '@fmg-packages/common-components';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { TimelineSection } from '@fmg-packages/common-components';
import { ModalContext } from '../../context/ModalContext';
import { observer } from 'mobx-react';
import { ConfirmDialog } from '../ConfirmDialog';
import { NotificationContext } from '../../context/NotificationContext';
import { handleTimelineNotification } from './utils';
import { TimelineSectionStore } from '../../stores/TimelineSectionStore';

interface ISectionTimelineFormProps {
  timelineSection?: TimelineSection;
  onSectionClick: (timelineSection: ITimelineSectionPayload) => void;
  onSave: (values: ITimelineSectionPayload) => Promise<void>;
  shouldConnect: boolean;
  store: TimelineSectionStore;
  type: TimelineSectionType;
}

const useStyles = makeStyles((theme) => ({
  timelineDot: {
    borderStyle: 'dotted',
    borderColor: theme.palette.common.white,
  },
  createIcon: {
    color: theme.palette.common.white,
  },
}));

const SectionTimelineForm: React.FunctionComponent<ISectionTimelineFormProps> = observer(
  ({ timelineSection, onSectionClick, onSave, shouldConnect, store, type }) => {
    const { setModalState, setIsModalOpen } = useContext(ModalContext);
    const { translate } = useContext(TranslationContext);
    const { notificationDispatch } = useContext(NotificationContext);
    const { timelineDot, createIcon } = useStyles();
    const { dayjsLocale } = useContext(DayjsContext);
    const initialValues: ITimelineSectionPayload = {
      title: timelineSection ? timelineSection.title : '',
      date: timelineSection ? timelineSection.date : dayjs(),
      events: timelineSection ? timelineSection.events : [],
    };

    if (timelineSection) {
      initialValues.id = timelineSection.id;
    }

    async function onSectionDelete(timelineSection: TimelineSection) {
      await store.deleteTimelineSection(timelineSection.id, type);
      setIsModalOpen(false);
      handleTimelineNotification(
        notificationDispatch,
        store,
        translate('successfully deleted'),
        translate('failed to delete'),
      );
    }

    return (
      <Formik
        onSubmit={onSave}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={yup.object({
          title: yup.string().required(translate('field is required')),
          date: yup.string().required(translate('field is required')),
        })}
      >
        {({ values, initialValues }) => (
          <Form name="section-timeline-form">
            <TimelineItem>
              <TimelineOppositeContent>
                <MuiPickersUtilsProvider utils={DayjsUtils} locale={dayjsLocale}>
                  <FormikDatePicker
                    formikKey="date"
                    labelContent={translate('date')}
                    showLabel={false}
                    format={dateFormat}
                    inputVariant="outlined"
                    hiddenLabel={true}
                    placeholder={translate('date')}
                    invalidLabel={translate('invalid date')}
                  />
                </MuiPickersUtilsProvider>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="secondary" className={timelineDot}>
                  <IconButton
                    onClick={() => {
                      onSectionClick(values);
                    }}
                  >
                    <CreateIcon className={createIcon} />
                  </IconButton>
                </TimelineDot>

                {shouldConnect && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <FormikFormInput
                  formikKey="title"
                  labelContent={translate('title')}
                  showLabel={false}
                  placeholder={translate('title')}
                />
                <Box pt={1}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <CustomButton type="submit" variant="contained" color="secondary">
                        {translate('save')}
                      </CustomButton>
                    </Grid>
                    {timelineSection && (
                      <Grid item>
                        <CustomButton
                          variant="contained"
                          color="default"
                          onClick={() => {
                            setModalState(
                              true,
                              <ConfirmDialog
                                data={timelineSection.id}
                                handleClick={() => onSectionDelete(timelineSection)}
                                dialogTitle={translate('are you sure you want to delete ')}
                                recordTitle={timelineSection.title}
                                content={translate("This item will be deleted immediately. You can't undo this action")}
                                primaryButtonLabel={translate('delete')}
                                cancelLabel={translate('cancel')}
                              />,
                            );
                          }}
                        >
                          {translate('delete')}
                        </CustomButton>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </TimelineContent>
            </TimelineItem>
          </Form>
        )}
      </Formik>
    );
  },
);

export { SectionTimelineForm };
