import React, { useEffect, useContext } from 'react';
import { ServiceContext } from '../../context/ServicesContext';
import { RemoteSuspense } from 'ts-remote-data-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { observer } from 'mobx-react';
import { Header } from '../../components/typography/Header';
import { TranslationContext } from '../../context/TranslationContext';
import { StyledTableCell } from '../../components/StyledCell';
import { PaginationTableContents } from '../../components/PaginationTableContents';
import { LocationTableRow } from './LocationTableRow';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useCallback } from 'react';
import { SortedCellContent } from '../../components/SortedCellContent';
import { ILocation } from '../../services/ApiServiceV1';
import GenericSelect from '../../components/GenericSelect';
import GenericSearch from '../../components/GenericSearch';
import RemoteData from 'ts-remote-data';
import { getLocationPropertyTypes } from '../../utils/locationPropertyTypes';

const useStyles = makeStyles((theme: Theme) => ({
  tableContainerRoot: {
    padding: theme.spacing(4),
  },
}));

const ListLocations: React.FunctionComponent = observer(() => {
  const { tableContainerRoot } = useStyles();
  const {
    stores: { locationsStore, countriesStore },
  } = useContext(ServiceContext);
  useEffect(() => {
    async function fetchLocations() {
      locationsStore.fetch(true);
      countriesStore.fetch();
    }

    fetchLocations();
    locationsStore.setSort('asc', ['client.name', (c: ILocation) => c.client.name]);
  }, [countriesStore, locationsStore]);
  const { translate } = useContext(TranslationContext);

  const onCountryChange = useCallback(
    (selectedValue: { value: string } | undefined) => {
      locationsStore.setCountryId(selectedValue?.value);
    },
    [locationsStore],
  );

  const onActiveFilterChange = useCallback(
    (selectedValue: { value: string } | undefined) => {
      locationsStore.setActiveFilter(selectedValue?.value);
    },
    [locationsStore],
  );

  const onClientNameFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      locationsStore.setCompanyNameFilter(e.target.value);
    },
    [locationsStore],
  );

  const nameArrowUpClick = useCallback(() => {
    locationsStore.setSort('asc', ['name', (l: ILocation) => l.name]);
  }, [locationsStore]);

  const nameArrowDownClick = useCallback(() => {
    locationsStore.setSort('desc', ['name', (l: ILocation) => l.name]);
  }, [locationsStore]);

  const clientArrowUpClick = useCallback(() => {
    locationsStore.setSort('asc', ['client.name', (c: ILocation) => c.client.name]);
  }, [locationsStore]);

  const clientArrowDownClick = useCallback(() => {
    locationsStore.setSort('desc', ['client.name', (c: ILocation) => c.client.name]);
  }, [locationsStore]);

  const countryArrowUpClick = useCallback(() => {
    locationsStore.setSort('asc', ['country.name', (l: ILocation) => l.country.name]);
  }, [locationsStore]);

  const countryArrowDownClick = useCallback(() => {
    locationsStore.setSort('desc', ['country.name', (l: ILocation) => l.country.name]);
  }, [locationsStore]);

  const addressArrowUpClick = useCallback(() => {
    locationsStore.setSort('asc', ['address', (l: ILocation) => l.address]);
  }, [locationsStore]);

  const addressArrowDownClick = useCallback(() => {
    locationsStore.setSort('desc', ['address', (l: ILocation) => l.address]);
  }, [locationsStore]);

  const propertyTypesArrowUpClick = useCallback(() => {
    locationsStore.setSort('asc', ['propertyTypes', (l: ILocation) => getLocationPropertyTypes(l, translate)]);
  }, [locationsStore]);

  const propertyTypesArrowDownClick = useCallback(() => {
    locationsStore.setSort('desc', ['propertyTypes', (l: ILocation) => getLocationPropertyTypes(l, translate)]);
  }, [locationsStore]);

  return (
    <>
      <Header>{translate('locations')}</Header>

      <RemoteSuspense
        data={RemoteData.all(countriesStore.remoteCountries, locationsStore.filteredSortedClients)}
        loadingFallback={<LinearProgress />}
      >
        {([countries, locations]) => (
          <>
            <TableContainer component={Paper} classes={{ root: tableContainerRoot }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ width: 250 }}>
                      <SortedCellContent
                        onUpClickHandler={nameArrowUpClick}
                        onDownClickHandler={nameArrowDownClick}
                        selectedUp={
                          locationsStore.sortColumns.length > 0 &&
                          locationsStore.sortColumns[0] === 'name' &&
                          locationsStore.sortDirection === 'asc'
                        }
                        selectedDown={
                          locationsStore.sortColumns.length > 0 &&
                          locationsStore.sortColumns[0] === 'name' &&
                          locationsStore.sortDirection === 'desc'
                        }
                      >
                        {translate('name')}
                      </SortedCellContent>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: 250 }}>
                      <SortedCellContent
                        onUpClickHandler={clientArrowUpClick}
                        onDownClickHandler={clientArrowDownClick}
                        selectedUp={
                          locationsStore.sortColumns.length > 0 &&
                          locationsStore.sortColumns[0] === 'client.name' &&
                          locationsStore.sortDirection === 'asc'
                        }
                        selectedDown={
                          locationsStore.sortColumns.length > 0 &&
                          locationsStore.sortColumns[0] === 'client.name' &&
                          locationsStore.sortDirection === 'desc'
                        }
                      >
                        <GenericSearch onChange={onClientNameFilterChange} placeholder={translate('client')} />
                      </SortedCellContent>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '0.1%' }}>
                      <SortedCellContent
                        onUpClickHandler={countryArrowUpClick}
                        onDownClickHandler={countryArrowDownClick}
                        selectedUp={
                          locationsStore.sortColumns.length > 0 &&
                          locationsStore.sortColumns[0] === 'country.name' &&
                          locationsStore.sortDirection === 'asc'
                        }
                        selectedDown={
                          locationsStore.sortColumns.length > 0 &&
                          locationsStore.sortColumns[0] === 'country.name' &&
                          locationsStore.sortDirection === 'desc'
                        }
                      >
                        <GenericSelect
                          options={[{ label: translate('country: all'), value: '' }].concat(
                            countries.map((country) => ({ value: country.id, label: country.name })),
                          )}
                          onChange={onCountryChange}
                          styles={{ width: 170 }}
                        ></GenericSelect>
                      </SortedCellContent>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '0.1%' }}>
                      <SortedCellContent
                        onUpClickHandler={addressArrowUpClick}
                        onDownClickHandler={addressArrowDownClick}
                        selectedUp={
                          locationsStore.sortColumns.length > 0 &&
                          locationsStore.sortColumns[0] === 'address' &&
                          locationsStore.sortDirection === 'asc'
                        }
                        selectedDown={
                          locationsStore.sortColumns.length > 0 &&
                          locationsStore.sortColumns[0] === 'address' &&
                          locationsStore.sortDirection === 'desc'
                        }
                      >
                        {translate('address')}
                      </SortedCellContent>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '0.1%' }}>
                      <SortedCellContent
                        onUpClickHandler={propertyTypesArrowUpClick}
                        onDownClickHandler={propertyTypesArrowDownClick}
                        selectedUp={
                          locationsStore.sortColumns.length > 0 &&
                          locationsStore.sortColumns[0] === 'propertyTypes' &&
                          locationsStore.sortDirection === 'asc'
                        }
                        selectedDown={
                          locationsStore.sortColumns.length > 0 &&
                          locationsStore.sortColumns[0] === 'propertyTypes' &&
                          locationsStore.sortDirection === 'desc'
                        }
                      >
                        {translate('property type')}
                      </SortedCellContent>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={{ width: '0.1%' }}>
                      {translate('is enabled')}
                      <GenericSelect
                        options={[
                          { label: translate('active'), value: 'return x.services.move?.isEnabled' },
                          { label: translate('inactive'), value: 'return !x.services.move?.isEnabled' },
                          { label: translate('all'), value: 'return true' },
                        ]}
                        onChange={onActiveFilterChange}
                        styles={{ width: 120 }}
                      ></GenericSelect>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <PaginationTableContents paginationColSpan={4} data={locations} RowComponent={LocationTableRow} />
              </Table>
            </TableContainer>
          </>
        )}
      </RemoteSuspense>
    </>
  );
});

export { ListLocations };
