import React, { PropsWithChildren, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ModalContext } from '../context/ModalContext';

const useStyles = makeStyles((theme: Theme) => ({
  dialogText: {
    color: theme.palette.text.primary,
  },
}));

interface IConfirmDialogProps<T> {
  data: T;
  handleClick: (item: T) => Promise<void>;
  recordTitle?: string;
  dialogTitle?: string;
  content: string;
  primaryButtonLabel: string;
  cancelLabel: string;
}

function ConfirmDialog<T>(props: PropsWithChildren<IConfirmDialogProps<T>>) {
  const { handleClick, recordTitle, dialogTitle, content, primaryButtonLabel, cancelLabel, data } = props;
  const { dialogText } = useStyles();
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext);

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {recordTitle && (
        <DialogTitle id="alert-dialog-title">
          {dialogTitle}&quot;{recordTitle}&quot;
        </DialogTitle>
      )}
      {!recordTitle && <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className={dialogText}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsModalOpen(false)} color="secondary" variant="contained">
          {cancelLabel}
        </Button>
        <Button onClick={() => handleClick(data)} color="secondary" variant="outlined" autoFocus>
          {primaryButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { ConfirmDialog };
