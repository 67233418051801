import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useContext, useState, useCallback } from 'react';
import { FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { RemoteSuspense } from 'ts-remote-data-react';
import { IUserFormValues, UserForm } from '../../components/UserForm';
import { NotificationContext } from '../../context/NotificationContext';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { IUser } from '../../services/ApiServiceV1';

const EditUser: FunctionComponent = () => {
  const { userId } = useParams<{ userId: string }>();

  const { apiServiceV1 } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);

  const history = useHistory();
  const [remoteUser, setRemoteUser] = useState<RemoteData<IUser>>(RemoteData.NOT_ASKED);

  useEffect(() => {
    async function fetchUser() {
      setRemoteUser(RemoteData.LOADING);
      try {
        const user = await apiServiceV1.fetchUser(userId);
        setRemoteUser(user);
      } catch (error) {
        setRemoteUser(RemoteData.failWith(error));
      }
    }

    fetchUser();
  }, [apiServiceV1, userId]);

  const onSubmit = useCallback(
    async (values: IUserFormValues) => {
      const remoteUserCopy = RemoteData.asReady(remoteUser);
      try {
        setRemoteUser(RemoteData.LOADING);
        const updatedUser = await apiServiceV1.updateUser(userId, {
          clientId: values.client.id,
        });
        setRemoteUser(updatedUser);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate("updated user's details"),
            color: 'success',
          },
        });
        history.push(getPathWithProps('USERS_LIST'));
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate("failed to update user's data"),
            color: 'error',
          },
        });
        setRemoteUser(remoteUserCopy as IUser);
      }
    },
    [apiServiceV1, getPathWithProps, history, notificationDispatch, remoteUser, translate, userId],
  );

  return (
    <RemoteSuspense
      data={remoteUser}
      loadingFallback={<LinearProgress />}
      failureFallback={(error) => <Typography color="error">{translate('failed to fetch user')}</Typography>}
    >
      {(user) => <UserForm userData={user} onSubmit={onSubmit} />}
    </RemoteSuspense>
  );
};

export { EditUser };
