import React, { useContext } from 'react';
import { RemoteSuspense } from 'ts-remote-data-react';
import { observer } from 'mobx-react';
import { FormikSelect, FormikSelectProps } from './FormikInputs';
import { TranslationContext } from '../context/TranslationContext';
import { ServiceContext } from '../context/ServicesContext';
import { useEffect } from 'react';

const FormikCountrySelect: React.FunctionComponent<FormikSelectProps> = observer((props) => {
  const { translate } = useContext(TranslationContext);
  const {
    stores: { countriesStore },
  } = useContext(ServiceContext);
  useEffect(() => {
    async function getCountries() {
      countriesStore.fetch();
    }

    getCountries();
  }, [countriesStore]);
  return (
    <FormikSelect {...props}>
      <option>{translate('please select')}</option>
      <RemoteSuspense data={countriesStore.remoteCountries}>
        {(countries) =>
          countries.map((country) => (
            <option key={country.id} value={country.id}>
              {country.name}
            </option>
          ))
        }
      </RemoteSuspense>
    </FormikSelect>
  );
});

export { FormikCountrySelect };
