import { IFAQ } from '../services/ApiServiceV1';

export function IsFAQs(feedback: unknown): feedback is IFAQ {
  return (
    feedback !== undefined &&
    (feedback as IFAQ).id !== undefined &&
    (feedback as IFAQ).question !== undefined &&
    (feedback as IFAQ).answer !== undefined
  );
}
