import 'typeface-barlow';
import { Theme } from '@material-ui/core/styles';
import { commonTheme } from '@fmg-packages/common-components';

const theme: Theme = {
  ...commonTheme,
};

theme.typography.fontFamily = 'Barlow';

export default theme;
