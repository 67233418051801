import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import React, { useCallback, useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IMove, IMoveDetailsPayload } from '../../services/ApiServiceV1';
import { MoveDetailForm } from './MoveDetailForm';
import { CustomButton } from '../../components/CustomButton';
import { TranslationContext } from '../../context/TranslationContext';
import { observer } from 'mobx-react';
import { ServiceContext } from '../../context/ServicesContext';
import { NotificationContext } from '../../context/NotificationContext';

interface ICreateMoveDetailsProps {
  move: IMove;
}

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  cancelButton: {
    marginTop: theme.spacing(1),
  },
}));

const CreateMoveDetails: React.FunctionComponent<ICreateMoveDetailsProps> = observer(({ move }) => {
  const {
    rootStore: { moveDetailsStore, moveDetailsUiStore },
  } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { translate } = useContext(TranslationContext);
  const onSaveNewMoveDetails = useCallback(
    async (values: IMoveDetailsPayload, { resetForm }) => {
      try {
        await moveDetailsStore.addNewMoveDetails(move.id, values);
        moveDetailsUiStore.toggleShouldShowCreateForm();
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully added move details'),
            color: 'success',
          },
        });
        resetForm();
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to add move details'),
            color: 'success',
          },
        });
      }
    },
    [move.id, moveDetailsStore, moveDetailsUiStore, notificationDispatch, translate],
  );

  const { formContainer, cancelButton } = useStyles();

  return (
    <Collapse in={moveDetailsUiStore.shouldShowCreateForm}>
      <Paper className={formContainer}>
        <MoveDetailForm onSubmit={onSaveNewMoveDetails} />
        <CustomButton
          className={cancelButton}
          variant="outlined"
          onClick={() => {
            moveDetailsUiStore.toggleShouldShowCreateForm();
          }}
        >
          {translate('cancel')}
        </CustomButton>
      </Paper>
    </Collapse>
  );
});

export { CreateMoveDetails };
