import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Subheader } from '../typography/Subheader';
import { Typography } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  clientInformationText: {
    paddingBottom: theme.spacing(3),
  },
}));

interface ISectionContainer {
  title?: string;
  subtitle?: string;
}

const SectionContainer: React.FunctionComponent<ISectionContainer> = ({ children, title, subtitle }) => {
  const { clientInformationText } = useStyles();
  const theme = useTheme();
  const isLowerThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Paper>
      <Box px={isLowerThanSmall ? 3 : 5} pt={3} pb={16}>
        {title && <Subheader>{title}</Subheader>}
        {subtitle && (
          <Typography className={clientInformationText} variant="body2">
            {subtitle}
          </Typography>
        )}
        {children}
      </Box>
    </Paper>
  );
};

export { SectionContainer };
