import React, { useCallback } from 'react';
import { LocationForm } from '../../components/LocationForm';
import { useContext } from 'react';
import { TranslationContext } from '../../context/TranslationContext';
import { ILocationPayload } from '../../services/ApiServiceV1';
import { ServiceContext } from '../../context/ServicesContext';
import { useParams } from 'react-router-dom';
import { NotificationContext } from '../../context/NotificationContext';
import { SectionContainer } from '../../components/containers/SectionContainer';
import AdminContentHeader from '../../components/AdminContentHeader';
import { useHistory } from 'react-router-dom';
import { RouteMapContext } from '../../context/RouteMapContext';
import { observer } from 'mobx-react';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { NavigationBreadcrumbsWrapper } from '../../components/NavigationBreadcrumbs';

const CreateLocation: React.FunctionComponent = observer(() => {
  const { translate } = useContext(TranslationContext);
  const {
    stores: { clientStore, locationsStore },
  } = useContext(ServiceContext);
  const { clientId } = useParams<{ clientId: string }>();
  const { notificationDispatch } = useContext(NotificationContext);
  const history = useHistory();
  const { getPathWithProps } = useContext(RouteMapContext);

  useAsyncOperation(() => clientStore.fetchClientData(clientId), [clientId]);

  const onSubmit = useCallback(
    async (payload: ILocationPayload) => {
      try {
        const location = await locationsStore.create(clientId, payload);

        clientStore.addClientLocation(location);
        clientStore.invalidateRemoteLocations();

        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully saved location'),
            color: 'success',
          },
        });

        const path = getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', {
          clientId: clientId,
        });
        history.push(path);
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to save location'),
            color: 'error',
          },
        });
      }
    },
    [locationsStore, clientId, clientStore, getPathWithProps, history, notificationDispatch, translate],
  );
  return (
    <RemoteSuspense data={clientStore.remoteClient}>
      {(client) => (
        <>
          <NavigationBreadcrumbsWrapper
            items={[
              {
                content: client.name,
                url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
              },
              {
                content: translate('create location'),
              },
            ]}
          />
          <AdminContentHeader headerText={translate('create location')} />
          <SectionContainer
            title={translate('key information')}
            subtitle={translate('enter the key information about the client here')}
          >
            <LocationForm formSubmit={onSubmit} />
          </SectionContainer>
        </>
      )}
    </RemoteSuspense>
  );
});

export { CreateLocation };
