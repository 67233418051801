export interface IFakeApiService {
  success: <T>(payload: T, timeout?: number) => Promise<T>;
  failure: (timeout: number) => Promise<void>;
}

export const fakeApiService: IFakeApiService = {
  success: async function <T>(payload: T, timeout: number | undefined = 300): Promise<T> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(payload);
      }, timeout);
    });
  },
  failure: async function (timeout = 300) {
    return new Promise((_resolve, reject) => {
      setTimeout(() => {
        reject();
      }, timeout);
    });
  },
};
