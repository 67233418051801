import { action, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';
import { IApiServiceV1, IMovePage, IMovePagePayload, IMovePagePositionPayload } from '../services/ApiServiceV1';
import { RootStore } from './RootStore';

class MovePagesStore {
  @observable
  remoteMovePages: RemoteData<IMovePage[]> = RemoteData.NOT_ASKED;

  constructor(private apiService: IApiServiceV1, private rootStore: RootStore) {}

  @action
  async fetch(moveId: string, forceFetch = false) {
    if (forceFetch) {
      return;
    }

    this.remoteMovePages = RemoteData.LOADING;
    try {
      const movePages = await this.apiService.fetchMovePages(moveId);
      runInAction(() => {
        this.remoteMovePages = movePages;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteMovePages = RemoteData.fail();
      });
    }
  }

  @action
  async updateMovePage(movePageId: string, payload: IMovePagePayload) {
    const updatedMovePage = await this.apiService.updateMovePage(movePageId, payload);

    runInAction(() => {
      if (RemoteData.isReady(this.remoteMovePages)) {
        this.remoteMovePages = this.remoteMovePages.map((movePage) => {
          if (movePage.id === updatedMovePage.id) {
            return updatedMovePage;
          }
          return movePage;
        });
      }
    });
  }

  @action
  async deleteMovePage(movePageId: string) {
    await this.apiService.deleteMovePage(movePageId);
    runInAction(() => {
      if (RemoteData.isReady(this.remoteMovePages)) {
        this.remoteMovePages = this.remoteMovePages.filter((movePage) => movePageId !== movePage.id);
      }
    });
  }

  @action
  async updateMovePagesPosition(moveId: string, payload: IMovePagePositionPayload) {
    this.remoteMovePages = RemoteData.LOADING;

    const reorderedMovePages = await this.apiService.updateMovePagePosition(moveId, payload);
    runInAction(() => {
      this.remoteMovePages = reorderedMovePages;
    });
  }
}

export { MovePagesStore };
