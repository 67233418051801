import {
  IApiServiceV1,
  IFeedback,
  FeedbackStatusType,
  IFeedbackStatusPayload,
  FeedbackType,
} from '../services/ApiServiceV1';
import RemoteData from 'ts-remote-data';
import { observable, action } from 'mobx';
import { runInAction } from 'mobx';

class FeedbackStore {
  _apiService: IApiServiceV1;
  @observable remoteFeedbacks: RemoteData<IFeedback[]> = RemoteData.NOT_ASKED;
  @observable feedbackStatus: FeedbackStatusType | undefined = undefined;
  @observable feedbackType: FeedbackType | undefined = undefined;
  initialFeedbacks: IFeedback[] = [];
  filteredFeedbacksByType: IFeedback[] = [];
  filteredFeedbackByStatus: IFeedback[] = [];

  constructor(apiService: IApiServiceV1) {
    this._apiService = apiService;
  }

  @action
  async fetch(moveId: string, forceFetch = false) {
    if (RemoteData.isReady(this.remoteFeedbacks) && !forceFetch) {
      return;
    }
    this.remoteFeedbacks = RemoteData.LOADING;
    try {
      const feedbacks = await this._apiService.fetchMoveFeedbacks(moveId);
      runInAction(() => {
        this.remoteFeedbacks = feedbacks;
        this.initialFeedbacks = feedbacks;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteFeedbacks = RemoteData.fail();
      });
    }
  }

  @action
  async deleteFeedback(moveFeedbackId: string) {
    if (RemoteData.isReady(this.remoteFeedbacks)) {
      const temporaryFeedbacks = this.remoteFeedbacks;
      this.remoteFeedbacks = RemoteData.LOADING;
      try {
        await this._apiService.deleteMoveFeedback(moveFeedbackId);
        runInAction(() => {
          this.remoteFeedbacks = temporaryFeedbacks.filter((feedback) => feedback.id !== moveFeedbackId);
        });
      } catch (error) {
        runInAction(() => {
          this.remoteFeedbacks = RemoteData.failWith(temporaryFeedbacks);
        });
      }
    }
  }

  @action
  async updateMoveFeedback(moveFeedbackId: string, payload: IFeedbackStatusPayload) {
    if (RemoteData.isReady(this.remoteFeedbacks)) {
      const temporaryFeedbacks = this.remoteFeedbacks;
      this.remoteFeedbacks = RemoteData.LOADING;
      try {
        const updatedFeedback = await this._apiService.updateMoveFeedback(moveFeedbackId, payload);
        runInAction(() => {
          this.remoteFeedbacks = temporaryFeedbacks.map((feedback) => {
            if (feedback.id === updatedFeedback.id) {
              return updatedFeedback;
            }
            return feedback;
          });
        });
      } catch (error) {
        runInAction(() => {
          this.remoteFeedbacks = RemoteData.failWith(temporaryFeedbacks);
        });
      }
    }
  }

  @action
  async sendFeedbackAnswer(moveFeedbackId: string, payload: IFeedbackStatusPayload) {
    if (RemoteData.isReady(this.remoteFeedbacks)) {
      const temporaryFeedbacks = this.remoteFeedbacks;
      this.remoteFeedbacks = RemoteData.LOADING;
      try {
        const updatedFeedback = await this._apiService.sendFeedbackAnswer(moveFeedbackId, payload);
        runInAction(() => {
          this.remoteFeedbacks = temporaryFeedbacks.map((feedback) => {
            if (feedback.id === updatedFeedback.id) {
              return updatedFeedback;
            }
            return feedback;
          });
        });
      } catch (error) {
        runInAction(() => {
          this.remoteFeedbacks = RemoteData.failWith(temporaryFeedbacks);
        });
      }
    }
  }

  @action
  filterFeedbackByStatus(feedbackStatus: FeedbackStatusType) {
    this.feedbackStatus = feedbackStatus;
    if (RemoteData.isReady(this.remoteFeedbacks) && this.filteredFeedbacksByType.length > 0) {
      this.remoteFeedbacks = this.filteredFeedbacksByType.filter((feedback) => feedback.status === feedbackStatus);
      this.filteredFeedbackByStatus = this.filteredFeedbacksByType.filter(
        (feedback) => feedback.status === feedbackStatus,
      );
    } else if (RemoteData.isReady(this.remoteFeedbacks)) {
      this.filteredFeedbackByStatus = this.initialFeedbacks.filter((feedback) => feedback.status === feedbackStatus);
      this.remoteFeedbacks = this.initialFeedbacks.filter((feedback) => feedback.status === feedbackStatus);
    }
  }

  @action
  filterFeedbackByType(feedbackType: FeedbackType | undefined) {
    this.feedbackType = feedbackType;
    if (!feedbackType) {
      this.remoteFeedbacks = this.initialFeedbacks;
    } else if (RemoteData.isReady(this.remoteFeedbacks)) {
      this.remoteFeedbacks = this.initialFeedbacks.filter((feedback) => feedback.feedbackType === feedbackType);
      this.filteredFeedbacksByType = this.initialFeedbacks.filter((feedback) => feedback.feedbackType === feedbackType);
    }
  }
}

export { FeedbackStore };
