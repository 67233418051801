import RemoteData from 'ts-remote-data';
import { IApiServiceV1, IBrokering, IMove } from '../services/ApiServiceV1';
import { UserDomainStore } from '../stores/UserDomainStore';

export async function updateTeamMembers(
  newItem: IBrokering | IMove,
  existingRemoteTeamMembers: RemoteData<UserDomainStore[]>,
  apiService: IApiServiceV1,
) {
  const existingTeamMemberIds = RemoteData.isReady(existingRemoteTeamMembers)
    ? existingRemoteTeamMembers.map((teamMember) => teamMember.user.user.id as string)
    : [];
  const newTeamMemberIds = newItem.teamMembers.map((teamMember) => teamMember.id);

  const usersToFetch = newTeamMemberIds.filter((newTeamMemberId) => !existingTeamMemberIds.includes(newTeamMemberId));
  const usersToRemove = existingTeamMemberIds.filter(
    (existingTeamMemberId) => !newTeamMemberIds.includes(existingTeamMemberId),
  );

  let existingRemoteTeamMembersCopy: UserDomainStore[] = RemoteData.isReady(existingRemoteTeamMembers)
    ? existingRemoteTeamMembers.map((teamMemberStore) => teamMemberStore)
    : [];

  // remove users
  if (usersToRemove.length > 0) {
    existingRemoteTeamMembersCopy = existingRemoteTeamMembersCopy.filter(
      (teamMemberUserStore) => usersToRemove.includes(teamMemberUserStore.user.user.id as string) === false,
    );
  }

  // add users
  if (usersToFetch.length > 0) {
    const newTeamMembers = await Promise.all(usersToFetch.map((userIdToFetch) => apiService.fetchUser(userIdToFetch)));
    const newTeamMembersUserStores = newTeamMembers.map((newTeamMember) => new UserDomainStore(newTeamMember));
    newTeamMembersUserStores.forEach((newTeamMembersUserStore) => {
      newTeamMembersUserStore.fetchUserImage(apiService);
    });
    return existingRemoteTeamMembersCopy.concat(newTeamMembersUserStores);
  }

  return existingRemoteTeamMembersCopy;
}
