import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Header } from './typography/Header';

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    '& h1': {
      paddingBottom: theme.spacing(2),
    },
  },
}));

const AdminContentHeader: React.FC<{ headerText: string }> = ({ headerText }) => {
  const { headerContainer } = useStyles();
  return (
    <div className={headerContainer}>
      <Header>{headerText}</Header>
    </div>
  );
};

export default AdminContentHeader;
