import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { MoveServiceForm } from '../../components/MoveServiceForm';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { ILocation, IMove, IMovePayload } from '../../services/ApiServiceV1';
import LinearProgress from '@material-ui/core/LinearProgress';
import { NotificationContext } from '../../context/NotificationContext';
import { SectionContainer } from '../../components/containers/SectionContainer';
import AdminContentHeader from '../../components/AdminContentHeader';
import { useHistory } from 'react-router-dom';
import { NavigationBreadcrumbs } from '../../components/NavigationBreadcrumbs';
import RemoteData from 'ts-remote-data';
import { RouteMapContext } from '../../context/RouteMapContext';
import { observer } from 'mobx-react';

const EditMoveLocationService: React.FunctionComponent = observer(() => {
  const {
    apiServiceV1,
    stores: { clientStore },
    rootStore: { moveStore },
  } = useContext(ServiceContext);
  const { translate } = useContext(TranslationContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const { locationId, moveId, clientId } = useParams<{ locationId: string; moveId: string; clientId: string }>();
  const history = useHistory();

  useAsyncOperation<RemoteData<void>>(() => {
    return clientStore.fetchClientData(clientId);
  }, [clientId]);

  useAsyncOperation(() => {
    return moveStore.fetchMove(locationId, moveId);
  }, [locationId, moveId]);

  const remoteLocation = useAsyncOperation<RemoteData<ILocation>>(() => {
    return apiServiceV1.fetchLocation(clientId, locationId);
  }, [clientId, locationId]);

  const onLogoChange = async (logo: File | null) => {
    await apiServiceV1.uploadMoveLogo(locationId, moveId, logo);
  };

  const onBackgroundChange = async (background: File | null) => {
    await apiServiceV1.uploadMoveBackground(locationId, moveId, background);
  };

  const onSubmit = useCallback(
    async (values: IMovePayload) => {
      try {
        const move = await apiServiceV1.updateMove(locationId, moveId, values);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'success',
            message: translate('successfully saved a move'),
          },
        });
        moveStore.setMove(move);
        history.push(
          getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
            clientId: RemoteData.asReady(clientStore.remoteClient)?.id as string,
            locationId,
          }),
        );
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to save a move') + ': ' + error.response.data.message,
          },
        });
      }
    },
    [
      apiServiceV1,
      locationId,
      moveId,
      moveStore,
      notificationDispatch,
      translate,
      history,
      getPathWithProps,
      clientStore.remoteClient,
    ],
  );

  return (
    <>
      <RemoteSuspense
        data={RemoteData.all(remoteLocation, clientStore.remoteClient)}
        loadingFallback={<LinearProgress />}
      >
        {([location, client]) => (
          <NavigationBreadcrumbs
            items={[
              {
                content: client.name,
                url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
              },
              {
                content: location.name,
                url: getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
                  clientId: client.id,
                  locationId: location.id,
                }),
              },
              {
                content: translate('edit move location service'),
              },
            ]}
          />
        )}
      </RemoteSuspense>

      <AdminContentHeader headerText={translate('edit move location service')} />
      <SectionContainer>
        <RemoteSuspense
          data={RemoteData.all(moveStore.remoteMove, moveStore.remoteClientContact, moveStore.remoteTeamMemberUsers)}
          loadingFallback={<LinearProgress />}
          failureFallback={(move: IMove) => (
            <MoveServiceForm onSubmit={onSubmit} move={move} onLogoChange={onLogoChange} />
          )}
        >
          {([move, clientContact, teamMembers]) => (
            <MoveServiceForm
              move={move}
              clientContact={clientContact?.map((c) => c.user)}
              teamMembers={teamMembers}
              onSubmit={onSubmit}
              onLogoChange={onLogoChange}
              onBackgroundChange={onBackgroundChange}
            />
          )}
        </RemoteSuspense>
      </SectionContainer>
    </>
  );
});

export { EditMoveLocationService };
