import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { TranslationContext } from '../context/TranslationContext';
import { Client, IClients, IUser } from '../services/ApiServiceV1';
import { ClientsFormikDropdown } from './ClientsFormikDropdown';
import { CustomButton } from './CustomButton';
import { FormikFormInput } from './FormikInputs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import * as yup from 'yup';

interface IUserForm {
  onSubmit: (values: IUserFormValues) => Promise<void>;
  userData?: IUser;
}

export interface IUserFormValues {
  name: string;
  email: string;
  client: Client;
}

const UserForm: React.FunctionComponent<IUserForm> = observer(({ onSubmit, userData }) => {
  const initialValues: IUserFormValues = {
    name: userData ? (userData.user.displayName as string) : '',
    email: userData ? (userData.user.mail as string) : '',
    client: userData && userData.client !== null ? userData.client : ((null as unknown) as IClients),
  };
  const { translate } = useContext(TranslationContext);
  const theme = useTheme();
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object({
          name: yup.string().required(translate('field is required')),
          email: yup.string().required(translate('field is required')).email(translate('field must be a valid email')),
          client: yup.mixed().required(translate('field is required')),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <FormikFormInput
                  formikKey="name"
                  labelContent={translate('name')}
                  formControlProps={{ fullWidth: true }}
                  disabled={userData !== undefined}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <ClientsFormikDropdown
                  formikKey="client"
                  labelContent={translate('client')}
                  formControlProps={{ fullWidth: true }}
                />
              </Grid>
              {isSmallerThanSm === false && <Grid item sm={4} />}
              <Grid item xs={12} sm={12} md={4}>
                <FormikFormInput
                  formikKey="email"
                  labelContent={translate('contact email')}
                  formControlProps={{ fullWidth: true }}
                  disabled={userData !== undefined}
                />
              </Grid>
              {isSmallerThanSm === false && <Grid item sm={8} />}
              <Grid item>
                <CustomButton disabled={isSubmitting} type="submit" variant="contained" color="secondary">
                  {translate('create user')}
                </CustomButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
});

export { UserForm };
