import React, { useContext } from 'react';
import { Header } from '../../components/typography/Header';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import { ServiceContext } from '../../context/ServicesContext';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ButtonLink } from '../../components/ButtonLink';
import { TranslationContext } from '../../context/TranslationContext';
import { ClientLocations } from './ClientLocations';
import { RouteMapContext } from '../../context/RouteMapContext';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import { RestrictedAccess } from '../../components/RestrictedAccess';
import RemoteData from 'ts-remote-data';
import { shouldDenyExternalUserAccess } from '../../utils/shouldAllowExternalUserAccess';
import { observer } from 'mobx-react';
import { ClientBrokeringProjects } from './ClientBrokeringProjects';
import { ClientLeases } from './ClientLeases';
import { ClientLeaseReminders } from './ClientLeaseReminders';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const ClientDashboard: React.FunctionComponent = observer(() => {
  const { clientId } = useParams<{ clientId: string }>();
  const {
    stores: { clientStore },
  } = useContext(ServiceContext);
  const { remoteUser } = useContext(AuthenticationContext);

  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const { headerContainer } = useStyles();

  useAsyncOperation(() => {
    return clientStore.fetchClientData(clientId);
  }, [clientId]);

  return (
    <>
      <RemoteSuspense
        data={RemoteData.all(clientStore.remoteClient, clientStore.remoteLocations, remoteUser)}
        loadingFallback={<LinearProgress />}
        failureFallback={
          <Typography variant="h4" color="error">
            {translate('failed to fetch client data')}
          </Typography>
        }
      >
        {([client, locations, user]) => {
          return shouldDenyExternalUserAccess(user, client) ? (
            <RestrictedAccess />
          ) : (
            <Grid container spacing={3}>
              <Grid container item xs={12} justify="space-between" alignItems="center" className={headerContainer}>
                <Grid item>
                  <Header>{client.name}</Header>
                </Grid>
                {user.isExternal === false && (
                  <Grid item>
                    <ButtonLink
                      color="secondary"
                      variant="contained"
                      to={getPathWithProps<{ clientId: string }>('EDIT_CLIENT', { clientId })}
                    >
                      {translate('edit client')}
                    </ButtonLink>
                  </Grid>
                )}
              </Grid>
              {/* content grid starts here */}

              <Grid container item xs={12} sm={12} md={12} lg={12}>
                <ClientLocations client={client} locations={locations} user={user} />
              </Grid>
            </Grid>
          );
        }}
      </RemoteSuspense>
    </>
  );
});

export { ClientDashboard };
