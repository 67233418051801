import React, { createContext, FunctionComponent } from 'react';
import { AuthenticationService, getAuthenticationService } from '../services/AuthenticationService';
import { IFakeApiService, fakeApiService } from '../services/FakeApiService';
import { createHttpService, IHttpService } from '../services/httpService';
import { createApiServiceV1, IApiServiceV1 } from '../services/ApiServiceV1';
import { CountryStore } from '../stores/Countries';
import { LocationsStore } from '../stores/LocationsStore';
import { ClientsStore } from '../stores/ClientsStore';
import { configure } from 'mobx';
import { FeedbackStore } from '../stores/FeedbackStore';
import { FAQsStore } from '../stores/FAQsStore';
import { UsersStore } from '../stores/UsersStore';
import { ClientStore } from '../stores/ClientStore';
import { BrokeringStore } from '../stores/BrokeringStore';
import { MoveContactsStore } from '../stores/MoveContactsStore';
import { LocationStore } from '../stores/LocationStore';
import { MoveContactsUiStore } from '../stores/MoveContactsUiStore';
import { RootStore } from '../stores/RootStore';
import { TemplatesStore } from '../stores/TemplateStore';

configure({
  enforceActions: 'observed',
  computedRequiresReaction: true,
});

interface IStores {
  countriesStore: CountryStore;
  clientsStore: ClientsStore;
  locationsStore: LocationsStore;
  templatesStore: TemplatesStore;
  feedbackStore: FeedbackStore;
  FAQsStore: FAQsStore;
  usersStore: UsersStore;
  clientStore: ClientStore;
  brokeringStore: BrokeringStore;
  moveContactsStore: MoveContactsStore;
  moveContactsUiStore: MoveContactsUiStore;
  locationStore: LocationStore;
}

export interface IServices {
  authenticationService: AuthenticationService;
  fakeApiService: IFakeApiService;
  httpService: IHttpService;
  apiServiceV1: IApiServiceV1;
  stores: IStores;
  rootStore: RootStore;
}

const authenticationService = getAuthenticationService({
  appId: process.env['REACT_APP_AD_APP_ID'] as string,
  redirectUri: process.env['REACT_APP_AD_REDIRECT_URI'] as string,
  scopes: [`api://${process.env['REACT_APP_AD_BACKEND_APP_ID']}/access_as_user`],
  authority: process.env['REACT_APP_AD_AUTHORITY_URI'] as string,
});
export const BASE_URL =
  process.env['NODE_ENV'] === 'development' ? '/api' : `${process.env['REACT_APP_BACKEND_BASE_URL']}/api`;
const httpService = createHttpService({
  baseURL: BASE_URL,
});

const apiServiceV1 = createApiServiceV1(httpService);

const stores: IStores = {
  countriesStore: new CountryStore(apiServiceV1),
  clientsStore: new ClientsStore(apiServiceV1),
  locationsStore: new LocationsStore(apiServiceV1),
  feedbackStore: new FeedbackStore(apiServiceV1),
  FAQsStore: new FAQsStore(apiServiceV1),
  usersStore: new UsersStore(apiServiceV1),
  clientStore: new ClientStore(apiServiceV1),
  brokeringStore: new BrokeringStore(apiServiceV1),
  moveContactsStore: new MoveContactsStore(apiServiceV1),
  moveContactsUiStore: new MoveContactsUiStore(),
  locationStore: new LocationStore(apiServiceV1),
  templatesStore: new TemplatesStore(apiServiceV1),
};

const services: IServices = {
  authenticationService,
  fakeApiService,
  httpService,
  apiServiceV1,
  stores,
  rootStore: new RootStore(apiServiceV1),
};

const ServiceContext = createContext<IServices>(services);

const ServiceProvider: FunctionComponent = ({ children }) => (
  <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>
);

export { ServiceProvider, ServiceContext };
