import React from 'react';
import Select, { SelectProps } from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/styles';
import { fieldPadding } from './FormInput';

const useStyles = makeStyles({
  select: {
    padding: fieldPadding,
  },
});

const FormSelect: React.FunctionComponent<SelectProps> = ({ children, ...props }) => {
  const { select } = useStyles();
  return (
    <Select native {...props} classes={{ select }}>
      {children}
    </Select>
  );
};

export default FormSelect;
