import React, { useContext } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { IClients } from '../../services/ApiServiceV1';
import { StyledLink } from '../../components/StyledLink';
import { RouteMapContext } from '../../context/RouteMapContext';

const ClientTableRow: React.FunctionComponent<IClients> = ({
  id,
  name,
  orgNumber,
  country: { name: countryName },
  countOfLocations,
  countOfActiveLocations,
}) => {
  const { getPathWithProps } = useContext(RouteMapContext);
  return (
    <TableRow>
      <TableCell>
        <StyledLink to={getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: id })}>
          {name}
        </StyledLink>
      </TableCell>
      <TableCell>{orgNumber}</TableCell>
      <TableCell>{countryName}</TableCell>
      <TableCell align="right">{countOfLocations}</TableCell>
      <TableCell align="right">{countOfActiveLocations}</TableCell>
    </TableRow>
  );
};

export { ClientTableRow };
