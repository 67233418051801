import { IFeedback } from '../services/ApiServiceV1';

export function IsFeedback(feedback: unknown): feedback is IFeedback {
  return (
    feedback !== undefined &&
    (feedback as IFeedback).id !== undefined &&
    (feedback as IFeedback).question !== undefined &&
    (feedback as IFeedback).feedbackType !== undefined
  );
}
