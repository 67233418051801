import React, { useContext } from 'react';
import { TranslationContext } from '../../context/TranslationContext';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { IFeedback } from '../../services/ApiServiceV1';
import { StyledTableCell } from '../../components/StyledCell';
import { IFormInitialValues, FeedbackRow } from './FeedbackRow';
import Typography from '@material-ui/core/Typography';

interface IFeedbackTable {
  feedbacks: IFeedback[];
  handleDelete: (feedbackId: string) => Promise<void>;
  handleSave: (values: IFormInitialValues) => Promise<void>;
  sendEmail: (values: IFormInitialValues) => Promise<void>;
}
const FeedbackTable: React.FC<IFeedbackTable> = ({ feedbacks, handleDelete, handleSave, sendEmail }) => {
  const { translate } = useContext(TranslationContext);
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <StyledTableCell size="small">{translate('status')}</StyledTableCell>
            <StyledTableCell size="small">{translate('type')}</StyledTableCell>
            <StyledTableCell align="right" size="small" />
          </TableRow>
        </TableHead>
        <TableBody>
          {feedbacks.length > 0 ? (
            feedbacks.map((feedback, index) => (
              <FeedbackRow
                onDelete={handleDelete}
                key={index}
                feedback={feedback}
                onSave={handleSave}
                sendEmail={sendEmail}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography>{translate('no feedback found')}</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FeedbackTable;
