import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import React from 'react';
import { useContext } from 'react';
import { RemoteSuspense } from 'ts-remote-data-react';
import { ServiceContext } from '../../context/ServicesContext';
import { RouteMapContext } from '../../context/RouteMapContext';
import { TimelineDashboardBox } from '../../components/TimelineDashboardBox';
import { IClient, ILocation, ILocationMove, IMove } from '../../services/ApiServiceV1';

interface IMoveTimelineWrapperProps {
  move: IMove | ILocationMove;
  client: IClient;
  location: ILocation;
}

const MoveTimelineBox: React.FunctionComponent<IMoveTimelineWrapperProps> = observer(({ move, location, client }) => {
  const {
    rootStore: { moveStore },
  } = useContext(ServiceContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  return (
    <RemoteSuspense data={moveStore.timelineSections.remoteTimelineSections} loadingFallback={<LinearProgress />}>
      {(timelineSections) => (
        <Grid item xs={12} sm={12} md={6}>
          <TimelineDashboardBox
            timelineSections={timelineSections}
            editTimelineUrl={getPathWithProps<{ clientId: string; locationId: string; moveId: string }>(
              'MOVE_TIMELINE',
              {
                clientId: client.id,
                locationId: location.id,
                moveId: move.id,
              },
            )}
          />
        </Grid>
      )}
    </RemoteSuspense>
  );
});

export { MoveTimelineBox };
