import Grid from '@material-ui/core/Grid';
import React from 'react';
import { DashboardBox } from '../DashboardBox';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IInfoItemProps, InfoItem } from './InfoItem';
import { ISubtextInfoItemProps, SubtextInfoItem } from './SubtextInfoItem';
import { DividedContentContainer, IDividedContentContainerProps } from './DividedContentContainer';
import { VerticalBoxDivider } from './VerticalBoxDivider';
import { HorizontalBoxDivider } from './HorizontalBoxDivider';
import { ITeamMemberItemProps, TeamMemberItem } from './TeamMemberItem';

interface IDividedInfoBoxProps {
  boxTitle: string;
  editUrl?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  boxContent: {
    padding: theme.spacing(2, 0),
    '& > * > *': {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(0, 2),
      },
    },
  },
}));

interface IDividedBoxComposition {
  DividedContentContainer: React.FunctionComponent<IDividedContentContainerProps>;
  InfoItem: React.FunctionComponent<IInfoItemProps>;
  SubtextInfoItem: React.FunctionComponent<ISubtextInfoItemProps>;
  VerticalBoxDivider: React.FunctionComponent;
  TeamMemberItem: React.FunctionComponent<ITeamMemberItemProps>;
  HorizontalBoxDivider: React.FunctionComponent;
}

const DividedInfoBox: React.FunctionComponent<IDividedInfoBoxProps> & IDividedBoxComposition = ({
  boxTitle,
  editUrl,
  children,
}) => {
  const { boxContent } = useStyles();
  return (
    <DashboardBox title={boxTitle} url={editUrl}>
      <Grid container justify="space-evenly" className={boxContent}>
        {children}
      </Grid>
    </DashboardBox>
  );
};

DividedInfoBox.DividedContentContainer = DividedContentContainer;
DividedInfoBox.InfoItem = InfoItem;
DividedInfoBox.SubtextInfoItem = SubtextInfoItem;
DividedInfoBox.VerticalBoxDivider = VerticalBoxDivider;
DividedInfoBox.TeamMemberItem = TeamMemberItem;
DividedInfoBox.HorizontalBoxDivider = HorizontalBoxDivider;

export { DividedInfoBox };
