import { AxiosError } from 'axios';
import React, { useCallback } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { IUserFormValues, UserForm } from '../../components/UserForm';
import { NotificationContext } from '../../context/NotificationContext';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';

const CreateUser: React.FunctionComponent = () => {
  const { apiServiceV1 } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const history = useHistory();
  const onSubmit = useCallback(
    async (values: IUserFormValues) => {
      try {
        await apiServiceV1.inviteExternalUser({
          email: values.email,
          clientId: values.client.id,
          name: values.name,
        });
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully saved'),
            color: 'success',
          },
        });
        history.push(getPathWithProps('USERS_LIST'));
      } catch (error) {
        if (error.isAxiosError && (error as AxiosError).response?.status === 409) {
          notificationDispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              message: translate('user with provided email already exists'),
              color: 'warning',
            },
          });
        } else {
          notificationDispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              message: translate('failed to save external user'),
              color: 'error',
            },
          });
        }
      }
    },
    [apiServiceV1, getPathWithProps, history, notificationDispatch, translate],
  );
  return (
    <>
      <UserForm onSubmit={onSubmit} />
    </>
  );
};

export { CreateUser };
