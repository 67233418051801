import React from 'react';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import { fieldPadding } from './FormInput';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  input: {
    padding: fieldPadding,
  },
});

const StyledKeyboardDatePicker: React.FunctionComponent<KeyboardDatePickerProps> = (props) => {
  const { input } = useStyles();
  return <KeyboardDatePicker {...props} InputProps={{ classes: { input }, error: props.error }} />;
};

export default StyledKeyboardDatePicker;
