import LinearProgress from '@material-ui/core/LinearProgress';
import { observer } from 'mobx-react';
import React from 'react';
import { useContext } from 'react';
import { RemoteSuspense } from 'ts-remote-data-react';
import { TimelineDashboardBox } from '../../components/TimelineDashboardBox';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ServiceContext } from '../../context/ServicesContext';
import { IBrokering, IClient } from '../../services/ApiServiceV1';

interface IBrokeringTimelineBoxProps {
  client: IClient;
  brokering: IBrokering;
}

const BrokeringTimelineBox: React.FunctionComponent<IBrokeringTimelineBoxProps> = observer(({ client, brokering }) => {
  const {
    stores: { brokeringStore },
  } = useContext(ServiceContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  return (
    <RemoteSuspense data={brokeringStore.timelineSections.remoteTimelineSections} loadingFallback={<LinearProgress />}>
      {(timelineSections) => (
        <TimelineDashboardBox
          timelineSections={timelineSections}
          editTimelineUrl={getPathWithProps<{ clientId: string; brokeringId: string }>('BROKERING_TIMELINE', {
            clientId: client.id,
            brokeringId: brokering.id,
          })}
        />
      )}
    </RemoteSuspense>
  );
});

export { BrokeringTimelineBox };
