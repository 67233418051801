import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface IStyledExternalLinkProps {
  url: string;
}

const StyledExternalLink: React.FunctionComponent<IStyledExternalLinkProps> = ({ url, children }) => {
  const { link } = useStyles();
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className={link}>
      {children}
    </a>
  );
};

export { StyledExternalLink };
