import React, { useContext, useEffect, useCallback } from 'react';
import { RemoteSuspense } from 'ts-remote-data-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { ButtonLink } from '../../components/ButtonLink';
import { observer } from 'mobx-react';
import { StyledTableCell } from '../../components/StyledCell';
import { SortedCellContent } from '../../components/SortedCellContent';
import { Header } from '../../components/typography/Header';
import { PaginationTableContents } from '../../components/PaginationTableContents';
import { ClientTableRow } from './ClientTableRow';
import { HeaderContainer } from '../../components/containers/HeaderContainer';
import GenericSelect from '../../components/GenericSelect';
import GenericSearch from '../../components/GenericSearch';
import RemoteData from 'ts-remote-data';

const useStyles = makeStyles((theme: Theme) => ({
  tableContainerRoot: {
    padding: theme.spacing(4),
  },
  filterGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  sortVerticalArrows: {
    display: 'inline-flex',
    flexDirection: 'column',
  },
  sortHorizontalArrows: {
    display: 'inline-flex',
  },
  sortCell: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ListClients: React.FunctionComponent = observer(() => {
  const {
    stores: { countriesStore, clientsStore },
  } = useContext(ServiceContext);
  const { tableContainerRoot, filterGrid } = useStyles();

  useEffect(() => {
    async function fetchData() {
      clientsStore.fetch(true);
      countriesStore.fetch();
      clientsStore.setSort('name', 'asc');
    }

    fetchData();
  }, [clientsStore, countriesStore]);

  const { translate } = useContext(TranslationContext);

  const onCompanyNameFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clientsStore.setCompanyNameFilter(e.target.value);
    },
    [clientsStore],
  );

  const onOrgNumberFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clientsStore.setOrgNumberFilter(e.target.value);
    },
    [clientsStore],
  );

  const onCountryChange = useCallback(
    (selectedValue: { value: string } | undefined) => {
      clientsStore.setCountryId(selectedValue?.value);
    },
    [clientsStore],
  );

  const onActiveFilterChange = useCallback(
    (selectedValue: { value: string } | undefined) => {
      clientsStore.setActiveFilter(selectedValue?.value);
    },
    [clientsStore],
  );

  const nameArrowUpClick = useCallback(() => {
    clientsStore.setSort('name', 'asc');
  }, [clientsStore]);

  const nameArrowDownClick = useCallback(() => {
    clientsStore.setSort('name', 'desc');
  }, [clientsStore]);

  const orgNumberArrowUpClick = useCallback(() => {
    clientsStore.setSort('orgNumber', 'asc');
  }, [clientsStore]);

  const orgNumberArrowDownClick = useCallback(() => {
    clientsStore.setSort('orgNumber', 'desc');
  }, [clientsStore]);

  const countryArrowUpClick = useCallback(() => {
    clientsStore.setSort('country.name', 'asc');
  }, [clientsStore]);

  const countryArrowDownClick = useCallback(() => {
    clientsStore.setSort('country.name', 'desc');
  }, [clientsStore]);

  const locationCountArrowUpClick = useCallback(() => {
    clientsStore.setSort('countOfLocations', 'asc');
  }, [clientsStore]);

  const locationCountArrowDownClick = useCallback(() => {
    clientsStore.setSort('countOfLocations', 'desc');
  }, [clientsStore]);

  const activeLocationCountArrowUpClick = useCallback(() => {
    clientsStore.setSort('countOfActiveLocations', 'asc');
  }, [clientsStore]);

  const activeLocationCountArrowDownClick = useCallback(() => {
    clientsStore.setSort('countOfActiveLocations', 'desc');
  }, [clientsStore]);

  return (
    <>
      <HeaderContainer>
        <Header>{translate('clients')}</Header>
      </HeaderContainer>
      <Grid container justify="space-between" classes={{ root: filterGrid }}>
        <Grid item>
          <ButtonLink to="/admin/clients/add-client" color="secondary" variant="contained">
            {translate('add client')}
          </ButtonLink>
        </Grid>
      </Grid>
      <RemoteSuspense data={RemoteData.all(countriesStore.remoteCountries, clientsStore.filteredSortedClients)}>
        {([countries, clients]) => (
          <TableContainer component={Paper} classes={{ root: tableContainerRoot }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: 280 }}>
                    <SortedCellContent
                      onUpClickHandler={nameArrowUpClick}
                      onDownClickHandler={nameArrowDownClick}
                      selectedUp={
                        !!clientsStore.sortColumn &&
                        !!clientsStore.sortDirection &&
                        clientsStore.sortColumn === 'name' &&
                        clientsStore.sortDirection === 'asc'
                      }
                      selectedDown={clientsStore.sortColumn === 'name' && clientsStore.sortDirection === 'desc'}
                    >
                      <GenericSearch onChange={onCompanyNameFilterChange} placeholder={translate('name')} />
                    </SortedCellContent>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 280 }}>
                    <SortedCellContent
                      onUpClickHandler={orgNumberArrowUpClick}
                      onDownClickHandler={orgNumberArrowDownClick}
                      selectedUp={
                        !!clientsStore.sortColumn &&
                        !!clientsStore.sortDirection &&
                        clientsStore.sortColumn === 'orgNumber' &&
                        clientsStore.sortDirection === 'asc'
                      }
                      selectedDown={clientsStore.sortColumn === 'orgNumber' && clientsStore.sortDirection === 'desc'}
                    >
                      <GenericSearch onChange={onOrgNumberFilterChange} placeholder={translate('org. number')} />
                    </SortedCellContent>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '0.1%' }}>
                    <SortedCellContent
                      onUpClickHandler={countryArrowUpClick}
                      onDownClickHandler={countryArrowDownClick}
                      selectedUp={
                        !!clientsStore.sortColumn &&
                        !!clientsStore.sortDirection &&
                        clientsStore.sortColumn === 'country.name' &&
                        clientsStore.sortDirection === 'asc'
                      }
                      selectedDown={clientsStore.sortColumn === 'country.name' && clientsStore.sortDirection === 'desc'}
                    >
                      <GenericSelect
                        options={[{ label: translate('country: all'), value: '' }].concat(
                          countries.map((country) => ({ value: country.id, label: country.name })),
                        )}
                        onChange={onCountryChange}
                        styles={{ width: 160 }}
                      ></GenericSelect>
                    </SortedCellContent>
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ width: '0.1%' }}>
                    <SortedCellContent
                      onUpClickHandler={locationCountArrowUpClick}
                      onDownClickHandler={locationCountArrowDownClick}
                      selectedUp={
                        clientsStore.sortColumn === 'countOfLocations' && clientsStore.sortDirection === 'asc'
                      }
                      selectedDown={
                        clientsStore.sortColumn === 'countOfLocations' && clientsStore.sortDirection === 'desc'
                      }
                    >
                      {translate('no. of locations')}
                    </SortedCellContent>
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ width: '0.1%' }}>
                    <SortedCellContent
                      onUpClickHandler={activeLocationCountArrowUpClick}
                      onDownClickHandler={activeLocationCountArrowDownClick}
                      selectedUp={
                        clientsStore.sortColumn === 'countOfActiveLocations' && clientsStore.sortDirection === 'asc'
                      }
                      selectedDown={
                        clientsStore.sortColumn === 'countOfActiveLocations' && clientsStore.sortDirection === 'desc'
                      }
                    >
                      {translate('no. of active locations')}
                      <GenericSelect
                        options={[
                          { label: translate('active'), value: 'return x.countOfActiveLocations > 0' },
                          { label: translate('inactive'), value: 'return x.countOfActiveLocations == 0' },
                          { label: translate('all'), value: 'return true' },
                        ]}
                        onChange={onActiveFilterChange}
                        styles={{ width: 120 }}
                      ></GenericSelect>
                    </SortedCellContent>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <PaginationTableContents paginationColSpan={5} data={clients} RowComponent={ClientTableRow} />
            </Table>
          </TableContainer>
        )}
      </RemoteSuspense>
    </>
  );
});

export default ListClients;
