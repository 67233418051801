import React from 'react';
import AdminLayout from './components/AdminLayout/AdminLayout';
import { RenderRoutes } from './components/RenderRoutes';
import { IAppRoute } from './context/RouteMapContext';
import AddClient from './screens/addClient';
import AdminHome from './screens/adminHome';
import { ClientDashboard } from './screens/clientDashboard';
import { CreateLocation } from './screens/createLocation';
import { CreateMovePage } from './screens/createMovePage';
import { CreateTemplate } from './screens/createTemplate';
import { EditClient } from './screens/editClient';
import { EditMoveLocationService } from './screens/editMoveLocationService';
import { EditPage } from './screens/editPage';
import { EditTemplate } from './screens/editTemplate';
import { ListFeedback } from './screens/feedback';
import FloorPlanner from './screens/floorPlanner/FloorPlanner';
import Home from './screens/home';
import ListClients from './screens/listClient';
import ListFAQs from './screens/faqs';
import { ListLocations } from './screens/listLocations';
import { ListPages } from './screens/listPages';
import { ListTemplates } from './screens/listTemplates';
import { LocationDashboard } from './screens/locationDashboard';
import { LocationEdit } from './screens/locationEdit';
import { MoveLocationService } from './screens/moveLocationService';
import { MoveTimeline } from './screens/moveTimeline';
import { ListUsers } from './screens/listUsers';
import { CreateUser } from './screens/createUser';
import { EditUser } from './screens/editUser';
import { NoClient } from './screens/noClient';
import { CreateBrokeringProject } from './screens/createBrokeringProject';
import { BrokeringDashboard } from './screens/brokeringDashboard';
import { EditBrokeringProject } from './screens/editBrokeringProject';
import { BrokeringTimeline } from './screens/brokeringTimeline';
import { CreateLease } from './screens/createLease';
import { EditLease } from './screens/editLease';
import { Reminders } from './screens/reminders';
import { MoveContacts } from './screens/moveContacts';
import { MoveDetails } from './screens/moveDetails';
import { Documents } from './screens/documents/Documents';

/* eslint-disable react/display-name */

export const ROUTES: IAppRoute[] = [
  {
    path: '/',
    key: 'ROOT',
    exact: true,
    component: () => <Home />,
    isProtected: false,
  },
  {
    path: '/admin',
    key: 'ADMIN',
    component: RenderRoutes,
    isProtected: true,
    routes: [
      {
        path: '/admin',
        key: 'ADMIN_ROOT',
        exact: true,
        component: () => {
          return (
            <AdminLayout>
              <AdminHome />
            </AdminLayout>
          );
        },
        isProtected: true,
        restrictExternal: true,
      },
      {
        path: '/admin/no-client',
        key: 'NO_CLIENT',
        exact: true,
        component: () => <NoClient />,
        isProtected: true,
      },
      /* CLIENTS */
      {
        path: '/admin/clients',
        key: 'CLIENTS',
        component: RenderRoutes,
        isProtected: true,
        routes: [
          {
            path: '/admin/clients',
            key: 'CLIENTS_ROOT',
            component: () => (
              <AdminLayout>
                <ListClients />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
            restrictExternal: true,
          },
          {
            path: '/admin/clients/add-client',
            key: 'ADD_CLIENT',
            exact: true,
            component: () => (
              <AdminLayout>
                <AddClient />
              </AdminLayout>
            ),
            isProtected: true,
            restrictExternal: true,
          },
          {
            path: '/admin/clients/:clientId',
            key: 'CLIENT_DASHBOARD',
            exact: true,
            component: () => (
              <AdminLayout>
                <ClientDashboard />
              </AdminLayout>
            ),
            isProtected: true,
          },
          {
            path: '/admin/clients/:clientId/edit',
            key: 'EDIT_CLIENT',
            exact: true,
            component: () => (
              <AdminLayout>
                <EditClient />
              </AdminLayout>
            ),
            isProtected: true,
            restrictExternal: true,
          },
          {
            path: '/admin/clients/:clientId/create-lease',
            key: 'CREATE_LEASE',
            exact: true,
            isProtected: true,
            restrictExternal: true,
            component: () => (
              <AdminLayout>
                <CreateLease />
              </AdminLayout>
            ),
          },
          {
            path: '/admin/clients/:clientId/locations/:locationId',
            key: 'CLIENT_LOCATION',
            exact: true,
            component: () => (
              <AdminLayout>
                <LocationDashboard />
              </AdminLayout>
            ),
            isProtected: true,
          },
          {
            path: '/admin/clients/:clientId/locations/:locationId/edit',
            key: 'CLIENT_LOCATION_EDIT',
            exact: true,
            component: () => (
              <AdminLayout>
                <LocationEdit />
              </AdminLayout>
            ),
            isProtected: true,
            restrictExternal: true,
          },
          {
            path: '/admin/clients/:clientId/create-location',
            key: 'CREATE_CLIENT_LOCATION',
            component: () => (
              <AdminLayout>
                <CreateLocation />
              </AdminLayout>
            ),
            isProtected: true,
            restrictExternal: true,
          },
          /* PAGES */
          {
            path: '/admin/clients/:clientId/:locationId/:moveId/pages',
            key: 'MOVE_PAGES',
            component: () => (
              <AdminLayout>
                <ListPages />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/clients/:clientId/:locationId/:moveId/feedback',
            key: 'MOVE_FEEDBACK',
            component: () => (
              <AdminLayout>
                <ListFeedback />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/clients/:clientId/:locationId/:moveId/FAQs',
            key: 'MOVE_FAQs',
            component: () => (
              <AdminLayout>
                <ListFAQs />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/clients/:clientId/:locationId/:templateId/new',
            key: 'CREATE_MOVE_PAGE',
            component: () => (
              <AdminLayout>
                <CreateMovePage />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/clients/:clientId/:locationId/:movePageId/edit',
            key: 'EDIT_PAGE',
            component: () => (
              <AdminLayout>
                <EditPage />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/clients/:clientId/:locationId/:moveId/floor-planner',
            key: 'FLOOR_PLANNER',
            component: (props) => (
              <AdminLayout>
                <FloorPlanner />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/clients/:clientId/:locationId/:moveId/documents',
            key: 'DOCUMENTS',
            component: (props) => (
              <AdminLayout>
                <Documents />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/clients/:clientId/:locationId/:moveId/timeline',
            key: 'MOVE_TIMELINE',
            component: () => (
              <AdminLayout>
                <MoveTimeline />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/clients/:clientId/:locationId/:moveId/contacts',
            key: 'MOVE_CONTACTS',
            component: () => (
              <AdminLayout>
                <MoveContacts />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/clients/:clientId/:locationId/:moveId/details',
            key: 'MOVE_DETAILS',
            component: () => (
              <AdminLayout>
                <MoveDetails />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          /* BROKERING */
          {
            path: '/admin/clients/:clientId/create-brokering',
            key: 'CREATE_BROKERING_SERVICE',
            component: () => (
              <AdminLayout>
                <CreateBrokeringProject />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
            restrictExternal: true,
          },
          {
            path: '/admin/clients/:clientId/:brokeringId/brokering-dashboard',
            key: 'BROKERING_DASHBOARD',
            component: () => (
              <AdminLayout>
                <BrokeringDashboard />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/clients/:clientId/:leaseId/edit-lease',
            key: 'EDIT_LEASE',
            exact: true,
            isProtected: true,
            restrictExternal: true,
            component: () => (
              <AdminLayout>
                <EditLease />
              </AdminLayout>
            ),
          },
          {
            path: '/admin/clients/:clientId/:brokeringId/timeline',
            key: 'BROKERING_TIMELINE',
            component: () => (
              <AdminLayout>
                <BrokeringTimeline />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/clients/:clientId/:brokeringId/edit',
            key: 'EDIT_BROKERING_PROJECT',
            component: () => (
              <AdminLayout>
                <EditBrokeringProject />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
            restrictExternal: true,
          },
          {
            path: '/admin/clients/:clientId/:locationId/:moveId/edit-move',
            key: 'EDIT_LOCATION_MOVE_SERVICE',
            component: () => (
              <AdminLayout>
                <EditMoveLocationService />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
            restrictExternal: true,
          },
          {
            path: '/admin/clients/:clientId/:leaseId/reminders',
            key: 'REMINDERS',
            component: () => (
              <AdminLayout>
                <Reminders />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
            restrictExternal: true,
          },
        ],
      },
      /* LOCATIONS */
      {
        path: '/admin/locations',
        key: 'LOCATIONS',
        component: RenderRoutes,
        isProtected: true,
        routes: [
          {
            path: '/admin/locations',
            key: 'LOCATIONS_ROOT',
            component: () => (
              <AdminLayout>
                <ListLocations />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
            restrictExternal: true,
          },
          {
            path: '/admin/locations/:clientId/:locationId/create-move',
            key: 'CREATE_LOCATION_SERVICE',
            component: () => (
              <AdminLayout>
                <MoveLocationService />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
            restrictExternal: true,
          },
        ],
      },
      /* TEMPLATES */
      {
        path: '/admin/templates',
        key: 'TEMPLATES',
        component: RenderRoutes,
        isProtected: true,
        restrictExternal: true,
        restrictInternalSuper: true,
        routes: [
          {
            path: '/admin/templates',
            key: 'TEMPLATES_ROOT',
            component: () => (
              <AdminLayout>
                <ListTemplates />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/templates/new',
            key: 'CREATE_TEMPLATE',
            component: () => (
              <AdminLayout>
                <CreateTemplate />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/templates/:templateId/edit',
            key: 'EDIT_TEMPLATE',
            component: () => (
              <AdminLayout>
                <EditTemplate />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
        ],
      },
      /* USERS */
      {
        path: '/admin/users',
        key: 'USERS',
        component: RenderRoutes,
        isProtected: true,
        restrictExternal: true,
        restrictInternalSuper: false,
        routes: [
          {
            path: '/admin/users/',
            key: 'USERS_LIST',
            component: () => (
              <AdminLayout>
                <ListUsers />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/users/create',
            key: 'CREATE_USER',
            component: () => (
              <AdminLayout>
                <CreateUser />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
          {
            path: '/admin/users/:userId',
            key: 'EDIT_USER',
            component: () => (
              <AdminLayout>
                <EditUser />
              </AdminLayout>
            ),
            isProtected: true,
            exact: true,
          },
        ],
      },
    ],
  },
];
