import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { observer } from 'mobx-react';
import React, { useCallback, useContext } from 'react';
import { ServiceContext } from '../../context/ServicesContext';
import { MoveContactForm } from './MoveContactForm';
import { CustomButton } from '../../components/CustomButton';
import { TranslationContext } from '../../context/TranslationContext';
import { ILocationMove, IMove, IMoveContactPayload } from '../../services/ApiServiceV1';
import { NotificationContext } from '../../context/NotificationContext';
import { FormikHelpers } from 'formik';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface ICreateMoveContactProps {
  move: IMove | ILocationMove;
}

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(2),
  },
  cancelButton: {
    marginTop: theme.spacing(1),
  },
}));

const CreateMoveContact: React.FunctionComponent<ICreateMoveContactProps> = observer(({ move }) => {
  const {
    stores: { moveContactsUiStore, moveContactsStore },
  } = useContext(ServiceContext);
  const { translate } = useContext(TranslationContext);
  const { notificationDispatch } = useContext(NotificationContext);

  const { formContainer } = useStyles();

  const onSubmit = useCallback(
    async (values: IMoveContactPayload, helpers: FormikHelpers<IMoveContactPayload>) => {
      try {
        await moveContactsStore.create(move.id, values);
        helpers.resetForm();
        moveContactsUiStore.toggleShouldShowAddMoveContactForm();
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully created new move contact'),
            color: 'success',
          },
        });
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to create new move contact'),
            color: 'error',
          },
        });
      }
    },
    [move.id, moveContactsStore, moveContactsUiStore, notificationDispatch, translate],
  );

  return (
    <Collapse in={moveContactsUiStore.shouldShowAddMoveContactForm}>
      <Paper className={formContainer}>
        <Box p={2}>
          <MoveContactForm onSubmit={onSubmit} />
          <Box mt={1}>
            <CustomButton
              variant="outlined"
              color="default"
              onClick={() => moveContactsUiStore.toggleShouldShowAddMoveContactForm()}
            >
              {translate('cancel')}
            </CustomButton>
          </Box>
        </Box>
      </Paper>
    </Collapse>
  );
});

export { CreateMoveContact };
