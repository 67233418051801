import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { RouteMapContext } from '../context/RouteMapContext';
import { TranslationContext } from '../context/TranslationContext';
import { Link } from 'react-router-dom';

const RestrictedAccess: React.FunctionComponent = () => {
  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  return (
    <Box mt={50}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            {translate('restricted access')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography paragraph={true} align="center">
            <Trans key="click {{here}} to be transferred home">
              Click <Link to={getPathWithProps('ROOT')}>here</Link> to be transferred home
            </Trans>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export { RestrictedAccess };
