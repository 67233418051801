import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
  sortHorizontalArrows: {
    display: 'inline-flex',
  },
  sortCell: {
    display: 'inline-flex',
    alignItems: 'center',
  },
});

interface ISortedCellContentProps {
  onUpClickHandler: () => void;
  onDownClickHandler: () => void;
  selectedUp: boolean;
  selectedDown: boolean;
}

export const SortedCellContent: React.FunctionComponent<ISortedCellContentProps> = ({
  children,
  onUpClickHandler,
  onDownClickHandler,
  selectedUp,
  selectedDown,
}) => {
  const { sortCell, sortHorizontalArrows } = useStyles();
  return (
    <div className={sortCell}>
      <span>{children}</span>
      <div className={sortHorizontalArrows}>
        <IconButton size="small" onClick={onUpClickHandler}>
          <ArrowDropUpIcon fontSize="small" color={selectedUp ? 'secondary' : 'inherit'} />
        </IconButton>
        <IconButton size="small" onClick={onDownClickHandler}>
          <ArrowDropDownIcon fontSize="small" color={selectedDown ? 'secondary' : 'inherit'} />
        </IconButton>
      </div>
    </div>
  );
};
