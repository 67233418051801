import { action, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';
import { IApiServiceV1, IReminderPayload } from '../../services/ApiServiceV1';
import { RootStore } from '../RootStore';
import { ReminderDomainStore } from './ReminderDomainStore';

class LeaseRemindersStore {
  @observable
  remoteReminders: RemoteData<ReminderDomainStore[]> = RemoteData.NOT_ASKED;

  leaseId: string | undefined;

  constructor(private apiService: IApiServiceV1, private rootStore: RootStore) {}

  @action
  async fetch(leaseId: string, forceFetch = false) {
    if (
      RemoteData.isReady(this.remoteReminders) &&
      this.leaseId !== undefined &&
      this.leaseId === leaseId &&
      !forceFetch
    ) {
      return;
    }

    this.leaseId = leaseId;
    this.remoteReminders = RemoteData.LOADING;

    try {
      const leaseReminders = await this.apiService.fetchLeaseReminders(leaseId);
      runInAction(() => {
        this.remoteReminders = leaseReminders.map((leaseReminder) => new ReminderDomainStore(leaseReminder));
      });
    } catch (error) {
      runInAction(() => {
        this.remoteReminders = RemoteData.failWith(error);
      });
    }
  }

  @action
  async create(leaseId: string, payload: IReminderPayload) {
    const leaseReminder = await this.apiService.addLeaseReminder(leaseId, payload);
    runInAction(() => {
      if (RemoteData.isReady(this.remoteReminders)) {
        this.remoteReminders = [...this.remoteReminders, new ReminderDomainStore(leaseReminder)];
      }
    });
    this.rootStore.clientLeasesStore.increaseLeaseRemindersCount(leaseId);
  }

  @action
  async update(reminderId: string, payload: IReminderPayload) {
    const updatedLeaseReminder = await this.apiService.updateLeaseReminder(reminderId, payload);
    runInAction(() => {
      if (RemoteData.isReady(this.remoteReminders)) {
        this.remoteReminders = this.remoteReminders.map((reminder) => {
          if (reminder.id === updatedLeaseReminder.id) {
            reminder.updateReminderData(updatedLeaseReminder);
          }
          return reminder;
        });
      }
    });
  }

  @action
  async delete(reminderId: string, leaseId: string) {
    await this.apiService.deleteLeaseReminder(reminderId);
    runInAction(() => {
      if (RemoteData.isReady(this.remoteReminders)) {
        this.remoteReminders = this.remoteReminders.filter((reminder) => reminder.id !== reminderId);
      }
    });
    this.rootStore.clientLeasesStore.decreaseLeaseRemindersCount(leaseId);
  }
}

export { LeaseRemindersStore };
