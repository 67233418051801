import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DrawerContent from './DrawerContent';
import clsx from 'clsx';
import { ReactComponent as Logo } from '../../assets/images/fmg-logo-new.svg';

export const drawerWidth = 220;

interface IMobileDrawer {
  isMobileOpen: boolean;
  handleDrawerToggle: () => void;
  isDrawerOpen: boolean;
}

const useMenuStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: drawerWidth,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

export const MobileMenu: React.FunctionComponent<IMobileDrawer> = ({
  isMobileOpen,
  handleDrawerToggle,
  isDrawerOpen,
}) => {
  const container = window !== undefined ? () => window.document.body : undefined;
  const { drawer, drawerPaper, toolbar } = useMenuStyles();
  return (
    <Drawer
      variant="temporary"
      // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
      className={drawer}
      classes={{
        paper: drawerPaper,
      }}
      open={isMobileOpen}
      onClose={handleDrawerToggle}
      container={container}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div>
        <div className={toolbar} />
        <DrawerContent isDrawerOpen={isDrawerOpen} />
      </div>
    </Drawer>
  );
};

interface ILargeScreenMenu {
  isDrawerOpen: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}

const useLargeScreenMenuStyles = makeStyles((theme: Theme) => ({
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  whiteColor: {
    color: theme.palette.common.white,
  },
  drawerContainer: {
    height: '100vh',
  },
  logo: {
    marginLeft: theme.spacing(1),
  },
}));

export const LargeScreenMenu: React.FunctionComponent<ILargeScreenMenu> = ({
  isDrawerOpen,
  handleDrawerOpen,
  handleDrawerClose,
}) => {
  const theme = useTheme();
  const { drawerOpen, drawerClose, whiteColor, drawerContainer, logo } = useLargeScreenMenuStyles();
  const { drawer, drawerPaper, toolbar } = useMenuStyles();
  return (
    <Drawer
      variant="permanent"
      open
      className={clsx(drawer, {
        [drawerOpen]: isDrawerOpen,
        [drawerClose]: !isDrawerOpen,
      })}
      classes={{
        paper: clsx(drawerPaper, {
          [drawerOpen]: isDrawerOpen,
          [drawerClose]: !isDrawerOpen,
        }),
      }}
    >
      <div className={drawerContainer}>
        <div className={toolbar}>
          {isDrawerOpen && (
            <Logo className={logo} fill={theme.palette.common.white} stroke={theme.palette.common.white} />
          )}
          <IconButton
            onClick={() => {
              isDrawerOpen ? handleDrawerClose() : handleDrawerOpen();
            }}
          >
            {isDrawerOpen ? (
              <ChevronLeftIcon classes={{ root: whiteColor }} />
            ) : (
              <ChevronRightIcon classes={{ root: whiteColor }} />
            )}
          </IconButton>
        </div>
        <DrawerContent isDrawerOpen={isDrawerOpen} />
      </div>
    </Drawer>
  );
};
