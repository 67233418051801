import React from 'react';
import { useContext } from 'react';
import { ServiceContext } from '../../context/ServicesContext';
import { useParams } from 'react-router-dom';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import RemoteData from 'ts-remote-data';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { ButtonLink } from '../../components/ButtonLink';
import { TranslationContext } from '../../context/TranslationContext';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { LocationInformationBox } from './LocationInformationBox';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { RouteMapContext } from '../../context/RouteMapContext';
import { MoveInformationBox } from './MoveInformationBox';
import { Header } from '../../components/typography/Header';
import { SubNavigationBox } from '../../components/SubNavigationBox';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import { shouldDenyExternalUserAccess } from '../../utils/shouldAllowExternalUserAccess';
import { RestrictedAccess } from '../../components/RestrictedAccess';
import { NavigationBreadcrumbs } from '../../components/NavigationBreadcrumbs';
import { observer } from 'mobx-react';
import { MoveTimelineBox } from './MoveTimelineBox';

interface IUrlParams {
  clientId: string;
  locationId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  locationTitle: {
    textTransform: 'uppercase',
  },
  tabStyled: {
    textTransform: 'uppercase',
  },
  tabContainer: {
    marginBottom: theme.spacing(3),
  },
  headerContainer: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& h1': {
        marginBottom: theme.spacing(2),
      },
    },
  },
  moveTabContentSectionContainer: {
    marginTop: theme.spacing(3),
  },
}));

function a11yProps(index: number) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      flex={1}
    >
      {value === index && children}
    </Box>
  );
}

const LocationDashboard: React.FunctionComponent = observer(() => {
  const { tabStyled, headerContainer, tabContainer, moveTabContentSectionContainer } = useStyles();
  const {
    stores: { clientStore, locationStore },
  } = useContext(ServiceContext);
  const { translate } = useContext(TranslationContext);
  const { remoteUser } = useContext(AuthenticationContext);
  const { clientId, locationId } = useParams<IUrlParams>();

  useAsyncOperation(() => {
    return clientStore.fetchClientData(clientId);
  }, [clientId]);

  useAsyncOperation(() => {
    return locationStore.fetch(clientId, locationId);
  }, [locationId]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const theme = useTheme();
  const isSmallUp = useMediaQuery(theme.breakpoints.only('sm'));
  const { getPathWithProps } = useContext(RouteMapContext);

  return (
    <RemoteSuspense
      data={RemoteData.all(locationStore.remoteLocation, clientStore.remoteClient, remoteUser)}
      loadingFallback={<LinearProgress />}
      failureFallback={
        <Typography variant="h5" color="error">
          {translate('failed to fetch data')}
        </Typography>
      }
    >
      {([location, client, user]) => {
        return shouldDenyExternalUserAccess(user, client) ? (
          <RestrictedAccess />
        ) : (
          <div>
            <NavigationBreadcrumbs
              items={[
                {
                  url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                  content: client.name,
                },
                {
                  content: location.name,
                },
              ]}
            />
            <Grid container justify="space-between" alignItems="center" className={headerContainer}>
              <Header>{location.name}</Header>
              {location.services.move === null && (
                <ButtonLink
                  color="secondary"
                  variant="contained"
                  to={getPathWithProps('CREATE_LOCATION_SERVICE', { clientId, locationId })}
                >
                  {translate('create location service')}
                </ButtonLink>
              )}
              <LocationInformationBox client={client} location={location} user={user} />
              {location.services.move && (
                <>
                  <div style={{ marginTop: '24px', width: '100%' }}></div>
                  <MoveInformationBox
                    location={location}
                    client={client}
                    moveId={location.services.move.id}
                    user={user}
                  />
                  <Grid container className={moveTabContentSectionContainer} spacing={2}>
                    <Grid item xs={12} sm={12} md={8} />
                    <Grid item xs={12} sm={12} md={6}>
                      <SubNavigationBox move={location.services.move} location={location} client={client} />
                    </Grid>
                    <MoveTimelineBox client={client} location={location} move={location.services.move} />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        );
      }}
    </RemoteSuspense>
  );
});

export { LocationDashboard };
