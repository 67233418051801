import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: theme.spacing(1),
  },
  subheader: {
    color: theme.palette.text.primary,
  },
}));

export interface ISubtextInfoItemProps {
  label: string;
}

const SubtextInfoItem: React.FunctionComponent<ISubtextInfoItemProps> = ({ label, children }) => {
  const { container, subheader } = useStyles();
  return (
    <Grid item className={container}>
      <Typography variant="body2">
        <span className={subheader}>{label}: </span>
        {children}
      </Typography>
    </Grid>
  );
};

export { SubtextInfoItem };
