import React, { useContext } from 'react';
import { FormikFormInput } from '../../components/FormikInputs';
import { Formik, Form } from 'formik';
import { AddFaqPayload } from '../../services/ApiServiceV1';
import * as yup from 'yup';
import { TranslationContext } from '../../context/TranslationContext';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    '& > button': {
      marginTop: theme.spacing(3),
    },
    '& button:first-of-type': {
      marginRight: theme.spacing(3),
    },
  },
}));

interface IAddFAQRowProps {
  handleClick: (values: AddFaqPayload) => Promise<void>;
  dialogTitle?: string;
  primaryButtonLabel: string;
  cancelLabel: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const initialValues: AddFaqPayload = {
  answer: '',
  question: '',
};

function AddFAQRow(props: IAddFAQRowProps) {
  const { handleClick, primaryButtonLabel, cancelLabel, isOpen, setIsOpen } = props;
  const { translate } = useContext(TranslationContext);
  const { formContainer } = useStyles();

  return (
    <Collapse in={isOpen} timeout="auto">
      <Formik
        initialValues={initialValues}
        onSubmit={handleClick}
        validationSchema={yup.object({
          question: yup.string().required(translate('field is required')),
          answer: yup.string().required(translate('field is required')),
        })}
      >
        {({ isSubmitting }) => (
          <Form className={formContainer}>
            <Grid container>
              <Grid item sm={9} lg={9} xs={12}>
                <Box py={1.5}>
                  <FormikFormInput
                    name="question"
                    formikKey="question"
                    fullWidth={true}
                    labelContent={translate('question')}
                    rows={1}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </Box>
                <Box py={1.5}>
                  <FormikFormInput
                    name="answer"
                    formikKey="answer"
                    multiline={true}
                    fullWidth={true}
                    labelContent={translate('answer')}
                    rows={5}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </Box>
                <Box py={1.5}>
                  <Button disabled={isSubmitting} type="submit" color="secondary" variant="contained">
                    {primaryButtonLabel}
                  </Button>
                  <Button onClick={() => setIsOpen(false)} color="secondary" variant="outlined">
                    {cancelLabel}
                  </Button>
                </Box>
              </Grid>
              <Grid item sm={3} lg={3} xs={11}></Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Collapse>
  );
}

export default AddFAQRow;
