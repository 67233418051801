import React, { FunctionComponent, useEffect, useContext, useState, createContext } from 'react';
import { IAuthenticationServiceNormalizedError } from '../services/AuthenticationService';
import { ServiceContext } from './ServicesContext';
import RemoteData from 'ts-remote-data';
import { useRef } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { IUser } from '../services/ApiServiceV1';
import { isBackendUrl } from '../utils/getBackendBaseUrl';
import { Role } from '../domain/Role';

interface IAuthenticationContextProps {
  remoteIsAuthenticated: RemoteData<boolean>;
  login: () => Promise<void>;
  logout: () => void;
  getAccessToken: () => Promise<void>;
  authenticationError: IAuthenticationServiceNormalizedError | undefined;
  remoteAccessToken: RemoteData<string>;
  remoteGraphAPIAccessToken: RemoteData<string>;
  remoteUser: RemoteData<IUser>;
  remotePhoto: RemoteData<string>;
  contextError: Error | undefined;
  accountRoles: RemoteData<Role[]>;
}

const AuthenticationContext = createContext({} as IAuthenticationContextProps);

const AuthenticationProvider: FunctionComponent = ({ children }) => {
  const { authenticationService, apiServiceV1, httpService } = useContext(ServiceContext);

  const [remoteAccessToken, setRemoteAccessToken] = useState<RemoteData<string>>(RemoteData.NOT_ASKED);
  const [remoteGraphAPIAccessToken, setRemoteGraphAPIAccessToken] = useState<RemoteData<string>>(RemoteData.NOT_ASKED);
  const [remoteIsAuthenticated, setRemoteIsAuthenticated] = useState<RemoteData<boolean>>(RemoteData.NOT_ASKED);
  const [remoteUser, setRemoteUser] = useState<RemoteData<IUser>>(RemoteData.NOT_ASKED);
  const [remotePhoto, setRemotePhoto] = useState<RemoteData<string>>(RemoteData.NOT_ASKED);
  const [authenticationContextError, setAuthenticationContextError] = useState<undefined | Error>();
  const [accountRoles, setAccountRoles] = useState<RemoteData<Role[]>>(RemoteData.NOT_ASKED);

  const isMountedRef = useRef<boolean | null>(null);

  async function fetchGraphApiToken() {
    const account = authenticationService.getAccount();
    if (account) {
      setRemoteGraphAPIAccessToken(RemoteData.LOADING);
      try {
        const graphApiToken = await authenticationService.getGraphApiAccessToken();
        httpService.setDocumentInstanceAuthToken(graphApiToken);
        if (isMountedRef.current) {
          setRemoteGraphAPIAccessToken(graphApiToken);
        }
      } catch (error) {
        if (isMountedRef.current) {
          setRemoteGraphAPIAccessToken(RemoteData.fail());
        }
      }
    }
  }

  async function fetchAccessToken() {
    const account = authenticationService.getAccount();
    if (account) {
      try {
        setAccountRoles((account.idTokenClaims as { roles: Role[] }).roles || []);
        setRemoteIsAuthenticated(RemoteData.LOADING);

        setRemoteAccessToken(RemoteData.LOADING);
        setRemoteGraphAPIAccessToken(RemoteData.LOADING);
        setRemoteUser(RemoteData.LOADING);

        const accessToken = await authenticationService.getAccessToken();

        httpService.setInstanceAuthToken(accessToken);

        const user = await apiServiceV1.fetchUserDetails();

        if (isMountedRef.current) {
          setRemoteAccessToken(accessToken);
          setRemoteIsAuthenticated(true);
          setRemoteUser(user);
        }
      } catch (error) {
        if (isMountedRef.current) {
          setRemoteAccessToken(RemoteData.failWith(authenticationService.getLatestError()));
          setRemoteIsAuthenticated(false);
        }
      }
    } else {
      if (isMountedRef.current) {
        setRemoteIsAuthenticated(false);
        const latestError = authenticationService.getLatestError();
        setRemoteAccessToken(RemoteData.failWith(latestError ? latestError : 'Not signed in'));
      }
    }
  }

  httpService.instance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response.request.responseURL) {
        // check if the respone url belong to the backend api
        if (isBackendUrl(response.request.responseURL) && httpService.tokenRefreshRetryAmount > 0) {
          httpService.setTokenRefreshRetryAmount(0);
        }
      }
      return response;
    },
    async function (error: AxiosError) {
      if (error.isAxiosError) {
        if (
          error.response?.status === 401 &&
          httpService.tokenRefreshRetryAmount < httpService.tokenRefreshRetryLimit
        ) {
          try {
            const accessToken = await authenticationService.getAccessToken();
            httpService.setInstanceAuthToken(accessToken);
            setRemoteAccessToken(accessToken);
            error.config.headers['Authorization'] = `Bearer ${accessToken}`;
            httpService.setTokenRefreshRetryAmount(httpService.tokenRefreshRetryAmount + 1);
            return httpService.instance.request(error.config);
          } catch (error) {
            setRemoteAccessToken(RemoteData.failWith(authenticationService.getLatestError()));
            setRemoteIsAuthenticated(false);
          }
        } else if (
          error.response?.status === 401 &&
          httpService.tokenRefreshRetryAmount === httpService.tokenRefreshRetryLimit
        ) {
          setRemoteIsAuthenticated(false);
          setAuthenticationContextError(new Error('Backend does not accept token or is not responding'));
        }
      }

      return Promise.reject(error);
    },
  );

  httpService.documentInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error: AxiosError) {
      if (error.isAxiosError) {
        if (error.response?.status === 401) {
          try {
            const accessToken = await authenticationService.getGraphApiAccessToken();
            httpService.setDocumentInstanceAuthToken(accessToken);
            setRemoteGraphAPIAccessToken(accessToken);
            error.config.headers['Authorization'] = `Bearer ${accessToken}`;
            return httpService.documentInstance.request(error.config);
          } catch (error) {
            setRemoteGraphAPIAccessToken(RemoteData.failWith(authenticationService.getLatestError()));
          }
        }
      }

      return Promise.reject(error);
    },
  );

  useEffect(() => {
    isMountedRef.current = true;
    async function fetchUserImage() {
      try {
        const photoData = await apiServiceV1.fetchUserImage('120x120');
        setRemotePhoto(photoData);
      } catch (error) {
        setRemotePhoto(RemoteData.fail());
      }
    }

    fetchAccessToken().then(() => {
      fetchUserImage();
      fetchGraphApiToken();
    });

    return () => {
      isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function logout() {
    authenticationService.logout();
    setRemoteAccessToken(RemoteData.NOT_ASKED);
  }

  async function getAccessToken() {
    await fetchAccessToken();
  }

  async function login() {
    try {
      await authenticationService.login();

      await fetchAccessToken();
      fetchGraphApiToken();
    } catch (error) {
      // console.error(error);
    }
  }

  return (
    <AuthenticationContext.Provider
      value={{
        remoteIsAuthenticated,
        getAccessToken,
        login,
        logout,
        authenticationError: authenticationService.latestError,
        remoteAccessToken,
        remoteGraphAPIAccessToken,
        remoteUser,
        remotePhoto,
        contextError: authenticationContextError,
        accountRoles,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationProvider, AuthenticationContext };
