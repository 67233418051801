import { useEffect } from 'react';
import { useState } from 'react';
import RemoteData from 'ts-remote-data';

interface IUseFetchRemoteData<T> {
  fetchData: () => Promise<T>;
}

export function useFetchRemoteData<T>({ fetchData }: IUseFetchRemoteData<T>) {
  const [remoteData, setRemoteData] = useState<RemoteData<T>>(RemoteData.NOT_ASKED);
  useEffect(() => {
    async function fetchRemoteData() {
      setRemoteData(RemoteData.LOADING);
      try {
        const data = await fetchData();
        setRemoteData(data);
      } catch (error) {
        setRemoteData(RemoteData.fail());
      }
    }

    fetchRemoteData();
  }, [fetchData]);

  return {
    remoteData,
    setRemoteData,
  };
}
