import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { TranslationContext } from '../../context/TranslationContext';
import { Formik, Form, FormikHelpers } from 'formik';
import { FormikFormInput, FormikSelect, FormikCheckbox } from '../FormikInputs';
import { CustomButton } from '../CustomButton';
import { IClient, IClientPayload } from '../../services/ApiServiceV1';
import { ServiceContext } from '../../context/ServicesContext';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as yup from 'yup';
import { observer } from 'mobx-react';

interface IClientFormProps {
  client?: IClient;
  onSubmit: (payload: IClientPayload, formikBag: FormikHelpers<IClientPayload>) => Promise<void>;
}

const ClientForm: React.FunctionComponent<IClientFormProps> = observer(({ client, onSubmit }) => {
  const {
    stores: { countriesStore },
  } = useContext(ServiceContext);
  const { translate } = useContext(TranslationContext);

  useAsyncOperation(() => {
    return countriesStore.fetch();
  }, [countriesStore]);

  return (
    <Formik
      initialValues={{
        name: client ? client.name : '',
        orgNumber: client ? client.orgNumber : '',
        active: client ? client.active : false,
        countryId: client ? client.country.id : '',
      }}
      onSubmit={async (values: IClientPayload, formikBag) => {
        onSubmit(values, formikBag);
      }}
      validationSchema={yup.object({
        name: yup.string().required(translate('field is required')),
        orgNumber: yup.string().required(translate('field is required')),
        countryId: yup.string().required(translate('field is required')),
      })}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <FormikFormInput
                formikKey="name"
                labelContent={translate('name')}
                formControlProps={{ fullWidth: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <FormikFormInput
                formikKey="orgNumber"
                labelContent={translate('organisation number')}
                formControlProps={{ fullWidth: true }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <FormikSelect
                formikKey="countryId"
                labelContent={translate('country')}
                native={true}
                variant="outlined"
                formControlProps={{
                  fullWidth: true,
                }}
              >
                <option>{translate('please select')}</option>
                <RemoteSuspense data={countriesStore.remoteCountries} loadingFallback={<LinearProgress />}>
                  {(countries) =>
                    countries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))
                  }
                </RemoteSuspense>
              </FormikSelect>
            </Grid>
            <Grid item xs={12}>
              <FormikCheckbox formikKey="active" labelContent={translate('is active')} color="primary" />
            </Grid>

            <Grid item>
              <CustomButton type="submit" variant="contained" color="secondary" disabled={isSubmitting}>
                {translate('save')}
              </CustomButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
});

export default ClientForm;
