import React, { createContext } from 'react';
import { generatePath, matchPath } from 'react-router-dom';
import { matchRoute } from '../utils/routeUtils';

export type RouteKeys =
  | 'ROOT'
  | 'ADMIN_ROOT'
  | 'ADMIN'
  | 'CLIENTS'
  | 'CLIENTS_ROOT'
  | 'ADD_CLIENT'
  | 'CLIENT_DASHBOARD'
  | 'EDIT_CLIENT'
  | 'CLIENT_LOCATION'
  | 'CLIENT_LOCATION_EDIT'
  | 'CREATE_CLIENT_LOCATION'
  | 'LOCATIONS'
  | 'LOCATIONS_ROOT'
  | 'CREATE_LOCATION_SERVICE'
  | 'EDIT_LOCATION_MOVE_SERVICE'
  | 'TEMPLATES'
  | 'TEMPLATES_ROOT'
  | 'CREATE_TEMPLATE'
  | 'EDIT_TEMPLATE'
  | 'MOVE_PAGES'
  | 'CREATE_MOVE_PAGE'
  | 'EDIT_PAGE'
  | 'MOVE_FEEDBACK'
  | 'FLOOR_PLANNER'
  | 'MOVE_FAQs'
  | 'MOVE_TIMELINE'
  | 'MOVES'
  | 'USERS'
  | 'USERS_LIST'
  | 'EDIT_USER'
  | 'CREATE_USER'
  | 'NO_CLIENT'
  | 'BROKERING'
  | 'CREATE_BROKERING_SERVICE'
  | 'BROKERING_DASHBOARD'
  | 'EDIT_BROKERING_PROJECT'
  | 'BROKERING_TIMELINE'
  | 'CREATE_LEASE'
  | 'EDIT_LEASE'
  | 'REMINDERS'
  | 'MOVE_CONTACTS'
  | 'MOVE_DETAILS'
  | 'DOCUMENTS';

export interface IAppRouteComponentProps {
  routes?: IAppRoute[];
}

export interface IAppRoute {
  path: string;
  key: RouteKeys;
  exact?: boolean;
  component: (props: IAppRouteComponentProps) => JSX.Element | null;
  routes?: IAppRoute[];
  isProtected: boolean;
  restrictExternal?: boolean;
  restrictInternalSuper?: boolean;
}

export type GetPathWithProps = <T>(key: RouteKeys, params?: T) => string;

interface IRouteMapContextProps {
  routes: IAppRoute[];
  matchRoute: (key: RouteKeys, routes: IAppRoute[]) => IAppRoute | undefined;
  getPathWithProps: GetPathWithProps;
}

const RouteMapContext = createContext<IRouteMapContextProps>({} as IRouteMapContextProps);

interface IRouteMapContextProviderProps {
  routes: IAppRoute[];
}

const RouteMapContextProvider: React.FunctionComponent<IRouteMapContextProviderProps> = ({ routes, children }) => {
  function getPathWithProps<T>(key: RouteKeys, params?: T) {
    const matchResult = matchRoute(key, routes);
    if (matchResult) {
      const matchedPath = matchPath(matchResult.path, {
        path: matchResult.path,
      });

      if (matchedPath) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return generatePath(matchResult.path, params as any);
      }
    }
    return '/404';
  }

  return (
    <RouteMapContext.Provider value={{ routes, matchRoute, getPathWithProps }}>{children}</RouteMapContext.Provider>
  );
};

export { RouteMapContextProvider, RouteMapContext };
