import React, { useEffect, useContext, useCallback } from 'react';
import { ServiceContext } from '../../context/ServicesContext';
import { observer } from 'mobx-react';
import { ButtonLink } from '../../components/ButtonLink';
import { RouteMapContext } from '../../context/RouteMapContext';
import { TranslationContext } from '../../context/TranslationContext';
import { SectionContainer } from '../../components/containers/SectionContainer';
import { Header } from '../../components/typography/Header';
import { HeaderContainer } from '../../components/containers/HeaderContainer';
import { TemplateMovePageTableRowContent } from '../../components/TemplateMovePageTableRowContent';
import { NotificationContext } from '../../context/NotificationContext';
import RemoteData from 'ts-remote-data';
import { ITemplate } from '../../services/ApiServiceV1';
import { ModalContext } from '../../context/ModalContext';
import { TableContainer } from '@material-ui/core';

const ListTemplates: React.FunctionComponent = observer(() => {
  const { translate } = useContext(TranslationContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { getPathWithProps } = useContext(RouteMapContext);

  const {
    apiServiceV1,
    stores: { templatesStore },
  } = useContext(ServiceContext);

  const { setIsModalOpen } = useContext(ModalContext);

  useEffect(() => {
    async function fetchTemplates() {
      await templatesStore.fetchTemplates(true);
    }
    fetchTemplates();
  }, [apiServiceV1, templatesStore]);

  const handleDelete = useCallback(
    async (itemId: string) => {
      try {
        await templatesStore.deleteTemplate(itemId);
        setIsModalOpen(false);
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to remove a move page'),
          },
        });
      }
    },
    [notificationDispatch, setIsModalOpen, templatesStore, translate],
  );

  const handlePositionUpdate = useCallback(
    async (itemId: string, position: number) => {
      try {
        await templatesStore.updateTemplatePosition(itemId, position);
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to remove a move page'),
          },
        });
      }
    },
    [notificationDispatch, templatesStore, translate],
  );
  const handleAvailableForInternalChange = useCallback(
    async (itemId: string, availableForInternal: boolean) => {
      try {
        await templatesStore.updateAvailableForInternal(itemId, availableForInternal);
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to remove a move page'),
          },
        });
      }
    },
    [notificationDispatch, templatesStore, translate],
  );
  const handleAvailableForCustomerChange = useCallback(
    async (itemId: string, availableForCustomer: boolean) => {
      try {
        await templatesStore.updateAvailableForCustomer(itemId, availableForCustomer);
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to remove a move page'),
          },
        });
      }
    },
    [notificationDispatch, templatesStore, translate],
  );

  return (
    <>
      <HeaderContainer>
        <Header>{translate('templates')}</Header>
        <ButtonLink variant="contained" color="secondary" to={getPathWithProps<{}>('CREATE_TEMPLATE', {})}>
          {translate('create new template')}
        </ButtonLink>
      </HeaderContainer>
      <SectionContainer>
        <TableContainer>
          <TemplateMovePageTableRowContent
            remoteItems={templatesStore.remoteTemplates as RemoteData<ITemplate[]>}
            handleDelete={handleDelete}
            handleAvailableForInternalChange={handleAvailableForInternalChange}
            handleAvailableForCustomerChange={handleAvailableForCustomerChange}
            handlePositionUpdate={handlePositionUpdate}
            url="EDIT_TEMPLATE"
            dialogText={translate("This item will be deleted immediately. You can't undo this action")}
            dialogPrimaryButtonLabel={translate('delete')}
            dialogCancelLabel={translate('cancel')}
            dialogTitle={translate('are you sure you want to delete ')}
          />
        </TableContainer>
      </SectionContainer>
    </>
  );
});

export { ListTemplates };
