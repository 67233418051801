import React, { useEffect, useContext, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { IFAQ, IFAQPayload } from '../../services/ApiServiceV1';
import { IsFAQs } from '../../utils/isFAQs';
import { observer } from 'mobx-react';
import { HeaderContainer } from '../../components/containers/HeaderContainer';
import { Header } from '../../components/typography/Header';
import { SectionContainer } from '../../components/containers/SectionContainer';
import RemoteData from 'ts-remote-data';
import { ModalContext } from '../../context/ModalContext';
import { NotificationContext } from '../../context/NotificationContext';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import FAQTable from './FAQTable';
import AddFAQRow from './AddFAQRow';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ClientUserWrapper } from '../../components/ClientUserWrapper';
import { NavigationBreadcrumbs } from '../../components/NavigationBreadcrumbs';
import { RouteMapContext } from '../../context/RouteMapContext';

const ListFAQs: React.FunctionComponent = observer(() => {
  const { clientId, locationId } = useParams<{ moveId: string; clientId: string; locationId: string }>();
  const { translate } = useContext(TranslationContext);
  const {
    stores: { locationStore },
  } = useContext(ServiceContext);
  const { getPathWithProps } = useContext(RouteMapContext);

  useAsyncOperation(() => {
    return locationStore.fetch(clientId, locationId);
  }, [clientId, locationId]);

  return (
    <ClientUserWrapper
      render={(client) => {
        return (
          <>
            <RemoteSuspense data={locationStore.remoteLocation}>
              {(location) => (
                <NavigationBreadcrumbs
                  items={[
                    {
                      content: client.name,
                      url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                    },
                    {
                      content: location.name,
                      url: getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
                        clientId: client.id,
                        locationId: location.id,
                      }),
                    },
                    {
                      content: translate("FAQ's"),
                    },
                  ]}
                />
              )}
            </RemoteSuspense>

            <HeaderContainer>
              <Header>{translate('frequently asked question')}</Header>
            </HeaderContainer>
            <FaqListImpl />
          </>
        );
      }}
    />
  );
});

export type OnFaqPublished = (faq: IFAQ, published: boolean) => Promise<void>;

const FaqListImpl: React.FunctionComponent = observer(() => {
  const { moveId } = useParams<{ moveId: string; clientId: string; locationId: string }>();
  const { translate } = useContext(TranslationContext);
  const { setIsModalOpen } = useContext(ModalContext);
  const { notificationDispatch } = useContext(NotificationContext);

  const [isAddQuestion, setIsAddQuestion] = useState(false);

  const {
    stores: { FAQsStore },
  } = useContext(ServiceContext);

  useEffect(() => {
    FAQsStore.fetchFAQs(moveId);
  }, [FAQsStore, moveId]);

  const handleDelete = useCallback(
    async (FAQid: string) => {
      await FAQsStore.deleteFAQ(FAQid);
      setIsModalOpen(false);
      if (RemoteData.isFailure(FAQsStore.remoteFAQs)) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to delete FAQ'),
          },
        });
      } else {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully deleted'),
            color: 'success',
          },
        });
      }
    },
    [FAQsStore, notificationDispatch, setIsModalOpen, translate],
  );

  const handleSave = useCallback(
    async (values: IFAQPayload, id?: string) => {
      if (!id) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to update FAQ'),
          },
        });
      }
      try {
        await FAQsStore.updateFAQ(id as string, values);
        setIsModalOpen(false);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully updated faq'),
            color: 'success',
          },
        });
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to update FAQ'),
          },
        });
      }
    },
    [FAQsStore, notificationDispatch, setIsModalOpen, translate],
  );

  const handlePublishedToggle: OnFaqPublished = useCallback(
    async (faq: IFAQ, published: boolean) => {
      try {
        await FAQsStore.updateFAQ(faq.id, {
          question: faq.question,
          answer: faq.answer,
          published,
        });
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully updated faq'),
            color: 'success',
          },
        });
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to update FAQ'),
            color: 'error',
          },
        });
      }
    },
    [FAQsStore, notificationDispatch, translate],
  );

  const handleOnDragEnd = useCallback(
    async (result: DropResult) => {
      if (result.destination) {
        await FAQsStore.updateFAQposition(moveId, {
          faqQuestionId: result.draggableId,
          newPosition: result.destination.index,
        });

        if (RemoteData.isFailure(FAQsStore.remoteFAQs)) {
          notificationDispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              color: 'error',
              message: translate('failed to reaarange FAQ'),
            },
          });
        } else {
          notificationDispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              message: translate('successfully rearranged'),
              color: 'success',
            },
          });
        }
      }
      return;
    },
    [FAQsStore, notificationDispatch, translate, moveId],
  );

  const handleAdd = useCallback(
    async (values: Omit<IFAQPayload, 'published'>) => {
      await FAQsStore.addFAQ(moveId, {
        ...values,
        published: false,
      });
      setIsAddQuestion(false);
      if (RemoteData.isFailure(FAQsStore.remoteFAQs)) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to add question'),
          },
        });
      } else {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully added'),
            color: 'success',
          },
        });
      }
    },
    [FAQsStore, notificationDispatch, translate, moveId],
  );
  return (
    <SectionContainer>
      <Box pb={1.5} pt={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setIsAddQuestion(!isAddQuestion);
          }}
        >
          {translate('add question')}
        </Button>
        <AddFAQRow
          handleClick={handleAdd}
          primaryButtonLabel={translate('submit')}
          cancelLabel={translate('cancel')}
          isOpen={isAddQuestion}
          setIsOpen={setIsAddQuestion}
        />
      </Box>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <RemoteSuspense
          data={FAQsStore.remoteFAQs}
          failureFallback={(error: IFAQ[]) =>
            Array.isArray(error) && IsFAQs(error[0]) ? (
              <FAQTable
                handleDelete={handleDelete}
                handleSave={handleSave}
                FAQs={error}
                handlePublishedToggle={handlePublishedToggle}
              />
            ) : (
              <Typography color="error">{translate('fetching feedback failed')}</Typography>
            )
          }
          loadingFallback={<LinearProgress />}
        >
          {(FAQs) => (
            <FAQTable
              handleDelete={handleDelete}
              handleSave={handleSave}
              FAQs={FAQs}
              handlePublishedToggle={handlePublishedToggle}
            />
          )}
        </RemoteSuspense>
      </DragDropContext>
    </SectionContainer>
  );
});

export default ListFAQs;
