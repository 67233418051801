import dayjs from 'dayjs';

import React, { createContext } from 'react';
import enLocale, { Locale } from 'dayjs/locale/en';
import noLocale from 'dayjs/locale/nb';
import deLocale from 'dayjs/locale/de';
import daLocale from 'dayjs/locale/da';
import seLocale from 'dayjs/locale/se';
import nnLocale from 'dayjs/locale/nn';
import { useContext } from 'react';

import { TranslationContext } from './TranslationContext';
import { Lang } from '../i18n';
import utc from 'dayjs/plugin/utc';

interface IDayjsContextProps {
  dayjsLocale: Locale;
}

export const localeMap: Record<Lang, Locale> = {
  en: enLocale,
  no: noLocale,
  nn: nnLocale,
  de: deLocale,
  da: daLocale,
  se: seLocale,
};

const DayjsContext = createContext<IDayjsContextProps>({} as IDayjsContextProps);

const DayjsProvider: React.FunctionComponent = ({ children }) => {
  const { locale } = useContext(TranslationContext);
  const dayjsLocale = localeMap[locale];
  dayjs.locale(dayjsLocale);
  dayjs.extend(utc);
  return <DayjsContext.Provider value={{ dayjsLocale }}>{children}</DayjsContext.Provider>;
};

export { DayjsContext, DayjsProvider };
