import { dateFormat } from '@fmg-packages/common-components';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import React, { useContext, useState, useCallback } from 'react';
import { ILease, IReminderPayload } from '../../services/ApiServiceV1';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TranslationContext } from '../../context/TranslationContext';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ReminderForm } from './ReminderForm';
import { ServiceContext } from '../../context/ServicesContext';
import { NotificationContext } from '../../context/NotificationContext';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { CustomButton } from '../../components/CustomButton';
import { ModalContext } from '../../context/ModalContext';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { ReminderDomainStore } from '../../stores/reminder/ReminderDomainStore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import SendIcon from '@material-ui/icons/Send';
import { Grid } from '@material-ui/core';

interface IReminderListItemProps {
  reminder: ReminderDomainStore;
  lease: ILease;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontWeight: theme.typography.fontWeightBold,
  },
  grayBackground: {
    backgroundColor: theme.palette.background.default,
  },
  deleteButton: {
    marginTop: theme.spacing(1),
  },
  alignRight: {
    marginRight: 'auto',
  },
}));

const ReminderListItem: React.FunctionComponent<IReminderListItemProps> = observer(({ reminder, lease }) => {
  const { translate } = useContext(TranslationContext);
  const { setModalState, setIsModalOpen } = useContext(ModalContext);
  const {
    stores: { clientStore },
    rootStore: { leaseRemindersStore },
  } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);

  const onUpdate = useCallback(
    async (values: IReminderPayload) => {
      try {
        await leaseRemindersStore.update(reminder.id, values);
        clientStore.invalidateRemoteLeaseReminders();
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully updated reminder'),
            color: 'success',
          },
        });
        setExpanded(false);
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to update reminder'),
            color: 'error',
          },
        });
      }
    },
    [clientStore, leaseRemindersStore, notificationDispatch, reminder.id, translate],
  );

  async function onReminderDelete() {
    try {
      await leaseRemindersStore.delete(reminder.id, lease.id);
      clientStore.invalidateRemoteLeaseReminders();
      notificationDispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          message: translate('successfully deleted reminder'),
          color: 'success',
        },
      });
    } catch (error) {
      notificationDispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          message: translate('failed to delete reminder'),
          color: 'error',
        },
      });
    }

    setIsModalOpen(false);
  }

  const [isExpanded, setExpanded] = useState(false);

  const { header, grayBackground, deleteButton, alignRight } = useStyles();

  return (
    <Accordion expanded={isExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        className={clsx(isExpanded && grayBackground)}
        onClick={() => {
          setExpanded((prevValue) => !prevValue);
        }}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Box display="flex" justifyContent="space-around" alignItems="center" width={`4rem`}>
              {reminder.done && <CheckCircleIcon fontSize="small" />}
              {reminder.unsent && <CancelScheduleSendIcon fontSize="small" />}
              {reminder.upcoming && <AccessAlarmIcon fontSize="small" />}
              {reminder.sentAt && <SendIcon fontSize="small" />}
            </Box>
          </Grid>
          <Grid item className={alignRight}>
            <Typography color="textPrimary" className={header}>
              {reminder.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="textPrimary">{reminder.dueDate.format(dateFormat)}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={grayBackground}>
        <Box width={1}>
          <ReminderForm onSubmit={onUpdate} reminder={reminder} />
          <CustomButton
            variant="contained"
            className={deleteButton}
            onClick={() => {
              setModalState(
                true,
                <ConfirmDialog
                  data={reminder.id}
                  handleClick={onReminderDelete}
                  dialogTitle={translate('are you sure you want to delete ')}
                  recordTitle={reminder.title}
                  content={translate("This item will be deleted immediately. You can't undo this action")}
                  primaryButtonLabel={translate('delete')}
                  cancelLabel={translate('cancel')}
                />,
              );
            }}
          >
            {translate('delete')}
          </CustomButton>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
});

export { ReminderListItem };
