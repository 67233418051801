import React from 'react';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import { Label } from '../Label';
import { IFormikField } from '.';
import { useField } from 'formik';

export const FormikRadioInput: React.FunctionComponent<IFormikField & RadioGroupProps & FormControlProps> = ({
  formikKey,
  children,
  labelContent,
  showLabel = false,
  ...props
}) => {
  const [field, meta, helper] = useField(formikKey);
  return (
    <FormControl error={meta.touched && Boolean(meta.error)}>
      {showLabel && <Label>{labelContent}</Label>}
      <RadioGroup
        name={field.name}
        onChange={(event) => {
          helper.setValue(parseInt(event.target.value));
        }}
        onBlur={field.onBlur}
        value={field.value}
        {...props}
      >
        {children}
      </RadioGroup>
      {meta.touched && meta.error && (
        <Box py={1}>
          <Typography color="error">{meta.error}</Typography>
        </Box>
      )}
    </FormControl>
  );
};
