import { useState } from 'react';

type Nullable<T> = T | null;

export const useModal = (initialModalState = false, initialModalContent = null) => {
  const [isModalOpen, setIsModalOpen] = useState(initialModalState);
  const [modalContent, setModalContent] = useState<Nullable<JSX.Element>>(initialModalContent);

  const setModalState = (state: boolean, content: Nullable<JSX.Element>) => {
    setIsModalOpen(state);
    if (state === false) {
      setModalContent(null);
    }
    setModalContent(content);
  };

  return {
    isModalOpen,
    setIsModalOpen,
    modalContent,
    setModalState,
  };
};
