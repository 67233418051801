import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Label } from '../Label';
import FormSelect from '../FormSelect';
import { FormikSelectProps } from '.';
import { useField } from 'formik';

export const FormikSelect: React.FunctionComponent<FormikSelectProps> = ({
  formikKey,
  children,
  labelContent,
  formControlProps,
  ...props
}) => {
  const [field, meta] = useField(formikKey);

  return (
    <FormControl error={meta.touched && Boolean(meta.error)} {...formControlProps}>
      <Label>{labelContent}</Label>
      <FormSelect name={field.name} onChange={field.onChange} onBlur={field.onBlur} value={field.value} {...props}>
        {children}
      </FormSelect>
      {meta.touched && meta.error && (
        <Box py={1}>
          <Typography color="error">{meta.error}</Typography>
        </Box>
      )}
    </FormControl>
  );
};
