import React, { ChangeEvent, ChangeEventHandler, useContext, useState } from 'react';
import { ServiceContext } from '../context/ServicesContext';
import { FormikAutocomplete, FormikCheckbox, FormikFormInput, FormikRadioInput, FormikSelect } from './FormikInputs';
import { TranslationContext } from '../context/TranslationContext';
import { Form, Formik, FormikHelpers } from 'formik';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { CustomButton } from './CustomButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { IMove, IBackendUser, IUser, IMovePayload, AuthorizationScheme } from '../services/ApiServiceV1';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as yup from 'yup';
import RemoteData from 'ts-remote-data';
import { observer } from 'mobx-react';
import { UserDomainStore } from '../stores/UserDomainStore';
import { Lang } from '../i18n';
import { getTranslatedLanguages } from '../locales/locales';
import { getBackendBaseUrl, getRelokatorPathLabel } from '../utils/getBackendBaseUrl';
import colors from '../utils/colors';
import styled from 'styled-components';
import { FormikChipInput } from './FormikInputs/FormikChipInput';
import { FormControlLabel, Radio } from '@material-ui/core';
import { BlockPicker, ColorResult } from 'react-color';
import { useEffect } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { ModalContext } from '../context/ModalContext';
import { ConfirmDialog } from './ConfirmDialog';

interface IMoveServiceFormProps {
  onSubmit: (values: IMovePayload, formikHelpers: FormikHelpers<IMoveServiceFormData>) => Promise<void>;
  move?: IMove;
  clientContact?: IBackendUser[];
  teamMembers?: UserDomainStore[];
  onLogoChange?: (logo: File | null) => Promise<void>;
  onBackgroundChange?: (background: File | null) => Promise<void>;
}

interface ILanguageFormData {
  language: Lang;
  languageName: string;
}

export interface IMoveServiceFormData {
  languages: ILanguageFormData[];
  defaultLanguage: Lang;
  password: string;
  stickersCount: number;
  clientContact: IBackendUser[];
  teamMembers: IBackendUser[];
  path: string;
  file?: File;
  authorizationScheme: AuthorizationScheme;
  whitelistedDomains: string[];
  hideFloorSelection: boolean;
  isEnabled: boolean;
}

const mainStyles = makeStyles((theme: Theme) => ({
  padTop24: {
    paddingTop: '24px',
  },
}));

const MoveServiceForm: React.FunctionComponent<IMoveServiceFormProps> = observer(
  ({ onSubmit, move, clientContact, teamMembers, onLogoChange, onBackgroundChange }) => {
    const {
      apiServiceV1,
      stores: { clientStore },
    } = useContext(ServiceContext);
    useEffect(() => {
      const getColors = async () => {
        if (move) {
          await clientStore.getColors(move.id);
          setPrimaryColor(clientStore.primaryColor);
          setSecondaryColor(clientStore.secondaryColor);
          setDarkerPrimaryColor(clientStore.darkerPrimaryColor);
          setDarkerSecondaryColor(clientStore.darkerSecondaryColor);
          setBackgroundColor(clientStore.backgroundColor);
          setHeadingColor(clientStore.headingColor);
        }
      };
      getColors();
    }, [clientStore, move]);
    const { translate } = useContext(TranslationContext);
    const [relocatorUrl, setRelocatorUrl] = useState(move?.path ? getRelokatorPathLabel(move.path) : '');
    const [primaryColor, setPrimaryColor] = useState(clientStore.primaryColor);
    const [secondaryColor, setSecondaryColor] = useState(clientStore.secondaryColor);
    const [darkerPrimaryColor, setDarkerPrimaryColor] = useState(clientStore.darkerPrimaryColor);
    const [darkerSecondaryColor, setDarkerSecondaryColor] = useState(clientStore.darkerSecondaryColor);
    const [backgroundColor, setBackgroundColor] = useState(clientStore.backgroundColor);
    const [headingColor, setHeadingColor] = useState(clientStore.headingColor);
    const [showNotification, setShowNotification] = useState(clientStore.showNotification);
    const [notificationText, setNotificationText] = useState(clientStore.notificationText);
    const [notificationColor, setNotificationColor] = useState<Color>(clientStore.notificationColor);
    const { setModalState, setIsModalOpen } = useContext(ModalContext);

    const remoteExternalUsers = useAsyncOperation<RemoteData<IUser[]>>(() => {
      if (RemoteData.isReady(clientStore.remoteClient)) {
        return apiServiceV1.fetchExternalUsers(clientStore.remoteClient.id);
      }
      return Promise.resolve(RemoteData.LOADING);
    }, [apiServiceV1]);
    const remoteInternalUsers = useAsyncOperation(() => apiServiceV1.fetchInternalUsers(), [apiServiceV1]);

    const languages = getTranslatedLanguages(translate).map<ILanguageFormData>((sourceLanguage) => ({
      language: sourceLanguage.language,
      languageName: sourceLanguage.translatedLanguageName,
    }));

    const baseLogoUrl = `${getBackendBaseUrl()}/v1/moves/${move?.id}/logo`;
    const [currentLogoUrl, setCurrentLogoUrl] = useState<string>(baseLogoUrl);

    const baseBackgroundUrl = `${getBackendBaseUrl()}/v1/moves/${move?.id}/background`;
    const [currentBackgroundUrl, setCurrentBackgroundUrl] = useState<string>(baseBackgroundUrl);

    const onLogoSelected = async (e: ChangeEvent<HTMLInputElement>) => {
      if (!onLogoChange) {
        return;
      }

      const selectedFile = e.target.files?.item(0) ?? null;
      await onLogoChange(selectedFile);
      setCurrentLogoUrl(`${baseLogoUrl}?${Date.now()}`);
    };

    const onBackgroundSelected = async (e: ChangeEvent<HTMLInputElement>) => {
      if (!onBackgroundChange) {
        return;
      }

      const selectedFile = e.target.files?.item(0) ?? null;
      await onBackgroundChange(selectedFile);
      setCurrentBackgroundUrl(`${baseBackgroundUrl}?${Date.now()}`);
    };

    const displayNotification = () => {
      setShowNotification(true);
      setNotificationColor(clientStore.notificationColor);
      setNotificationText(clientStore.notificationText);
    };

    const { padTop24 } = mainStyles();

    const updatePrimaryColor = async (color?: ColorResult) => {
      if (color) {
        if (move) {
          setPrimaryColor(color.hex);
          await clientStore.updatePrimaryColor(color.hex, move.id).then(() => displayNotification());
        }
      } else setPrimaryColor('');
    };
    const updateSecondaryColor = async (color?: ColorResult) => {
      if (color) {
        if (move) {
          setSecondaryColor(color.hex);
          await clientStore.updateSecondaryColor(color.hex, move.id).then(() => displayNotification());
        }
      } else setSecondaryColor('');
    };
    const updateDarkerPrimaryColor = async (color?: ColorResult) => {
      if (color) {
        if (move) {
          setDarkerPrimaryColor(color.hex);
          clientStore.updateDarkerPrimaryColor(color.hex, move.id).then(() => displayNotification());
        }
      } else setDarkerPrimaryColor('');
    };
    const updateDarkerSecondaryColor = (color?: ColorResult) => {
      if (color) {
        if (move) {
          setDarkerSecondaryColor(color.hex);
          clientStore.updateDarkerSecondaryColor(color.hex, move.id).then(() => displayNotification());
        }
      } else setDarkerSecondaryColor('');
    };
    const updateBackgroundColor = (color?: ColorResult) => {
      if (color) {
        if (move) {
          setBackgroundColor(color.hex);
          clientStore.updateBackgroundColor(color.hex, move.id).then(() => displayNotification());
        }
      } else setBackgroundColor('');
    };
    const updateHeadingColor = (color?: ColorResult) => {
      if (color) {
        if (move) {
          setHeadingColor(color.hex);
          clientStore.updateHeadingColor(color.hex, move.id).then(() => displayNotification());
        }
      } else setHeadingColor('');
    };

    const clearColors = async () => {
      if (move) {
        setIsModalOpen(false);
        await clientStore.clearColors(move.id).then(() => displayNotification());
        setPrimaryColor('');
        setSecondaryColor('');
        setDarkerPrimaryColor('');
        setDarkerSecondaryColor('');
        setBackgroundColor('');
        setHeadingColor('');
      }
    };
    const clearColor = async (color: string) => {
      if (move) {
        setIsModalOpen(false);
        switch (color) {
          case 'headingColor':
            setHeadingColor('');
            clientStore.updateHeadingColor('', move.id).then(() => displayNotification());
            break;
          case 'backgroundColor':
            setBackgroundColor('');
            clientStore.updateBackgroundColor('', move.id).then(() => displayNotification());
            break;
          case 'primaryColor':
            setPrimaryColor('');
            clientStore.updatePrimaryColor('', move.id).then(() => displayNotification());
            break;
          case 'darkerPrimaryColor':
            setDarkerPrimaryColor('');
            clientStore.updateDarkerPrimaryColor('', move.id).then(() => displayNotification());
            break;
          case 'secondaryColor':
            setSecondaryColor('');
            clientStore.updateSecondaryColor('', move.id).then(() => displayNotification());
            break;
          case 'darkerSecondaryColor':
            setDarkerSecondaryColor('');
            clientStore.updateDarkerSecondaryColor('', move.id).then(() => displayNotification());
            break;
        }
      }
    };

    const clearColorsButtonDisabled =
      !primaryColor &&
      !secondaryColor &&
      !darkerPrimaryColor &&
      !darkerSecondaryColor &&
      !backgroundColor &&
      !headingColor;

    const initialValues: IMoveServiceFormData = {
      password: move ? move.password : '',
      stickersCount: move ? move.stickersCount : 0,
      clientContact: move && clientContact && clientContact.length ? clientContact : ([] as IBackendUser[]),
      teamMembers: move && teamMembers ? teamMembers.map((teamMember) => teamMember.user.user) : [],
      path: move ? move.path : '',
      languages: move
        ? languages
            .filter((lang) => move.languages.find((moveLanguage) => moveLanguage === lang.language))
            .map(({ language, languageName }) => ({
              language,
              languageName,
            }))
        : [],
      defaultLanguage: move?.defaultLanguage || ('' as Lang),
      authorizationScheme: move?.authorizationScheme ?? AuthorizationScheme.EmailWithPassword,
      whitelistedDomains: move ? move.whitelistedDomains : [],
      hideFloorSelection: move ? move.hideFloorSelection : false,
      isEnabled: move ? move.isEnabled : true,
    };
    const theme = useTheme();
    const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (values, formikHelpers) => {
            onSubmit(
              {
                ...values,
                languages: values.languages.map(({ language }) => language),
              },
              formikHelpers,
            );
          }}
          validationSchema={yup.object({
            password: yup.string().when(['authorizationScheme'], (authorizationScheme: AuthorizationScheme) => {
              return authorizationScheme !== AuthorizationScheme.Email
                ? yup.string().required(translate('field is required'))
                : yup.string().optional();
            }),
            path: yup
              .string()
              .required(translate('field is required'))
              .matches(/^[a-zA-Z0-9_-]+$/, translate('path can be letters numbers dash or underscore')),
            languages: yup.array().min(1, translate('minimum amount is {{minimum}}', { minimum: 1 })),
            defaultLanguage: yup.string().required(translate('field is required')),
            whitelistedDomains: yup
              .array()
              .when(['authorizationScheme'], (authorizationScheme: AuthorizationScheme) => {
                return authorizationScheme !== AuthorizationScheme.Password
                  ? yup.array().min(1).required(translate('field is required'))
                  : yup.array().min(0);
              }),
          })}
        >
          {({ values, isSubmitting, setFieldValue }) => {
            return (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={4}>
                    <FormikFormInput
                      formikKey="path"
                      labelContent={translate('path')}
                      formControlProps={{ fullWidth: true }}
                      onChangeCb={(value) => {
                        setRelocatorUrl(value === '' ? '' : getRelokatorPathLabel(value));
                      }}
                    />
                    {relocatorUrl}
                    <br></br>
                    <br></br>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormikCheckbox
                        formikKey="isEnabled"
                        labelContent={translate('active_accessible')}
                        color="primary"
                      />
                    </Grid>
                    <FormikRadioInput
                      formikKey="authorizationScheme"
                      showLabel={true}
                      labelContent={translate('authorization scheme')}
                    >
                      <FormControlLabel
                        value={AuthorizationScheme.EmailWithPassword}
                        control={<Radio />}
                        label={translate('password and email')}
                      />
                      <FormControlLabel
                        value={AuthorizationScheme.Email}
                        control={<Radio />}
                        label={translate('email only')}
                      />
                      <FormControlLabel
                        value={AuthorizationScheme.Password}
                        control={<Radio />}
                        label={translate('password only')}
                      />
                    </FormikRadioInput>
                    <br></br>
                    <br></br>
                    {values.authorizationScheme !== AuthorizationScheme.Password && (
                      <>
                        <FormikChipInput
                          formikKey="whitelistedDomains"
                          labelContent={translate('whitelisted domains eg')}
                          formControlProps={{ fullWidth: true }}
                        />
                        <br></br>
                        <br></br>
                      </>
                    )}
                    {values.authorizationScheme !== AuthorizationScheme.Email && (
                      <FormikFormInput
                        formikKey="password"
                        labelContent={translate('password')}
                        formControlProps={{ fullWidth: true }}
                      />
                    )}
                  </Grid>
                  {isSmallerThanSm === false && <Grid item sm={8} />}
                  <Grid item xs={12} sm={12} md={4}>
                    <FormikAutocomplete
                      data={languages}
                      isMultiple={true}
                      formikKey="languages"
                      labelContent={translate('languages')}
                      formControlProps={{ fullWidth: true }}
                      optionLabelKey="languageName"
                      onChangeCb={(event, value) => {
                        if (value.length === 1) {
                          setFieldValue('defaultLanguage', value[0].language);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <FormikSelect
                      formikKey="defaultLanguage"
                      labelContent={translate('default language')}
                      native
                      variant="outlined"
                      formControlProps={{ fullWidth: true }}
                    >
                      {values.languages.map(({ language, languageName }) => (
                        <option key={language} value={language}>
                          {languageName}
                        </option>
                      ))}
                    </FormikSelect>
                  </Grid>
                  {isSmallerThanSm === false && <Grid item sm={4} />}
                  <RemoteSuspense data={remoteInternalUsers} loadingFallback={<LinearProgress />}>
                    {(internalUsers) => {
                      return (
                        <Grid item xs={12} sm={12} md={8}>
                          <FormikAutocomplete
                            formikKey="teamMembers"
                            labelContent={translate('team members')}
                            data={internalUsers}
                            optionLabelKey="displayName"
                            formControlProps={{ fullWidth: true }}
                            isMultiple={true}
                          />
                        </Grid>
                      );
                    }}
                  </RemoteSuspense>
                  <RemoteSuspense data={remoteExternalUsers} loadingFallback={<LinearProgress />}>
                    {(externalUsers) => {
                      const users = externalUsers.map((user) => user.user);
                      return (
                        <Grid item xs={12} sm={12} md={8}>
                          <FormikAutocomplete
                            data={users}
                            optionLabelKey="displayName"
                            formikKey="clientContact"
                            labelContent={translate('main client contact')}
                            formControlProps={{ fullWidth: true }}
                            isMultiple={true}
                          />
                        </Grid>
                      );
                    }}
                  </RemoteSuspense>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormikCheckbox
                      formikKey="hideFloorSelection"
                      labelContent={translate('hide floor selection')}
                      color="primary"
                    />
                  </Grid>
                </Grid>
                <br></br>
                <br></br>
                <br></br>
                <h3>Customize color themes</h3>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <label className={logoStyles().logoLabel}>{translate('heading color uppercase')}</label>
                    <BlockPicker color={headingColor} onChange={updateHeadingColor} triangle="hide" />
                    <CustomButton
                      color="secondary"
                      disabled={!headingColor}
                      variant="contained"
                      onClick={() => {
                        setModalState(
                          true,
                          <ConfirmDialog
                            data={'headingColor'}
                            handleClick={() => clearColor('headingColor')}
                            dialogTitle={translate('Are you sure you want to clear') + translate('heading color') + '?'}
                            content={translate("You can't undo this action")}
                            primaryButtonLabel={translate('UppercaseClear')}
                            cancelLabel={translate('cancel')}
                          />,
                        );
                      }}
                    >
                      {translate('UppercaseClear') + ' ' + translate('heading color')}
                    </CustomButton>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <label className={logoStyles().logoLabel}>{translate('background color uppercase')}</label>
                    <BlockPicker color={backgroundColor} onChange={updateBackgroundColor} triangle="hide" />
                    <CustomButton
                      color="secondary"
                      disabled={!backgroundColor}
                      variant="contained"
                      onClick={() => {
                        setModalState(
                          true,
                          <ConfirmDialog
                            data={'backgroundColor'}
                            handleClick={() => clearColor('backgroundColor')}
                            dialogTitle={
                              translate('Are you sure you want to clear') + translate('background color') + '?'
                            }
                            content={translate("You can't undo this action")}
                            primaryButtonLabel={translate('UppercaseClear')}
                            cancelLabel={translate('cancel')}
                          />,
                        );
                      }}
                    >
                      {translate('UppercaseClear') + ' ' + translate('background color')}
                    </CustomButton>
                  </Grid>{' '}
                </Grid>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <label className={logoStyles().logoLabel}>{translate('primary color uppercase')}</label>
                    <BlockPicker color={primaryColor} onChange={updatePrimaryColor} triangle="hide" />
                    <CustomButton
                      color="secondary"
                      disabled={!primaryColor}
                      variant="contained"
                      onClick={() => {
                        setModalState(
                          true,
                          <ConfirmDialog
                            data={'primaryColor'}
                            handleClick={() => clearColor('primaryColor')}
                            dialogTitle={translate('Are you sure you want to clear') + translate('primary color') + '?'}
                            content={translate("You can't undo this action")}
                            primaryButtonLabel={translate('UppercaseClear')}
                            cancelLabel={translate('cancel')}
                          />,
                        );
                      }}
                    >
                      {translate('UppercaseClear') + ' ' + translate('primary color')}
                    </CustomButton>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <label className={logoStyles().logoLabel}>{translate('darker primary color uppercase')}</label>
                    <BlockPicker color={darkerPrimaryColor} onChange={updateDarkerPrimaryColor} triangle="hide" />
                    <CustomButton
                      color="secondary"
                      disabled={!darkerPrimaryColor}
                      variant="contained"
                      onClick={() => {
                        setModalState(
                          true,
                          <ConfirmDialog
                            data={'darkerPrimaryColor'}
                            handleClick={() => clearColor('darkerPrimaryColor')}
                            dialogTitle={
                              translate('Are you sure you want to clear') + translate('darker primary color') + '?'
                            }
                            content={translate("You can't undo this action")}
                            primaryButtonLabel={translate('UppercaseClear')}
                            cancelLabel={translate('cancel')}
                          />,
                        );
                      }}
                    >
                      {translate('UppercaseClear') + ' ' + translate('darker primary color')}
                    </CustomButton>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <label className={logoStyles().logoLabel}>{translate('secondary color uppercase')}</label>
                    <BlockPicker color={secondaryColor} onChange={updateSecondaryColor} triangle="hide" />
                    <CustomButton
                      color="secondary"
                      disabled={!secondaryColor}
                      variant="contained"
                      onClick={() => {
                        setModalState(
                          true,
                          <ConfirmDialog
                            data={'secondaryColor'}
                            handleClick={() => clearColor('secondaryColor')}
                            dialogTitle={
                              translate('Are you sure you want to clear') + translate('secondary color') + '?'
                            }
                            content={translate("You can't undo this action")}
                            primaryButtonLabel={translate('UppercaseClear')}
                            cancelLabel={translate('cancel')}
                          />,
                        );
                      }}
                    >
                      {translate('UppercaseClear') + ' ' + translate('secondary color')}
                    </CustomButton>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <label className={logoStyles().logoLabel}>{translate('darker secondary color uppercase')}</label>
                    <BlockPicker color={darkerSecondaryColor} onChange={updateDarkerSecondaryColor} triangle="hide" />
                    <CustomButton
                      color="secondary"
                      disabled={!darkerSecondaryColor}
                      variant="contained"
                      onClick={() => {
                        setModalState(
                          true,
                          <ConfirmDialog
                            data={'darkerSecondaryColor'}
                            handleClick={() => clearColor('darkerSecondaryColor')}
                            dialogTitle={
                              translate('Are you sure you want to clear') + translate('darker secondary color') + '?'
                            }
                            content={translate("You can't undo this action")}
                            primaryButtonLabel={translate('UppercaseClear')}
                            cancelLabel={translate('cancel')}
                          />,
                        );
                      }}
                    >
                      {translate('UppercaseClear') + ' ' + translate('darker secondary color')}
                    </CustomButton>
                  </Grid>
                </Grid>
                <br></br>
                <CustomButton
                  color="secondary"
                  disabled={clearColorsButtonDisabled}
                  variant="contained"
                  onClick={() => {
                    setModalState(
                      true,
                      <ConfirmDialog
                        data={'backgroundColor'}
                        handleClick={clearColors}
                        dialogTitle={translate('Are you sure you want to clear') + translate('all custom colors') + '?'}
                        content={translate("You can't undo this action")}
                        primaryButtonLabel={translate('UppercaseClear')}
                        cancelLabel={translate('cancel')}
                      />,
                    );
                  }}
                >
                  {translate('UppercaseClear') + ' ' + translate('all custom colors')}
                </CustomButton>
                <br></br>
                <Box pt={2} className={padTop24}>
                  <MoveLogoInput url={currentLogoUrl} onChange={onLogoSelected} title="Logo" />
                </Box>
                <Box pt={2} className={padTop24}>
                  <MoveLogoInput url={currentBackgroundUrl} onChange={onBackgroundSelected} title="Background image" />
                </Box>
                {translate('recommendations about image')}
                <Box pt={2} className={padTop24}>
                  <CustomButton color="secondary" disabled={isSubmitting} variant="contained" type="submit">
                    {translate('save')}
                  </CustomButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
        <Snackbar
          open={showNotification}
          autoHideDuration={3000}
          onClose={() => {
            setShowNotification(false);
          }}
        >
          <Alert variant="filled" severity={notificationColor}>
            {notificationText}
          </Alert>
        </Snackbar>
      </div>
    );
  },
);

const logoStyles = makeStyles((theme: Theme) => ({
  logoBox: {
    paddingTop: '24px',
  },
  moveLogo: {
    margin: '0',
    '& h1': {
      paddingBottom: theme.spacing(2),
    },
  },
  logoContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
  },
  logoLabel: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: 1,
    marginBottom: '8px',
  },
}));

const FileUploadLabel = styled.label`
  display: inline-block;
`;

const FileUploadInput = styled.input`
  display: none;
`;

const LogoThumb = styled.div`
  height: 200px;
  width: 200px;
  border: 1px solid rgba(163, 163, 163);
  border-radius: 4px;
  background-color: ${colors.WHITE};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export interface IRrenderLogoProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  url: string;
  title: string;
}

const MoveLogoInput: React.FunctionComponent<IRrenderLogoProps> = ({ url, onChange, title }) => {
  const { moveLogo: logoInputLabel, logoContainer, logoLabel } = logoStyles();

  return (
    <div className={logoContainer}>
      <label className={logoLabel}>{title}</label>
      <FileUploadLabel className={logoInputLabel}>
        <LogoThumb style={{ backgroundImage: `url(${url})` }} />
        <FileUploadInput type="file" accept=".jpg,.jpeg,.png,.svg" onChange={onChange} />
      </FileUploadLabel>
    </div>
  );
};

export { MoveServiceForm };
