import { observable, action, runInAction } from 'mobx';
import { ICountry, IApiServiceV1 } from '../services/ApiServiceV1';
import RemoteData from 'ts-remote-data';

class CountryStore {
  _apiService: IApiServiceV1;
  @observable remoteCountries: RemoteData<ICountry[]> = RemoteData.NOT_ASKED;

  constructor(apiService: IApiServiceV1) {
    this._apiService = apiService;
  }

  @action
  async fetch(forceFetch = false) {
    if (RemoteData.isReady(this.remoteCountries) && !forceFetch) {
      return;
    }
    this.remoteCountries = RemoteData.LOADING;
    try {
      const countries = await this._apiService.fetchCountries();
      runInAction(() => {
        this.remoteCountries = countries;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteCountries = RemoteData.fail();
      });
    }
  }
}

export { CountryStore };
