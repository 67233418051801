import { Typography } from '@material-ui/core';
import React from 'react';
import { useContext } from 'react';
import { CustomButton } from '../../components/CustomButton';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import { TranslationContext } from '../../context/TranslationContext';

const NoClient: React.FunctionComponent = () => {
  const { translate } = useContext(TranslationContext);
  const { logout } = useContext(AuthenticationContext);
  return (
    <>
      <Typography variant="h5">{translate('no assigned client')}</Typography>
      <CustomButton variant="contained" color="secondary" onClick={logout}>
        {translate('sign out')}
      </CustomButton>
    </>
  );
};

export { NoClient };
