import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { RemoteSuspense } from 'ts-remote-data-react';
import { AuthenticationContext } from '../context/AuthenticationContext';
import { ServiceContext } from '../context/ServicesContext';
import { useFetchClient } from '../hooks/useFetchClient';
import { IClient, IUser } from '../services/ApiServiceV1';
import { shouldDenyExternalUserAccess } from '../utils/shouldAllowExternalUserAccess';
import { RestrictedAccess } from './RestrictedAccess';

interface IClientUserWrapperRenderProppedProps {
  render: (client: IClient, user: IUser) => React.ReactNode;
}

const ClientUserWrapper: React.FunctionComponent<IClientUserWrapperRenderProppedProps> = (props) => {
  const { clientId } = useParams<{ clientId: string }>();
  const {
    apiServiceV1,
    stores: { clientStore },
  } = useContext(ServiceContext);
  const { remoteUser } = useContext(AuthenticationContext);
  const remoteClient = useFetchClient(apiServiceV1, clientId, clientStore);
  return (
    <RemoteSuspense data={RemoteData.all(remoteClient, remoteUser)} loadingFallback={<LinearProgress />}>
      {([client, user]) =>
        shouldDenyExternalUserAccess(user, client) ? <RestrictedAccess /> : props.render(client, user)
      }
    </RemoteSuspense>
  );
};

export { ClientUserWrapper };
