import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { ServiceContext } from '../context/ServicesContext';
import { TranslationContext } from '../context/TranslationContext';
import { FormikAutocomplete, FormikFormInput } from './FormikInputs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import RemoteData from 'ts-remote-data';
import { CustomButton } from './CustomButton';
import { IBackendUser, IBrokering, IBrokeringPayload, IUser } from '../services/ApiServiceV1';
import Typography from '@material-ui/core/Typography';
import { observer } from 'mobx-react';
import * as yup from 'yup';

interface IBrokeringProjectFormProps {
  onSubmit: (values: IBrokeringPayload) => Promise<void>;
  brokeringProject?: IBrokering;
  clientContact?: IBackendUser;
  projectManager?: IBackendUser;
  teamMembers?: IBackendUser[];
  externalTeamMembers?: IBackendUser[];
}

const BrokeringProjectForm: React.FunctionComponent<IBrokeringProjectFormProps> = observer(
  ({ onSubmit, brokeringProject, clientContact, projectManager, teamMembers, externalTeamMembers }) => {
    const { translate } = useContext(TranslationContext);
    const {
      apiServiceV1,
      stores: { clientStore },
    } = useContext(ServiceContext);

    const remoteExternalUsers = useAsyncOperation<RemoteData<IUser[]>>(() => {
      if (RemoteData.isReady(clientStore.remoteClient)) {
        return apiServiceV1.fetchExternalUsers(clientStore.remoteClient.id);
      }
      return Promise.resolve(RemoteData.LOADING);
    }, [apiServiceV1, clientStore.remoteClient]);
    const remoteIntenalUsers = useAsyncOperation(() => {
      return apiServiceV1.fetchInternalUsers();
    }, [apiServiceV1]);

    const theme = useTheme();
    const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));
    const shouldCollapseSharepointFields = useMediaQuery(theme.breakpoints.up('lg'));
    const isMedium = useMediaQuery(theme.breakpoints.only('md'));

    const formInitialValues: IBrokeringPayload = {
      name: brokeringProject ? brokeringProject.name : '',
      clientContact: brokeringProject && clientContact ? clientContact : ((null as unknown) as IBackendUser),
      projectManager: brokeringProject && projectManager ? projectManager : ((null as unknown) as IBackendUser),
      teamMembers: brokeringProject && teamMembers ? teamMembers : [],
      externalTeamMembers: brokeringProject && externalTeamMembers ? externalTeamMembers : [],
      documentPrefix: brokeringProject && brokeringProject.documentPrefix ? brokeringProject.documentPrefix : '',
      documentProjectId:
        brokeringProject && brokeringProject.documentProjectId ? brokeringProject.documentProjectId : '',
    };

    return (
      <Formik
        initialValues={formInitialValues}
        onSubmit={onSubmit}
        validationSchema={yup.object({
          name: yup.string().required(translate('field is required')),
          teamMembers: yup.array().min(1, translate('minimum amount is {{minimum}}', { minimum: 1 })),
          documentPrefix: yup.string().required(translate('field is required')),
          documentProjectId: yup.string().required(translate('field is required')),
        })}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <FormikFormInput
                  formikKey="name"
                  labelContent={translate('project name')}
                  formControlProps={{ fullWidth: true }}
                />
              </Grid>
              {isSmallerThanSm === false && <Grid item md={6} />}
              <Grid item xs={12}>
                <Typography variant="h6">{translate('team members')}</Typography>
              </Grid>
              <RemoteSuspense
                data={remoteExternalUsers}
                loadingFallback={<LinearProgress />}
                failureFallback={
                  <Typography color="error">{translate('unable to fetch external user list')}</Typography>
                }
              >
                {(remoteUsers) => {
                  const users = remoteUsers.map((user) => user.user);
                  return (
                    <>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormikAutocomplete
                          data={users}
                          optionLabelKey="displayName"
                          formikKey="clientContact"
                          labelContent={translate('main client contact')}
                          formControlProps={{ fullWidth: true }}
                        />
                      </Grid>
                      {isSmallerThanSm === false && <Grid item md={6} />}
                      <Grid item xs={12} sm={12} md={6}>
                        <FormikAutocomplete
                          data={users}
                          isMultiple={true}
                          optionLabelKey="displayName"
                          formikKey="externalTeamMembers"
                          labelContent={translate('external team members')}
                          formControlProps={{ fullWidth: true }}
                        />
                      </Grid>
                      {isSmallerThanSm === false && <Grid item md={6} />}
                    </>
                  );
                }}
              </RemoteSuspense>
              <RemoteSuspense
                data={remoteIntenalUsers}
                loadingFallback={<LinearProgress />}
                failureFallback={
                  <Typography color="error">{translate('unable to fetch internal user list')}</Typography>
                }
              >
                {(internalUsers) => (
                  <>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormikAutocomplete
                        data={internalUsers}
                        optionLabelKey="displayName"
                        formikKey="projectManager"
                        labelContent={translate('project manager')}
                        formControlProps={{ fullWidth: true }}
                      />
                    </Grid>
                    {isSmallerThanSm === false && <Grid item md={6} />}
                    <Grid item xs={12} sm={12} md={6}>
                      <FormikAutocomplete
                        formikKey="teamMembers"
                        labelContent={translate('team members')}
                        data={internalUsers}
                        optionLabelKey="displayName"
                        formControlProps={{ fullWidth: true }}
                        isMultiple={true}
                      />
                    </Grid>
                    {isSmallerThanSm === false && <Grid item md={6} />}
                  </>
                )}
              </RemoteSuspense>
              <Grid item xs={12}>
                <Typography variant="h6">{translate('sharepoint connection')}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormikFormInput
                  formikKey="documentPrefix"
                  labelContent={translate('document prefix')}
                  formControlProps={{ fullWidth: true }}
                />
              </Grid>
              {isMedium && <Grid item md={6} />}
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <FormikFormInput
                  formikKey="documentProjectId"
                  labelContent={translate('project id')}
                  formControlProps={{ fullWidth: true }}
                />
              </Grid>
              {isMedium && <Grid item md={6} />}
              {shouldCollapseSharepointFields && <Grid item lg={6} />}
              <RemoteSuspense
                data={RemoteData.all(remoteExternalUsers, remoteIntenalUsers)}
                loadingFallback={<LinearProgress />}
              >
                {() => (
                  <Grid item>
                    <CustomButton variant="contained" color="secondary" type="submit" disabled={isSubmitting}>
                      {translate('save')}
                    </CustomButton>
                  </Grid>
                )}
              </RemoteSuspense>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  },
);

export { BrokeringProjectForm };
