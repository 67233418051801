import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link, { LinkProps } from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

type Items = {
  content: string;
  url?: string;
}[];

interface INavigationBreadcrumbs {
  items: Items;
}

interface ILinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: ILinkRouterProps) => <Link color="secondary" {...props} component={RouterLink} />;

const NavigationBreadcrumbs: React.FunctionComponent<INavigationBreadcrumbs> = ({ items }) => {
  return (
    <Breadcrumbs separator={<NavigateBeforeIcon fontSize="small" />}>
      {items.map((item, index) =>
        item.url ? (
          <LinkRouter key={index} to={item.url}>
            {item.content}
          </LinkRouter>
        ) : (
          <Typography key={index}>{item.content}</Typography>
        ),
      )}
    </Breadcrumbs>
  );
};

const NavigationBreadcrumbsWrapper: React.FunctionComponent<INavigationBreadcrumbs> = ({ items }) => {
  return (
    <Box mb={2}>
      <NavigationBreadcrumbs items={items} />
    </Box>
  );
};

export { NavigationBreadcrumbs, NavigationBreadcrumbsWrapper };
