import { action, computed, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';

import { IApiServiceV1, IMoveContact, IMoveContactOrderPosition, IMoveContactPayload } from '../services/ApiServiceV1';
import { compareValues } from '../utils/sort';

class MoveContactsStore {
  @observable
  remoteMoveContacts: RemoteData<IMoveContact[]> = RemoteData.NOT_ASKED;

  constructor(private apiService: IApiServiceV1) {}

  @action
  async fetch(moveId: string, forceFetch = false) {
    if (RemoteData.isReady(this.remoteMoveContacts) && !forceFetch) {
      return;
    }

    this.remoteMoveContacts = RemoteData.LOADING;

    try {
      const moveContacts = await this.apiService.fetchMoveContacts(moveId);
      runInAction(() => {
        this.remoteMoveContacts = moveContacts;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteMoveContacts = RemoteData.fail();
      });
    }
  }

  @action
  async create(moveId: string, payload: IMoveContactPayload) {
    const newMoveContact = await this.apiService.addMoveContact(moveId, payload);

    runInAction(() => {
      if (RemoteData.isReady(this.remoteMoveContacts)) {
        this.remoteMoveContacts.push(newMoveContact);
      }
    });
  }

  @action
  async update(moveContactId: string, payload: IMoveContactPayload) {
    const updatedContact = await this.apiService.updateMoveContact(moveContactId, payload);

    runInAction(() => {
      if (RemoteData.isReady(this.remoteMoveContacts)) {
        this.remoteMoveContacts = this.remoteMoveContacts.map((moveContact) => {
          if (moveContact.id === updatedContact.id) {
            return updatedContact;
          }
          return moveContact;
        });
      }
    });
  }

  @action
  async delete(moveContactId: string) {
    await this.apiService.deleteMoveContact(moveContactId);

    runInAction(() => {
      if (RemoteData.isReady(this.remoteMoveContacts)) {
        this.remoteMoveContacts = this.remoteMoveContacts.filter((moveContact) => moveContact.id !== moveContactId);
      }
    });
  }

  @computed
  get sortedRemoteContacts(): RemoteData<IMoveContact[]> {
    if (RemoteData.isReady(this.remoteMoveContacts)) {
      const moveContactsCopy = this.remoteMoveContacts.slice();
      return moveContactsCopy.sort(compareValues(['index'], 'asc'));
    } else {
      return [];
    }
  }

  @action
  async reorder(moveId: string, payload: IMoveContactOrderPosition) {
    this.remoteMoveContacts = RemoteData.LOADING;
    const reorderedMoveContacts = await this.apiService.reorderMoveContacts(moveId, payload);
    runInAction(() => {
      this.remoteMoveContacts = reorderedMoveContacts;
    });
  }
}

export { MoveContactsStore };
