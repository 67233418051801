import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { LinkProps, Link as ReactRouterLink } from 'react-router-dom';

const useStyles = makeStyles({
  linkStyle: {
    textDecoration: 'initial',
    textTransform: 'capitalize',
  },
});

const ButtonLink: React.FC<LinkProps & ButtonProps> = ({ component = Button, to, ...props }) => {
  const { linkStyle } = useStyles();

  const C = component;
  return (
    <ReactRouterLink to={to} className={linkStyle}>
      <C {...props} />
    </ReactRouterLink>
  );
};

export { ButtonLink };
