import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import React, { useEffect, useContext } from 'react';
import { ServiceContext } from '../../context/ServicesContext';
import { RemoteSuspense } from 'ts-remote-data-react';
import { Header } from '../../components/typography/Header';
import { TranslationContext } from '../../context/TranslationContext';
import FormSelect from '../../components/FormSelect';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { observer } from 'mobx-react';
import { StyledTableCell } from '../../components/StyledCell';
import { UserTableRow } from './UserTableRow';
import { PaginationTableContents } from '../../components/PaginationTableContents';
import { ButtonLink } from '../../components/ButtonLink';
import { HeaderContainer } from '../../components/containers/HeaderContainer';
import { SortedCellContent } from '../../components/SortedCellContent';
import { useCallback } from 'react';
import { RouteMapContext } from '../../context/RouteMapContext';
import { Typography } from '@material-ui/core';
import { IUser } from '../../services/ApiServiceV1';
import GenericSelect from '../../components/GenericSelect';
import GenericSearch from '../../components/GenericSearch';
import RemoteData from 'ts-remote-data';

const ListUsers: React.FunctionComponent = observer(() => {
  const {
    stores: { countriesStore, usersStore },
  } = useContext(ServiceContext);
  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);

  useEffect(() => {
    async function fetchUsers() {
      usersStore.fetch(true);
    }
    fetchUsers();
  }, [usersStore]);

  useEffect(() => {
    async function fetchCountries() {
      countriesStore.fetch();
    }

    fetchCountries();
  }, [countriesStore]);

  const onCountryChange = useCallback(
    (selectedValue: { value: string } | undefined) => {
      usersStore.setCountryId(selectedValue?.value);
    },
    [usersStore],
  );

  const onClientNameFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      usersStore.setCompanyNameFilter(e.target.value);
    },
    [usersStore],
  );

  const clientNameAscendingSortHandler = useCallback(() => {
    usersStore.setSort(['client.name', (u: IUser) => u.client?.name], 'asc');
  }, [usersStore]);
  const clientNameDescendingSortHandler = useCallback(() => {
    usersStore.setSort(['client.name', (u: IUser) => u.client?.name], 'desc');
  }, [usersStore]);

  const userNameAscendingSortHandler = useCallback(() => {
    usersStore.setSort(['user.displayName', (u: IUser) => u.user.displayName], 'asc');
  }, [usersStore]);
  const userNameDescendingSortHandler = useCallback(() => {
    usersStore.setSort(['user.displayName', (u: IUser) => u.user.displayName], 'desc');
  }, [usersStore]);

  const countryNameAscendingSortHandler = useCallback(() => {
    usersStore.setSort(['client.country.name', (u: IUser) => u.client?.country?.name], 'asc');
  }, [usersStore]);
  const countryNameDescendingSortHandler = useCallback(() => {
    usersStore.setSort(['client.country.name', (u: IUser) => u.client?.country?.name], 'desc');
  }, [usersStore]);

  const externalUserStateAscendingSortHandler = useCallback(() => {
    usersStore.setSort(['user.externalUserState', (u: IUser) => u.user?.externalUserState], 'asc');
  }, [usersStore]);
  const externalUserStateDescendingSortHandler = useCallback(() => {
    usersStore.setSort(['user.externalUserState', (u: IUser) => u.user?.externalUserState], 'desc');
  }, [usersStore]);

  return (
    <>
      <HeaderContainer>
        <Header>{translate('external users')}</Header>
      </HeaderContainer>
      <Box pb={4}>
        <Grid container justify="space-between">
          <Grid item>
            <ButtonLink to={getPathWithProps('CREATE_USER')} variant="contained" color="secondary">
              {translate('add user')}
            </ButtonLink>
          </Grid>
        </Grid>
      </Box>
      <RemoteSuspense
        data={RemoteData.all(countriesStore.remoteCountries, usersStore.filteredSortUsers)}
        loadingFallback={<LinearProgress />}
        failureFallback={(error) => <Typography color="error">{translate('failed to fetch users')}</Typography>}
      >
        {([countries, users]) => (
          <Box py={2}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ width: 250 }}>
                      <SortedCellContent
                        onUpClickHandler={clientNameAscendingSortHandler}
                        onDownClickHandler={clientNameDescendingSortHandler}
                        selectedUp={
                          !!usersStore.sortColumnNames &&
                          !!usersStore.sortDirection &&
                          usersStore.sortColumnNames[0] == 'client.name' &&
                          usersStore.sortDirection === 'asc'
                        }
                        selectedDown={
                          !!usersStore.sortColumnNames &&
                          !!usersStore.sortDirection &&
                          usersStore.sortColumnNames[0] == 'client.name' &&
                          usersStore.sortDirection === 'desc'
                        }
                      >
                        <GenericSearch onChange={onClientNameFilterChange} placeholder={translate('client')} />
                      </SortedCellContent>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: 250 }}>
                      <SortedCellContent
                        onUpClickHandler={userNameAscendingSortHandler}
                        onDownClickHandler={userNameDescendingSortHandler}
                        selectedUp={
                          !!usersStore.sortColumnNames &&
                          !!usersStore.sortDirection &&
                          usersStore.sortColumnNames[0] == 'user.displayName' &&
                          usersStore.sortDirection === 'asc'
                        }
                        selectedDown={
                          !!usersStore.sortColumnNames &&
                          !!usersStore.sortDirection &&
                          usersStore.sortColumnNames[0] == 'user.displayName' &&
                          usersStore.sortDirection === 'desc'
                        }
                      >
                        {translate('name')}
                      </SortedCellContent>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '0.1%' }}>
                      <SortedCellContent
                        onUpClickHandler={countryNameAscendingSortHandler}
                        onDownClickHandler={countryNameDescendingSortHandler}
                        selectedUp={
                          !!usersStore.sortColumnNames &&
                          !!usersStore.sortDirection &&
                          usersStore.sortColumnNames[0] == 'client.country.name' &&
                          usersStore.sortDirection === 'asc'
                        }
                        selectedDown={
                          !!usersStore.sortColumnNames &&
                          !!usersStore.sortDirection &&
                          usersStore.sortColumnNames[0] == 'client.country.name' &&
                          usersStore.sortDirection === 'desc'
                        }
                      >
                        <GenericSelect
                          options={[{ label: translate('country: all'), value: '' }].concat(
                            countries.map((country) => ({ value: country.id, label: country.name })),
                          )}
                          onChange={onCountryChange}
                          styles={{ width: 170 }}
                        ></GenericSelect>
                      </SortedCellContent>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '0.1%' }}>
                      <SortedCellContent
                        onUpClickHandler={externalUserStateAscendingSortHandler}
                        onDownClickHandler={externalUserStateDescendingSortHandler}
                        selectedUp={
                          !!usersStore.sortColumnNames &&
                          !!usersStore.sortDirection &&
                          usersStore.sortColumnNames[0] == 'user.externalUserState' &&
                          usersStore.sortDirection === 'asc'
                        }
                        selectedDown={
                          !!usersStore.sortColumnNames &&
                          !!usersStore.sortDirection &&
                          usersStore.sortColumnNames[0] == 'user.externalUserState' &&
                          usersStore.sortDirection === 'desc'
                        }
                      >
                        {translate('status')}
                      </SortedCellContent>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <PaginationTableContents paginationColSpan={3} data={users} RowComponent={UserTableRow} />
              </Table>
            </TableContainer>
          </Box>
        )}
      </RemoteSuspense>
    </>
  );
});

export { ListUsers };
