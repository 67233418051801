import React from 'react';
import Accordion, { AccordionProps } from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { DraggableProvided } from 'react-beautiful-dnd';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

interface IDraggableGreyAccordionContainerProps {
  provided: DraggableProvided;
  summaryComponent: React.ReactNode;
  onSummaryClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  grayBackground: {
    backgroundColor: theme.palette.background.default,
  },
}));

const DraggableGreyAccordionContainer: React.FunctionComponent<
  IDraggableGreyAccordionContainerProps & AccordionProps
> = ({ provided, summaryComponent, children, onSummaryClick, ...props }) => {
  const { grayBackground } = useStyles();
  return (
    <Accordion ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} {...props}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        className={clsx(props.expanded && grayBackground)}
        onClick={onSummaryClick}
      >
        {summaryComponent}
      </AccordionSummary>
      <AccordionDetails className={grayBackground}>{children}</AccordionDetails>
    </Accordion>
  );
};

export { DraggableGreyAccordionContainer };
