import React, { useState, useContext } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { TranslationContext } from '../context/TranslationContext';
import { TablePaginationActions } from '../screens/listClient/PaginationActions';
import { PropsWithChildren } from 'react';

interface IPaginationTableContents<DataType> {
  data: DataType[];
  RowComponent: React.FunctionComponent<DataType>;
  paginationColSpan: number;
}

function PaginationTableContents<DataType>(props: PropsWithChildren<IPaginationTableContents<DataType>>) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const { translate } = useContext(TranslationContext);
  const { RowComponent, paginationColSpan } = props;
  return (
    <>
      <TableBody>
        {(rowsPerPage > 0 ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : props.data).map(
          (data, index) => (
            <RowComponent key={index} {...data} />
          ),
        )}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: translate('all'), value: -1 }]}
            colSpan={paginationColSpan}
            count={props.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': translate('rows per page') },
              native: true,
            }}
            onChangePage={(event, newPage) => setPage(newPage)}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            labelRowsPerPage={translate('rows per page')}
          />
        </TableRow>
      </TableFooter>
    </>
  );
}

export { PaginationTableContents };
