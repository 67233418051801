import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { UserDomainStore } from '../../stores/UserDomainStore';
import { TeamMemberAvatar } from '../../screens/locationDashboard/TeamMemberAvatar';

export interface ITeamMemberItemProps {
  userStore: UserDomainStore;
}

const useStyles = makeStyles((theme: Theme) => ({
  teamMemberContainer: {
    padding: theme.spacing(2, 0),
  },
  teamMemberWrapper: {
    padding: theme.spacing(0, 2),
  },
}));

export const TeamMemberItem: React.FunctionComponent<ITeamMemberItemProps> = ({ userStore }) => {
  const { teamMemberContainer, teamMemberWrapper } = useStyles();
  return (
    <Box>
      <Grid container direction="row" className={teamMemberContainer}>
        <TeamMemberAvatar userDomainStore={userStore} />
        <Grid className={teamMemberWrapper} item>
          <Typography>{userStore.user.user.displayName}</Typography>
          <Typography variant="body2">{userStore.user.user.mail}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
