import React, { useContext } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { ILocation } from '../../services/ApiServiceV1';
import { getLocationPropertyTypes } from '../../utils/locationPropertyTypes';
import { TranslationContext } from '../../context/TranslationContext';
import { StyledLink } from '../../components/StyledLink';
import { RouteMapContext } from '../../context/RouteMapContext';

const LocationTableRow: React.FunctionComponent<ILocation> = (props) => {
  const { translate } = useContext(TranslationContext);
  const propertyTypes = getLocationPropertyTypes(props, translate);
  const { getPathWithProps } = useContext(RouteMapContext);

  return (
    <TableRow>
      <TableCell>
        <StyledLink
          to={getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
            clientId: props.client.id,
            locationId: props.id,
          })}
        >
          {props.name}
        </StyledLink>
      </TableCell>
      <TableCell>{props.client.name}</TableCell>
      <TableCell>{props.country.name}</TableCell>
      <TableCell>{props.address}</TableCell>
      <TableCell>{propertyTypes.join(', ')}</TableCell>
      <TableCell>{props.services.move?.isEnabled ? translate('active') : translate('inactive')}</TableCell>
    </TableRow>
  );
};

export { LocationTableRow };
