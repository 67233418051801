import { IApiServiceV1, ILocation, ILocationPayload, IClient } from '../services/ApiServiceV1';
import RemoteData from 'ts-remote-data';
import { observable, action, computed } from 'mobx';
import { runInAction } from 'mobx';
import { SortDirection, extractAndCompareValues } from '../utils/sort';

type SortColumns = [string, (_: ILocation) => any];

class LocationsStore {
  _apiService: IApiServiceV1;
  @observable selectedCountryId: string | undefined = undefined;
  @observable selectedActiveInfoFilter: string | undefined = undefined;
  @observable companyNameFilter: string | undefined = undefined;
  @observable remoteLocations: RemoteData<ILocation[]> = RemoteData.NOT_ASKED;
  @observable initialLocations: Array<ILocation> = [];
  @observable sortColumns: SortColumns = ['none', (_: ILocation) => _];
  @observable sortDirection: SortDirection | undefined;

  constructor(apiService: IApiServiceV1) {
    this._apiService = apiService;
  }

  @action
  async fetch(forceFetch = false) {
    if (RemoteData.isReady(this.remoteLocations) && !forceFetch) {
      return;
    }
    this.remoteLocations = RemoteData.LOADING;
    try {
      const locations = await this._apiService.fetchLocations();
      runInAction(() => {
        this.remoteLocations = locations;
        this.initialLocations = locations;
      });
    } catch (error) {
      action(() => {
        this.remoteLocations = RemoteData.fail();
      });
    }
  }

  @action
  setCountryId(countryId?: string) {
    this.selectedCountryId = countryId;
  }

  @action
  setActiveFilter(activeInfoFilter?: string) {
    this.selectedActiveInfoFilter = activeInfoFilter;
  }

  @action
  setCompanyNameFilter(companyName?: string) {
    this.companyNameFilter = companyName;
  }

  @action setSort(sortDirection: SortDirection, locationSortColumns: SortColumns) {
    this.sortColumns = locationSortColumns;
    this.sortDirection = sortDirection;
  }

  @computed
  get filteredSortedClients(): ILocation[] {
    if (RemoteData.isReady(this.remoteLocations)) {
      const filteredResult = this.selectedCountryId
        ? this.remoteLocations.filter((client) => client.country.id === this.selectedCountryId)
        : this.remoteLocations;
      return this.sortColumns && this.sortDirection ? this.sort(filteredResult) : filteredResult;
    } else {
      return [];
    }
  }

  private sort(clients: ILocation[]) {
    if (RemoteData.isReady(this.remoteLocations) && this.sortColumns && this.sortDirection) {
      let sortedClients = this.selectedCountryId
        ? clients.filter((client) => client.country.id === this.selectedCountryId)
        : clients.slice();

      sortedClients = this.selectedActiveInfoFilter
        ? sortedClients.filter((client) => Function('x', this.selectedActiveInfoFilter!)(client))
        : sortedClients.filter((client) => client.services.move?.isEnabled);

      sortedClients = this.companyNameFilter
        ? sortedClients.filter(
            (client) => client.client.name.toLowerCase().indexOf(this.companyNameFilter!.toLowerCase()) >= 0,
          )
        : sortedClients;

      sortedClients.sort(extractAndCompareValues(this.sortColumns[1], this.sortDirection));
      return sortedClients;
    }

    return clients;
  }

  @action
  async create(clientId: string, payload: ILocationPayload) {
    const newLocation = await this._apiService.addLocation(clientId, payload);
    runInAction(() => {
      if (RemoteData.isReady(this.remoteLocations)) {
        this.remoteLocations.push(newLocation);
        this.initialLocations.push(newLocation);
      }
    });

    return newLocation;
  }

  @action
  async update(clientId: string, locationId: string, payload: ILocationPayload) {
    const updatedLocation = await this._apiService.updateLocation(clientId, locationId, payload);
    runInAction(() => {
      if (RemoteData.isReady(this.remoteLocations)) {
        const updatedLocations = this.remoteLocations.map((location) => {
          if (location.id === updatedLocation.id) {
            return updatedLocation;
          }

          return location;
        });
        this.remoteLocations = updatedLocations;
        this.initialLocations = updatedLocations;
      }
    });

    return updatedLocation;
  }
}

export { LocationsStore };
