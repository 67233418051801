import React, { useContext, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { FormikFormInput } from '../../components/FormikInputs';
import { IFAQ, IFAQPayload } from '../../services/ApiServiceV1';
import { TranslationContext } from '../../context/TranslationContext';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { ModalContext } from '../../context/ModalContext';
import { Button } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import { DragButtonIcon } from '../../components/DragButtonIcon';
import { IosSwitch } from '../../components/IosSwitch';
import { OnFaqPublished } from '.';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(4),
      marginLRight: theme.spacing(4),
    },
  },
  background: {
    backgroundColor: theme.palette.background.default,
  },
  buttonContainer: {
    '& button:first-of-type': {
      marginRight: theme.spacing(3),
    },
  },
}));

interface IFAQRowProps {
  FAQ: IFAQ;
  handleDelete: (feedbackId: string) => Promise<void>;
  handleSave: (values: IFAQPayload, id?: string) => Promise<void>;
  index: number;
  handlePublishedToggle: OnFaqPublished;
}

export const FAQRow: React.FC<IFAQRowProps> = ({ FAQ, handleDelete, handleSave, index, handlePublishedToggle }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isOpen, setIsOpen] = useState(false);

  const { translate } = useContext(TranslationContext);
  const { setModalState } = useContext(ModalContext);

  const { id, question, answer, published } = FAQ;
  const initialValues: IFAQPayload = {
    answer,
    question,
    published,
  };

  const { formWrapper, buttonContainer } = useStyles();

  return (
    <>
      <TableHead>
        <TableRow className={isOpen ? classes.background : ''}>
          <TableCell align="left" padding="checkbox">
            <DragButtonIcon />
          </TableCell>
          <TableCell align="left">{question}</TableCell>
          <TableCell size="small" align="right" padding="checkbox">
            <IosSwitch
              checked={published}
              onChange={(_event, checked) => {
                handlePublishedToggle(FAQ, checked);
              }}
            />
          </TableCell>
          <TableCell align="right" size="small" padding="checkbox">
            <IconButton onClick={() => setIsOpen(!isOpen)} aria-label="expand row">
              {isOpen ? <KeyboardArrowUpIcon color="secondary" /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={isOpen ? classes.background : ''}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }} colSpan={4}>
            <Collapse in={isOpen} timeout="auto">
              <Formik
                initialValues={initialValues}
                onSubmit={async (values: IFAQPayload) => {
                  handleSave(values, FAQ.id);
                }}
                validationSchema={yup.object({
                  answer: yup.string().required(translate('field is required')),
                })}
              >
                {({ isSubmitting }) => (
                  <Form className={formWrapper}>
                    <Grid container spacing={5}>
                      <Grid item sm={9} lg={9} xs={12}>
                        <Box py={1.5}>
                          <FormikFormInput
                            name="question"
                            formikKey="question"
                            fullWidth={true}
                            labelContent={translate('question')}
                            rows={1}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </Box>
                        <Box py={1.5}>
                          <FormikFormInput
                            name="answer"
                            formikKey="answer"
                            multiline={true}
                            fullWidth={true}
                            labelContent={translate('answer')}
                            rows={5}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box pt={1.5} pb={3} display="flex" justifyContent="space-between">
                      <Box className={buttonContainer}>
                        <Button variant="contained" disabled={isSubmitting} type="submit" color="secondary">
                          {translate('save')}
                        </Button>
                        <Button variant="outlined" onClick={() => setIsOpen(!isOpen)}>
                          {translate('cancel')}
                        </Button>
                      </Box>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setModalState(
                            true,
                            <ConfirmDialog
                              data={id}
                              handleClick={() => handleDelete(id)}
                              dialogTitle={translate('are you sure you want to delete ')}
                              recordTitle={question}
                              content={translate("This item will be deleted immediately. You can't undo this action")}
                              primaryButtonLabel={translate('delete')}
                              cancelLabel={translate('cancel')}
                            />,
                          );
                        }}
                      >
                        {translate('delete')}
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Collapse>
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
};
