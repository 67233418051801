import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { IFormikField } from '.';
import { useField } from 'formik';

export const FormikCheckbox: React.FunctionComponent<IFormikField & CheckboxProps> = ({
  labelContent,
  formikKey,
  ...props
}) => {
  const [field, meta] = useField({ name: formikKey, type: 'checkbox' });

  return (
    <FormControl error={meta.touched && Boolean(meta.error)}>
      <FormControlLabel
        control={<Checkbox name={field.name} {...props} />}
        onChange={field.onChange}
        label={labelContent}
        checked={field.checked}
        onBlur={field.onBlur}
        defaultChecked={false}
      />
    </FormControl>
  );
};
