import React, { createContext } from 'react';
import { useModal } from '../hooks/useModal';

type Nullable<T> = T | null;

interface IModalContext {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setModalState: (state: boolean, content: Nullable<JSX.Element>) => void;
}
const ModalContext = createContext({} as IModalContext);

const ModalContextProvider: React.FunctionComponent = ({ children }) => {
  const { isModalOpen, setIsModalOpen, modalContent, setModalState } = useModal();

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        setIsModalOpen,
        setModalState,
      }}
    >
      {children}
      {modalContent}
    </ModalContext.Provider>
  );
};
export { ModalContext, ModalContextProvider };
