import React, { useContext } from 'react';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { HeaderContainer } from '../../components/containers/HeaderContainer';
import { SectionContainer } from '../../components/containers/SectionContainer';
import { TemplateForm } from '../../components/TemplateForm';
import { Header } from '../../components/typography/Header';
import { NotificationContext } from '../../context/NotificationContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { useFetchRemoteData } from '../../hooks/useFetchRemoteData';
import { IMove, ITemplate, ITemplatePayload } from '../../services/ApiServiceV1';
import LinearProgress from '@material-ui/core/LinearProgress';
import { RouteMapContext } from '../../context/RouteMapContext';
import { isTemplateOrMovePage } from '../../utils/isTemplateOrMovePage';
import { ClientUserWrapper } from '../../components/ClientUserWrapper';
import { NavigationBreadcrumbs } from '../../components/NavigationBreadcrumbs';
import { observer } from 'mobx-react';

const CreateMovePage: React.FunctionComponent = observer(() => {
  const {
    apiServiceV1,
    stores: { locationStore },
  } = useContext(ServiceContext);
  const { translate } = useContext(TranslationContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const { clientId, locationId, templateId } = useParams<{
    clientId: string;
    locationId: string;
    templateId: string;
  }>();
  useAsyncOperation(() => {
    return locationStore.fetch(clientId, locationId);
  }, [clientId, locationId]);

  const history = useHistory();

  const fetchRemoteTemplate = useCallback(() => {
    return apiServiceV1.fetchTemplate(templateId);
  }, [apiServiceV1, templateId]);
  const { remoteData: remoteTemplate, setRemoteData: setRemoteTemplate } = useFetchRemoteData<ITemplate>({
    fetchData: fetchRemoteTemplate,
  });

  const onSubmit = useCallback(
    async (values: ITemplatePayload) => {
      if (RemoteData.isReady(locationStore.remoteLocation)) {
        try {
          await apiServiceV1.addMovePage((locationStore.remoteLocation.services.move as IMove).id, {
            published: true,
            templateId,
            translations: values.translations,
          });
          history.push(
            getPathWithProps<{ clientId: string; locationId: string; moveId: string }>('MOVE_PAGES', {
              clientId,
              locationId,
              moveId: (locationStore.remoteLocation.services.move as IMove).id,
            }),
          );
          notificationDispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              color: 'success',
              message: translate('move page added'),
            },
          });
        } catch (error) {
          notificationDispatch({
            type: 'SET_NOTIFICATION',
            payload: {
              color: 'error',
              message: translate('failed to create move page'),
            },
          });
          setRemoteTemplate(RemoteData.failWith(remoteTemplate));
        }
      }
    },
    [
      apiServiceV1,
      clientId,
      getPathWithProps,
      history,
      locationId,
      locationStore.remoteLocation,
      notificationDispatch,
      remoteTemplate,
      setRemoteTemplate,
      templateId,
      translate,
    ],
  );

  return (
    <ClientUserWrapper
      render={(client) => (
        <>
          <RemoteSuspense data={locationStore.remoteLocation} loadingFallback={<LinearProgress />}>
            {(location) => (
              <NavigationBreadcrumbs
                items={[
                  {
                    content: client.name,
                    url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                  },
                  {
                    content: location.name,
                    url: getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
                      clientId: client.id,
                      locationId: location.id,
                    }),
                  },
                  {
                    content: translate('pages'),
                    url: getPathWithProps<{ clientId: string; locationId: string; moveId: string }>('MOVE_PAGES', {
                      clientId: client.id,
                      locationId: location.id,
                      moveId: (location.services.move as IMove).id,
                    }),
                  },
                  { content: translate('create new page') },
                ]}
              />
            )}
          </RemoteSuspense>

          <HeaderContainer>
            <Header>{translate('create new page')}</Header>
          </HeaderContainer>
          <SectionContainer>
            <RemoteSuspense
              data={remoteTemplate}
              loadingFallback={<LinearProgress />}
              failureFallback={(error) =>
                isTemplateOrMovePage(error) ? (
                  <TemplateForm onSubmit={onSubmit} template={error} saveButtonLabel={translate('create')} />
                ) : null
              }
            >
              {(template) => (
                <TemplateForm onSubmit={onSubmit} template={template} saveButtonLabel={translate('create')} />
              )}
            </RemoteSuspense>
          </SectionContainer>
        </>
      )}
    />
  );
});

export { CreateMovePage };
