import React, { useContext } from 'react';
import { TranslationContext } from '../../context/TranslationContext';
import { ServiceContext } from '../../context/ServicesContext';
import { useCallback } from 'react';
import { IClientPayload } from '../../services/ApiServiceV1';
import { useParams } from 'react-router-dom';
import { NotificationContext } from '../../context/NotificationContext';
import { SectionContainer } from '../../components/containers/SectionContainer';
import ClientForm from '../../components/ClientForm/ClientForm';
import { useHistory } from 'react-router-dom';
import AdminContentHeader from '../../components/AdminContentHeader';
import { RouteMapContext } from '../../context/RouteMapContext';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { observer } from 'mobx-react';

const EditClient: React.FunctionComponent = observer(() => {
  const { translate } = useContext(TranslationContext);
  const {
    apiServiceV1,
    stores: { clientStore },
  } = useContext(ServiceContext);
  const { clientId } = useParams<{ clientId: string }>();
  const { notificationDispatch } = useContext(NotificationContext);
  const history = useHistory();
  const { getPathWithProps } = useContext(RouteMapContext);

  useAsyncOperation(() => {
    return clientStore.fetchClientData(clientId);
  }, [clientStore]);

  const onClientFormSubmit = useCallback(
    async (payload: IClientPayload) => {
      try {
        const updatedClient = await apiServiceV1.updateClient(clientId, payload);
        clientStore.updateRemoteClientData(updatedClient);
        const path = getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', {
          clientId,
        });
        history.push(path);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully saved client'),
            color: 'success',
          },
        });
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to save client'),
            color: 'error',
          },
        });
      }
    },
    [apiServiceV1, clientId, getPathWithProps, clientStore, history, notificationDispatch, translate],
  );

  return (
    <>
      <AdminContentHeader headerText={translate('edit client')} />
      <SectionContainer
        subtitle={translate('enter the key information about the client here')}
        title={translate('client information')}
      >
        <RemoteSuspense data={clientStore.remoteClient} loadingFallback={<LinearProgress />}>
          {(client) => <ClientForm client={client} onSubmit={onClientFormSubmit} />}
        </RemoteSuspense>
      </SectionContainer>
    </>
  );
});

export { EditClient };
