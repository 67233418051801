import { IApiServiceV1, IFAQ, IFAQPayload, IFAQPositionPayload } from '../services/ApiServiceV1';
import RemoteData from 'ts-remote-data';
import { observable, action } from 'mobx';
import { runInAction } from 'mobx';

class FAQsStore {
  _apiService: IApiServiceV1;
  @observable remoteFAQs: RemoteData<IFAQ[]> = RemoteData.NOT_ASKED;
  constructor(apiService: IApiServiceV1) {
    this._apiService = apiService;
  }

  @action
  async fetchFAQs(moveId: string, forceFetch = false) {
    if (RemoteData.isReady(this.remoteFAQs) && !forceFetch) {
      return;
    }
    this.remoteFAQs = RemoteData.LOADING;
    try {
      const FAQs = await this._apiService.fetchFAQs(moveId);
      runInAction(() => {
        this.remoteFAQs = FAQs;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteFAQs = RemoteData.fail();
      });
    }
  }

  @action
  async deleteFAQ(moveFaqQuestionId: string) {
    if (RemoteData.isReady(this.remoteFAQs)) {
      const temporaryFAQs = this.remoteFAQs;
      this.remoteFAQs = RemoteData.LOADING;
      try {
        await this._apiService.deleteFAQ(moveFaqQuestionId);
        runInAction(() => {
          this.remoteFAQs = temporaryFAQs.filter((FAQ) => FAQ.id !== moveFaqQuestionId);
        });
      } catch (error) {
        runInAction(() => {
          this.remoteFAQs = RemoteData.failWith(temporaryFAQs);
        });
      }
    }
  }

  @action
  async updateFAQ(moveFaqQuestionId: string, payload: IFAQPayload) {
    if (RemoteData.isReady(this.remoteFAQs)) {
      const updatedFAQ = await this._apiService.updateFAQ(moveFaqQuestionId, payload);
      runInAction(() => {
        this.remoteFAQs = (this.remoteFAQs as IFAQ[]).map((faq) => {
          if (faq.id === updatedFAQ.id) {
            return updatedFAQ;
          }
          return faq;
        });
      });
    }
  }

  @action
  async updateFAQposition(moveId: string, payload: IFAQPositionPayload) {
    if (RemoteData.isReady(this.remoteFAQs)) {
      const temporaryFAQs = this.remoteFAQs;
      this.remoteFAQs = RemoteData.LOADING;
      try {
        const updatedPositionFAQs = await this._apiService.updateFAQposition(moveId, payload);
        runInAction(() => {
          this.remoteFAQs = updatedPositionFAQs;
        });
      } catch (error) {
        runInAction(() => {
          this.remoteFAQs = RemoteData.failWith(temporaryFAQs);
        });
      }
    }
  }

  @action
  async addFAQ(moveId: string, payload: IFAQPayload) {
    if (RemoteData.isReady(this.remoteFAQs)) {
      const temporaryFAQs = this.remoteFAQs;
      this.remoteFAQs = RemoteData.LOADING;
      try {
        const addedFAQ = await this._apiService.addFAQ(moveId, payload);
        runInAction(() => {
          this.remoteFAQs = temporaryFAQs.concat(addedFAQ);
        });
      } catch (error) {
        runInAction(() => {
          this.remoteFAQs = RemoteData.failWith(temporaryFAQs);
        });
      }
    }
  }
}

export { FAQsStore };
