import React from 'react';
import AuthButton from '../../components/AuthButton';
import { useContext } from 'react';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import backgroundImage from '../../assets/images/login-background.jpg';
import svgLogo from '../../assets/images/fmg-logo.svg';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { RouteMapContext } from '../../context/RouteMapContext';
import { useUserRedirect } from '../../hooks/useUserRedirect';
import { RemoteSuspense } from 'ts-remote-data-react';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    backgroundImage: `linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,133,187,0.2595413165266106) 0%, rgba(0,212,255,0.26234243697478987) 0%), url(${backgroundImage})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    height: '100vh',
  },
  signInBox: {
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(4),
  },
  loginGridItem: {
    padding: theme.spacing(4, 0),
  },
  logoImage: {
    marginLeft: 50,
  },
  logoImageSmall: {},
}));

const Home: React.FunctionComponent = () => {
  const { background, signInBox, loginGridItem, logoImage, logoImageSmall } = useStyles();
  const { remoteIsAuthenticated, remoteUser } = useContext(AuthenticationContext);
  const { getPathWithProps } = useContext(RouteMapContext);

  const history = useHistory();

  useUserRedirect(remoteIsAuthenticated, remoteUser, history, getPathWithProps);

  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <RemoteSuspense data={remoteIsAuthenticated} loadingFallback={<LinearProgress />}>
      {() => (
        <Box className={background} display="flex" justifyContent="center" alignItems="center">
          <Grid container item xs={12} sm={8} md={6} lg={4} className={signInBox}>
            <Grid item xs={12} sm={6} className={loginGridItem} justify="center" alignItems="center" container>
              <Box display="flex" justifyContent="center" width={1}>
                <img src={svgLogo} alt="fmg-logo" className={isExtraSmall ? logoImageSmall : logoImage} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} justify="center" alignItems="center" container className={loginGridItem}>
              <AuthButton />
              {/* {error && <p>{error.message}</p>} */}
              {/* {authenticationContextError && <Typography>{authenticationContextError.message}</Typography>} */}
            </Grid>
          </Grid>
        </Box>
      )}
    </RemoteSuspense>
  );
};

export default Home;
