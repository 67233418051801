import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { RemoteSuspense } from 'ts-remote-data-react';
import { DividedInfoBox } from '../../components/DividedInfoBox/DividedInfoBox';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import Typography from '@material-ui/core/Typography';
import { RouteMapContext } from '../../context/RouteMapContext';
import { IBrokering, IClient } from '../../services/ApiServiceV1';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IGeneralInfoBoxProps {
  client: IClient;
  brokering: IBrokering;
}

const useStyles = makeStyles((theme: Theme) => ({
  externalTeamMemberMail: {
    marginLeft: theme.spacing(1),
  },
}));

const GeneralInfoBox: React.FunctionComponent<IGeneralInfoBoxProps> = observer(({ client, brokering }) => {
  const { translate } = useContext(TranslationContext);
  const {
    stores: { brokeringStore },
  } = useContext(ServiceContext);
  const { getPathWithProps } = useContext(RouteMapContext);

  const theme = useTheme();
  const shouldShowVerticalDivider = useMediaQuery(theme.breakpoints.up('md'));
  const horizontalPadding = useMediaQuery(theme.breakpoints.down('md')) ? 1 : 2;
  const shouldShowLastVerticalDivider = useMediaQuery(theme.breakpoints.up('lg'));
  const { externalTeamMemberMail } = useStyles();

  return (
    <DividedInfoBox
      boxTitle={translate('general information')}
      editUrl={getPathWithProps<{ clientId: string; brokeringId: string }>('EDIT_BROKERING_PROJECT', {
        clientId: client.id,
        brokeringId: brokering.id,
      })}
    >
      {/* top */}
      <DividedInfoBox.DividedContentContainer md={6} lg={4}>
        <Box px={horizontalPadding} py={2}>
          <RemoteSuspense data={brokeringStore.remoteClientContact} loadingFallback={<LinearProgress />}>
            {(clientContact) =>
              clientContact && (
                <DividedInfoBox.InfoItem
                  label={translate('client contact person')}
                  content={clientContact.user.displayName as string}
                >
                  <DividedInfoBox.SubtextInfoItem label={translate('email')}>
                    {clientContact.user.mail}
                  </DividedInfoBox.SubtextInfoItem>
                </DividedInfoBox.InfoItem>
              )
            }
          </RemoteSuspense>
          <RemoteSuspense data={brokeringStore.remoteProjectManager} loadingFallback={<LinearProgress />}>
            {(projectManager) =>
              projectManager && (
                <DividedInfoBox.InfoItem
                  label={translate('project manager')}
                  content={projectManager.user.displayName as string}
                >
                  <DividedInfoBox.SubtextInfoItem label={translate('email')}>
                    {projectManager.user.mail}
                  </DividedInfoBox.SubtextInfoItem>
                </DividedInfoBox.InfoItem>
              )
            }
          </RemoteSuspense>
        </Box>
      </DividedInfoBox.DividedContentContainer>
      {/* divider */}
      {shouldShowVerticalDivider && <DividedInfoBox.VerticalBoxDivider />}
      {shouldShowVerticalDivider === false && (
        <Box width={1}>
          <DividedInfoBox.HorizontalBoxDivider />
        </Box>
      )}
      {/* internal team members */}
      <DividedInfoBox.DividedContentContainer md={6} lg={4}>
        <Box px={horizontalPadding}>
          <Typography variant="button">{translate('team')}</Typography>
          <RemoteSuspense data={brokeringStore.remoteTeamMembers} loadingFallback={<LinearProgress />}>
            {(teamMembers) =>
              teamMembers.map((teamMemberUserStore) => (
                <DividedInfoBox.TeamMemberItem
                  key={teamMemberUserStore.user.user.id as string}
                  userStore={teamMemberUserStore}
                />
              ))
            }
          </RemoteSuspense>
        </Box>
      </DividedInfoBox.DividedContentContainer>

      {/* divider */}
      {shouldShowLastVerticalDivider && <DividedInfoBox.VerticalBoxDivider />}
      {!shouldShowLastVerticalDivider && (
        <Box width={1}>
          <DividedInfoBox.HorizontalBoxDivider />
        </Box>
      )}
      {/* external team members should go under this */}
      <DividedInfoBox.DividedContentContainer md={12} lg={4}>
        <Box px={horizontalPadding}>
          <Typography variant="button">{translate('external team members')}</Typography>
          <RemoteSuspense data={brokeringStore.remoteExternalTeamMembers} loadingFallback={<LinearProgress />}>
            {(externalTeamMembers) =>
              externalTeamMembers.map((externalTeamMember) => (
                <Box key={externalTeamMember.user.id}>
                  <Typography>{externalTeamMember.user.displayName}</Typography>
                  <Typography className={externalTeamMemberMail} variant="body2">
                    {externalTeamMember.user.mail}
                  </Typography>
                </Box>
              ))
            }
          </RemoteSuspense>
        </Box>
      </DividedInfoBox.DividedContentContainer>
    </DividedInfoBox>
  );
});

export { GeneralInfoBox };
