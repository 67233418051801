import React, { createContext, useReducer } from 'react';
import Alert, { Color } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

export type Action =
  | { type: 'SET_NOTIFICATION'; payload: { message: string; color: Color } }
  | { type: 'UNSET_NOTIFICATION' };

interface INotificationState {
  message: string;
  color: Color;
  isOpen: boolean;
}

const initialNotificationReducerState: INotificationState = {
  message: '',
  color: 'info',
  isOpen: false,
};

function reducer(state: INotificationState, action: Action): INotificationState {
  switch (action.type) {
    case 'SET_NOTIFICATION':
      return {
        ...state,
        message: action.payload.message,
        color: action.payload.color,
        isOpen: true,
      };
    case 'UNSET_NOTIFICATION':
      return initialNotificationReducerState;
  }
}

interface INotificationContextState {
  notificationDispatch: React.Dispatch<Action>;
}

const NotificationContext = createContext<INotificationContextState>({} as INotificationContextState);

const NotificationProvider: React.FunctionComponent = ({ children }) => {
  // notification state
  const [{ isOpen, color, message }, dispatch] = useReducer(reducer, initialNotificationReducerState);
  const handleCloseNotification = () => {
    dispatch({ type: 'UNSET_NOTIFICATION' });
  };

  return (
    <NotificationContext.Provider
      value={{
        notificationDispatch: dispatch,
      }}
    >
      <>
        {children}
        <Snackbar open={isOpen} onClose={handleCloseNotification} autoHideDuration={4000}>
          <Alert severity={color} onClose={handleCloseNotification} variant="filled">
            {message}
          </Alert>
        </Snackbar>
      </>
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
