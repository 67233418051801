import React from 'react';
import { InputBaseProps } from '@material-ui/core/InputBase';
import { withStyles, createStyles, styled } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { FormLabelProps } from '@material-ui/core/FormLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Label } from './Label';

export const fieldPadding = '0.625rem 0.875rem';

const fieldStyles = createStyles({
  input: {
    padding: fieldPadding,
  },
});

export const StyledOutlinedSelect = styled(Select)({
  select: {
    padding: fieldPadding,
  },
});
export const StyledOutlinedInput = withStyles(fieldStyles)(OutlinedInput);

interface IFormInputProps {
  inputProps: InputBaseProps;
  labelProps?: FormLabelProps;
  labelContent: string;
  formHelperText?: string;
}

const FormInput: React.FunctionComponent<IFormInputProps> = ({
  inputProps,
  labelProps,
  labelContent,
  formHelperText,
}) => (
  <FormControl fullWidth={true}>
    <Label labelProps={labelProps}>{labelContent}</Label>
    <StyledOutlinedInput {...inputProps} />
    {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
  </FormControl>
);

export default FormInput;
