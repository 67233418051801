import React, { useContext } from 'react';
import { RouteProps, Redirect, Route } from 'react-router-dom';
import { AuthenticationContext } from '../context/AuthenticationContext';
import { RemoteSuspense } from 'ts-remote-data-react';
import { Role } from '../domain/Role';
import RemoteData from 'ts-remote-data';

interface IProtectedRouteProps {
  redirectPath?: string;
  restrictInternalSuper?: boolean;
}

const ProtectedRoute: React.FunctionComponent<RouteProps & IProtectedRouteProps> = ({
  children,
  redirectPath = '/',
  restrictInternalSuper,
  ...rest
}) => {
  const { remoteIsAuthenticated, accountRoles } = useContext(AuthenticationContext);

  return (
    <RemoteSuspense data={remoteIsAuthenticated} failureFallback={<Route path={redirectPath} />}>
      {(isAuthenticated) => {
        const isLocalSuper = RemoteData.isReady(accountRoles) && accountRoles.some((x) => x === Role.InternalSuper);
        const canAccess = isAuthenticated && (!restrictInternalSuper || isLocalSuper);

        return (
          <Route
            {...rest}
            render={({ location }) => {
              return canAccess ? (
                children
              ) : (
                <Redirect
                  to={{
                    pathname: redirectPath,
                    state: { from: location },
                  }}
                />
              );
            }}
          />
        );
      }}
    </RemoteSuspense>
  );
};

export default ProtectedRoute;
