import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { ServiceContext } from '../../context/ServicesContext';
import { ILease } from '../../services/ApiServiceV1';
import { ReminderListItem } from './ReminderListItem';

interface ILeaseRemindersListProps {
  lease: ILease;
}

const LeaseRemindersList: React.FunctionComponent<ILeaseRemindersListProps> = observer(({ lease }) => {
  const {
    rootStore: { leaseRemindersStore, leaseRemindersUiStore },
  } = useContext(ServiceContext);

  useAsyncOperation(() => {
    return leaseRemindersStore.fetch(lease.id);
  }, [lease.id]);

  return (
    <RemoteSuspense data={leaseRemindersUiStore.sortedRemoteReminders} loadingFallback={<LinearProgress />}>
      {(reminders) => (
        <Box>
          {reminders.map((reminder) => (
            <ReminderListItem key={reminder.id} reminder={reminder} lease={lease} />
          ))}
        </Box>
      )}
    </RemoteSuspense>
  );
});

export { LeaseRemindersList };
