import React, { useContext, useCallback, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { IMoveDetails, IMoveDetailsPayload } from '../../services/ApiServiceV1';
import { TranslationContext } from '../../context/TranslationContext';
import { MoveDetailForm } from './MoveDetailForm';
import { Draggable } from 'react-beautiful-dnd';
import { DraggableGreyAccordionContainer } from '../../components/DraggableGreyAccordionContainer';
import { CustomButton } from '../../components/CustomButton';
import { observer } from 'mobx-react';
import { ServiceContext } from '../../context/ServicesContext';
import { NotificationContext } from '../../context/NotificationContext';
import { ModalContext } from '../../context/ModalContext';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { DragButtonIcon } from '../../components/DragButtonIcon';

interface IMoveDetailsFormItemProps {
  moveDetails: IMoveDetails;
  index: number;
}

const MoveDetailsFormItem: React.FunctionComponent<IMoveDetailsFormItemProps> = observer(({ moveDetails, index }) => {
  const { translate } = useContext(TranslationContext);
  const {
    rootStore: { moveDetailsStore },
  } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { setModalState, setIsModalOpen } = useContext(ModalContext);
  const [isExpanded, setIsExpanded] = useState(false);

  const onMoveDetailsUpdate = useCallback(
    async (values: IMoveDetailsPayload) => {
      try {
        await moveDetailsStore.updateMoveDetails(moveDetails.id, values);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully updated move details'),
            color: 'success',
          },
        });
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to update move details'),
            color: 'error',
          },
        });
      }
    },
    [moveDetails.id, moveDetailsStore, notificationDispatch, translate],
  );

  const onMoveDetailsDelete = useCallback(async () => {
    try {
      await moveDetailsStore.deleteMoveDetails(moveDetails.id);
      notificationDispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          message: translate('successfully deleted move details'),
          color: 'success',
        },
      });
    } catch (error) {
      notificationDispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          message: translate('failed to delete move details'),
          color: 'error',
        },
      });
    } finally {
      setIsModalOpen(false);
    }
  }, [moveDetails.id, moveDetailsStore, notificationDispatch, setIsModalOpen, translate]);

  return (
    <Draggable draggableId={moveDetails.id} index={index}>
      {(provided) => (
        <DraggableGreyAccordionContainer
          provided={provided}
          expanded={isExpanded}
          onSummaryClick={() => {
            setIsExpanded((prevValue) => !prevValue);
          }}
          summaryComponent={
            <Box display="flex" alignItems="center">
              <DragButtonIcon />
              <Typography>{moveDetails.title || translate('click to expand')}</Typography>
            </Box>
          }
        >
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <MoveDetailForm moveDetails={moveDetails} onSubmit={onMoveDetailsUpdate} />
            </Grid>
            <Grid item>
              <CustomButton
                onClick={() => {
                  setModalState(
                    true,
                    <ConfirmDialog
                      data={moveDetails.id}
                      handleClick={onMoveDetailsDelete}
                      dialogTitle={translate('are you sure you want to delete ')}
                      recordTitle={moveDetails.title}
                      content={translate("This item will be deleted immediately. You can't undo this action")}
                      primaryButtonLabel={translate('delete')}
                      cancelLabel={translate('cancel')}
                    />,
                  );
                }}
                variant="outlined"
              >
                {translate('delete')}
              </CustomButton>
            </Grid>
          </Grid>
        </DraggableGreyAccordionContainer>
      )}
    </Draggable>
  );
});

export { MoveDetailsFormItem };
