import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.text.secondary,
    },
    body: {
      fontSize: '0.875rem',
    },
    root: {
      textTransform: 'uppercase',
    },
    sizeSmall: {
      width: '1%',
    },
  }),
)(TableCell);
