import React from 'react';
import { IMoveContact } from '../../services/ApiServiceV1';
import { MoveContactListItem } from './MoveContactListItem';

interface IMoveContactsListProps {
  moveContacts: IMoveContact[];
}

const MoveContactsList: React.FunctionComponent<IMoveContactsListProps> = ({ moveContacts }) => {
  return (
    <>
      {moveContacts.map((moveContact, index) => (
        <MoveContactListItem key={moveContact.id} moveContact={moveContact} index={index} />
      ))}
    </>
  );
};

export { MoveContactsList };
