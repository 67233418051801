import React, { useCallback, useContext } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { NavigationBreadcrumbsWrapper } from '../../components/NavigationBreadcrumbs';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { observer } from 'mobx-react';
import { IMove, IMoveDetails } from '../../services/ApiServiceV1';
import { CustomButton } from '../../components/CustomButton';
import { CreateMoveDetails } from './CreateMoveDetails';
import LinearProgress from '@material-ui/core/LinearProgress';
import Collapse from '@material-ui/core/Collapse';
import { MoveDetailsFormItem } from './MoveDetailsFormItem';
import { NotificationContext } from '../../context/NotificationContext';

const MoveDetails: React.FunctionComponent = observer(() => {
  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const {
    stores: { clientStore, locationStore },
    rootStore: { moveDetailsStore, moveStore },
  } = useContext(ServiceContext);

  const { clientId, locationId, moveId } = useParams<{ clientId: string; locationId: string; moveId: string }>();

  useAsyncOperation(() => {
    return clientStore.fetchClientData(clientId);
  }, [clientId]);

  useAsyncOperation(() => {
    return locationStore.fetch(clientId, locationId);
  }, [clientId, locationId]);

  useAsyncOperation(() => {
    return moveDetailsStore.fetch(moveId);
  }, [moveId]);

  useAsyncOperation(() => {
    return moveStore.fetchMove(locationId, moveId);
  }, [moveId]);

  return (
    <RemoteSuspense
      data={RemoteData.all(
        clientStore.remoteClient,
        locationStore.remoteLocation,
        moveStore.remoteMove,
        moveDetailsStore.sortedMoveDetails,
      )}
      loadingFallback={<LinearProgress />}
    >
      {([client, location, move, moveDetails]) => (
        <>
          <NavigationBreadcrumbsWrapper
            items={[
              {
                url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                content: client.name,
              },
              {
                url: getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
                  clientId: clientId,
                  locationId: location.id,
                }),
                content: location.name,
              },
              {
                content: translate('move details'),
              },
            ]}
          />
          <MoveDetailsImpl moveDetails={moveDetails} move={move} />
        </>
      )}
    </RemoteSuspense>
  );
});

const MoveDetailsImpl: React.FunctionComponent<{ moveDetails: IMoveDetails[]; move: IMove }> = observer(
  ({ moveDetails, move }) => {
    const {
      rootStore: { moveDetailsStore },
    } = useContext(ServiceContext);
    const { notificationDispatch } = useContext(NotificationContext);
    const {
      rootStore: { moveDetailsUiStore },
    } = useContext(ServiceContext);
    const { translate } = useContext(TranslationContext);

    const onDragEnd = useCallback(
      async (result: DropResult) => {
        if (result.destination) {
          try {
            await moveDetailsStore.updateMoveDetailsPosition(move.id, {
              newPosition: result.destination?.index,
              moveDetailId: result.draggableId,
            });
            notificationDispatch({
              type: 'SET_NOTIFICATION',
              payload: {
                message: translate('successfully updated move details position'),
                color: 'success',
              },
            });
          } catch (error) {
            notificationDispatch({
              type: 'SET_NOTIFICATION',
              payload: {
                message: translate('failed to update move details position'),
                color: 'error',
              },
            });
          }
        }
      },
      [move.id, moveDetailsStore, notificationDispatch, translate],
    );

    return (
      <Paper>
        <Box py={6} px={5}>
          <Typography variant="h6">{translate('move details')}</Typography>
          <Box pb={5}>
            <Typography>
              {translate('add move information that you would like to share with your employees')}
            </Typography>
          </Box>

          <Collapse in={!moveDetailsUiStore.shouldShowCreateForm}>
            <CustomButton
              color="secondary"
              variant="contained"
              onClick={() => moveDetailsUiStore.toggleShouldShowCreateForm()}
            >
              {translate('add move details')}
            </CustomButton>
          </Collapse>

          <Box pt={2}>
            <CreateMoveDetails move={move} />
          </Box>
          <Box py={3}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="moveDetailsForms">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} style={{ cursor: 'grab' }}>
                    {moveDetails.map((moveDetailsItem, index) => (
                      <MoveDetailsFormItem moveDetails={moveDetailsItem} key={index} index={index} />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>
      </Paper>
    );
  },
);

export { MoveDetails };
