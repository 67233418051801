import React from 'react';
import { withStyles, createStyles, styled } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import ChipInput from 'material-ui-chip-input';

export const fieldPadding = '0.625rem 0.875rem';

const fieldStyles = createStyles({
  input: {
    padding: fieldPadding,
  },
});

export const StyledOutlinedSelect = styled(Select)({
  select: {
    padding: fieldPadding,
  },
});
export const StyledOutlinedInput = withStyles(fieldStyles)(OutlinedInput);

interface IChipInputProps {
  // This is just a subset of props from https://www.npmjs.com/package/material-ui-chip-input
  defaultValue: string[];
  onChange: (chips: string[]) => any;
  formHelperText?: string;
}

const FormChipInput: React.FunctionComponent<IChipInputProps> = ({ defaultValue, onChange, formHelperText }) => (
  <FormControl fullWidth={true}>
    <ChipInput defaultValue={defaultValue} onChange={onChange} />
    {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
  </FormControl>
);

export default FormChipInput;
