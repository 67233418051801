import { action, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';
import { IApiServiceV1, ILocation, IMove } from '../services/ApiServiceV1';

class LocationStore {
  @observable
  remoteLocation: RemoteData<ILocation> = RemoteData.NOT_ASKED;

  constructor(private apiService: IApiServiceV1) {}

  @action
  async fetch(clientId: string, locationId: string, forceFetch = false) {
    if (
      RemoteData.isReady(this.remoteLocation) &&
      this.remoteLocation.clientId === clientId &&
      this.remoteLocation.id === locationId &&
      !forceFetch
    ) {
      return;
    }

    this.remoteLocation = RemoteData.LOADING;

    try {
      const location = await this.apiService.fetchLocation(clientId, locationId);
      runInAction(() => {
        this.remoteLocation = location;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteLocation = RemoteData.fail();
      });
    }
  }

  @action
  updateLocation(updatedLocation: ILocation) {
    this.remoteLocation = updatedLocation;
  }

  @action
  updateMove(move: IMove) {
    if (RemoteData.isReady(this.remoteLocation)) {
      this.remoteLocation.services.move = move;
    }
  }
}

export { LocationStore };
