import React from 'react';
import { FloorPlannerApp } from '@fmg-packages/floor-planner';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ClientUserWrapper } from '../../components/ClientUserWrapper';
import { NavigationBreadcrumbs } from '../../components/NavigationBreadcrumbs';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { observer } from 'mobx-react';

const FloorPlanner: React.FC<{}> = observer(() => {
  const { moveId, clientId, locationId } = useParams<{ moveId: string; locationId: string; clientId: string }>();
  const { remoteAccessToken } = useContext(AuthenticationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const {
    stores: { locationStore },
  } = useContext(ServiceContext);
  const { translate } = useContext(TranslationContext);

  useAsyncOperation(() => {
    return locationStore.fetch(clientId, locationId);
  }, [clientId, locationId]);

  return (
    <ClientUserWrapper
      render={(client) => (
        <>
          <RemoteSuspense data={locationStore.remoteLocation}>
            {(location) => (
              <NavigationBreadcrumbs
                items={[
                  {
                    content: client.name,
                    url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                  },
                  {
                    content: location.name,
                    url: getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
                      clientId: client.id,
                      locationId: location.id,
                    }),
                  },
                  {
                    content: translate('floorplan'),
                  },
                ]}
              />
            )}
          </RemoteSuspense>

          <RemoteSuspense data={remoteAccessToken} loadingFallback={<LinearProgress />}>
            {(accessToken: string) => <FloorPlannerApp moveId={moveId} accessToken={accessToken} />}
          </RemoteSuspense>
        </>
      )}
    />
  );
});

export default FloorPlanner;
