import { action, computed, observable, runInAction } from 'mobx';
import RemoteData from 'ts-remote-data';
import {
  IApiServiceV1,
  IMoveDetails,
  IMoveDetailsPayload,
  IMoveDetailsPositionPayload,
} from '../services/ApiServiceV1';
import { compareValues } from '../utils/sort';
import { RootStore } from './RootStore';

class MoveDetailsStore {
  @observable
  remoteMoveDetails: RemoteData<IMoveDetails[]> = RemoteData.NOT_ASKED;

  constructor(private apiService: IApiServiceV1, private rootStore: RootStore) {}

  @action
  async fetch(moveId: string, forceFetch = false) {
    if (
      RemoteData.isReady(this.remoteMoveDetails) &&
      RemoteData.isReady(this.rootStore.moveStore.remoteMove) &&
      this.rootStore.moveStore.remoteMove.id === moveId &&
      !forceFetch
    ) {
      return;
    }
    this.remoteMoveDetails = RemoteData.LOADING;

    try {
      const moveDetails = await this.apiService.fetchMoveDetails(moveId);

      runInAction(() => {
        this.remoteMoveDetails = moveDetails;
      });
    } catch (error) {
      runInAction(() => {
        this.remoteMoveDetails = RemoteData.fail();
      });
    }
  }

  @computed
  get sortedMoveDetails(): RemoteData<IMoveDetails[]> {
    if (RemoteData.isReady(this.remoteMoveDetails)) {
      const moveDetailsCopy = this.remoteMoveDetails.slice();
      return moveDetailsCopy.sort(compareValues(['position'], 'asc'));
    } else {
      return this.remoteMoveDetails;
    }
  }

  @action
  async updateMoveDetails(moveDetailsId: string, moveDetailsPayload: IMoveDetailsPayload) {
    const updatedMoveDetails = await this.apiService.updateMoveDetails(moveDetailsId, moveDetailsPayload);

    runInAction(() => {
      if (RemoteData.isReady(this.remoteMoveDetails)) {
        this.remoteMoveDetails = this.remoteMoveDetails.map((moveDetails) => {
          if (moveDetails.id === updatedMoveDetails.id) {
            return updatedMoveDetails;
          }
          return moveDetails;
        });
      }
    });
  }

  @action
  async addNewMoveDetails(moveId: string, moveDetailsPayload: IMoveDetailsPayload) {
    const newMoveDetails = await this.apiService.addMoveDetails(moveId, moveDetailsPayload);

    runInAction(() => {
      if (RemoteData.isReady(this.remoteMoveDetails)) {
        this.remoteMoveDetails.push(newMoveDetails);
      }
    });
  }

  @action
  async deleteMoveDetails(moveDetailsId: string) {
    await this.apiService.deleteMoveDetails(moveDetailsId);

    runInAction(() => {
      if (RemoteData.isReady(this.remoteMoveDetails)) {
        this.remoteMoveDetails = this.remoteMoveDetails.filter((moveDetails) => moveDetails.id !== moveDetailsId);
      }
    });
  }

  @action
  async updateMoveDetailsPosition(moveId: string, payload: IMoveDetailsPositionPayload) {
    this.remoteMoveDetails = RemoteData.LOADING;
    const updatedPositions = await this.apiService.updateMoveDetailsPosition(moveId, payload);

    runInAction(() => {
      this.remoteMoveDetails = updatedPositions;
    });
  }
}

export { MoveDetailsStore };
