import { LinearProgress } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { ClientUserWrapper } from '../../components/ClientUserWrapper';
import { HeaderContainer } from '../../components/containers/HeaderContainer';
import { SectionContainer } from '../../components/containers/SectionContainer';
import { NavigationBreadcrumbs } from '../../components/NavigationBreadcrumbs';
import { TemplateForm } from '../../components/TemplateForm';
import { Header } from '../../components/typography/Header';
import { NotificationContext } from '../../context/NotificationContext';
import { RouteMapContext } from '../../context/RouteMapContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { useFetchRemoteData } from '../../hooks/useFetchRemoteData';
import { IMove, ITemplatePayload } from '../../services/ApiServiceV1';

const EditPage: React.FunctionComponent = observer(() => {
  const { movePageId, clientId, locationId } = useParams<{
    clientId: string;
    locationId: string;
    movePageId: string;
  }>();
  const {
    apiServiceV1,
    stores: { locationStore },
  } = useContext(ServiceContext);
  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const { notificationDispatch } = useContext(NotificationContext);

  const fetchMovePage = useCallback(() => {
    return apiServiceV1.fetchMovePage(movePageId);
  }, [apiServiceV1, movePageId]);
  const { remoteData: remoteMovePage, setRemoteData: setRemoteMovePage } = useFetchRemoteData({
    fetchData: fetchMovePage,
  });

  useAsyncOperation(() => {
    return locationStore.fetch(clientId, locationId);
  }, [clientId, locationId]);

  const onSubmit = useCallback(
    async (values: ITemplatePayload) => {
      const temporaryMovePage = remoteMovePage;
      setRemoteMovePage(RemoteData.LOADING);
      try {
        const movePage = await apiServiceV1.updateMovePage(movePageId, {
          published: true,
          templateId: RemoteData.asReady(remoteMovePage)?.templateId as string,
          translations: values.translations,
        });
        setRemoteMovePage(movePage);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'success',
            message: translate('successfully saved a page'),
          },
        });
      } catch {
        setRemoteMovePage(temporaryMovePage);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            color: 'error',
            message: translate('failed to update move page'),
          },
        });
      }
    },
    [apiServiceV1, movePageId, notificationDispatch, remoteMovePage, setRemoteMovePage, translate],
  );

  return (
    <ClientUserWrapper
      render={(client) => (
        <>
          <RemoteSuspense data={locationStore.remoteLocation} loadingFallback={<LinearProgress />}>
            {(location) => (
              <NavigationBreadcrumbs
                items={[
                  {
                    content: client.name,
                    url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
                  },
                  {
                    content: location.name,
                    url: getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', {
                      clientId: client.id,
                      locationId: location.id,
                    }),
                  },
                  {
                    content: translate('pages'),
                    url: getPathWithProps<{ clientId: string; locationId: string; moveId: string }>('MOVE_PAGES', {
                      clientId: client.id,
                      locationId: location.id,
                      moveId: (location.services.move as IMove).id,
                    }),
                  },
                  {
                    content: translate('edit move page'),
                  },
                ]}
              />
            )}
          </RemoteSuspense>

          <HeaderContainer>
            <Header>{translate('edit move page')}</Header>
          </HeaderContainer>
          <SectionContainer>
            <RemoteSuspense data={remoteMovePage} loadingFallback={<LinearProgress />}>
              {(movePage) => (
                <TemplateForm onSubmit={onSubmit} template={movePage} saveButtonLabel={translate('save')} />
              )}
            </RemoteSuspense>
          </SectionContainer>
        </>
      )}
    />
  );
});

export { EditPage };
