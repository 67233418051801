import { action, observable } from 'mobx';
import { RootStore } from './RootStore';

class MoveDetailsUiStore {
  @observable
  shouldShowCreateForm = false;

  constructor(private rootStore: RootStore) {}

  @action
  toggleShouldShowCreateForm() {
    this.shouldShowCreateForm = !this.shouldShowCreateForm;
  }
}

export { MoveDetailsUiStore };
