import React from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import RemoteData from 'ts-remote-data';
import { MoveServiceForm } from '../../components/MoveServiceForm';
import { NotificationContext } from '../../context/NotificationContext';
import { ServiceContext } from '../../context/ServicesContext';
import { TranslationContext } from '../../context/TranslationContext';
import { ILocation, IMovePayload } from '../../services/ApiServiceV1';
import { SectionContainer } from '../../components/containers/SectionContainer';
import AdminContentHeader from '../../components/AdminContentHeader';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { observer } from 'mobx-react';
import { RouteMapContext } from '../../context/RouteMapContext';
import { NavigationBreadcrumbsWrapper } from '../../components/NavigationBreadcrumbs';

const MoveLocationService: React.FunctionComponent = observer(() => {
  const { clientId, locationId } = useParams<{ clientId: string; locationId: string }>();
  const {
    apiServiceV1,
    stores: { locationStore, clientStore },
  } = useContext(ServiceContext);
  const { notificationDispatch } = useContext(NotificationContext);
  const { translate } = useContext(TranslationContext);
  const { getPathWithProps } = useContext(RouteMapContext);
  const history = useHistory();

  useAsyncOperation(() => clientStore.fetchClientData(clientId), [clientId]);

  useAsyncOperation(() => {
    return locationStore.fetch(clientId, locationId);
  }, [clientId, locationId]);

  const onSubmit = useCallback(
    async (values: IMovePayload) => {
      try {
        const location = RemoteData.asReady(locationStore.remoteLocation);
        const move = await apiServiceV1.addMove((location as ILocation).id, values);
        locationStore.updateMove(move);
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('successfully saved a move'),
            color: 'success',
          },
        });
        history.push(
          getPathWithProps<{ clientId: string; locationId: string }>('CLIENT_LOCATION', { clientId, locationId }),
        );
      } catch (error) {
        notificationDispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            message: translate('failed to save a move'),
            color: 'error',
          },
        });
      }
    },
    [apiServiceV1, clientId, getPathWithProps, history, locationId, locationStore, notificationDispatch, translate],
  );
  return (
    <RemoteSuspense data={clientStore.remoteClient}>
      {(client) => (
        <>
          <NavigationBreadcrumbsWrapper
            items={[
              {
                content: client.name,
                url: getPathWithProps<{ clientId: string }>('CLIENT_DASHBOARD', { clientId: client.id }),
              },
              {
                content: translate('create location'),
              },
            ]}
          />
          <AdminContentHeader headerText={translate('add move location service')} />
          <SectionContainer>
            <MoveServiceForm onSubmit={onSubmit} />
          </SectionContainer>
        </>
      )}
    </RemoteSuspense>
  );
});

export { MoveLocationService };
