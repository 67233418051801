import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import LanguageIcon from '@material-ui/icons/Language';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TranslationContext } from '../../context/TranslationContext';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface ILanguageSelectProps {
  isDrawerOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  whiteColor: {
    color: theme.palette.common.white,
  },
  buttonIconRoot: {
    paddingLeft: theme.spacing(2),
  },
  language: {
    marginLeft: theme.spacing(1),
  },
}));

const LanguageSelect: React.FunctionComponent<ILanguageSelectProps> = ({ isDrawerOpen }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);
  const isOpen = !!anchorEl;
  const { buttonIconRoot, whiteColor, language } = useStyles(theme);
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { translate, locale } = useContext(TranslationContext);
  const { i18n } = useTranslation();

  // We want to migrate existing users to English language by default as there is only one option available
  // If there ever happen to be more then we need to remove this line and let users pick
  // Part of task 1568
  if (i18n.language !== 'en') {
    i18n.changeLanguage('en');
  }

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} classes={{ root: buttonIconRoot }}>
        <LanguageIcon classes={{ root: whiteColor }} style={{ marginRight: '.5rem' }} />
        {(isDrawerOpen || isXs) && (
          <Typography variant="subtitle2" classes={{ root: whiteColor }}>
            {translate('language')}
          </Typography>
        )}
      </IconButton>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose} keepMounted>
        <MenuItem
          onClick={() => {
            handleClose();
            i18n.changeLanguage('en');
          }}
        >
          {locale === 'en' && <CheckCircleIcon fontSize="small" color="secondary" />}
          <Typography className={language}>{translate('english')}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default LanguageSelect;
