import React from 'react';
import { RenderRoutes } from '../../components/RenderRoutes';
import { useContext } from 'react';
import { RouteMapContext } from '../../context/RouteMapContext';

function App() {
  const { routes } = useContext(RouteMapContext);
  return <RenderRoutes routes={routes} />;
}

export default App;
