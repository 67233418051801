import { IDocument, IDocumentsResponse, ILibrary } from '../services/ApiServiceV1';
import { isNil } from 'lodash';

export interface ILibrarySegment {
  documents: {
    segments: string[];
    document: IDocument;
  }[];
  library: ILibrary;
}

export interface ISegmentDataNode {
  parentId: string | null;
  id: string;
  document?: IDocument;
}

export interface IFileTreeNode {
  parentId: string | null;
  id: string;
  document?: IDocument;
  children?: IFileTreeNode[];
}

export interface IFileTree {
  files: null | IFileTreeNode;
  title: string;
}

interface ITreeNode {
  parentId: string | null;
  id: string;
  children?: ITreeNode[];
}

export interface ILibraryNode {
  documents: ISegmentDataNode[][];
  library: ILibrary;
}

export function _getLibrarySegments(libraries: ILibrary[]) {
  return libraries.map<ILibrarySegment>((library) => {
    const documents = library.documents.map((document) => {
      const filePathRegex = /\/[A-Z0-9-]+(.+)/gm;
      const result = filePathRegex.exec(document.documentPath);
      if (result && result.length > 1) {
        return {
          segments: result[1]
            .split('/')
            .filter((segment) => segment.trim())
            .map((segment) => decodeURIComponent(segment)),
          document,
        };
      } else {
        return {
          segments: [],
          document,
        };
      }
    });

    return {
      library,
      documents,
    };
  });
}

export function _convertDocumentsToNodes(librarySegments: ILibrarySegment[]): ILibraryNode[] {
  return librarySegments.map((librarySegment) => {
    const documents = librarySegment.documents.map((documentData) => {
      const documentPathComponents = documentData.segments.map<ISegmentDataNode>((segment, index) => {
        if (index === 0) {
          return {
            id: segment,
            parentId: null,
          };
        }
        const isLastSegment = index === documentData.segments.length - 1;
        const segmentNodeData: ISegmentDataNode = {
          id: segment,
          parentId: documentData.segments[index - 1],
        };
        // if it's the final segment, it's a file, attach a document to the node
        if (isLastSegment) {
          segmentNodeData.document = documentData.document;
        }
        return segmentNodeData;
      });
      return documentPathComponents;
    });

    return {
      documents,
      library: librarySegment.library,
    };
  });
}

export function _flattenLibraryDocumentSegments(librarySegments: ILibraryNode[]) {
  return librarySegments.map<{ library: ILibrary; segments: ISegmentDataNode[] }>((documentLibrary) => {
    const documentSegments = documentLibrary.documents.reduce((acc, pathSegments) => {
      pathSegments.forEach((pathSegment) => {
        const foundElement = acc.find(
          (existingSegment) =>
            existingSegment.id === pathSegment.id && existingSegment.parentId === pathSegment.parentId,
        );
        if (!foundElement) {
          acc.push(pathSegment);
        }
      });
      return acc;
    }, []);

    return {
      library: documentLibrary.library,
      segments: documentSegments,
    };
  });
}

export function _createLibraryTree(data: ITreeNode[]): IFileTreeNode {
  const idMapping = data.reduce((acc, el, i) => {
    acc[el.id] = i;
    return acc;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as any);

  let root;

  for (let i = 0; i < data.length; i++) {
    const dataElement = data[i];

    if (dataElement.parentId === null) {
      root = dataElement;
      continue;
    }

    const parentEl = data[idMapping[dataElement.parentId]];
    parentEl.children = [...(parentEl.children || []), dataElement];
  }

  return root as IFileTreeNode;
}

export function convertLibraryResponse(response: ILibrary[]): IDocumentsResponse {
  // create document segments
  const librarySegments = _getLibrarySegments(response);
  // create parent-child nodes
  const librarySegmentNodes = _convertDocumentsToNodes(librarySegments);
  // flatten nodes
  const librarySegmentsFlattened = _flattenLibraryDocumentSegments(librarySegmentNodes);

  // create filetree
  const fileTree = librarySegmentsFlattened.map((librarySegments) => {
    const files = _createLibraryTree(librarySegments.segments);
    return {
      files: files !== undefined ? files : null,
      title: librarySegments.library.title,
    };
  });

  return {
    filetree: fileTree,
    baseUrl: getSharepointBaseUrl(response),
  };
}

function getSharepointBaseUrl(response: ILibrary[]) {
  const libraryWithDocuments = response.find((library) =>
    library.documents.find((document) => !isNil(document.documentPath)),
  );

  if (!libraryWithDocuments) {
    return null;
  }

  const sharepointBaseUrl = getBaseSharepointUrl(libraryWithDocuments.documents[0].documentPath);
  return sharepointBaseUrl ? sharepointBaseUrl : null;
}

export function getBaseSharepointUrl(documentPath: string) {
  const baseUrlRegex = /.+sites\/[^/]+\//gm;
  const result = baseUrlRegex.exec(documentPath);
  if (result !== null) {
    return result[0];
  }
}
