import { observer } from 'mobx-react';
import React from 'react';
import { RemoteSuspense, useAsyncOperation } from 'ts-remote-data-react';
import { UserDomainStore } from '../../stores/UserDomainStore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import { useContext } from 'react';
import { ServiceContext } from '../../context/ServicesContext';

interface IUserAvatarProps {
  userDomainStore: UserDomainStore;
}

const TeamMemberAvatar: React.FunctionComponent<IUserAvatarProps> = observer(({ userDomainStore }) => {
  const { apiServiceV1 } = useContext(ServiceContext);
  useAsyncOperation(() => {
    return userDomainStore.fetchUserImage(apiServiceV1);
  }, [userDomainStore.user.user]);
  return (
    <RemoteSuspense
      data={userDomainStore.remotePhoto}
      loadingFallback={<CircularProgress />}
      failureFallback={<Avatar />}
    >
      {(photo) => {
        return <Avatar src={photo} />;
      }}
    </RemoteSuspense>
  );
});

export { TeamMemberAvatar };
